// sass-lint:disable-all

// Browser Hacks

// more browser hacks:

// https://browserstrangeness.bitbucket.io/css_hacks.html
// http://browserhacks.com/



//  styles only for IE
// ----------------------------------------------------------
// http://keithclark.co.uk/articles/moving-ie-specific-css-into-media-blocks/media-tests/

// '8-' - ie8 & below, 8+ - ie8 & above, 10+ - ie10 & above

@mixin ie($ver:'8+') {

  @if $ver == '8+' {
    /* autoprefixer: off */
    @media screen\0 {
      @content;
    }

  } @else if $ver == '8-' {
    /* autoprefixer: off */
    @media \0screen\,screen\9 {
      @content;
    }

  } @else if $ver == '10+' {
    /* autoprefixer: off */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      @content;
    }

  } @else if $ver == '11+' {
     /* autoprefixer: off */
    _:-ms-fullscreen, :root & {
      @content;
    }
  }

}


@mixin not-ie {
  /* autoprefixer: off */
  @supports (not (-ms-ime-align:auto)) {
      @content;
  }
}


//  styles only for SAFARI
// ----------------------------------------------------------
// https://jeffclayton.wordpress.com/2015/04/28/css-hacks-for-safari-6-1-7-and-8-not-chrome/
// https://stackoverflow.com/questions/16348489/is-there-a-css-hack-for-safari-only-not-chrome

@mixin safari() {
    /* autoprefixer: off */

    // Safari 7.1+
    // _::-webkit-full-page-media, _:future {
    //     @content;
    // }

    //  @media screen and (-webkit-min-device-pixel-ratio:0) { xyz {} } /* Safari */

    // Safari 6.1-10.0

    // scss-lint:disable ImportantRule
    @media screen and (min-color-index:0) and (-webkit-min-device-pixel-ratio:0) { @media and {
      @content;
    }}

    // Safari 10.1+
    // @media not all and (min-resolution:.001dpcm)
    // { @supports (-webkit-appearance:none) {
    //     @content;
    // }}

    // scss-lint:disable ImportantRule
    @media not all and (min-resolution:.001dpcm) { @media and {
      @content;
    }}
}



// Firefox

@mixin firefox() {
   /* autoprefixer: off */
  @-moz-document url-prefix() {
    @content;
  }
}

@mixin not-firefox() {
  @at-root {
     /* autoprefixer: off */
    _::selection, &:not([attr*='']) {
      @content;
    }
  }
}


// Chrome

@mixin chrome() {
  body:nth-of-type(1) & {
    @content;
  }
}

// Only Desktop Browsers (no touch)

@mixin desktop-browsers($extend:false) {
  @media not all and (hover: none) {
    @content;
  }
  @if $extend {
    @include ie {
      @content;
    }
    @include firefox {
      @content;
    }
  }
}


// only touch browsers (no hover)

@mixin touch-devices {
  /* autoprefixer: off */
  @media (hover: none), (pointer: coarse), (pointer: none) {
    @content;
  }
}