// sass-lint:disable-all


// Visual: DOTTED BORDER
// by Pavel G
// ----------------------------------------------------------


@mixin dotted-border($color:black, $pos:bottom, $width:1, $spacing:7px, $height:1px) {
  $width: $width * 15%;
  background-position: $pos;
  background-repeat: repeat-x;
  background-size: $spacing $height;
  background-image: linear-gradient(to right, $color $width, rgba($color, 0) 0%);
}

@mixin dotted-border-spacing($spacing:7px, $height:1px) {
  background-size: $spacing $height;
}

@mixin undo-dotted-border() {
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  background-image: none;
}


// MARCHING ANTS
// https://codepen.io/pixelass/pen/cdrwI

  // @include marching-ants-init(20px, 2px, 1s, 1);
  // @include marching-ants-color(#fff, #000);
  // @include marching-ants-animation(20px, 1);


@mixin marching-ants-init($ant-size,$ant-width,$speed,$id){
    //padding: 5px + $ant-width;

    background-size:
      $ant-size $ant-width,
      $ant-size $ant-width,
      $ant-width $ant-size,
      $ant-width $ant-size;
    background-position:
      0 0,
      0 100%,
      0 0,
      100% 0;
    background-repeat:
      repeat-x,
      repeat-x,
      repeat-y,
      repeat-y;
    animation: marching-ants-#{$id} $speed;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-play-state: paused;
  &:hover, &:focus, &.marching {
    animation-play-state: running;
  }
  &.reverse {
    animation-direction: reverse;
  }
};

@mixin marching-ants-color($a, $b){
    background-image:
      linear-gradient(to right, $a 50%, $b 50%),
      linear-gradient(to right, $a 50%, $b 50%),
      linear-gradient(to bottom, $a 50%, $b 50%),
      linear-gradient(to bottom, $a 50%, $b 50%);
};

@mixin marching-ants-animation($ant-size, $id){
  @keyframes marching-ants-#{$id} {
    0% {
      background-position:
        0 0,
        0 100%,
        0 0,
        100% 0;
    }
    100% {
      background-position:
        2*$ant-size 0,
        -2*$ant-size 100%,
        0 -2*$ant-size,
        100% 2*$ant-size;
    }
  }
}















