// sass-lint:disable-all

// use: #{$a-tags} {}, #{$a-tags-hover} {}



$a-tags: 'a, a:active, a:hover, a:visited';
$a-tags-hover: 'a:active, a:hover';
$a-tags-all: 'a, a:active, a:hover, a:visited';

$this-a-tags: '&, &:active, &:hover, &:visited';
$this-a-tags-hover: '&:active, &:hover';
$this-a-tags-all: '&, &:active, &:hover, &:visited';

$button: '.button, button, input[type=button], input[type=submit], [role=button]'; //, a[class*=button]' !default;
$submit-button: '.button.submit, input[type=button].submit, button.submit, button[type=submit], input[type=submit]' !default;
$close-button: 'a.close, input[type=button].close, button.close' !default;
$button-states: '&, &:active, &:hover, &:focus';

$checkbox: 'input[type=checkbox]:not(#burger-control):not(.collapse-control):not(.multiselect-checkbox)' !default;
$radio: 'input[type=radio]' !default;

$disabled: '.disabled &, .inactive &, &:disabled, &.disabled, &.inactive' !default;
$pseudos: '&:before,&:after';

@mixin popup-width{
  width: 500px;
  height: 300px;
  max-width: 96%;
}
$mail-link: 'a[href*=mailto]';

@mixin all {
  *,*:before,*:after{
    @content;
  }
}