// PAGER

@mixin pager-compact{

  button, & > li {
    @include s(36);
  }

  li:not(.pager-item):not(.pager-current),
  button {
    
    background-size: 10px auto;

    &.pager-next,
    &.next {
      background-position: left 9px top 50%;
    }

    // &.pager-last,
    &.pager-previous,
    &.prev {
      background-position: left 12px top 50%;
    }
  }

  p {
    .month,.year {
      line-height: 13px;
    }
    .month {
      border-left: solid 2px $light-grey-2;
    }
    .j-month{
      font-size: rem(13);
    }
    .month {
      @include p(l 5);
    }
    .year{
      @include p(r 5);
    }
  }

}

.pager {

  display: inline-block;
  white-space: nowrap;
  @include flex-spread;
  user-select: none;
  
  .pager-ellipsis {
    display: none !important;
  }

  li {
    font-size: 15px;
    font-weight: bold;
  }

  button, & > li {
    @include make-button;
    @include outline-button($charcoal, $bg:transparent);
    width: 43px;

    a {
      color: $greyish;
      text-decoration: none;
      display: block;
      @include size;
    }
    
    &.pager-item {
      background-color: $light-grey-3;
      border: 0;
    }

    &.pager-current {
      color: $off-white;
      background-color: $charcoal;
      
    }

    &.disabled,
    &:disabled {
      border-color: $light-grey-2;
      background-color: transparent;

      a {
        pointer-events: none;
        color: $light-grey-2;
      }pager-previous

      &:hover {
        opacity: 1;
      }
    }    
  }

  li:not(.pager-item):not(.pager-current),
  button {
    
    @include text-hide;
    background-size: 12px auto;
    background-position: center;

    &.pager-next,
    &.next {
      @extend %icn_ar_left-chrk;
      background-position: left 12px top 50%;

      &.disabled,
      &:disabled {
        @extend %icn_ar_left-lghtgr2;
	cursor:initial;
	&:hover {
		opacity: 1;
	}
      }
    }

    // &.pager-last,
    &.pager-previous,
    &.prev {
      @extend %icn_ar_right-chrk;
      background-position: left 15px top 50%;

      &.disabled,
      &:disabled {
        @extend %icn_ar_right-lghtgr2;
	cursor:initial;
	&:hover {
		opacity: 1;
	}
      }
    }

  }

  // text in the middle

  p {
    display: inline-block;
    text-align: center;
    font-weight: 600;
    white-space: nowrap;
    margin: 0;
    vertical-align: middle;
    @include m(x 6);
    font-size: 0;
    flex-grow: 10;
    @include flex-align;

    .month,.year {
      display: inline-block;
      line-height: 16px;
      font-size: rem(16);
    }

    .month {
      @include p(l 8);
      border-left: 2px solid $charcoal;
    }
    .year{
      @include p(r 8);
    }

    .j-month {
      display: block;
      font-size: rem(15);
      color: black(0.7);
      font-weight: 400;
      letter-spacing: -0.2px;
    }
  }

  // mobile

  @include media("<=mobile"){
    @include pager-compact;
  }

}
