.page-error {

  .page {
    background: none;
  }

  #page-title {
    @include desktop{
      display: none;
    }
  }

  .main-nav {
    @include desktop{
      display: none;
    }
  }


  main {

    @include flex-align;
    text-align: center;
    color: white;
    text-shadow: 0 0 1px black(0.5);

    background-size: cover;
    background-image: $default-bg;

    background-position: left center;
    @include media("<=1180px"){
      background-position: left 18% center;
    }

    @include bg-overlay(0.45);

    .wrap {
      min-height: 0;
    }

    h2 {
      font-size: rem(50);
      font-weight: 600;
    }

    .subtitle {
      display: block;
      font-size: rem(16);
      @include m(t 13);
      letter-spacing: normal;
      font-weight: 400;

      @include mobile{
        font-size: rem(22);
        max-width: 310px;
      }

    }

    .button {
      width: 100%;
      max-width: 230px;
      @include m(t 20);
    }

  }

}


