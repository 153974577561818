// sass-lint:disable-all


// BOX DECORATIONS
// ----------------------------------------------------------





// box with inner triangle arrow up, all with inner shadow

@mixin box-trngl-up-shad($use-arrow-element:false) {

  $loc-left: 50%;

  @if not $use-arrow-element {
    $scope: '&'
  } @else {
    $scope: '.arrow'
  }

  display: block;
  width: auto;
  height: auto;
  position: relative;
  overflow: visible;
  box-shadow: inset 0 9px 8px -9px rgba(0, 0, 0, 0.35);
  //margin-top: 15px;
  background-color: white;
  z-index: 1;

  #{$scope} {

    position: relative;

    &:before,
    &:after {
      content: "";
      position: absolute;
      transform: rotate(45deg);
    }

    &:before {
      width: 24px;
      height: 24px;
      top: -13px;
      left: calc(#{$loc-left} - 10px - 2px);
      z-index:998;
      z-index: 50;
      background: rgba(255,255,255,1);
      -webkit-filter: blur(2px);
      filter: blur(2px);
    }

    &:after {
      width: 20px;
      height: 20px;
      top: -10px;
      left: calc(#{$loc-left} - 10px);
      z-index: 51;
      background: transparent;
      box-shadow: inset 3px 3px 6px -3px rgba(0, 0, 0, 0.3);
    }

    & > * {
      position: relative;
      z-index: 52;
    }


  }

}


