// sass-lint:disable-all

//    INCLUDE MEDIA
//    v1.4.9
//    http://include-media.com
//
//    Authors: Eduardo Boucas (@eduardoboucas)
//    Hugo Giraudel (@hugogiraudel)

// simplified & shortened by Pavel G


$breakpoints: (
  'phone': 320px,
  'tablet': 768px,
  'desktop': 1024px
) !default;

$media-expressions: (
  'screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 3), (min-resolution: 350dpi), (min-resolution: 3dppx)'
) !default;

$unit-intervals: (
  'px': 1,
  'em': 0.01,
  'rem': 0.1,
  '': 0
) !default;


/// Log a message either with `@error` if supported
/// else with `@warn`, using `feature-exists('at-error')`
/// to detect support.
@function im-log($message) {
  @if feature-exists('at-error') {
    @error $message;
  } @else {
    @warn $message;
    $_: noop();
  }
  @return $message;
}

/// Wrapper mixin for the log function so it can be used with a more friendly
/// API than `@if im-log('..') {}` or `$_: im-log('..')`. Basically, use the function
/// within functions because it is not possible to include a mixin in a function
/// and use the mixin everywhere else because it's much more elegant.
@mixin log($message) {
  @if im-log($message) {}
}

/// Function with no `@return` called next to `@warn` in Sass 3.3
/// to trigger a compiling error and stop the process.
@function noop() {}


/// Get operator of an expression
/// @param {String} $expression - Expression to extract operator from
/// @return {String} - Any of `>=`, `>`, `<=`, `<`, `≥`, `≤`
@function get-expression-operator($expression) {
  @each $operator in ('>=', '>', '<=', '<', '≥', '≤') {
    @if str-index($expression, $operator) {
      @return $operator;
    }
  }

  // It is not possible to include a mixin inside a function, so we have to
  // rely on the `im-log(..)` function rather than the `log(..)` mixin. Because
  // functions cannot be called anywhere in Sass, we need to hack the call in
  // a dummy variable, such as `$_`. If anybody ever raise a scoping issue with
  // Sass 3.3, change this line in `@if im-log(..) {}` instead.
  $_: im-log('No operator found in `#{$expression}`.');
}


/// Get dimension of an expression, based on a found operator
/// @param {String} $expression - Expression to extract dimension from
/// @param {String} $operator - Operator from `$expression`
/// @return {String} - `width` or `height` (or potentially anything else)
@function get-expression-dimension($expression, $operator) {
  $operator-index: str-index($expression, $operator);
  $parsed-dimension: str-slice($expression, 0, $operator-index - 1);
  $dimension: 'width';

  @if str-length($parsed-dimension) > 0 {
    $dimension: $parsed-dimension;
  }

  @return $dimension;
}

/// Get dimension prefix based on an operator
/// @param {String} $operator - Operator
/// @return {String} - `min` or `max`
@function get-expression-prefix($operator) {
  @return if(index(('<', '<=', '≤'), $operator), 'max', 'min');
}

/// Get value of an expression, based on a found operator
/// @param {String} $expression - Expression to extract value from
/// @param {String} $operator - Operator from `$expression`
/// @return {Number} - A numeric value
@function get-expression-value($expression, $operator) {
  $operator-index: str-index($expression, $operator);
  $value: str-slice($expression, $operator-index + str-length($operator));

  @if map-has-key($breakpoints, $value) {
    $value: map-get($breakpoints, $value);
  } @else {
    $value: to-number($value);
  }

  $interval: map-get($unit-intervals, unit($value));

  @if not $interval {
    // It is not possible to include a mixin inside a function, so we have to
    // rely on the `im-log(..)` function rather than the `log(..)` mixin. Because
    // functions cannot be called anywhere in Sass, we need to hack the call in
    // a dummy variable, such as `$_`. If anybody ever raise a scoping issue with
    // Sass 3.3, change this line in `@if im-log(..) {}` instead.
    $_: im-log('Unknown unit `#{unit($value)}`.');
  }

  @if $operator == '>' {
    $value: $value + $interval;
  } @else if $operator == '<' {
    $value: $value - $interval;
  }

  @return $value;
}


/// Parse an expression to return a valid media-query expression
/// @param {String} $expression - Expression to parse
/// @return {String} - Valid media query
@function parse-expression($expression) {
  // If it is part of $media-expressions, it has no operator
  // then there is no need to go any further, just return the value
  @if map-has-key($media-expressions, $expression) {
    @return map-get($media-expressions, $expression);
  }

  $operator: get-expression-operator($expression);
  $dimension: get-expression-dimension($expression, $operator);
  $prefix: get-expression-prefix($operator);
  $value: get-expression-value($expression, $operator);

  @return '(#{$prefix}-#{$dimension}: #{$value})';
}



/// include-media public exposed API
/// Generates a media query based on a list of conditions
/// @param {Arglist}   $conditions  - Media query conditions

/// @example scss - With a single set breakpoint
///  @include media('>phone') { }
///
/// @example scss - With two set breakpoints
///  @include media('>phone', '<=tablet') { }
///
/// @example scss - With custom values
///  @include media('>=358px', '<850px') { }
///
/// @example scss - With set breakpoints with custom values
///  @include media('>desktop', '<=1350px') { }
///
/// @example scss - With a static expression
///  @include media('retina2x') { }
///
/// @example scss - Mixing everything
///  @include media('>=350px', '<tablet', 'retina3x') { }
///
@mixin media($conditions...) {
  @if (length($conditions) == 0) {
    @content;
  } @else if (length($conditions) > 0) {
    @media #{unquote(parse-expression(nth($conditions, 1)))} {
      // Recursive call
      @include media(slice($conditions, 2)...) {
        @content;
      }
    }
  }
}
