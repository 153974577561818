.page-articlepage {
    .pager, .view-content ~ .item-list {
        display: none;
    }
}
.node-type-article{
    #views-exposed-form-search-page{
        float: left;
        padding: 0 0 0 0;
        .views-exposed-form{
            .views-exposed-widgets{
                margin-bottom: 0;
                .views-exposed-widget{
                    position: static;
                    display: inline;
                    float: none;
                    padding: 0;
                    margin: 0;
                    #edit-submit-search {
                        @include make-button;
                        @include outline-button($charcoal);
                        @include visually-hidden;
                    }
                }
            }
        } 
    }
    .field-type-file{
	display: inline-block;
    	margin: 0 0 30px 30px;
    }
}
.article {
    @include m(b 30);
    &-section {
        @include p(b 22);
        border-bottom: 1px solid $light-grey-2;
    }

    &-title {
        color: black;
        font-size: rem(30);
        line-height: 1.1;
        font-weight: normal;
        margin: 0;


        @include desktop {
            font-size: rem(35);
            // font-weight: normal;
            // position: relative;
            // @include p(x 0);
            // bottom: auto;
        }
    }

    &-item {
        .item {
            &-header {

            }

            &-author {
                font-size: rem(16);
                @include m(t 5 b 7);
            }

            &-categories {
                @include m(t 25 b 10);

                @include mobile {
                    @include m(b 15);
                }

                .categories {

                    &-list {
                        display: flex;

                        li {
                            display: flex;
                            flex-flow: row nowrap;
                            align-items: center;

                            @include desktop {
                                flex-direction: column;
                                justify-content: center;
                                @include margin(left 34);
                            }

                            @include mobile {
                                @include margin(left 17);
                            }
                        }
                    }
                }

                .img {
                    @include size(50);

                    @include media("<=small-mobile"){
                        @include size(38);
                    }
                }

                .category-text {
                    color: $charcoal;
                    font-size: rem(14);
                    line-height: 2.5;

                    @include mobile {
                        // @include p(r 10);
                    }
                }
            }

            &-details {
                display: flex;
                flex-flow: row nowrap;

                span {
                    font-size: rem(15);
                    float: right;
                    display: block;
                    position: relative;
                    white-space: nowrap;

                    @include desktop {
                        font-size: rem(16);
                    }

                    &.date {
                        @include mobile {
                            @include p(l 10);
                        }

                        @include desktop {
                            @include details-limitter;
                            display: flex;
                            flex-flow: row nowrap;
                            align-items: flex-start;
                        }
                    }

                    &.j-date {
                        //TODO
                        @include desktop {
                            color: $charcoal;
                            float: left;
                        }
                    }
                }
            }

            &-content {
                & > img {
                    display: block;
                    @include margin(y 15);
                }
            }
        }

        .details {
            &-date {
                @include mag-icons($icn: calendar, $col: chrk, $w: 14.6, $h: 14.1);

                @include mobile {
                    @include details-limitter;
                }

                @include desktop {
                    @include p(l 45);
                }
            }

            &-comments {
                @include mag-icons($icn: comment, $col: chrk, $w: 14.6, $h: 14);

                @include details-limitter;
            }

            &-seen {
                @include mag-icons($icn: eye, $col: chrk, $w: 22.6, $h: 10.4);
            }
        }
    }

    &-mood {
        @include p(t 22);

        h3 {
            font-size: rem(22);
            font-weight: normal;
            @include margin(b 22);
        }
    }

    .mood-icon {
        cursor: pointer;
    }

    $m1: mood !default;

    &-#{$m1} {
        .#{$m1} {
            &-selection {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-between;
            }

            &-item {
                position: relative;
                @media (max-width:767px){
                    width: 27%;
                }
            }

            &-input {
                display: none;
            }

            &-icon {
                @include size(90, 80);
                text-align: center;
                @include flex-align(b x);
                background-position: center top;
                @media (max-width:768px){
                    background-size: 45px;
                }
            }

            &-counter {
                color: black;
                font-size: rem(12);
                line-height: normal;
                text-align: center;
                @include size(18px);
                background-color: #fff;
                border: 1px solid black;
                border-radius: 50%;
                position: absolute;
                bottom: 33px;
                right: 10px;
            }
        }

        @mixin mood-icon($svg-icn: null, $svg-col: $brownish-grey, $svg-chk-col: $greeny-blue ) {
            & + .#{$m1}-icon {
                background-image: encoded-svg($svg-icn, $svg-col);
            }

            &:checked {
                & + .#{$m1}-icon {
                    background-image: encoded-svg($svg-icn, $svg-chk-col);
                }
            }
        }

        ##{$m1} {
            &-1 {
                @include mood-icon($emoji-excited);
            }

            &-2 {
                @include mood-icon($emoji-inlove);
            }

            &-3 {
                @include mood-icon($emoji-high);
            }

            &-4 {
                @include mood-icon($emoji-happy);
            }

            &-5 {
                @include mood-icon($emoji-sad);
            }

            &-6 {
                @include mood-icon($emoji-angry);
            }
        }
    }

    &-author {
        color: black;
        display: flex;
        flex-flow: row nowrap;
        align-content: flex-start;
        @include p(t 17.3 b 25.5);
        border: 0;

        .author {
            &-img {
                @include size(77px);
                flex: 0 0 auto;

                .userpic {
                    @include userpic-size(81px, 5px, 4px 0px);
                }
            }

            &-content {
                @include p(r 24);
            }

            &-title {
                font-size: rem(22);
                font-weight: normal;
                line-height: 1.59;
                margin: 0;
            }

            &-text {
                font-size: rem(14);
                line-height: 1.43;
                margin: 0;

                span {
                    display: block;
                    clear: both;
                }
            }
        }
    }
}
.article-mood #mood-3 + .mood-icon{
    @media (max-width:768px){
        background-size: 75px;
    }
}
.node-type-article .content {
    section.article + .view,
    section.article + .view .view-content {
        position: static;
        display: inline;
        flex-basis: 100%;
        max-width: none;
        margin: 0;
    }
    .node-article:not(:last-child){
        padding-bottom: 0;
        clear: both;
    }
}
