// CATEGORY BADGES

.categories-list{
  clear: both;

  &:empty{
    // display: none;
    margin: 0;
    padding: 0;
  }

  li {

    display: inline-block;
    position: relative;
    @include m(b 0 l 15);
    text-align: center;

    @include last{
      @include m(l 0);
    }

    span,img {
      display: block;
      @include m(x auto);
    }

    .img {
      border-radius: 50%;
      background-size: contain;
      @include s(40);

      // default background
      background-color: $pinkish-grey;
      box-shadow: inset 0 0 0 2px $brick;

      // mock images
      @if $do-mock {
        @include assign-categories-images;
      }
    }

    img {
      @include s(100%, auto);
    }

    .txt {
      display: block;
    }


    // tooltip

    &[data-tooltip]{
      @include simptip(
        $position: bottom,
        $width: auto,
        $padding: 5px 15px,
        $font-size: rem(15),
        $rounded: false,
        $distance: 6px
      );
      &:after {
        font-family: $font-family-base;
      }
    }

  }


}