// sass-lint:disable-all


// HELPER FUNCTIONS:
// UNITS
//---------------------------------


/// Removes the unit (e.g. px, em, rem) from a value, returning the number only.
///
/// @param {Number} $num - Number to strip unit from.
///
/// @returns {Number} The same number, sans unit.
@function strip-unit($num) {
  @if not $num{
    @return null;
  }
  @return $num / ($num * 0 + 1);
}

// adds "px" if necessary
@function set-unit($val){
  @if not $val{
    @return null;
  }
  @return if(unitless($val), $val * 1px, $val);
}


// UNIT CHECKERS
//--------------------------------


// returns true if its a length with unit or 'calc...', 'auto' or 'none'
@function has-unit($val:null) {
  $return: true;
  $allowed: auto none inherit;
  $val-str: to-string($val);

  @if not ($val) {
    $return: false;
  }
  @if ($val) and not ( is-length($val) )
             and not ( is-percentage($val) )
             and not ( index($allowed, $val-str) )
             and not ( str-index($val-str, 'calc' ) )
  {
    $return: false;
  }
  @return $return;
}

// returns true if has unit or is number (so it can be turned into length with unit)
// returns false for strings etc
@function can-be-length($val:null) {
  $return: false;
  @if ( has-unit($val) ) or ( is-number($val) ) {
    $return: true;
  }
  @return $return;
}

@function can-be-rem($value){
  @return (type-of($value) == 'number')
    and (unit($value) != 'rem')
    and (unit($value) != 'em')
    and (unit($value) != '%')
    and (unit($value) != 'vw')
    and (unit($value) != 'vh');
}


// UNIT ADDERS
//--------------------------------

// if unitless returns PX, allows for Calc, Auto, None and Null
// accepts a list of values (10px 0 auto 10px)
@function check-unit($val:null, $unit: $global-unit) {
  $return: $val;
  @if $val {
    $return: ();
    // if its a list of values
    @each $v in $val {
      // does not have proper unit
      @if not (has-unit($v)) {
        // if is a string => convert to number
        @if not ( is-number($v) ) {
          $v: to-number($v);
        }
        //if it is number
        @if (is-number($v)) {
          $v: $v * 1px;
        }
      }
      // convert to rem if needed
      @if (is-number($v)) and ($unit == rem){
        @if ($unit($v) == 'px'){
          $v: rem($v);
        }
      }
      $return: append($return, $v);
    }
    // if single value, dont return list
    @if length($return)==1{
      $return: nth($return,1);
    }
  }
  @return $return;
}


/// Add `$unit` to `$value`
///
/// @param {Number} $value - Value to add unit to
/// @param {String} $unit - String representation of the unit
///
/// @return {Number} - `$value` expressed in `$unit`
///
@function to-length($value, $unit) {
  $units: ('px': 1px, 'cm': 1cm, 'mm': 1mm, '%': 1%, 'ch': 1ch, 'pc': 1pc, 'in': 1in, 'em': 1em, 'rem': 1rem, 'pt': 1pt, 'ex': 1ex, 'vw': 1vw, 'vh': 1vh, 'vmin': 1vmin, 'vmax': 1vmax);

  @if not index(map-keys($units), $unit) {
    $_: log('Invalid unit `#{$unit}`.');
  }

  @return $value * map-get($units, $unit);
}