// sass-lint:disable-all

// HELPER FUNCTIONS:
// COLORS
//---------------------------------


@function black($opacity:1) {
  @return rgba(0,0,0,$opacity);
}

@function white($opacity:1) {
  @return rgba(255,255,255,$opacity);
}

@function random-color($opacity:1){
  @return rgba(random(255), random(255), random(255), $opacity);
}


/// Slightly lighten a color (use instead of 'lighten' function)
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

/// Slightly darken a color (use instead of 'darken' function)
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}