// MAIN


$exclude-body-classes: login-flow page-creating-activity page-registration;


html,
body {
  font-family: $font-family-base;
  font-size: 16px;
  direction: rtl;
  text-align: right;
  min-height: 0;

  @include media("<=mobile") {
    min-width: 320px;
  }
}

html {
  @include media(">#{$body-min-width}") {
    overflow-x: hidden;
  }
}

@mixin padding-when-fixed-header($add: 0){
  @include mobile{
    @include p(t ($header-height-mobile * $mob-head-mod + $add),$i: true);
  }
  @include media("<=small-mobile"){
    @include p(t ($header-height-mobile + $add),$i: true);
  }
}

body {
  min-height: 100vh;

  @include media(">mobile", "<desktop") {
      line-height: 1.3;
  }


  // excluding login flow pages
  @include not-class($exclude-body-classes)
  {

    // min-width
    @if $use-page-min-width {
      @include desktop{
        min-width: $body-min-width;
      }
    }

    // pading-top when header is fixed on top
    @include padding-when-fixed-header;
  }

}

body > .page,
main,
section,
footer,
header,
[class*=part] {
    width: 100%;
    position: relative;

    &>.wrap {
        @if $use-page-max-width{
            @include max-width($content-max-width + $boxpad*2);

            @include media("<=#{$content-max-width + $boxpad*2}"){
                max-width: 100%;
            }
        }
        @include wrap-pads(x);
        position: relative;
    }
}


main {

  &, &> .wrap {
    min-height: calc(100vh - #{$header-height + $footer-height - $rope-height});

    @include mobile{
      min-height: calc(100vh - #{$header-height-mobile * $mob-head-mod + $footer-height-mobile - ($rope-height - $rope-mod-1)});
    }
    @include media("<=small-mobile"){
      min-height: calc(100vh - #{$header-height-mobile + $footer-height-mobile - ($rope-height - $rope-mod-2)});
    }
  }

  // @include mobile{
  //   margin-top: $header-height-mobile * $mob-head-mod;
  // }
  // @include media("<=small-mobile"){
  //   margin-top: $header-height-mobile;
  // }

}

section {
  clear: both;
}


// general page layout

.page {

  overflow: hidden;
  @include mobile{
    @include p(r 20 l 20);
  };

  // backgrounds

  @include desktop{
    background-image: linear-gradient(to left, #{$light-grey-3} 0%, #{$light-grey-3} 30%, white 50%, white 100%);
    background-size: 100% 100%;
  }

  main {
    background-color: white;
  }

  // layout

  main {
    min-width: calc(100% - 205px);
  }

  & > .wrap {
    display: flex;
  }

}


// drupal page-node page generic layout

.page-node.node-type-page {
  @extend %drupal-page;
}




