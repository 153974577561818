// sass-lint:disable-all

// SLIDEOUT SIDEBAR
//-------------------------------------


// expected markup

//      <div class="sidebar-wrap">
//
//        <input type="checkbox" id="burger-control">
//        <label class="burger-button-wrap" for="burger-control">
//          <span class="burger-button">X</span>
//        </label>
//
//        <div class="sidebar">
//
//
//        </div>
//
//      </div>

// apply @include on sidebar-wrap

// use these: ${$sidebar-off-state} { foo: bar; }

$sidebar-off-state: '#burger-control:not(:checked) ~ .sidebar' !default;
$sidebar-on-state: '#burger-control:checked ~ .sidebar' !default;

$burger-wrap: '.burger-button-wrap';
$burger-button: '.burger-button';

$burger-wrap-off-state: '#burger-control:not(:checked) + .burger-button-wrap';
$burger-wrap-on-state: '#burger-control:checked + .burger-button-wrap';

$burger-off-state: '#burger-control:not(:checked) + .burger-button-wrap .burger-button';
$burger-on-state: '#burger-control:checked + .burger-button-wrap .burger-button';


@mixin slideout-sidebar(

  // sidebar

  $target: '.sidebar',
  $width: 350px,
  $loc:left,
  $pos: fixed,
  $bg-color: silver,
  $sidebar-pad: 50px,
  $sidebar-add-pad-top: false,
  $z-index: 3000,
  $shadow: 0 0 40px rgba(0, 0, 0, 0.35),
  $scrollable: false,

  // burger button

  $burg-width: 50px,
  $burg-line-height: 6px,
  $burg-line-gutter: 8px,
  $burg-line-brdr-rad: 4px,
  $burg-color: black,
  $burg-pos-top: 40px,
  $burg-pos-left: 45px,
  $burg-pos-right: $burg-pos-top, // position from right when sidebar is open, put 0, null or false to leave where it was;

  $burg-height: $burg-line-height * 3 + $burg-line-gutter * 2,

  $burg-on-mods: false, // to modify burger button when sidebar is open
  $burg-on-mod-color: white,
  $burg-on-mod-size: null, // expects multiplier: 0.5
  $burg-wrap-pad: 0px,

  // animations

  $use-animation: false, // use animation or transition;
  $use-page-ready-class: false, // to prevent animation flicking at page load, add class 'ready' on body after page loads;

  $burg-anim-length: 0.2s,
  $slide-anim-length: 0.5s,
  $delay-burger-anim: true,
  $shorter-remove-anim: false,
  $slide-easing: ease(in-back), //linear,
  $burg-easing: ease(in-out-back)


) {

  // expose variables to outside world (to be able to modify burger size later)

  $sidebar-loc: $loc !global;
  $sidebar-width: $width !global;
  $sidebar-pad: $sidebar-pad !global;
  $sidebar-add-pad-top: $sidebar-add-pad-top !global;

  $burg-width: $burg-width !global;
  $burg-height: $burg-height !global;
  $burg-wrap-pad: $burg-wrap-pad !global;
  $burg-line-height: $burg-line-height !global;
  $burg-line-gutter: $burg-line-gutter !global;

  $burger-height: $burg-line-height !global;
  $burger-gutter: $burg-line-gutter !global;

  $burg-pos-right:  $burg-pos-right !global;
  $burg-pos-left: $burg-pos-left !global;
  $burg-pos-top: $burg-pos-top !global;

  $burg-on-mods: $burg-on-mods !global;
  $burg-on-mod-size: $burg-on-mod-size !global;

  $burg-color: $burg-color !global;
  $burg-line-brdr-rad: $burg-line-brdr-rad !global;
  $burg-anim-length: $burg-anim-length !global;
  $use-animation: $use-animation !global;


  @if $use-page-ready-class {
    @at-root {
      body:not(.ready) {
        overflow: hidden;
        .burger-button-wrap {
          opacity: 0;
        }
        .sidebar {
          margin-left: -200vw;
        }
      }
    }
  }

  #{$burger-wrap} {
    display: block;
    position: $pos;
    z-index: $z-index;
    margin: 0;
    cursor: pointer;
  }

  #{$burger-button} {
    display: block;
    @include text-hide;
    padding: 0;
    min-width: 0;
    pointer-events: none;

    &,&:before,&:after {
      transition-property: all;
      transition-timing-function: $burg-easing;
      @if $delay-burger-anim {
        transition-delay: $slide-anim-length;
      }
    }

  } // .burger-button

  #{$target} {
    width: $width + $sidebar-pad;
    padding-#{$loc}: $sidebar-pad;
    position: $pos;
    top: 0;
    #{$loc}: -($width + $sidebar-pad);
    z-index: $z-index - 1;
    height: 100vh;
    background-color: $bg-color;

    @if $scrollable {
      max-height: 100%;
      overflow-y: auto;
    }
  }

  .burger-button-wrap, #{$target} {
    transition: all $slide-anim-length $slide-easing;
  }

  #burger-control {
    @include visually-hidden;
  }

  #{$burger-on-state} {
    @include burger-to-cross(
      $burger-gutter: $burg-line-gutter,
      $burger-height:  $burg-line-height
    );

    @if $burg-on-mods {
      @include burger-modify(
        $state: on,
        $color: $burg-on-mod-color
      );
    }
  }

  #{$sidebar-on-state} {
    #{$loc}: -$sidebar-pad;
    box-shadow: $shadow;
  }

  @include sidebar-burger-size-and-position;

} // mixin



@mixin modify-burger-size(
  $burg-mod-size:null,
  $state:both,
  $use-marg-mod: true,
  $burg-wrap-pad: $burg-wrap-pad,
  $burg-pos-right: $burg-pos-right
) {

  @if ( $state == both) {
    $burger-wrap: $burger-wrap;
  } @else if ($state == on) {
    $burger-wrap: $burger-wrap-on-state;
  } @else if ($state == off) {
    $burger-wrap: $burger-wrap-off-state;
  }

  $br-marg1:  $burg-pos-left;
  @if to-bool($burg-pos-right) and $burg-mod-size {
    $br-marg1: round( ($sidebar-width - ($burg-width * $burg-mod-size) - $burg-pos-right) - ($sidebar-width - $burg-width - $burg-pos-right) );
  }

  #{$burger-wrap} {
    @if $use-marg-mod and $burg-mod-size {
      margin-#{$sidebar-loc}: $br-marg1;
    }
    @if $burg-mod-size {
      width: round( $burg-width * $burg-mod-size + $burg-wrap-pad*2);
      min-height: round( ($burg-line-height * 3 + $burg-line-gutter * 2) * $burg-mod-size + $burg-wrap-pad*2);
    }
  }

  $w: null;
  $h: null;
  $g: null;

  @if $burg-mod-size {
    $w: round($burg-width * $burg-mod-size);
    $h: round($burg-line-height * $burg-mod-size);
    $g: round($burg-line-gutter * $burg-mod-size);
  }

  @if ($state == both or $state == off) {
    #{$burger-off-state} {
      @include burger-modify(
        $state: off,
        $width: $w,
        $height: $h,
        $gutter: $g
      );
    }
  }

  @if ($state == both or $state == on) {
    #{$burger-on-state}{
      @include burger-modify(
        $state: on,
        $width: $w,
        $height: $h,
        $gutter: $g
      );
    }
  }
}

@mixin modify-sidebar-size(
  $width: 350px,
  $loc:left,
  $sidebar-pad: 35px,
  $burg-width: 50px,
  $burg-pos-left: 45px,
  $burg-pos-right: 40px,
  $burg-wrap-pad: 0px,
  $target: '.sidebar'
) {

  #{$target} {
    width: $width + $sidebar-pad;
    #{$loc}: -($width + $sidebar-pad);
  }

  $br-pos1: $burg-pos-left - $burg-wrap-pad;
  @if to-bool($burg-pos-right)  {
    $br-pos1: $width - $burg-width - $burg-pos-right - $burg-wrap-pad;
  }

  #{$burger-wrap-on-state} {
    #{$loc}: $br-pos1;
  }

}

@mixin sidebar-burger-size-and-position(
  $target: '.sidebar',
  $sidebar-loc: $sidebar-loc,
  $sidebar-width: $sidebar-width,
  $sidebar-pad: $sidebar-pad,
  $burg-width: $burg-width,
  $burg-line-height: $burg-line-height,
  $burg-line-gutter: $burg-line-gutter,
  $burg-wrap-pad: $burg-wrap-pad,
  $burg-pos-right:  $burg-pos-right,
  $burg-pos-left: $burg-pos-left,
  $burg-pos-top: $burg-pos-top,
  $do-dims: true
){
  $dice: random(100);

  $burg-height: $burg-line-height * 3 + $burg-line-gutter * 2;

  $kfr-loc1: $burg-pos-left;
  $kfr-loc2: $burg-pos-left;
  $kfr-loc3: $burg-pos-left;
  $kfr-loc4: $burg-pos-left;

  @if to-bool($burg-pos-right)  {
    $kfr-loc1: $sidebar-width + $sidebar-pad - $burg-width - $burg-pos-left - $burg-pos-right;
    $kfr-loc2: $sidebar-width - $burg-width - $burg-pos-right;
    $kfr-loc3: $sidebar-width + $sidebar-pad - $burg-width - $burg-pos-left - $burg-pos-right;
    $kfr-loc4: $sidebar-width - $burg-width - $burg-pos-right;
  }


  #{$burger-wrap} {
    #{$sidebar-loc}: $burg-pos-left - $burg-wrap-pad;
    top: $burg-pos-top;

    @if $do-dims {
      width: $burg-width + $burg-wrap-pad*2;
      min-height: $burg-height + $burg-wrap-pad*2;
      padding: $burg-wrap-pad;
    }
  }

  #{$burger-button} {
    @if $do-dims {
      @include burger(
        $width: $burg-width,
        $height: $burg-line-height,
        $gutter: $burg-line-gutter,
        $color: $burg-color,
        $border-radius: $burg-line-brdr-rad,
        $transition-duration: $burg-anim-length
      )
    }
  }

  #{$target} {
    @if $sidebar-add-pad-top {
      padding-top: $burg-pos-top * 2 + $burg-height;
    }
  }

  $br-pos1: $burg-pos-left - $burg-wrap-pad;
  $br-marg1: $burg-pos-left;

  @if to-bool($burg-pos-right)  {
    $br-pos1: $sidebar-width - $burg-width - $burg-pos-right - $burg-wrap-pad;

  }
  @if to-bool($burg-pos-right) and ($burg-on-mod-size)  {
    $br-marg1: round( ($sidebar-width - ($burg-width * $burg-on-mod-size) - $burg-pos-right) - ($sidebar-width - $burg-width - $burg-pos-right) );
  }

  #{$burger-wrap-on-state} {
    #{$sidebar-loc}: $br-pos1;

    @if $do-dims and $burg-on-mods and $burg-on-mod-size {
      margin-#{$sidebar-loc}: $br-marg1;
      width: round( $burg-width * $burg-on-mod-size );
      min-height: round( ($burg-line-height * 3 + $burg-line-gutter * 2) * $burg-on-mod-size );
    }
  }

  #{$burger-on-state} {
    @if $do-dims and $burg-on-mods and $burg-on-mod-size {
      @include burger-modify(
        $state: on,
        $width: round( $burg-width * $burg-on-mod-size ),
        $height: round( $burg-line-height * $burg-on-mod-size ),
        $gutter: round( $burg-line-gutter * $burg-on-mod-size )
      )
    }
  }

}