// form inputs


@mixin underlined-input($h:35px) {
  direction: rtl;
  @include p(x 0);
  @include borders(x 0 t 0 b $charcoal);
  height: $h;
  line-height: $h - 1px;
}


// general inputs

input:not(.multiselect-checkbox), select, .input {
  width: 100%;
  border: solid 1px $greyish;
  background-color: transparent;
  @include p(x $input-padding-x y $input-padding-y);
  color: $black;
}
input:not(.multiselect-checkbox), select:not([multiple]), .input {
  height: $input-height;
  line-height: $input-line-height;
}

input {

  @include placeholder{
    color: $brown-grey;
  }

  &:focus {
    @include placeholder {
      color: transparent;
    }
  }

}


.hidden{display:none !important;}

// general select

select:not([multiple]) {

  @include nice-select(
    $color: $charcoal,
    $symb-off: get-icn(icn_ar_down),
    $symb-on: get-icn(icn_ar_up),
    $symb-size: 13px
  );

  padding-left: 22px;
  cursor: pointer;
  background-position: left center;

  &.link-select {
    height: auto;
    line-height: inherit;
    border: 0;
    background-color: transparent;
    width: auto;
    text-decoration: underline;
    font-size: rem(15);
    font-weight: 600;
    color: $off-black;

    &:hover,&:focus {
      outline: none;
    }
  }

}

// boxy select

select.boxy {

  background-image: encoded-svg( fill-svg( get-icn(icn_ar_down), $charcoal) ), linear-gradient(to right,#{$charcoal}, #{$charcoal});
  background-position: left 15px top 50%, left 43px top 50%;
  background-size: 13px auto, 2px 100%;
  padding-left: 50px;

  &:focus {
    background-image: encoded-svg( fill-svg( get-icn(icn_ar_up), $charcoal) ), linear-gradient(to right,#{$charcoal}, #{$charcoal});
  }

}

// checkbox

#{$checkbox}
// input[type=checkbox]
{

  @include nice-checkbox(
    $color: transparent,
    $chk-mrk-color: black,
    $off-chk-mrk-color: transparent,
    $off-brdr: 1px black,
    $on-brdr: 1px black,
    $off-bg-color: transparent,
    $checkbox-svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.6 32" width="41.6" height="32"><path fill="none" stroke="fillColor" stroke-width="5" stroke-miterlimit="10" d="M1.1 19.2l10.6 10.7L40.5 1.1"/></svg>',

    $bg-mltpr: 0.7
  );

}

@mixin bigger-checkbox($s:20, $bg-mltpr:0.7)
{
  $s: set-unit($s);

  & + label:before {
    @include s($s);
  }
  &:checked + label:before {
    background-size: floor($s*$bg-mltpr) auto;
  }
}


// multiple checkbox select

.multiple-checkbox-select {

  $single-option-height: 18px;
  $option-margin-y: 15px;
  $border-width: 1px;
  $padding: 15px;

  min-height: $single-option-height + $padding * 2 + $border-width * 2;

  @for $i from 1 through 5 {
    &[size="#{$i}"] {
      height: ($single-option-height * $i + $option-margin-y * ($i - 1) + $padding * 2 + $border-width * 2);
    }
  }

  span {
    display: block;
  }

  width: 100%;
  color: $black;
  padding: $padding;
  text-align: right;
  overflow-x: hidden;
  overflow-y: scroll;

  border-radius: 1px;
  border: solid $border-width $charcoal;

  direction: ltr;
  & > span {
    direction: rtl;
    @include grid(2,20,$option-margin-y,'.check-wrap');
  }

  .check-wrap {
    // @include m(y 5);
    @include animate(slide-in-up,0.3s);
  }

  #{$checkbox} {
    @include bigger-checkbox($single-option-height);

    & + label {
      span {
        vertical-align: middle;
        @include truncate-text(null);
      }
      &:before {
        // backface-visibility: none;
        outline: 1px solid transparent;
      }
    }
    &:focus + label {
      &:before {
        @extend %focus;
      }
    }
  }

}




// errors & validation

textarea,
input,select,.input {
  &.invalid,&.error

  // ,&:invalid
  {
    &:not(:focus)
    {
      border-color: $error-color;
    }
  }

}

select[aria-invalid="true"],
input[aria-invalid="true"] {
  &:not(:focus)
  {
    border-color: $error-color;
  }
}

.error:not(.required), .messages.error,
.form-error,
.input-error {
  color: $error-color;
  @include animate(slide-in-down,0.5s);
}

input.form-text {
  &.error:not(:focus){
    border-color: $error-color;
  }
  &.error{
    &,&:-webkit-autofill {
      color: inherit;
    }
  }
}

.form-success,.input-valid,.messages.status {
  color: green;
}


// cleanup drupal error

.messages.status,
.messages.error {
  background: none;
  padding: 0;
  border: 0;
  margin: 0;

  .element-invisible {
    display: none;
  }
}
