// sass-lint:disable-all

// FORMS-RELATED MIXINS
//---------------------------------



@mixin placeholder {
  &:-ms-input-placeholder {@content;}
  &::-ms-input-placeholder {@content;}
  &:-moz-placeholder {@content;}
  &::-moz-placeholder {@content;}
  &::-webkit-input-placeholder {@content;}
  &::placeholder {@content;}
}