.page-introduction {

  .page-header {
    .links li:nth-child(1){
      background-color: rgba($off-white-2,0.13);
    }
  }

  .content-header > *,
  .item-list > .wrap {
    flex-basis: $content-pages-max-width-2-cols;
    max-width: $content-pages-max-width-2-cols;
  }

  .item-list > .wrap {

    @if $content-pages-center-content {
      @include m(x auto);
    }

    @include desktop{
      @include flex-spread;
    }
  }

  section.introduction {

    @include desktop{
      flex-basis: 600px;
      @include m(l 35);
    }

    article {

      h5 {
        font-weight: 400;
        font-size: rem(18);
      }

      h4 ~ h4 {
        font-size: rem(16);
      }

      p {
        line-height: 1.56;
      }

      @include not-last-child{
        border-bottom: 1px solid $light-grey-2;
        @include p(b 24);
        @include m(b 20);
      }

      .cols {
        @include desktop{
          display: flex;
        }
      }

      &.two-columns {
        p {
          @include desktop{
            min-width: 35%;
            @include m(b 0);

            @include nth(1){
              @include m(l 20);
            }

            @include nth(2){
              @include m(r 20);
            }
          }
        }
      }

      &.promise {
        p {
          @include nth(2){
            font-size: rem(15);
            line-height: 1.6;
          }
        }
      }

    }
  }

  aside {
    @include desktop{
      flex-basis: 292px;
    }

    @include mobile{
      @include m(b 20 t -30);
    }

    .commandments {
      padding: 20px;
      font-size: rem(15);
      background-color: rgba($light-grey-3,0.5);
      @include m(b 30);

      h4 {
        @include m(b 13);
      }

      ol {
        line-height: 1.67;
      }

      counter-reset: item-counter;
      li {
        display: flex;

        &:before {
          content: counter(item-counter) '.';
          counter-increment: item-counter;
          text-align: right;
          @include m(l 5);
        }
      }
    }

    .anthem {
      position: relative;
      @include overlay-stripes($bg:$stripe-bg, $w:100%, $m:false);
      @include box-double-border($t: '&:after', $c: $light-grey-3, $m: 8px, $a:false, $od:false);
      padding: 30px;
      text-align: center;

      h4 {
        @include m(b 13);
      }

      .credits {
        display: inline-block;
        font-size: rem(14);
        line-height: 1.14;
        @include p(y 10);
        @include m(b 10);
        @include borders(y, $greyish);
      }

      .txt {
        line-height: 1.56;
      }
    }
  }


}