// sass-lint:disable-all


// apply on Input[type=checkbox], label element should be next sibling

$checkbox-svg: '<svg xmlns="http://www.w3.org/2000/svg" width="290.22" height="222.44" viewBox="0 0 290.22 222.44"><path fill="fillColor" d="M290.22 43.43a17.36 17.36 0 0 1-5.24 12.69l-135.56 135.6-25.46 25.46a18.08 18.08 0 0 1-25.46 0l-25.48-25.46-67.78-67.78a18.08 18.08 0 0 1 0-25.46l25.45-25.47a17.36 17.36 0 0 1 12.74-5.24 17.33 17.33 0 0 1 12.73 5.24l55.05 55.24 122.83-123a18.08 18.08 0 0 1 25.46 0l25.46 25.46a17.34 17.34 0 0 1 5.26 12.72z"/></svg>' !default;



@mixin nice-checkbox(

  $size: 15px,
  $color: black,
  $chk-mrk-color: white,
  $brd-radius: 0px,
  $off-chk-mrk-color: transparent,
  $on-chk-mrk-color: $chk-mrk-color,
  $brdr-width: 1px,
  $off-brdr: $brdr-width $color,
  $on-brdr: $brdr-width $color,
  $off-bg-color: white,
  $on-bg-color: $color,
  $checkbox-svg: $checkbox-svg,
  $margin: 10px,
  $dir:$direction,
  $use: before,
  $bg-mltpr: 0.75,
  $general-sibling: false,
  $label-class: '' //'.checkbox-label'
) {


  $j:'+';
  @if $general-sibling {$j:'~';}

  $marg-side: right;
  @if ($dir == rtl) {
    $marg-side: left;
  }
  @if ($use == after) {
    $marg-side: opposite-direction($marg-side);
  }

  @include visually-hidden;

  & #{$j} label#{$label-class} {

    padding: 0;
    user-select: none;

    @if $enable-flex {
      display: flex;
    }

    &, &:#{$use} {
      //display: inline-block;
      vertical-align: middle;
      user-select: none;
      cursor: pointer;
    }

    &:#{$use} {
      display: inline-block;
      margin-#{$marg-side}: check-unit($margin);
      flex-shrink: 0;
    }

    & > span {
      pointer-events: none;
      display: inline-block;
      max-width: calc(100% - #{ check-unit($size + 15) } );
      vertical-align: top;
      line-height: 1;
    }

  }

  & #{$j} label#{$label-class}:#{$use} {
    content: "";
    zoom: 1;
    @include size($size);
    border-radius: check-unit($brd-radius);
    border: check-unit(nth($off-brdr, 1),px) solid nth($off-brdr, 2);
    background-color: $off-bg-color;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0%;
    //transition: all 0.1s;
    transition: all 0.2s ease(out-back);

    @content;
  }

  &:disabled #{$j} label#{$label-class}, &:disabled:active #{$j} label#{$label-class} {
    cursor: $cursor-disabled;
  }

  &:checked #{$j} label#{$label-class}:#{$use} {
    background-image: encoded-svg( fill-svg($checkbox-svg, $on-chk-mrk-color) );
    background-color: $on-bg-color;
    border: check-unit(nth($on-brdr, 1),px) solid nth($on-brdr, 2);
    background-size: check-unit(floor($size*$bg-mltpr)) auto;
  }

}

