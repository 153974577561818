// KEYFRAMES




@include use-keyframe(zoom-in, 0.95);
@include use-keyframe(fade-in);

@include use-keyframe(slide-in-down, 20px);
@include use-keyframe(slide-in-up, 20px);
@include use-keyframe(slide-in-left, 20px);
@include use-keyframe(slide-in-right, 20px);

@include use-keyframe(slide-in-down, 20px, $add: 'translateX(-50%)', $sfx:2);
@include use-keyframe(slide-in-up, 20px, $add: 'translateX(-50%)', $sfx:2);


$blink-color: $orangeish;
$blink-size: 16px;

@keyframes blink {
  0% {
    box-shadow: 0 0 0 -5px rgba($blink-color,0);
  }
  50% {
    box-shadow: 0 0 $blink-size 1px rgba($blink-color,1);
  }
  100% {
    box-shadow: 0 0 0 -5px rgba($blink-color,0);
  }
}