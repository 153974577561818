$remify-desktop: 1920 !default;
$remify-mobile: 768 !default;
$remify-base: 16 !default;
$remify-base-mob: null !default;

$remify-base-min: 13 !default;
$remify-base-max: null !default;
$remify-base-mob-min: 10 !default;

$emify-desktop: $remify-desktop !default;
$emify-mobile: $remify-mobile !default;
$emify-base: $remify-base !default;
$emify-base-mob: $remify-base-mob !default;

$emify-base-min: $remify-base-min !default;
$emify-base-max: $remify-base-max !default;
$emify-base-mob-min: $remify-base-mob-min !default;


@mixin remify-base(
  $desktop: $remify-desktop,
  $mobile: $remify-mobile,
  $base: $remify-base,
  $base-mob: $remify-base-mob,
  $min: $remify-base-min,
  $max: $remify-base-max,
  $min-mob: $remify-base-mob-min,
  $do-min-width: false,
  $i: '',
  $add-class: null
) {
  $desktop: strip-unit($desktop);
  $mobile: strip-unit($mobile);
  $base: strip-unit($base);
  $min: set-unit($min);
  $max: set-unit($max);
  $mbase: $base; @if ($base-mob) { $mbase: strip-unit($base-mob); }
  $mmin: $min; @if ($min-mob) { $mmin: set-unit($min-mob); }

  @if ($i != '') { $i: '!important'; }

  // desktop

  $min-w: null;
  @if ($min) {
    $min-w: $min / ($base/$desktop*100) * 100;
  }
  @if ( is-number($do-min-width) ) {
    $do-min-width: strip-unit($do-min-width);
    $min: set-unit(($do-min-width / 100) * ($base/$desktop*100));
    $min-w: set-unit($do-min-width);
  }

  @if ($base != $min) {
    font-size: $base/$desktop*100*1vw #{$i};

    @if $min {
      @media (max-width: #{$min-w}) {
        font-size: $min #{$i};
      }

      @if ($do-min-width) {
        @if strip-unit($min-w) > $mobile {
          @media (min-width: #{$mobile + 1}px) {
            min-width: $min-w;

            @if $add-class {
              @at-root {
                #{$add-class} {
                  min-width: $min-w;
                }
              }
            }
          }
        } @else {
          min-width: $min-w;

          @if $add-class {
            @at-root {
              #{$add-class} {
                min-width: $min-w;
              }
            }
          }
        }
      }
    }
  } @else {
    font-size: $min #{$i};
  }

  @if $max {
    @media (min-width: #{$max / ($base/$desktop*100) * 100}) {
      font-size: $max #{$i};
    }
  }

  // mobile

  @if ($mbase != $mmin) {
    @media (max-width: #{$mobile}px) {
      font-size: $mbase/$mobile*100*1vw #{$i};
    }
    @if $mmin {
      @media (max-width: #{$mmin / ($mbase/$mobile*100) * 100}) {
        font-size: $mmin #{$i};
      }
    }
  } @else {
    @media (max-width: #{$mobile}px) {
      font-size: $mmin #{$i};
    }
  }

}

@mixin emify-base(
  $desktop: $emify-desktop,
  $mobile: $emify-mobile,
  $base: $emify-base,
  $base-mob: $emify-base-mob,
  $min: $emify-base-min,
  $max: $emify-base-max,
  $min-mob: $emify-base-mob-min,
  $i: ''
){
  @include remify-base($desktop, $mobile, $base, $base-mob, $min, $max, $min-mob, $i: $i);
}

@mixin remify(
  $props,
  $mobile: $remify-mobile,
  $base: $remify-base,
  $base-mob: $remify-base-mob,
  $unit: 1rem
){
  @if (type-of(nth($props,1)) != 'list'){
    $props: ($props,);
  }
  $mobile: strip-unit($mobile);
  $base: strip-unit($base);
  $mbase: $base; @if ($base-mob) { $mbase: strip-unit($base-mob); }

  @each $prop in $props {
    $the-prop: nth($prop,1);
    @if ($the-prop == font) or ($the-prop == f) { $the-prop: font-size; }
    @if ($the-prop == line) { $the-prop: line-height; }
    @if ($the-prop == border) { $the-prop: border-width; }
    @if ($the-prop == w) { $the-prop: width; }
    @if ($the-prop == h) { $the-prop: height; }

    #{$the-prop}: strip-unit(nth($prop,2)) / $base * $unit;

    @if ( length($prop) > 2 ) {
      @media (max-width: #{$mobile}px) {
        #{$the-prop}: strip-unit(nth($prop,3)) / $mbase * $unit;
      }
    }
  }
}

@mixin emify(
  $props,
  $mobile: $emify-mobile,
  $base: $emify-base,
  $base-mob: $emify-base-mob
){
  @include remify($props, $mobile, $base, $base-mob, $unit: 1em);
}
