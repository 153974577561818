$simplebar-color: $charcoal-grey;

[data-simplebar] {
  position: relative;
  z-index: 0;
  -webkit-overflow-scrolling: touch; /* Trigger native scrolling for mobile, if not supported, plugin is used. */
}

[data-simplebar="init"] {
  overflow: hidden;
  display: flex;
}

.simplebar-scroll-content {
  overflow-x: hidden;
  overflow-y: scroll;
  min-width: 100%;
  box-sizing: content-box;
}

.simplebar-content {
  overflow-x: scroll;
  overflow-y: hidden;
  box-sizing: border-box;
  // min-height: 100%;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  border-radius: 7px;
  // min-height: 10px;
  width: 7px;
  opacity: 0;
  transition: opacity 0.2s linear;
  background: $simplebar-color;
  background-clip: padding-box;
}

.simplebar-track {
  z-index: 10;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 11px;
  visibility: visible!important;

  &.horizontal {
    left: 0;
    width: auto;
    height: 11px;

    .simplebar-scrollbar {
      right: auto;
      top: 2px;
      height: 7px;
      min-height: 0;
      min-width: 10px;
      width: auto;
    }
  }

  &.vertical {
    top: 0;
    bottom: 6px;
  }

  .simplebar-scrollbar.visible {
    opacity: 0.5;
  }

  &:hover .simplebar-scrollbar {
    /* When hovered, remove all transitions from drag handle */
    opacity: 0.5;
    transition: opacity 0 linear;
  }
}


//==============================================================
// ZOFIM Mods


// removing horizontal scroll

.simplebar-track.horizontal {
  display: none;
}

.simplebar-scroll-content {
  margin-bottom: 0 !important;
}
.simplebar-content {
  padding-bottom: 0 !important;
  overflow-x: hidden;
}

// weird chrome direction / scrollbar position fix

[data-simplebar] {
  direction: ltr;
}
.simplebar-content{
  direction: rtl;
}

// move scrollbar outside content

@mixin simplebar-outside($m:16px,$t:'',$pmod:0) {
  @include not-ie{
    #{$t}[data-simplebar] {
      margin-right: -1*$m;

      .simplebar-content {
        padding-right: $m + $pmod;
      }
    }
  }
}

// undo that

@mixin simplebar-inside($t:'') {
  #{$t}[data-simplebar] {
    margin-right: 0;

    .simplebar-content {
      padding-right: 0;
    }
  }
}

// only-desktop type

@mixin remove-simplebar {
  width: 100%;
  max-width: none;
  max-height: none;

  min-height: 0;
  margin-right: 0;
  // overflow: auto;
  overflow: visible;

  .simplebar-scroll-content  {
    overflow: initial;
  }

  .simplebar-track, .simplebar-scrollbar
  {
    display: none;
  }

  &, .simplebar-scroll-content, .simplebar-content
  {
    margin: 0 !important;
    padding: 0 !important;
  }
}

[data-simplebar].only-desktop{
  @include media("<=mobile"){
    @include remove-simplebar;
  }
}

// use native scroll

@mixin simplebar-native-scroll($t: ':first-child', $h: 100%, $p: b 0 r -30, $cp: 8, $bgc: $light-grey-3, $sp:-24) {

  overflow-x: hidden;
  overflow-y: scroll; //auto;
  direction: ltr;
  @include m(r $sp);
  position: static;

  &:after {
    @include pseudo;
    height: $h;
    @include ps($p);
    background-color: $bgc;
    transition: all 0.6s;
    transition-delay: 0.7s;
    pointer-events: none;
    width: 30px;
    opacity: 1;
  }

  &:hover {
    &:after {
      opacity: 0;
    }
  }

  & > #{$t} {
    direction: rtl;
    @if $cp {
      @include p(r $cp);
    }
  }
}

// misc stuff

.simplebar-scroll-content {
  max-height: inherit;
}

