// sass-lint:disable-all


$close-icon-normal: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.64 22.65" width="22.64" height="22.65"><path fill="fillColor" d="M22.15 19.65l-8-8.33 8-8.33A1.772 1.772 0 0 0 19.59.54l-8.21 8.58h-.13L3.05.54A1.772 1.772 0 1 0 .49 2.99l8 8.33-8 8.33a1.772 1.772 0 0 0 2.56 2.45l8.21-8.58h.13l8.2 8.58a1.772 1.772 0 1 0 2.56-2.45z"/></svg>';

$close-icon-fat: '<svg xmlns="http://www.w3.org/2000/svg" width="278.18" height="278.2" viewBox="0 0 278.18 278.2"><path fill="fillColor" d="M278.21 223.87a21.69 21.69 0 0 1-6.56 15.93l-31.87 31.85a22.61 22.61 0 0 1-31.84 0l-68.85-68.85-68.88 68.85a21.69 21.69 0 0 1-15.93 6.56 21.65 21.65 0 0 1-15.91-6.56L6.55 239.8a22.61 22.61 0 0 1 0-31.84l68.85-68.85L6.55 70.27A21.62 21.62 0 0 1 0 54.35a21.65 21.65 0 0 1 6.56-15.93L38.4 6.56A21.65 21.65 0 0 1 54.31 0a21.69 21.69 0 0 1 15.9 6.56l68.85 68.85 68.85-68.85a22.61 22.61 0 0 1 31.84 0l31.84 31.84a21.71 21.71 0 0 1 6.62 15.95 21.69 21.69 0 0 1-6.56 15.93l-68.85 68.85 68.85 68.85a21.68 21.68 0 0 1 6.56 15.89z"/></svg>';

$close-icon-thin: '<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64"><path fill="fillColor" d="M28.94 31.786L.614 60.114c-.787.787-.787 2.062 0 2.85.393.393.91.59 1.424.59.516 0 1.03-.197 1.424-.59L32.003 34.42l28.54 28.54c.395.395.91.59 1.425.59s1.03-.195 1.424-.59c.788-.786.788-2.06 0-2.848L35.065 31.786 63.41 3.438c.787-.787.787-2.062 0-2.85-.787-.785-2.062-.785-2.848 0L32.002 29.15 3.443.59C2.653-.196 1.38-.196.59.59c-.786.787-.786 2.062 0 2.85l28.35 28.346z"/></svg>';

@mixin close-button(
    $size:20px,
    $type:normal,
    $color:black,
    $bg:transparent,
    $shad:false,
    $zoom: false,
    $border:0,
    $c: 2.5
  ) {

  $icon: $close-icon-normal;
  @if ($type == fat) { $icon: $close-icon-fat; }
  @if ($type == thin) { $icon: $close-icon-thin; }

  @include text-hide();
  @include size($size);
  border-radius: $size/2;

  padding: 0;
  min-height: 0;
  min-width: 0;
  display: block;

  background-image: encoded-svg( fill-svg($icon,$color) );

  background-position: center;
  background-size: #{round($size/$c)} #{round($size/$c)};
  background-repeat: no-repeat;
  cursor: pointer;
  border: $border;

  #{$button-states} {
    background-color: $bg;

    @if $shad {
      box-shadow: 0 0 6px 2px rgba(0,0,0,0.28);
    } @else {
      box-shadow: none;
    }
  }

  opacity: 0.8;

  &:hover {
    opacity: 1;
    @if $zoom { transform: scale(1.1); }
  }
  &:active {
    transform: scale(0.95);
    box-shadow: none;
  }

}

@mixin modify-close-button(
  $size: null
  ,$type: null
  ,$color: black
  ,$c: 2.5
  ,$dims: null
) {       // $dims - outer dimentions, $size - size of picture

//  @if ($dims) and not ($size) { $size: $dims; }

  $icon: $close-icon-normal;
  @if ($type == fat) { $icon: $close-icon-fat; }
  @if ($type == thin) { $icon: $close-icon-thin; }

  @if ($dims) and not ($size) {
    @include size($dims);
  } @else if ($dims) and ($size) {
    @include size($dims);
    background-size: #{$size} auto;
  } @else if ($size) and not ($dims) {
    @include size($size);
    background-size: #{$size/$c} #{$size/$c};
  }

  @if ($type) and ($color) {
    background-image: encoded-svg( fill-svg($icon,$color) );
  }

}
