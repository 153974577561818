.inner-wrapper{
    position: relative;
}
.efi-game{
    position: absolute;
    z-index: 100;

    &.right_top{
        top: 100px;
        right: 250px;

        @include media("<=mobile"){
            right: 20px;
        }
    }
    &.right_bottom{
        bottom: 50px;
        right: 250px;

        @include media("<=mobile"){
            right: 20px;
        }
    }
    &.left_top{
        top: 100px;
        left: 50px;

        @include media("<=mobile"){
            left: 20px;
        }
    }
    &.left_bottom{
        bottom: 50px;
        left: 50px;

        @include media("<=mobile"){
            left: 20px;
        }
    }
    &.center{
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    .congratulations{
        display:none;
    }
    .efi img{
        width: 90px;
        height: auto;
        @include media("<=mobile"){
            width: 60px;
        }
        
    }
    .congratulations img{
        width: 350px;
        height: auto;
        @include media("<=mobile"){
            width: 200px;
        }
    }
}

.page-courses{
    .efi-game{
        &.right_top{
            top: 0px;
            right: 0px;
        }
        &.right_bottom{
            bottom: 0;
            right: 0px;
        }
        &.left_top{
            top: 0px;
            left: 0px;
        }
        &.left_bottom{
            bottom: 0;
            left: 0px;
        }
        &.center{
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
}
.activities-popup{
    .efi-game{
        &.right_top{
            top: 20px;
            right: 30px;
        }
        &.right_bottom{
            bottom: 20px;
            right: 30px;
        }
        &.left_top{
            top: 20px;
            left: 30px;
        }
        &.left_bottom{
            bottom: 20px;
            left: 30px;
        }
        &.center{
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }
}