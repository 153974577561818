.rashgad-overview {

  $attendance-max-width: 452px;

  @include prepare-schedule-section;

  @include desktop{
    @include outstretch-bg(left,rgba($light-grey-2,0.2));
  }

  @include mobile{
    &:before {
      width: 120vw;
      left: -30px;
      right: -30px;
    }
  }


  // layout

  @include m(l 15);

  & > .wrap {
    justify-content: space-between;

    @include desktop{
      @include p(b 70);
    }

    @include mobile{
      flex-wrap: wrap;
      @include break-out-wrap(x);
      @include p(b 0);
    }
  }
  .merakes_filter {
    width: 100%;
    .selectRashgad {
      max-width: 250px;
      display: inline-block;
      @include mobile{
        margin: 0 auto 12px;
        max-width: 90%;
        display: block;
      }
    }
    .section-title {
      display: inline-block;
      width: auto;
      margin-left: 15px;
    }
  }
  .section-title {
    width: 100%;
    @include m(b 20);

    // @include mobile{
      @include wrap-pads(x, $do-desktop:false);
    // }
  }
  .guides-and-courses{
    .guides-list{
      display: flex;
      flex-direction: column;
      li{
        order: 2;
        &.bold{
          order: 1;
          font-weight: 600
        }
      }
    }
  }
  @include desktop{

    .guides-and-courses {
      @include grid-cell(22% 3, 40px, $minmax:false){
        @include m(r 0);
      }
    }

    .activity-types {
      @include grid-cell(32% 3, 40px, $minmax:false){
        flex-grow: 1;
      }

      @include media(">1400px"){
        @include p(x 2vw);
      }
    }

    .attendance-graphs {
      @include grid-cell(46% 3, 40px, $minmax:false){
        @include m(l 0);
        max-width: $attendance-max-width;
      }
    }

  }

  @include mobile{
    .guides-and-courses,.activity-types,.attendance-graphs{
      width: 100%;
      @include wrap-pads(x y, $do-desktop:false);
      // @include m(b 40);
      background-color: rgba($light-grey-2,0.2);
      display: none;
    }

    .rashgad-panel {
      @include p(t 15);

      & > h3 {
        display: none;
      }
    }
  }


  // styles

  h3 {
    font-size: rem(22);
    font-weight: 500;
    @include m(b 15);
  }

  // guides list

  .guides-list {

    li {
      display: block;
      @include flex-spread;

      &.active {
        .name {
          font-weight: 600;
        }
      }
    }

    .counter {
      font-size: rem(18);
    }

  }

  // guides list show-more

  $guides-show-limit: 12;

  input#show-more {
    @include toggle-collapsible('.guides-list li:nth-child(n+#{$guides-show-limit + 1})', 50px);
  }

  label[for="show-more"] {
    @include m(t 10);
    font-weight: 600;
    font-size: rem(15);
    cursor: pointer;
    @include p(l 20);
    background-size: 12px auto;
    background-position: left center;

    @extend %icn_ar_down-chrk;
  }

  input#show-more:checked ~ label[for="show-more"] {
    @extend %icn_ar_up-chrk;

    span {
      display: none;
    }
    &:after {
      content: 'הראה פחות';
    }
  }


  // activity types

  .activity-types {

    h3 {
      .course {
        display: inline-block;
        font-size: rem(18);
        font-weight: 400;
        color: $charcoal;
        @include m(r 15);
      }
    }

    .activity-types-list {

      @include media(">1700px") {
        @include grid(5, 20px, 20px,'li');
      }

      @include media(">1550px","<=1700px") {
        @include grid(4, 20px, 20px,'li');
      }

      @include media-combine(">1100px&<=1550px","<=mobile") {
        @include grid(3, 20px, 20px,'li');
      }

      @include media("<=1100px",">#{$switch-to-mobile}") {
        @include grid(2, 15px, 15px,'li');
      }

      @include media("<=#{$switch-to-mobile}",">mobile") {
        @include grid(4, 20px, 20px,'li');
      }


      .single-activity {

        margin-bottom: 0;
        font-size: rem(15);
        padding: 0;
        cursor: default;
        display: block;
        height: auto;

        @include mobile {
          font-size: rem(17);
        }

        & > div {
          width: 100%;
          // max-height: 130px;
          min-height: 100px;
          position: relative;
          z-index: 5;
          background: inherit;
          overflow: hidden;


          @include ratio(0.75);
        }

        span.content {
          @include s;
          @include ps;
          padding: 15px 5px;
          @include flex-spread(y,true);
        }

        .activity-title {
          line-height: 1.2;
          // @include multiline-truncate(2,15px,1.2);
        }
      }

      .counter {
        display: block;
        font-size: rem(34);
        line-height: 1;

        @include media-combine(">1100px&<=desktop","<=mobile"){
          font-size: rem(25);
        }
      }

    }

  }

  // graphs

  .attendance-graphs {

    .coming-soon {
      @include ratio(452,368);
      // max-width: 452px;
      width: 100%;
      position: relative;
      background-image: url('../img/graph-mock.png');
      background-size: contain;
      border: 1px dashed lighten($charcoal,70%);
      pointer-events: none;
      user-select: none;

      span{
        @include s;
        @include ps;
        @include flex-align;
        background-color: white(0.8);
        // color: white;
        font-size: rem(22);
      }
    }

    .attendance-graphs-header{
    	display:flex;
	align-items:center;
	justify-content: space-between;
	margin-bottom:20px;

	h3{
		margin:0;
	}

	@include media("<=mobile") {
		display:none;
	}
    }
    .graph-types{
	height: 29px;
	background-color: #dfdcd5;
	display:flex;
	align-items:center;

	@include media("<=mobile") {
		background-color: #f1efea;
		height:50px;
		padding: 0 10%;
	}
	@include media("<=320px") {
		padding: 0 5%;
	}

	li{
		text-align:center;
		cursor:pointer;
		flex-grow: 1;
		flex-shrink: 1;
    		flex-basis: 0;
		font-size: 16px;
		color: rgba(0, 0, 0, 0.3);

		&.active{
			font-weight: 600;
			color:#34332f;
		}
		&#month{
			border-left: 1px solid rgba(151, 151, 151, 0.43);
			border-right: 1px solid rgba(151, 151, 151, 0.43);

			@include media("<=mobile") {
				border:none;
			}
		}

		@include media("<=mobile") {
			font-size: 14px;
			flex-basis: initial;
		}
	}
    }
    
    .graph-wrap{
	clear:both;
	background:rgba(223, 220, 213, 0.2);
	padding:15px;

	@include media("<=mobile") {
		background:rgba(223, 220, 213, 0.1);
	}

	.graph-content{
		min-height:385px;
		max-height: 392px;
		overflow:hidden;

		&.noResults{
			min-height:392px;
			max-height: 392px;
		}

		@include media("<=mobile") {
			max-height: 387px;

			&.noResults{
				min-height:387px;
			}
		}
	}

	.subtitle{
		font-size: 22px;
		/*@include media("<=mobile") {
			font-size: 18px;
		}*/
		@include media("<=320px") {
			font-size: 18px;
		}
    	}

	&.month,&.month_4{
		.subtitle{
			display:flex;
			align-items:center;
			justify-content: space-between;

			.graph-date{
				font-size: 18px;
				@include media("<=mobile") {
					font-size: 17px;
				}
				@include media("<=320px") {
					font-size: 15px;
				}
			}
	    	}
	}
	.dates-period{
		font-size: 18px;
		margin:7px 0;

		@include media("<=mobile") {
			font-size: 16px;
		}
	}
	&.month{
		padding-right:0;

		.subtitle{
			margin-right:15px;
	    	}
	}

	span.apexcharts-legend-marker{
		display:inline-block;
		margin-right: 0;
		margin-left: 10px;
    		height: 34px !important;
		width: 10px !important;
    		float: right;
		border-radius:0 !important;
		
	}
	.apexcharts-legend-series{
		margin:8px 0 !important;
		width: 105px;

		.apexcharts-legend-text{
			font-size: 18px;
			position:relative;
			top:-3px; 

			span{
				font-size: 14px;
				position:relative;
				top:-3px;
			}
		}
	}
	.apexcharts-legend {
	    padding: 0;
	    top: 15% !important;
	}
	
	.apexcharts-tooltip {
		width: 68px;
  		height: 61px;
		background-color:#34332f;
		display: flex;
	    	align-items: center;
	    	justify-content: center;
	    	flex-direction: column-reverse;

		.apexcharts-tooltip-series-group{
			padding:0;

			.apexcharts-tooltip-text{
				font-size: 18px;
				font-weight:600;
				color:#ffffff;
				text-align:center;

				.apexcharts-tooltip-y-group {
				    padding: 0;
				}
				.apexcharts-tooltip-text-value, .apexcharts-tooltip-text-z-value {
				    margin: 0;
				}
			}
		}
		.apexcharts-tooltip-title{
			direction:ltr;
			color:#2bc39e;
			font-size:14px;
			padding: 0;
			margin:0;
		}
	}
    }
  }


  // schedule section

  & + .schedule {

    & > .wrap {
      // min-height: inherit;
    }

    .section-title {
      max-width: none;

      @include media("<=mobile"){
        @include m(l 0 b 14);
        width: 100%;
      }
    }

    .guide-select-wrap {
      float: right;
      @include m(l 30);
      // max-width: calc(100% - 200px);
      // flex-grow: 10;

      @include mobile{
        margin: 0;
        flex-grow: 5;
        max-width: calc(100% - 100px);
        margin-left: auto;

        select {
          max-width: 100%;
        }
      }
    }

    .guide-select {
      width: auto;
      min-width: 200px;
      height: 43px;
      line-height: 41px;
      border-radius: 1px;
      border: solid 1.5px $charcoal;
    }

    label[for="week-view"]{
      @include desktop{
        @include m(l 130);
      }
    }

  }


  // general mobile view


  // mobile tabs

  input[name="rashgad-tabs"] {
    @include visually-hidden;

    &:checked + .rashgad-tab {
      font-weight: 600;
      color: black;
    }
  }

  .rashgad-tab {
    @include desktop{
      display: none;
    }

    background-color: $light-grey-2;
    color: black(0.3);
    font-size: rem(16);
    display: block;
    width: (100% / 3);
    height: 51px;
    margin: 0;
    line-height: 51px;
    text-align: center;
    cursor: pointer;

    &[for='activity-types-tab'] {
      @include borders(x rgba($some-gray-1,0.43));
    }
  }

  @include mobile{
    #guides-and-courses-tab {
      &:checked ~ #guides-and-courses-panel {
        display: block;
      }
    }
    #activity-types-tab {
      &:checked ~ #activity-types-panel {
        display: block;
      }
    }
    #attendance-graphs-tab {
      &:checked ~ #attendance-graphs-panel {
        display: block;
	padding:0;
      }
    }
  }

}
