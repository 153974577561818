

.video-wrap {
  outline: 0 !important;

  &, span {
    display: block;
    width: 100%;
    position: relative;
    cursor: pointer;
  }

  span {
    z-index: 1;

    @include ratio(16/9);
    background-size: auto 140%;

    @include media("<=mobile"){
      @include ratio(315/350);
    }
  }

  &:after,&:before {
    pointer-events: all;
  }

  iframe, &:after,&:before {
    @include s;
    @include ps(y 0 r 0);
  }

  &:before,&:after,span {
    background-position: center;
  }
  &:before {
    z-index: 2;
  }
  &:after {
    @include pseudo;
    background-color: black(0.35);
    @extend %icn_play-wht;
    background-size: 64px auto;
    z-index: 3;
  }

  // mock video thumbnail
  &:before{
    @if $do-mock {
      @include pseudo;
      background-size: cover;
      background-image: url('../img/vid-th.jpg');
    }
  }

  &:focus,
  &:focus-within,
  &.focus {
    outline: 0;

    &:after,&:before{
      display: none !important;
    }
  }
}
.video-wrap.focus {
    outline: 0;

    &:after,
    &:before{
      display: none !important;
    }
}
