// sass-lint:disable-all

// media query helpers


// INPUT: two OR conditions, that might have AND inside them
// EXAMPLE:  @include media-combine( '>small-tablet&<=laptop', '>small-desktop' ) { bow: wow; }
// needs Include Media mixins/functions to work

@mixin media-combine($condition-1:null, $condition-2:null) {

  $test: str-index($condition-1, '&');

  @if $test {

    $condition-1-1: str-slice( $condition-1,  0, $test - 1  );
    $condition-1-2: str-slice( $condition-1,  $test + 1  );

    $condition-1:  unquote(parse-expression( $condition-1-1) + " and " + parse-expression( $condition-1-2));

  } @else {
    $condition-1:  unquote(parse-expression( $condition-1));
  }

  @if $condition-2 {

    $test: str-index($condition-2, '&');

    @if $test {

      $condition-2-1: str-slice( $condition-2,  0, $test - 1  );
      $condition-2-2: str-slice( $condition-2,  $test + 1  );

      $condition-2:  unquote(parse-expression( $condition-2-1) + " and " + parse-expression( $condition-2-2));

    } @else {
      $condition-2:  unquote(parse-expression( $condition-2));
    }

    @media #{$condition-1}, #{$condition-2} {
      @content;
    }

  } @else {

    @media #{$condition-1} {
      @content;
    }

  }

}


@mixin print() {
  @media print {
    @content;
  }
}

@mixin not-print() {
  @media not print {
    @content;
  }
}