// Single Activity Planner View (simple colored box)

.single-activity.planner-view {

  $h4-font-size: 18px;
  $h4-line-height: 1.17;
  $padding-y: 30px;
  $padding-x: 10px;

  display: block;
  position: relative;
  color: white;
  text-align: center;
  @include flex-align;

  @include p(x $padding-x t ($padding-y + 2px) b ($padding-y - 8px));

  cursor: pointer;
  user-select: none;

  @include not-last{
    @include m(b 15);
  }

  @at-root {
    li:not(:last-child) .single-activity.planner-view:only-child {
      @include m(b 15);
    }
  }

  h4, p{
    max-width: 100%;
  }

  h4 {
    color: inherit;
    background-color: inherit;
    font-size: rem($h4-font-size);
    line-height: $h4-line-height;
  }

  // span
  .sawrp
  {
    width: 100%;
    flex-grow: 10;
    background-color: inherit;
    display: inline-block;
    // position: relative;
    z-index: 3;
  }

  .length {
    @include m(b 0);

    &:empty {
      display: none;
    }
  }

  &:before,&:after {
    background-color: inherit;
  }
  // span,span:before
  .sawrp,.sawrp:before
  {
    border-color: inherit;
  }

  &:before,&:after{
    @include pseudo;
    @include s;
    z-index: 1;
  }

  &:after {
    @include ps;
  }

  &:before{
    @include ps(t 4 r -4);
    opacity: 0.2;
  }

  // triangle arrow for activity selector view

  $arrow-size: 28px; //25px;
  $coef: 1.25; // 1.4

  // span:before
  .sawrp:before
  {
    width: 0;
    height: 0;
    border-top: $arrow-size solid transparent;
    border-right-style: solid;
    border-right-width: $arrow-size*$coef;
    border-bottom: $arrow-size solid transparent;
    @include ps(t 50% l -17);
    @include m(t -56px/2);
  }

  // default bg color
  background-color: $brown-grey;
  border-color: $brown-grey;

  // mock colors
  @if $do-mock {
    @include assign-colors($target: '&', $props: 'background-color' 'border-color');
  }

  // IE mods

  @include ie{
    height: 1px;

    .sawrp {
      // height: 100%;
      // flex-grow: 10;
      // @include flex-align($col:true);
    }
  }

  // 3 heigths depending on time length
  // height limited by number of lines in header text

  $short-lines: 1;
  $medium-lines: 3;
  $long-lines: 4;
  $enlarge-delay: 1.5s; //0s;
  $add-to-height: $padding-y * 2 + 23px;
  $add-to-short: 15px;
  $add-to-medium: 20px;
  $add-to-long: 50px;

  $enlarge-toggle: ':hover';

  $shorten-text: false;

  &:focus {
    outline: 0;
  }

  h4 {
    text-align: center;

    @if $shorten-text {
      @include multiline-truncate($state: off);
      transition-delay: $enlarge-delay;

      &:before,&:after{
        transition-delay: ($enlarge-delay + 0.3s);
        // transition-delay: 0s;
      }
      &:before {
        font-size: 85%;
      }
    }
  }

  &.short {
    min-height: get-height-by-font-size($short-lines, $h4-font-size, $h4-line-height) + $add-to-height + $add-to-short;

    @include media("height<=#{$short-screen}"){
      min-height: get-height-by-font-size($short-lines, $h4-font-size, $h4-line-height) + $add-to-height;
    }

    @include media("height<=#{$very-short-screen}"){
      min-height: 0;
    }

    @if $shorten-text {
      @if $enlarge-toggle{
        &:not(#{$enlarge-toggle}){
          h4 {
            @include multiline-truncate($short-lines, $h4-font-size, $h4-line-height, $state: on);
          }
        }
      } @else {
        h4 {
          @include multiline-truncate($short-lines, $h4-font-size, $h4-line-height, $state: on);
        }
      }
    }
  }

  &.medium {
    min-height: get-height-by-font-size($medium-lines, $h4-font-size, $h4-line-height) + $add-to-height + $add-to-medium;

    @include media("height<=#{$short-screen}"){
      min-height: get-height-by-font-size($medium-lines, $h4-font-size, $h4-line-height) + $add-to-height;
    }

    @include media("height<=#{$very-short-screen}"){
      min-height: get-height-by-font-size($medium-lines, $h4-font-size, $h4-line-height) + $add-to-height/2;
    }

    @if $shorten-text {
      @if $enlarge-toggle{
        &:not(#{$enlarge-toggle}){
          h4 {
            @include multiline-truncate($medium-lines, $h4-font-size, $h4-line-height, $state: on);
          }
        }
      } @else {
        h4 {
          @include multiline-truncate($medium-lines, $h4-font-size, $h4-line-height, $state: on);
        }
      }
    }
  }

  &.long {
    min-height: get-height-by-font-size($long-lines, $h4-font-size, $h4-line-height) + $add-to-height + $add-to-long;

    @include media("height<=#{$short-screen}"){
      min-height: get-height-by-font-size($long-lines, $h4-font-size, $h4-line-height) + $add-to-height + $add-to-long/2;
    }

    @include media("height<=#{$very-short-screen}"){
      min-height: get-height-by-font-size($long-lines, $h4-font-size, $h4-line-height) + $add-to-long/2;
    }

    @if $shorten-text {
      @if $enlarge-toggle{
        &:not(#{$enlarge-toggle}){
          h4 {
            @include multiline-truncate($long-lines, $h4-font-size, $h4-line-height, $state: on);
          }
        }
      } @else {
        h4 {
          @include multiline-truncate($long-lines, $h4-font-size, $h4-line-height, $state: on);
        }
      }
    }
  }

  // icons

  .icons-list {

    @include ps(t 10 r 10);
    height: 23px;
    display: flex;
    z-index: 4;

    li {
      display: inline-block;
      @include text-hide;
      background-position: center;
      @include s(23);
      @include m(l 3 b 0);

      @include simptip(
        $width: auto,
        $position: top,
        $padding: 10px 15px,
        $font-size: rem(15),
        $align: center,
        $rounded: false,
        $distance: 0
      );

      &:after {
        font-family: $font-family-base;
      }
    }

    .edited {
      @extend %icn_edited-wht;
      background-size: 19px auto;
    }
    .flag {
      @extend %icn_flag-wht;
      background-size: 20px auto;
    }
    .anchor-rashgad {
      @extend %icn_shield-empty-wht;
      background-size: 15px auto;
    }
    .anchor-clan {
      @extend %icn_shield-filled-wht;
      background-size: 15px auto;
    }

    li[data-tooltip=''] {
      &:before,&:after{
        display: none;
      }
    }

  }

  // hiding on medium/small activities

  &.short,&.medium {
    .icons-list .edited {
      // display: none;
    }
  }

  // newly added activity

  &.is-new {
    animation-name: blink;
    animation-duration: 1s;
    animation-delay: 0.7s;
    animation-iteration-count: 3;
    animation-fill-mode: backwards;
  }

}