

$loader-color: white;

@mixin loader-keyframes($id:'', $loader-color: $loader-color){
  @at-root {
    @keyframes loader#{$id} {
      0%,
      100% {
        box-shadow: 0em -2.6em 0em 0em $loader-color, 1.8em -1.8em 0 0em rgba($loader-color, 0.2), 2.5em 0em 0 0em rgba($loader-color, 0.2), 1.75em 1.75em 0 0em rgba($loader-color, 0.2), 0em 2.5em 0 0em rgba($loader-color, 0.2), -1.8em 1.8em 0 0em rgba($loader-color, 0.2), -2.6em 0em 0 0em rgba($loader-color, 0.5), -1.8em -1.8em 0 0em rgba($loader-color, 0.7);
      }
      12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba($loader-color, 0.7), 1.8em -1.8em 0 0em $loader-color, 2.5em 0em 0 0em rgba($loader-color, 0.2), 1.75em 1.75em 0 0em rgba($loader-color, 0.2), 0em 2.5em 0 0em rgba($loader-color, 0.2), -1.8em 1.8em 0 0em rgba($loader-color, 0.2), -2.6em 0em 0 0em rgba($loader-color, 0.2), -1.8em -1.8em 0 0em rgba($loader-color, 0.5);
      }
      25% {
        box-shadow: 0em -2.6em 0em 0em rgba($loader-color, 0.5), 1.8em -1.8em 0 0em rgba($loader-color, 0.7), 2.5em 0em 0 0em $loader-color, 1.75em 1.75em 0 0em rgba($loader-color, 0.2), 0em 2.5em 0 0em rgba($loader-color, 0.2), -1.8em 1.8em 0 0em rgba($loader-color, 0.2), -2.6em 0em 0 0em rgba($loader-color, 0.2), -1.8em -1.8em 0 0em rgba($loader-color, 0.2);
      }
      37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba($loader-color, 0.2), 1.8em -1.8em 0 0em rgba($loader-color, 0.5), 2.5em 0em 0 0em rgba($loader-color, 0.7), 1.75em 1.75em 0 0em $loader-color, 0em 2.5em 0 0em rgba($loader-color, 0.2), -1.8em 1.8em 0 0em rgba($loader-color, 0.2), -2.6em 0em 0 0em rgba($loader-color, 0.2), -1.8em -1.8em 0 0em rgba($loader-color, 0.2);
      }
      50% {
        box-shadow: 0em -2.6em 0em 0em rgba($loader-color, 0.2), 1.8em -1.8em 0 0em rgba($loader-color, 0.2), 2.5em 0em 0 0em rgba($loader-color, 0.5), 1.75em 1.75em 0 0em rgba($loader-color, 0.7), 0em 2.5em 0 0em $loader-color, -1.8em 1.8em 0 0em rgba($loader-color, 0.2), -2.6em 0em 0 0em rgba($loader-color, 0.2), -1.8em -1.8em 0 0em rgba($loader-color, 0.2);
      }
      62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba($loader-color, 0.2), 1.8em -1.8em 0 0em rgba($loader-color, 0.2), 2.5em 0em 0 0em rgba($loader-color, 0.2), 1.75em 1.75em 0 0em rgba($loader-color, 0.5), 0em 2.5em 0 0em rgba($loader-color, 0.7), -1.8em 1.8em 0 0em $loader-color, -2.6em 0em 0 0em rgba($loader-color, 0.2), -1.8em -1.8em 0 0em rgba($loader-color, 0.2);
      }
      75% {
        box-shadow: 0em -2.6em 0em 0em rgba($loader-color, 0.2), 1.8em -1.8em 0 0em rgba($loader-color, 0.2), 2.5em 0em 0 0em rgba($loader-color, 0.2), 1.75em 1.75em 0 0em rgba($loader-color, 0.2), 0em 2.5em 0 0em rgba($loader-color, 0.5), -1.8em 1.8em 0 0em rgba($loader-color, 0.7), -2.6em 0em 0 0em $loader-color, -1.8em -1.8em 0 0em rgba($loader-color, 0.2);
      }
      87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba($loader-color, 0.2), 1.8em -1.8em 0 0em rgba($loader-color, 0.2), 2.5em 0em 0 0em rgba($loader-color, 0.2), 1.75em 1.75em 0 0em rgba($loader-color, 0.2), 0em 2.5em 0 0em rgba($loader-color, 0.2), -1.8em 1.8em 0 0em rgba($loader-color, 0.5), -2.6em 0em 0 0em rgba($loader-color, 0.7), -1.8em -1.8em 0 0em $loader-color;
      }
    }
  }
}

@mixin loader( // use on .loader
 $pos: fixed,
 $color: $loader-color,
 $bg-color: $charcoal,
 $overlay-color: white(0.25),
 $mode: pos col
){

  @if ( contains($mode, pos) ) {
    max-width: 100%;
    position:$pos;
    @if ($pos == fixed){
      width: 100vw;
      height: 100vh;
    }
    @if ($pos == absolute){
      @include s;
    }
  }

  @if ( contains($mode, col) ) {
    background-color: $overlay-color;
    $dice: random(100);
    @include loader-keyframes($dice, $color);

    &:before {
      @if ( $bg-color == transparent ) {
        background-color: transparent;
      } @else {
        background-color: rgba($bg-color,0.95);
      }
    }
    &:after{
      animation: loader#{$dice} 1.1s infinite ease;
    }
  }
}

.loader {
  @include text-hide;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: 999;

  &:before,&:after {
    @include pseudo;
    @include ps(t 50% l 50%);
  }

  &:before {
    @include s(98);
    border-radius: 4px;
    @include m(t 98px/-2 l 98px/-2);
    z-index: 10;
  }

  &:after{
    border-radius: 50%;
    z-index: 20;
    @include s(12);
    @include m(t -5px l -5px);
    font-size: 10px;
    transform: translateZ(0);
  }

  @include loader($mode:col);

  &.fixed {
    @include loader($pos: fixed, $mode: pos);
  }

  &.absolute {
    @include loader($pos: absolute, $mode: pos);
  }

}

