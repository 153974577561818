// sass-lint:disable-all


$rad-label: '&+label' !default;
$rad-checked-label: '&:checked+label' !default;
$rad-disdabled-label: '&[disabled]+label' !default;

@mixin nice-radio(
  $radius: 15px,
  $brdr-color: silver,
  $brdr-width: 1px,
  $off-bg: white,
  $on-bg: black,
  $hover-bg: lighten($on-bg,35%),
  $off-brdr-color: $brdr-color,
  $inset-width: 3px,
  $margin: 10px,
  $dir: $direction,
  $use: before,
  $general-sibling: false,
  $label-class: '' //'.radio-label'
) {

  $j:'+';
  @if $general-sibling {$j:'~';}

  $marg-side: right;
  @if ($dir == rtl) {
    $marg-side: left;
  }
  @if ($use == after) {
    $marg-side: opposite-direction($marg-side);
  }

  @include visually-hidden;

  & #{$j} label#{$label-class} {

    cursor: pointer;
    user-select: none;

    &:#{$use} {
      @include pseudo(inline-block);
      @include circle($radius, $off-bg);
      vertical-align: middle;
      flex-shrink: 0;
      transition: all 0.2s ease-in-out;
      box-shadow: inset 0 0 0 check-unit($inset-width) $off-bg;
      border: check-unit($brdr-width,px) solid $off-brdr-color;
      margin-#{$marg-side}: check-unit($margin);

      @content;
    }

    &:hover, &:active {
      &:#{$use} {
        background-color: $hover-bg;
        background: radial-gradient($hover-bg 0, $hover-bg 60%, white 60%, white 100%);
        box-shadow: inset 0 0 0 check-unit($inset-width + 2) $off-bg;
      }
    }

  }

  &:checked #{$j} label#{$label-class}
  {
    &:#{$use} {
      background-color: $on-bg;
      background: radial-gradient($on-bg 0, $on-bg 60%, white 60%, white 100%);
      box-shadow: inset 0 0 0 check-unit($inset-width) $off-bg;
      border-color: $brdr-color;
    }
  }

  &:disabled #{$j} label#{$label-class}
  {
    cursor: $cursor-disabled;

    &:hover, &:active {
      &:#{$use} {
        background-color: $off-bg;
        background: radial-gradient($off-bg 0, $off-bg 60%, white 60%, white 100%);
        box-shadow: inset 0 0 0 check-unit($inset-width) $off-bg;
      }
    }
  }

}


