// sass-lint:disable-all

// ACCESSIBILITY - INVERT ALL MIXIN
// (inverts colors of everything, works in all browsers)
// by Pavel G, CodeOasis

// USE:
//----------------------------
//    html.invert {
//      @include invert-all;
//    }


@mixin invert-this($use: before, $contrast: false, $self-pos: relative, $trick-pos: absolute, $trick-size: 500px) {

  // svg fallback (old firefox)
  filter: url("data:image/svg+xml;utf8,<svg height='0' xmlns='http://www.w3.org/2000/svg'><filter id='negative'><feColorMatrix values='-1 0 0 0 1 0 -1 0 0 1 0 0 -1 0 1 0 0 0 1 0'/></filter></svg>#negative");

  // ie <8
  @media screen\9 {
    filter: progid:DXImageTransform.Microsoft.BasicImage(invert=1);
    -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(invert=1)';
  }

  // ie 8+
  @media screen\0 {
    @if $self-pos {
      position: $self-pos;
      overflow: hidden;
    }

    &:#{$use} {
      content:"";
      width:0px;
      height: 0px;
      position: $trick-pos;
      top:50%;
      left: 50%;
      z-index:99999;
      outline: $trick-size solid invert; // <= magic here
    }
  }

  // modern browsers
  @if $contrast {
    filter: invert(1) contrast(1.2) saturate(0.8);
  } @else {
    filter: invert(1);
  }
}



@mixin invert-all($use:before, $contrast:false) {

  // element itself (html) is not inverted, so background is set to black
  background-color: black;

  // text color & border color defaults
  color: black;
  * {
    border-color: black !important;
  }
  *:before, *:after {
    border-color: black;
  }

  @include invert-this($use: $use, $self-pos: false, $trick-pos: fixed, $trick-size: 2999px, $contrast: $contrast);
}


