// sass-lint:disable-all


// BASIC STUFF
// ----------------------------------------------------------


@mixin clearfix($use:after) {
  @if ($use == both) { $use: '&:before,&:after' }
  @else if ($use == after) { $use: '&:after' }
  @else if ($use == before) { $use: '&:before' }

  #{$use} {
    content: "";
    display: table;
    clear: both;

    @if ($enable-flex) {
      flex-basis: 0;
      order: 1;
    }

    @content;
  }
}


@mixin pseudo($display: block) {
  content: "";
  display: $display;
}

@mixin ignore-div($i:false) {
  @if $i {$i:'!important';} @else {$i:'';}
  position: static#{$i};
  display: inline#{$i};
  margin: 0#{$i};
  padding: 0#{$i};
  text-align: inherit#{$i};
  float: none#{$i};
  color: inherit;
  background: none#{$i};

  &:before,&:after {
    display: none;
  }
}

@mixin visually-hidden($i:false) {
  @if $i {$i:'!important'} @else {$i:''}
    border: 0#{$i};
    display: inline#{$i};
    clip: rect(0 0 0 0)#{$i};
    clip-path: inset(50%)#{$i};
    height: 1px#{$i};
    width: 1px#{$i};
    margin: -1px#{$i};
    overflow: hidden#{$i};
    padding: 0#{$i};
    position: absolute#{$i};
    word-wrap: normal#{$i};
    line-height: 0#{$i};
    min-height: 0#{$i};
    min-width: 0#{$i};
}



// $classes: space separated list of classes without . (dot)
@mixin not-class($classes:null){
  $not-classes: '&';
  @if $classes {
    @each $class in $classes {
      $not-classes: $not-classes + ':not(.' + $class + ')';
    }
    #{$not-classes} {
      @content;
    }
  }
}








///-----------------------


@mixin fix-weird-webkit-shit(){
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
  //-webkit-transform: translateZ(0) scale(1.0, 1.0);
  //overflow:auto;
}

@mixin animation-fix {
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}


//--------------------------

// adds shekel symbol
@mixin shekel() {
  &:after {
    @include pseudo(inline-block);
    content:"\20AA";
  }
}
%shekel {
  @include shekel;
}

//--------------------------





