// Modal

$modal-double-border-margin: 10px;
$modal-double-border-width: 2px;
$animate-modal-entrance: true;
$animate-modal-type: slide-in-down; //zoom-in;
$animate-modal-length: 0.5s;
$animate-modal-border: true;

$modal-pad-x: 35;


@mixin border-enter-keyframes($id:'',$m:$modal-double-border-margin){
  @at-root {
    @keyframes border-enter#{$id} {
      0% {
        top: 0;
        right: 0;
        opacity: 0;
      }
      20% {
        opacity: 0;
      }
      30% {
        opacity: 1;
      }
      100% {
        top: $m;
        right: -1*$m;
      }
    }
  }
}

// double border
@mixin box-double-border($m:$modal-double-border-margin, $w: $modal-double-border-width, $t:'&:before',$a:$animate-modal-border, $c: $greyish, $od:true){

  $media: 0px;
  @if $od {
    $media: $switch-to-mobile;
  }

  @include media(">#{$media}"){
    #{$t} {
      @include pseudo;
      @include s;
      @include ps(r  -1*$m t $m);

      border-right: $w solid $c;
      border-bottom: $w solid $c;
      background-image: linear-gradient(to right,#{$c},#{$c}),linear-gradient(to right,#{$c},#{$c});
      background-size: ($m - $w) $w, $w ($m - $w);
      background-position: right top, bottom left;

      @if $a{
        animation-name: border-enter;
        animation-fill-mode: both;
        animation-duration: 0.2s;
        animation-delay: 0.2s;
      }
    }
  }

}

@include border-enter-keyframes;

.modal-backdrop,.modal-box{
  &:focus {
    outline: 0;
  }
}

.modal-backdrop {
  @include fix {
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
  }
  z-index: 3000;
  background-color: black(0.8);
  direction: ltr;

  @include wrap-pads(x y);

  @include desktop{
    @include flex-align;
  }

  @include mobile{
    overflow-y: auto;
  }

  .click-catcher {
    @include s;
    @include fix;
  }
}

.modal-box {

  width: 100%;
  max-width: 920px;
  background-color: $light-grey-3;
  position: relative;
  z-index: 2;
  direction: rtl;

  @include desktop{
    max-height: 90vh;
  }

  @include not-ie{
    @include m(x auto);
  }

  @include box-double-border;

  @if $animate-modal-entrance{
    @include animate($animate-modal-type, $animate-modal-length);
  }


  .wrap {
    min-height: 300px;
    background-color: inherit;
    position: relative;
    flex-direction: column;

    & > section{
      @include animate(fade-in, 0.5s);
    }

    @include wrap-pads(x b, $do-huge:false, $x: $modal-pad-x);
  }

  .modal-header {
    @include break-out-wrap(x, $do-huge:false, $x: $modal-pad-x);
    @include wrap-pads($do-huge:false, $x: $modal-pad-x);

    h2 {

    }
  }


  // close button

  .close-modal {
    @include ps(l ($boxpad - 2) t ($header-height/2 - 10));
    z-index: 20;

    & ~ * {
      position: relative;
    }

    &.move-right-left {
      animation:linear infinite alternate;
      animation-name: move;
      animation-duration: .5s;
    }
    @keyframes move {
      0% { transform: translateX(-10px);}
      100% { transform: translateX(10px);;}
    }
  }


  // loader

  .loader{

    @include loader(
      $pos: absolute,
      $color: $charcoal,
      $bg-color: transparent,
      $overlay-color: white(0.25)
    );

    & + section {
      background-color: inherit;
      z-index: 30;

      & > * {
        visibility: hidden;
      }
    }
  }
}

@mixin mod-modal(
  $m: null,
  $w: null
){

  @if $w{
    &:before {
      border-right: $w solid $greyish;
      border-bottom: $w solid $greyish;
    }
  }

  @if $m{

    @if not $w {
      $w: $modal-double-border-width;
    }

    &:before {
      @include ps(r  -1*$m t $m);
      background-size: ($m - $w) $w, $w ($m - $w);
    }
    @if $animate-modal-border {
      $dice: random(100);
      @include border-enter-keyframes($dice, $m);

      &:before {
        animation-name: border-enter#{$dice};
      }
    }
  }

}

// compact modal

.modal-backdrop.cmpct {

  .close-modal{
    @include close-button-color(blk);
    @include s(14);
    background-size: 14px auto;
    top: 14px;
    left: 14px;
  }

  .modal-box {

    @include mod-modal(6px);

    max-width: 315px;
    background: white;
    text-align: center;

    .wrap {
      padding: 15px;
      min-height: 0;
    }

    h3,h4{
      line-height: 1.1;
    }

    h3 {
      font-size: rem(30);
      font-weight: 400;
      @include m(b 25);
    }

    h4 {
      font-size: rem(16);
      font-weight: 700;
      @include p(x 30);
      @include m(b 15);
    }

    p {
      font-size: rem(14);
    }

    .actions {
      font-size: 0;

      button {
        width: calc(50% - 3px);

        @include not-first {
          @include m(r 6);
        }

        @include only {
          width: 100%;
          @include m(x auto);
        }
      }
    }

  }

}

// compact but a bit bigger

@mixin bigger-cmpct-modal {

  .close-modal {
    @include s(20);
    background-size: 18px auto;
    top: 23px;
    left: 20px;
  }

  .modal-box {
    max-width: 400px;

    & > .wrap {
      @include p(x 20 b 20 t 17);
    }
  }

  .actions, .button-wrap {
    @include m(t 25);
  }

  button:only-child {
    width: 100%;
    @extend %color-button;
  }

}
%bigger-cmpct-modal{
  @include bigger-cmpct-modal;
}