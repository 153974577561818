// sass-lint:disable-all

// FLEXBOX MIXINS


// reorders items
// provide {{$new-order}} for all items.
// by default, sets vertical flow (items go from top to bottom), if you need gorizontal flow (from left to right), set {{$dir}} to 'row';

// @include flex-order(1 3 2 4 5); - will make 2nd and 3rd items switch positions, leaving other items in place.

@mixin flex-order($new-order,$dir:column,$item:'') {

  display: flex;
  flex-direction: $dir;

  //if just 1 number passed, that is considered to be total number of children to be ordered subsequently
  @if (not is-list($new-order)) and (is-number($new-order)) {

    @for $i from 1 through $new-order {
      &>#{$item}:nth-child(#{$i}) {
        order: $i;
      }
    }

  } @else {

    @each $order in $new-order {
      $i: index($new-order, $order);

      &>#{$item}:nth-child(#{$i}) {
        order: $order;
      }
    }

  }


}


// inserts a full width pseudo elememnt after {{$after}} column cells, to start a new column row. If there are more that 10 column cells total, provide parameter {{$total}}

// @include flex-break(10,20) - insert a flow break after 10th cell out of 20 total

@mixin flex-break($after:null, $total:10, $item: $grid-cell-class, $type:false) {
  $child: 'child'; @if $type { $child: 'of-type' }

  &:after {
    content:'';
    width: 100%;
    order: $after + 1;
  }

  & > #{$item} {

    flex-grow: 1;
    flex-shrink: 1;

    @for $i from 1 through $after {
      &:nth-#{$child}( #{$i} ) {
        order: $i;
      }
    }
    &:nth-#{$child}( #{$after + 1} ) {
      order: $after + 2;
      clear: both;
    }
    @for $i from ($after + 1) through $total {
      &:nth-#{$child}( #{$i} ) {
        order: $i+1;
      }
    }
  }

}



// aligners etc

@mixin flex-align($pos: x y, $col: false) {
  display: flex;

  $x-prop: justify-content;
  $y-prop: align-items;

  @if $col {
    flex-direction: column;
    $x-prop: align-items;
    $y-prop: justify-content;
  }

  @if ( contains($pos, x) ) {
   #{$x-prop}: center;
  }
  @if ( contains($pos, l) ) {
    @if ($direction==ltr){
      #{$x-prop}: flex-start;
    }
    @if ($direction==rtl){
      #{$x-prop}: flex-end;
    }
  }
  @if ( contains($pos, r) ) {
    @if ($direction==ltr){
      #{$x-prop}: flex-end;
    }
    @if ($direction==rtl){
      #{$x-prop}: flex-start;
    }
  }
  @if ( contains($pos, y) ) {
    #{$y-prop}: center;
  }
  @if ( contains($pos, t) ) {
    #{$y-prop}: flex-start;
  }
  @if ( contains($pos, b) ) {
    #{$y-prop}: flex-end;
  }
}

@mixin flex-spread($d: x, $col: false, $display:flex) {
  display: $display;

  @if not $col {
    @if ( contains($d, x) ) {
      justify-content: space-between;
    }
    @if ( contains($d, y) ) {
      flex-wrap: wrap;
      align-content: space-between;
    }
  }

  @if $col {
    flex-direction: column;

    @if ( contains($d, x) ) {
      align-content: space-between;
    }
    @if ( contains($d, y) ) {
      justify-content: space-between;
    }
  }
}

