// onboarding popups

@mixin block-interface($t:'&:after'){

  pointer-events: none;

  & > * {
    position: relative;
  }

  #{$t}{
    @include pseudo;
    @include s;
    @include ps;
    background-color: white(0);
    pointer-events: none;
    z-index: 20;
  }
}


.onboarding {

  white-space: normal;

  .click-catcher,
  .close-modal,
  .modal-box:before
  {
    display: none;
  }

  .modal-box {
    text-align: center;

    & > .wrap {
      padding: 30px 25px;
      @include flex-align;
    }
  }

  button.next-step {
    font-size: 18px;
    width: 230px;
    @include m(x 0, $i: true);
    @include color-button($i:true);
  }

  h2, p {
    user-select: none;
    letter-spacing: -0.5px;
  }


  &.step-1 {

    .modal-box {
      max-width: 675px;
    }

    h2 {
      font-size: rem(45);
      font-weight: 400;
    }

    p {
      font-size: rem(32);
      @include m(b 25);
    }

  }

  &.step-2,&.step-4,
  &.step-3,&.step-5,&.step-6
  {
    .modal-box {
      max-width: 400px;

      & > .wrap {
        min-height: 0;
      }
    }

    p {
      font-size: rem(22);
      color: black;
    }
  }

  &.step-2,&.step-4
  {
    .modal-box {
      @include m(b 15%);
    }
  }


  &.step-2,&.step-4 {

    .modal-box {
      &:after{
        @include ps(t 50%);
        @include m(t -15);
      }
    }

    & + span {
      width: 100%;
      @include ps(x 0 y 0);
      z-index: 3500;
      padding: inherit;
      display: flex;
      flex-direction: column;
      @include block-interface;
    }

  }

  &.step-2 {

    .modal-box {

      @include m(r calc(22% + 40px));

      @if $adjust-layouts-for-bigger-screens {
        @include media(">huge"){
          @include m(r calc(25% - 4vw + 80px));
        }
      }

      @include media("<=1000px"){
        @include m(r (215px + 30px));
      }

      @if $animate-modal-entrance{
        @include animate(slide-in-right, 0.5s);
      }

      &:after {
        @include triangle(right, $light-grey-3, 15px, 1.2);
        right: -18px;
      }
    }

    & + span {
      .top-part, .activity-filters {
        background: white;
        box-shadow: 0 0 0 20px white;
        pointer-events: none;
      }
      .activity-filters {
        flex-grow: 10;
      }
    }
  }

  &.step-4 {

    .modal-box {

      @include m(l calc(25% + 50px));

      @if $adjust-layouts-for-bigger-screens {
        @include media(">huge"){
          @include m(l calc(25% - 4vw + 80px));
        }
      }

      @include media("<=1200px"){
        @include m(l (295px + 40px));
      }

      @if $animate-modal-entrance{
        @include animate(slide-in-left, 0.5s);
      }

      &:after {
        @include triangle(left, $light-grey-3, 15px, 1.2);
        left: -18px;
      }
    }

    & + span {
      .section-title, & > .actions, .date-and-time, .activities-list {
        background: $light-grey-2;
      }
      .section-title, .date-and-time, .activities-list {
        box-shadow: 0 0 0 20px $light-grey-2;
      }
      & > .actions{
        box-shadow: 0 22px 0 20px $light-grey-2;
      }

      .activities-list {
        display: none;
      }

      .date-and-time {
        flex-grow: 10;
      }
    }

  }

  &.step-3,&.step-5{
    .modal-box {

      &:after {
        @include triangle(top, $light-grey-3, 15px, 1.2);
        @include ps(t -18);
      }
    }
  }

  &.step-3 {

    align-items: flex-start;

    .modal-box {

      @include m(t 350);

      @include media("<=huge"){
        position: relative;
        left: 1.5%;
      }

      @include media("<=#{1160px}"){
        left: 3%;
      }

      @include media("<=#{1050px}"){
        left: 4%;
      }

      @include media("<=#{1000px}"){
        position: static;
        left: 0; //-7%;
      }

      &:after {
        @include ps(l 50%);
        @include m(l -15);
      }
    }

    & ~ .section-title,
    & ~ .activities-list > li:first-child {
      position: relative;
      z-index: 3500;
      background-color: white;
      box-shadow: 0 0 0 20px white;
    }

    & ~ .activities-list > li:first-child {
      @include block-interface;
    }

    & ~ .section-title {
      width: 100%;
      max-width: none;
    }

  }

  &.step-5 {

    justify-content: flex-start;
    align-items: flex-start;

    .modal-box {
      @include m(x 0 t ($header-height - 15px));

      &:after {
        @include ps(l 155);
        @include m(l -15);
      }

      @if $adjust-layouts-for-bigger-screens {
        @include media(">huge"){
          @include m(l 1vw);
        }
      }
    }

    & ~ button.save {
      z-index: 3500;
      opacity: 1;
      pointer-events: none;
      cursor: default;
    }
  }

  &.step-6 {

    @include s;
    @include ps;
    z-index: 25;
    @include flex-align;
    background-color: black(0.8);

    @include box-double-border($c:rgb(47,46,46),$a:false);

    & ~ .modal-header {

      button.add, button.edit, button.next {

        @include simptip(
          $position: bottom,
          $color: $light-grey-3,
          $text-color: $charcoal,
          $font-size: rem(18),
          $rounded: false,
          $animate: false,
          $padding: 15px,
          $arrow: 15px,
          $hover: always,
          $align-to-host: right,
          $width: 200px
        ){
          font-family: $font-family-base;
          white-space: normal;
        }

        z-index: 3500;
        overflow: visible;
        opacity: 1;
        cursor: default !important;

        &:before,&:after{
          // display: none;
        }

        &:before{
          @include animate(slide-in-up2, 0.4s);
        }
        &:after {
          @include animate(slide-in-down, 0.4s);
        }
      }

      button.add {
        &:after {
          max-width: 100%;
        }
      }

      button.edit {
        &:before,&:after{
          @include m(t 2);
        }
        &:after {
          width: 275px;
        }
      }

      button.next {
        border-color: $light-grey-2;
        background-color: transparent;
        @extend %icn_ar_left-lghtgr2;

        &:after {
          width: 250px;
          right: -45px;
        }
      }

    }
  }

}