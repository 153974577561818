// sass-lint:disable-all


// HELPER FUNCTIONS FOR BOXMODEL-RELATED MIXINS
// by Pavel G
// ----------------------------------------------------------

@function unshorten-pos($p:null) {
  @if ($p == abs) or ($p == a) {
    $p: absolute;
  }
  @if ($p == fix) or ($p == f)  {
    $p: fixed;
  }
  @if ($p == rel) or ($p == r)  {
    $p: relative;
  }
  @return $p;
}

