

.page-faq-page {

  @include drupal-page(b);

  // $content-pages-center-content: false;
  // $content-pages-max-width-2-cols: null;

  $border-color: rgb(232,229,224); // $light-grey-2

  // layout

  #content.column > .section{
    @include p(t 20);
  }

  .main-nav {
    @include desktop{
      z-index: 10;
    }
  }

  #page-title {
    @include desktop{
      font-weight: 400;
      position: relative;
      z-index: 6;
      @include wrap-pads(r, $do-mobile:false, $do-huge:false);
      @include m(b 20);
    }
  }

  #content, .section {
    // height: 100%;
  }

  #page-title,
  .tab-panels {

    @include desktop{
      display: flex;

      max-width: $content-pages-max-width-2-cols;

      @if $content-pages-center-content {
        @include m(x auto);
      }
    }
  }

  .tab-list {
    display: block;

    @include desktop{
      @include grid-cell(35% 2, 0);
    }

  }

  .tab {
    @include wrap-pads(x, $do-huge:false);
  }

  .panel-list {
    @include desktop{
      @include grid-cell(65% 2, 0);
      // @include wrap-pads(r, $do-mobile:false,$do-huge:false);
      @include p(r 2.8vw);
    }
  }


  // style

  #page-title {
    @include desktop{
      font-size: rem(30);
      font-weight: 400;
    }
  }

  .tab-panels {

    .tab-list {
      position: relative;
      z-index: 5;

      @include desktop{
        background-color: $off-white-2;
        @include outstretch-bg(right, $off-white-2, $h:300vh, $t:-100px);
      }

    }

    .tab {
      @include p(y 20);
      border-bottom: 2px solid $border-color;

      @if $content-pages-center-content {
        @include desktop{
          @include outstretch-border(right, $border-color, 2px){
            bottom: -2px;
          }
        }
      }

      @include nth(1){
        @include desktop{
          border-top: 2px solid $border-color;

          @if $content-pages-center-content {
            @include outstretch-border(right, $border-color, 2px, before){
              top: -2px;
            }
          }
        }
      }

      @include desktop{
        color: $brown-grey;
      }

      &[aria-selected='true'] {
        color: rgb(8,8,8);
        font-weight: 600;
        letter-spacing: -0.15px;
      }

    }

    .tab, .panel-title {
      // span,
      a {
        display: inline;
        text-decoration: none;
        color: inherit;
        pointer-events: none;
      }
    }

    .panel-list {

      @include desktop{
        @include overlay-stripes($bg:$stripe-bg, $h:300vh);
      }

      .panel, .panel-title, .the-answer {
        @include desktop{
          position: relative;
          z-index: 2;
        }
      }

      .panel-title {
        line-height: 1.2;
        @include m(t 14 b 20);
      }

    }

  }


  // mobile

  @include mobile{

    // general

    .page > .wrap {
      @include p(x 0);
    }

    #content.column > .section {
      @include p(t 0 b 50);
    }

  }


  // wtf
  .block-system + .contextual-links-region,
  #block-views-faq-faq-recent {
    display: none;
  }

}




