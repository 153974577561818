

#comment-form,
.comments, .comment
{
    .attribution {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }

    .userpic {
        @include userpic-size(65px, 4px, 4px 0px);
        @include m(l 17);
    }
}

#comment-form,
.comments
{
    padding-bottom: 60px;

    .title {
        color: black;
        font-size: rem(22);
        font-weight: normal;
        line-height: normal;
        margin-bottom: 14px;
    }

    @include desktop {
        .comment-text, form > div {
            max-width: 706px;
        }
    }

    .submitted {
        color: black;
        font-size: rem(15);
        font-weight: 600;
        line-height: 2.33;
    }

    .form-item {
        label,
        a,
        .grippie {
            display: none;
        }

        textarea {
            height: 180px;
            border: solid 1px $greyish;
            @include p(15);
        }
    }

    .form-actions {
        display: flex;
        justify-content: flex-end;

        input {
            @include make-button;
            @include outline-button($charcoal);
            @include m(t 15);
            max-width: 230px;

            @include mobile {
                max-width: 140px;
            }
        }
    }

}

.comment {

    &-control {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        @include p(b 28);
        @include m(b 29);
        border-bottom: 1px solid lighten($some-gray-1, 10%);

        @include mobile {
            flex-flow: row wrap;
        }

        .links {
            font-size: rem(15);
            font-weight: 600;
            line-height: normal;

            @include mobile {
                margin-right: auto;
		margin-top:10px;
            }

            a {
                text-decoration: none;
            }
        }
    }

    @mixin comment-control-icon($comment-icn: null, $icn-col: null) {
        a {
            position: relative;

            &::before {
                @include pseudo(inline-block);
                @include size(14px);
                @extend %icn_#{$comment-icn}-#{$icn-col};
                background-size: contain;
                @include p(l 30);
                @include m(b -3);
            }
        }
    }

    &-delete {
        @include comment-control-icon(trash, chrk);
    }

    &-edit {
        @include comment-control-icon(pencil, chrk);
    }

    &-reply {
        @include comment-control-icon(comment, chrk);
    }

    &-time {
        color: $brown-grey;
        font-size: rem(14);
        font-weight: normal;
        line-height: 2.5;
    }

    &-text {
        .field-name-comment-body {
            font-size: rem(16);
            font-weight: normal;
            line-height: 1.56;
            min-height: 135px;
            display: block;
            @include m(t 16);

            @include desktop {
                @include p(x 29 y 21);
                background-color: rgba($light-grey-3, 0.3);
            }

	    @include mobile {
		min-height: initial;
	    }
        }

        .new {
            display: none;
        }
    }
}

// drupal mods

.node-type-article .comments#comments {
    margin-top: 25px;
}


// edit / delete comment

.page-comment-reply .attribution,
#comment-form:first-child, #comment-confirm-delete {
    @include padding(y 60);
}
.comment + #comment-form{
    padding: 0;
}


#comment-form {

    // hiding  stuff on edit/delete page
    #edit-author {
        display: none;
    }

    .form-wrapper {
        max-width: 706px;
    }

}

.comment  {
    .comment-text .content > * {
        max-width: 706px;
    }
}

#comment-confirm-delete {
    .form-actions.form-wrapper {
        @include flex-spread;
        @include padding(t 30);
	max-width:480px;

    }
    #edit-cancel, #edit-submit {
        @include make-button;
        @include outline-button($charcoal);
        @include m(t 15);
        width: 230px;

        &:hover {
            color: inherit;
        }
    }
    #edit-submit, #edit-cancel{
        background-size: 14px auto;
        background-position: left 12px center;
        max-width: 48%;
    }
    #edit-submit{
        @extend %icn_trash-chrk;
    }
    #edit-cancel {
        @extend %icn_close-chrk;
    }

    @include mobile {
        padding-left:10px;
	padding-right:10px;
    }
}
