
// sass-lint:disable-all

// MISC GLOBAL CSS
//---------------------------------

// grid init

// default row class
@if (not  $use-pure-css) and ($include-grid){
  #{$grid-row-class}
  {
    @include grid-row($use-firstlast: true,  $force: true) {
      padding: 0;
      margin: 0;
      position: relative;
    };
  }
  #{$grid-cell-class}
  {
    width: 100%;
    min-height: 1px;
    margin: 0;
    position: relative;
  }
}


@if $use-global-css {

  // tiny normalize

  html,body {
    padding: 0;
    margin: 0;
    border: 0;
    min-height: 100vh;
    box-sizing: border-box;

    @media only screen and (max-device-width: 480px) {
      -webkit-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
    }
    @media only screen and (max-device-width: 768px) {
      background:#ffffff;
    }
  }

  // body {
    *,
    *::before,
    *::after {
      backface-visibility: hidden;
      -webkit-font-smoothing: antialiased;
      box-sizing: inherit;
      background-repeat: no-repeat;
	    background-position: center;
      -webkit-overflow-scrolling: touch;
    }
  // }

  @-webkit-viewport {
    width: device-width;
    zoom: 1.0;
  }

  @-moz-viewport {
      width: device-width;
      zoom: 1.0;
  }

  @-ms-viewport {
      width: device-width;
      zoom: 1.0;
  }

  @-o-viewport {
      width: device-width;
      zoom: 1.0;
  }

  @viewport {
      width: device-width;
      zoom: 1.0;
  }

  img {
    border: 0;
    border-style: none;
    vertical-align: middle;
  }

  img,
  audio,
  video,
  canvas,
  svg {
      max-width: 100%;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  p, input, label, select,
  h1,h2,h3,h4,h5,h6
  {
    &:only-child,
    &:last-child {
      margin-bottom: 0;
    }
  }
  br {
    &:only-child,
    &:last-child {
      //display: none;
    }
  }

  a, button, .button {
    cursor: pointer;

    &:active {
      position: relative;
      top: 1px;
    }
  }

  table {
    //table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
  }

  ul, ol {
    @if (not $use-pure-css){
      @include reset-list;
    }
  }


  // angular stuff

  [ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak, .ng-hide, [v-cloak], .v-cloak
  // [hidden], .hidden
  // , [aria-hidden="true"]
  {
    display: none !important;
    position: static;
  }

  [ng-click], [data-ng-click] {
    cursor: pointer;
  }


  // forms & inputs

  label > *:not(.help) {
    // pointer-events: none;
  }


  // removing extra browser controls on inputs

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    height: auto;
  }

  input[type=number] {
    -moz-appearance:textfield;
  }

  input {
    &::-ms-clear {
      display: none;
      width : 0;
      height: 0;
    }
  }
  select {
    &::-ms-expand {
      display: none;
    }
  }

  input[type=date]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    display: none;
  }
  input[type=date]::-webkit-calendar-picker-indicator {
    -webkit-appearance: none;
    appearance: none;
    display: none;
  }


  // removing Yellow autocomplete background

  input,select,textarea {
    @if (not $use-pure-css){
      @include remove-autofill;
    }
  }


  // making inputs that expect numbers & latin letters be LTR

  input[type=email], input[type=number], input[type=tel], input[type=url],
  input[type=text].en, input[type=text][class*="email"], input[type=text][class*="number"]
  {
    //direction: ltr !important;
    //text-align: left;
  }

} // @if $use-global-css

@mixin on-modal-open(){
  @include desktop-browsers(true){

  }
}

@if $use-global-css {

  // PREVENT WINDOW JUMP ONMODAL OPEN
  //html.modal-open, html:not(.modal-open) body.modal-open
  //body
  @if $include-modal-open {
    #{$modal-open-class-holder} {

      #{$modal-open-scroll-element}{
        @if $modal-open-insist-on-scroll {
          overflow-y: scroll;
        }
      }

      $modal-open-scroll-element-has-scroll:'&';
      @if ($modal-open-use-scroll-class){
        $modal-open-scroll-element-has-scroll: '&.#{$modal-open-has-scroll-class}';
      }

      #{$modal-open-classes} {
        #{$modal-open-scroll-element}{
          overflow: hidden;

          @if ($modal-open-use-scroll-class or $modal-open-insist-on-scroll) {

            #{$modal-open-scroll-element-has-scroll}{

              /* autoprefixer: off */
              @media not all and (hover: none) {
                max-width: calc(100vw - 16px);
              }
              // undo on mobile devices
              @media (hover: none), (pointer: coarse), (pointer: none) {
                max-width: none;
              }
              // safari
              @include safari {
                max-width: calc(100vw - 15px);
              }

              // same for extra classes
              @if $modal-open-also-do {
                .#{$modal-open-also-do-class}{
                  /* autoprefixer: off */
                  @media not all and (hover: none) {
                    max-width: calc(100vw - 16px);
                  }
                  // undo on mobile devices
                  @media (hover: none), (pointer: coarse), (pointer: none) {
                    max-width: none;
                  }
                  // safari
                  @include safari {
                    max-width: calc(100vw - 15px);
                  }
                }
              }

              // this depends on additional javascript

              @at-root {
                &.osx,&.s15 {
                  max-width: calc(100% - 15px);
                  @if $modal-open-also-do {
                    .#{$modal-open-also-do-class}{
                      max-width: calc(100vw - 15px);
                    }
                  }
                }
                &.s17 {
                  max-width: calc(100% - 17px);
                  @if $modal-open-also-do {
                    .#{$modal-open-also-do-class}{
                      max-width: calc(100vw - 17px);
                    }
                  }
                }
                &.s12 {
                  max-width: calc(100% - 12px);
                  @if $modal-open-also-do {
                    .#{$modal-open-also-do-class}{
                      max-width: calc(100vw - 12px);
                    }
                  }
                }
                // and another undo for mobiles
                &.is-mobile,
                &.is-touch
                {
                  max-width: none;
                  @if $modal-open-also-do {
                    .#{$modal-open-also-do-class}{
                      max-width: none;
                    }
                  }
                }
              }

              margin-right: auto;
              @if $modal-open-also-do {
                .#{$modal-open-also-do-class}{
                  margin-right: auto;
                }
              }
            }
          }

        }
      }
    }
  }

  // COMMON CLASSES

  .pull-left {
    float: left;
  }
  .pull-right {
    float: right;
  }
  em,.em {
    font-weight: normal;
    font-style: italic;
  }

  .de-em {
    font-weight: 400;
  }

  .nowrap {
    white-space: nowrap;
  }

  // most of the time, we want pseudo elements to be click-transparent
  @if $global-css-seudo-rules {
    :before,:after {
      pointer-events: none;
    }
  }

  main,section,footer,header,.wrap {
    position: relative;
  }

}

// chosen from bootstrap 4

@if $use-bootstrap-reboot {

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    main,
    menu,
    nav,
    section,
    summary {
      display: block;
    }
    audio,
    canvas,
    progress,
    video {
      display: inline-block;
    }
    html {
      -ms-overflow-style: scrollbar;
      -webkit-tap-highlight-color: rgba(0,0,0,0);
    }

    [tabindex="-1"]:focus {
      outline: none !important;
    }
    [role="button"] {
      cursor: pointer;
    }
    a {
      background-color: transparent;
    }
    a:active,
    a:hover {
      outline-width: 0;
    }
    b,
    strong {
      font-weight: inherit;
    }
    b,
    strong {
      font-weight: bolder;
    }
    small {
      font-size: 80%;
    }
    sub {
      bottom: -0.25em;
    }
    sup {
      top: -0.5em;
    }
    input:focus, input:valid {
      box-shadow: none;
      // outline: none;
    }
    p:last-child {
      margin-bottom: 0;
    }
    li:last-child, ul:last-child {
        margin-bottom: 0;
    }

    h1, h2, h3, h4, h5, h6 {
      margin-top: 0;
      margin-bottom: .5rem;
    }
    p {
      margin-top: 0;
      margin-bottom: 1rem;
    }
    ol,
    ul,
    dl {
      margin-top: 0;
      //margin-bottom: 1rem;
    }
    ol ol,
    ul ul,
    ol ul,
    ul ol {
      margin-bottom: 0;
    }
    a,
    area,
    button,
    [role="button"],
    input,
    label,
    select,
    summary,
    textarea {
      touch-action: manipulation;
    }
    [type="checkbox"],
    [type="radio"] {
      box-sizing: border-box;
      padding: 0;
    }
    textarea {
      overflow: auto;
    }
    th {
      text-align: left;
    }
    label {
      display: inline-block;
      margin-bottom: .5rem;
    }
    textarea {
      resize: vertical;
    }
    input,
    button,
    select,
    textarea {
      margin: 0;
      line-height: inherit;
      border-radius: 0;
    }
    fieldset {
      min-width: 0;
      padding: 0;
      margin: 0;
      border: 0;
    }
    button,
    input,
    select,
    textarea {
      font: inherit;
    }
    button,
    input,
    select {
      overflow: visible;
    }
    button,
    input,
    select,
    textarea {
      margin: 0;
    }
    button,
    select {
      text-transform: none;
    }
    button,
    [type="button"],
    [type="reset"],
    [type="submit"] {
      cursor: pointer;
    }
    [disabled] {
      cursor: default;
    }
    button,
    html [type="button"],
    [type="reset"],
    [type="submit"] {
      -webkit-appearance: button;
    }
    button::-moz-focus-inner,
    input::-moz-focus-inner {
      border: 0;
      padding: 0;
    }
    button:-moz-focusring,
    input:-moz-focusring {
      outline: 1px dotted ButtonText;
    }

}

