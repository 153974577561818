// PAGE: CREATE ACTIVITY


.page-creating-activity {

  $huge-add-pad-1: 4vw;
  $huge-add-pad-2: 1vw;

  min-width: $body-min-width;


  .page-header {
    @if $adjust-layouts-for-bigger-screens {
      @include media(">huge"){
        @include p(x $huge-add-pad-2);
      }
    }
  }



  fieldset {
    position: relative;
    margin: 0;
  }

  // backgrounds

  &, .filters, .activities {
    background-color: $off-white-2;
  }

  .planner {
    background-color: $light-grey-2;
  }

  background-image: linear-gradient(to right, #{$light-grey-2} 0%, #{$light-grey-2} 30%, #{$off-white-2} 50%, #{$off-white-2} 100%);
  background-size: 100% 100%;


  // general layout

  main > .wrap{
    // @include grid-row;

    display: flex;
    flex-wrap: nowrap;
  }

  .filters {
    // @include grid-cell(22%, 0, $minmax:false);

    flex-basis: 22%;
    flex-shrink: 1;
    min-width: 215px;

    border-left: 1px solid $light-grey-2;
    @include p(l $boxpad);

    @if $adjust-layouts-for-bigger-screens {
      @include media(">huge"){
        flex-basis: 25%;
        @include p(l $huge-add-pad-1 r $huge-add-pad-2);
      }
    }
  }

  .activities {
    // @include grid-cell(53%, 0, $minmax:false);
    flex-basis: 52%;
    flex-shrink: 2;

    @include p(x $boxpad);

    @if $adjust-layouts-for-bigger-screens {
      @include media(">huge"){
        flex-basis: 50%;
        flex-shrink: 0;
        @include p(x $huge-add-pad-1);

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		flex-basis: 46%;
	}
      }
    }

  }

  .planner {
    // @include grid-cell(25%, 0, $minmax:false);
    flex-basis: 25%;
    min-width: 295px;


    @include p(r $boxpad);

    @if $adjust-layouts-for-bigger-screens {
      @include media(">huge"){
        flex-shrink: 1;
        @include p(r $huge-add-pad-1 l $huge-add-pad-2);
      }
		}
		.share-schedule{
			label{
				line-height: 1;
			}
		}
  }

  .filters,.activities,.planner {
    @include p(t 40 b 60);
  }


  li {
    // display: block;
  }

  // footer modified for rope knot to be off-centrt

  .page-footer {
    @if $adjust-layouts-for-bigger-screens {
      @include media("<=huge"){
        @include move-footer-knot($new: 52%);
      }
    } @else {
      @include move-footer-knot($new: 52%);
    }
  }

  // headers

  h3, section.activities h4 {
    font-size: rem(22);
    font-weight: 400;
  }

  h3 {
    line-height: 1.2;
  }

  .section-title {
    @include m(b 25);

    & + select {
      @include m(t 3);
    }
  }


  // filters

  section.filters {

    h3 {
      float: right;
      max-width: calc(100% - 100px);
    }

    .select-wrap {
      text-align: left;
    }
    .info-text{
	background:#262626;
	color:#ffffff;
	display:none;
	font-weight: 600;
    	font-size: 14px;
	padding:5px;
	text-align:center;
	position: absolute;
    	left: -65px;
    	z-index: 5;
    	top: 30px;
	width:150px;

	&:before {
		position: absolute;
	    	content: "";
	    	top: -7px;
    		left: 70px;
    		width: 0;
    		height: 0;
    		border-style: solid;
    		border-width: 0 7px 7px 7px;
    		border-color: transparent transparent #262626 transparent;
	}
    }
    .info-icon{
	padding:0 9px;
	cursor:pointer;
	border:1px solid #262626;
	border-radius:50%;
	margin-right:5px;
	position:relative;
    }
    .top-part {
      @include flex-spread;
      align-items: flex-start;
    }

    .activity-filters {
      clear: both;
    }

    .single-filter {

      // mock colors
      @if $do-mock {
        @include assign-colors($target: '&', $mod: '[type="radio"]:checked + label', $props: 'background-color');
        @include assign-colors($target: '&', $mod: 'label',  $props: 'border-color');
      }

      display: block;
      @include m(b 10);
    }

    [type="radio"] {
      @include visually-hidden;
    }

    label {
      display: block;
      width: 100%;
      cursor: pointer;
      height: 58px;
      line-height: 58px;
      font-size: rem(18); //rem(20);
      text-align: center;
      border-right-width: 8px;
      border-right-style: solid;
      background-color: $light-grey-3;

      &:hover {
        opacity: 0.6;
      }

      &:empty {
        display: none;
      }
    }

    [type="radio"]:not(:checked) + label {
      background-color: $light-grey-3 !important;
    }

    [type="radio"]:checked + label {
      color: white;
    }


  }


  // activities section > activity list

  section.activities {
    
    h3, h4, .right-wrap {
      float: right;
    }
    
    h3 {
      max-width: calc(100% - 115px);
    }

    h4 {
      max-width: calc(100% - 120px);
      line-height: 1.2;
    }

    & > .actions {
      float: left;
      line-height: 2.4;
    }

    .activities-list {
      clear: both;

      & > li{
        display: block;
        @include m(b 20);
      }
    }

    .single-activity {
      border: 1px solid $light-grey-2;
      padding: 20px;

      &:after {
				content: "";
				display: table;
				clear: both;
      }
    
      .right-wrap {
        width:78%;
      }

      h4 {
        @include m(b 15);
      }

      .length {
        color: black(0.7);
        float: left;
        margin: 0;
        white-space: nowrap;
        line-height: normal;
				text-align:center;

				.versions{
					font-size: 1.375rem;
					color:red;
				}

				.move-down{
					background: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.25 6C11.25 6.19888 11.1809 6.38961 11.0578 6.53024C10.9348 6.67086 10.7679 6.74987 10.5938 6.74987L2.99136 6.74986L5.80898 9.96828C5.86999 10.038 5.91839 10.1208 5.9514 10.2119C5.98442 10.303 6.00142 10.4006 6.00142 10.4992C6.00142 10.5978 5.98442 10.6954 5.9514 10.7865C5.91839 10.8776 5.86999 10.9604 5.80898 11.0301C5.74797 11.0998 5.67555 11.1551 5.59583 11.1928C5.51612 11.2306 5.43069 11.25 5.34441 11.25C5.25813 11.25 5.1727 11.2306 5.09298 11.1928C5.01327 11.1551 4.94084 11.0998 4.87984 11.0301L0.942776 6.5309C0.881669 6.46125 0.833187 6.3785 0.800107 6.2874C0.767028 6.1963 0.75 6.09863 0.75 6C0.75 5.90137 0.767028 5.8037 0.800107 5.7126C0.833187 5.6215 0.881669 5.53875 0.942776 5.4691L4.87984 0.969908C4.94085 0.900188 5.01327 0.844884 5.09298 0.807152C5.1727 0.76942 5.25813 0.75 5.34441 0.75C5.43069 0.75 5.51612 0.76942 5.59584 0.807152C5.67555 0.844884 5.74797 0.900188 5.80898 0.969908C5.9322 1.11071 6.00142 1.30168 6.00142 1.50081C6.00142 1.59941 5.98442 1.69704 5.9514 1.78814C5.91839 1.87923 5.86999 1.962 5.80898 2.03172L2.99136 5.25014L10.5938 5.25014C10.7679 5.25014 10.9348 5.32914 11.0578 5.46977C11.1809 5.61039 11.25 5.80112 11.25 6V6Z' fill='black'/%3E%3C/svg%3E") no-repeat center center;
					height:20px;
					width:20px;
					transform: rotate(-90deg);
					
					@media (max-width: 768px){
						float:left;
					}
				}
      }

      &:hover {
          cursor: pointer;
          background-color: rgba($light-grey-3, 0.3);
          box-shadow: 5px 5px 0 0 $light-grey-3;
          position: relative;
          top: -2px;
          left: -2px;
        }
    }

    .categories-list{
      .txt {
        @include visually-hidden;
      }
    }
    
    .age-select{
	float: left;
	font-size: 1.2rem;
    	font-weight: 400;
	line-height:normal;
    }

  }


  // planner

  section.planner {

    h3 {
      @include m(b 5);
    }

    & > span > .actions,
    & > .actions {
      font-size: rem(15);
      @include m(b 25);

      li {
        display: inline-block;

        @include not-last{
          @include p(l 10);
          @include m(l 6);
          border-left: 1px solid $some-gray-1;
        }
      }

    }

    .date-and-time {
      @include m(b 5);
    }

    // time select & choose date common

    select.time-select, button.choose-date {
      background-color: $light-grey-3;
      width: 100%;
      font-size: rem(14);
      text-align: right;
      letter-spacing: -0.3px;
      @include p(l 40);

      background-size: 13px auto;
      background-position: left 20px center;

      @include m(b 10);
    }
		.schedule-title{
			position:relative;
			@include m(b 10);

			label {
				margin: 0;
				position: absolute;
				top: 0;
				line-height: $base-button-height;
				@include p(r 20);
				font-size: rem(14);
				pointer-events: none;
			}
			#scheduleTitle {
				background-color: $light-grey-3;
				width: 100%;
				font-size: rem(14);
				text-align: right;
				letter-spacing: -0.3px;
				@include p(r 93);
				@include p(l 20);
				color:black(1);
				border:none;
				height: 43px;
			}
			.error-note{
				display:block;
				padding-bottom: 3px;
				background-color: #f1c40f;
				color: #000;
			}
		}
		.schedule-title #scheduleTitle::-webkit-input-placeholder{
			color:black(1);
			opacity: 1;
		}
		.schedule-title #scheduleTitle:-moz-placeholder{
			color:black(1);
			opacity: 1;
		}
		.schedule-title #scheduleTitle::-moz-placeholder{
			color:black(1);
			opacity: 1;
		}
		.schedule-title #scheduleTitle:-ms-input-placeholder{
			color:black(1);
			opacity: 1;
		}

    // time select

    .time-select {

      height: $base-button-height;
      line-height: $base-button-height;
      border: 0;
      @include p(r 95);
      color: black(0.7);

      &:hover:not(:disabled)
      {
        opacity: 0.6;
      }

      &:focus{
        outline: 0;
      }
    }

    label[for='time-select'] {
      margin: 0;
      position: absolute;
      top: 0;
      line-height: $base-button-height;
      @include p(r 20);
      font-size: rem(14);
      pointer-events: none;
    }

    // datepicker

    button.choose-date {

      @include p(r 20);

      @extend %icn_ar_down-chrk;
      &.active {
        @extend %icn_ar_up-chrk;
      }

      span {
        display: inline-block;
        line-height: 1;

        @include first{
          border-left: 1px solid $some-gray-1;
          padding-left: 7px;
          margin-left: 4px;
        }
        @include last{
          color: black(0.7);
        }
      }

    }

    .planner-datepicker {
      position: relative;
      z-index: 100;
      @include animate(slide-in-down, 0.3s);


      .close {
        @include mod-close;
        @include ps(t 10 l 10);
        z-index: 50;
        display: none;
      }
    }

    // anchor checkbox

    .anchor {
      font-size: rem(15);
      @include m(b 15);
    }

    // activity boxes

    .activities-list {

      // planner view (inherits from Partials file)

      &.anchors {
        @include m(b 20);

        .single-activity {
          &.disabled {
            cursor: default;

            &,&:hover{
              &:before, .actions{
                display: none;
              }
            }
          }
        }
      }

      .single-activity {
        @include p(x 30 t 35 b 30);
        cursor: move;

        .length {
          margin: 0;
        }

        .actions {
          z-index: 10;
        }

        &:not(:hover){
          &:before,
          .actions
          {
            display: none;
          }
	
	  &:not(.disabled) {
            &:after {
              @extend %icn_drag-wht;
              display:none;
            }
          }
        }
	&:focus,
	&.focused {
	   &:before,
          .actions
          {
            display: block;
          }
	
	  &:not(.disabled) {
            &:after {
              display:block;
            }
          }
	}
        &:hover,
	&:focus {
          &:not(.disabled) {
            top: -1px;
            left: -1px;

            &:after {
	      display:block;
              @extend %icn_drag-wht;
              background-position: right 10px top 50%;
              backgruond-size: 10px auto;
            }
          }
        }


        // actions

        .actions {
          @include ps(t 7 l 10);
        }

        button.trash,
        button.edit
        {
          min-width: 0;
          @include s(23);
          background-color: white(0.2);
        }
        button.edit {
          background-size: 14px auto;
        }
        button.trash {
          background-size: 11px auto;
        }


        // mock colors
        @if $do-mock {
          @include assign-colors($target: '&', $props: 'background-color');
        }

      }

    }

    .total-time {
      text-align: center;
      @include m(t 20);
      font-weight: 600;
      color: black(0.7);
		}
		
		.shared-with-title{
			margin:10px 0 5px 0;
			font-weight: 600;
		}
		.shared-with-date{
			font-weight: 600;
		}
		.shared-with-user:not(:last-child) {
			margin-left: 3px;
		}
  }

}

// activity box when dragged

.page-creating-activity {

  .single-activity{
    &.gu-mirror{

      &:before, .actions, .icons
      {
        display: none !important;
      }

    }
  }

}


// misc fixes

.page-creating-activity {
  &.modal-open{
    main > .wrap {
      overflow: hidden;
    }
  }
}
.activities-popup.modal-backdrop.confirmation-modal.share-activity-confirm {
	.modal-box {
				max-width: 420px;
				h4{
					font-size: 1.2rem;
				}
				p{
					font-size: 1rem;
    			line-height: 1.3;
				}
	    	max-width: 430px;
	
		.actions{
			button{
				white-space: initial;
				line-height:normal;
				height:50px;
				padding: 0 5px;
			}
		}
	}
}
.activities-popup.modal-backdrop{
	.mobile.print-menu {
		display:none;
	}
}
.activities-popup.modal-backdrop.activity-info-modal{
	.modal-header{
		.top-part{
			.right-side{
				.used-sort-activity{
					margin-top:3px;
				}
			}
			.left-side.used-sort-activity {
				display: flex;
				align-items: flex-end;
			}
		}
	}
}
@media (max-width: 768px){
	.activities-popup.modal-backdrop{
		&.activity-edit-modal{
			.buttons-wrap{
				button{
					width: 32%!important;
					margin-left: 1px;
					&.btn-cancel{
						margin-left: 1px;
					}
				}
			}
		}
		padding:0;
	
		.modal-box {
		    max-width: initial;
		}

		header{
			margin-bottom:0;

			h2{
				font-size:22px;
				height:45px;
			}
			.mobile.print-menu{
				align-self: flex-start;
				top: 15px;
				left: -15px;
				position: relative;
				display: block;

				button{
					background: none;
					border: none;
					height: 23px;

					span{
						background: #fff;
						display: block;
						width:4px;
						height: 4px;
						border-radius: 50%;
						position: relative;

						&:after,
						&:before{
							content:'';
							background: #fff;
							display: block;
							width:4px;
							height: 4px;
							border-radius: 50%;
							position: absolute;
							left:0;
						}
						&:before{
							top:-8px;
						}
						&:after{
							bottom:-8px;
						}
					}
				}
				.print-popup {
					position: absolute;
					left: 0;
					bottom: -47px;
					background: #F1F0EA;
					border: 1px solid #DDDBDA;
					box-shadow: 0 1px 3px 0 rgba(0,0,0,0.20);
					border-radius: 4px;
					width: 150px;
    			height: 40px;

					button.mobile-print {
						width: 100%;
						height: 100%;
						background: none;
						border: none;
						font-size: 13px;
						display: block;
						line-height: normal;
						max-height: none;
						padding-left: 10px;
						font-weight: 600;
						text-align: left;
					}
				}
			}
		}
		
		.close.back {
		    	top: 17px;
		    	right: 20px;
			position:absolute;
		    	z-index: 101;
		}
		.close-modal,
		.picked-date,
		.datepicker-calendar .button-wrap{
			display:none;
		}
		.pager p .month, 
		.pager p .year {
		    font-size: 1.4rem;
		}
		.buttons-wrap{
			padding: 13px 5%;

			.btn-cancel{
				background:#ffffff;
				border: 1px solid #000000;
				font-size: 17px;
				font-weight: 600;
				height:55px;
				margin-left:2.5%;
				width:48%;
			}
			.btn-next{
				background:#000000;
				border: 1px solid #000000;
				color:#ffffff;
				font-size: 17px;
				font-weight: 600;
				height:55px;
				width:48%;
			}
			.btn-edit,
			.btn-change,
			.btn-delete{
				display:none;
			}
		}
	}
	.shared-schedule-info{
		.mobile.buttons-wrap{
			display: none;
		}
		.mobile.buttons-wrap.shrd-schdl{
			display: flex;
			justify-content: space-between;
			margin-top:10px;
			
			button{
				width: 30%;
				margin: 0;
			}
		}
	}
	.page-creating-activity {
		min-width:initial;
		padding-bottom:40px;
		background:#f1efea;

		.link-home,
		.page-header .actions,
		.page-header .summary,
		.planner,
		section.activities h3,
		footer,
		.categories-list li .img,
		.date-and-time fieldset,
		.total-time{
			display:none;
		}

		section.filters [type="radio"]:not(:checked) + label {
		    background-color: #dfdcd5 !important;
		}	

		.selected-date{
			background:none;
		  	font-size: 22px;
		  	font-weight: bold;
		  	text-align: center;
		  	color: #ffffff;
			margin-right: -10px;
			width:61%;
			&:after{
				content:'';
				background:url('../img/ic-drop.svg') no-repeat left center;
				height: 16px;
			    	width: 16px;
			    	display: inline-block;
			    	background-size: cover;
			}
		}
		.selected-time {
		  	font-size: 18px;
		  	font-weight: 600;
			text-align: center;
			background-color: #f1efea;
			color: #000000;
			padding:5px 9px 3px;
			line-height:1;
			width: 88px;
    			height: 44px;

			.ovals span{
				display:inline-block;
				width: 5.5px;
  				height: 5.5px;
				border-radius:3px;
				margin:0 1px;
			}
			
		}
		.page-header .summary{
			font-size: 22px;
  			font-weight: bold;
			line-height: normal;
			color: #ffffff;
			text-align:center;
			width:100%;
		}
		 main > .wrap {
		    	display: block;
		    	flex-wrap: initial;
			min-height:initial;
			padding:0;
			margin:0;

			.filters,
			.activities {
			    	flex-basis: initial;
			    	flex-shrink: initial;
			    	min-width: initial;
				padding:0;
				margin:0;
				background:none;
			}

			section.filters{
				.single-filter{
					display:inline-block;
					margin-bottom: 0;

					label{
						padding:0 25px;
						border-right-width: 4px;
						height: 50px;
    						line-height: 50px;
					}
				}
				.top-part {
					padding:15px 15px 0;
					background:#ffffff;

					.section-title {
					    	margin-bottom: 20px;
						
					}

					.select-wrap{
						float:left;
						width: 60%;

						.list-icon{
							background:transparent url('../img/ic-list.png') no-repeat center center;
							width:30px;
							height:43px;
							float:left;
							margin-top:-5px;
							margin-right:5px;
						}
						.course-select{
							font-size:18px;
							background-size: 17px auto;
							background-position: left 9px;
							padding: 0 0 0 20px;
							margin-top: 2px;
							max-width: 65%;
						}
						.info-icon{
							margin-right:2px;
							top:2px;
						}
						.info-text{
							left: 0;
							top:50px;

							&:before {
							    top: -6px;
							    left: 55px;
							}
						}
					}
				}
			}
			section.activities {
				
				.categories-list {
					li{
						margin-left: 10px;
					}
					li:last-child, 
					li:last-of-type {
					    	margin-left: 0px;
					}
					.txt {
					    	clip: initial;
					    	clip-path: initial;
					    	height: initial;
					    	width: auto;
					    	margin: 0;
					    	position: static;
					    	word-wrap: normal;
					    	line-height: normal; 
	  					height: 32px;
	  					border: solid 1px #76746e;
						padding: 5px 12px;
						display: inline-block;
					}
				}
				.right-wrap{
					max-width: 100%;
				    	float:none;
					width: 100%;
				}
				h4 {
					max-width: 100%;
				    	float:none;
					margin-bottom: 0;
					font-size: 20px;
					font-weight: 600;
				}
				.single-activity {
					padding: 12px 25px;
					border:none;
					border-bottom: 1px solid #dfdcd5;

					.length {
					    	float: none;
	  					font-size: 16px;
	  					font-weight: normal;
	  					line-height: 1.38;
	 					color: #000000;
						margin-bottom:18px;
						text-align:initial;
					}
				}
				.activities-list > li {
				    	margin-bottom: 0;
				}
				&.empty-ul{
					background:none;
					.activities-list:empty{
						background:url('../img/profile-pic.png') no-repeat center center;
						height:175px;
					}
					.empty{
						display:block;
						font-size: 22px;
						font-weight: 600;
	  					line-height: normal;
	  					text-align: center;
						color: #000000;
						width:100%;
						margin-top:55px;
						max-width:100%;
					}
				}
			}
			section.planner {
				background:#ffffff;
			    	flex-basis: initial;
			    	min-width: initial;
			    	padding: 20px 15px;

				.section-title,
				.actions,
				.activity-info{
					display:none;
				}

				.date-time{
					font-size: 18px;
					line-height: 1.33;
					color: #000000;
					text-align:center;

					.date-chosen,
					.s-time .time,
					.time-total{
						font-weight:bold;
					}
					.time-total {
						margin-top:5px;
		
						span{
							font-weight:normal;
						}
					}
				}
				.activities-list {
					&.anchors {
						margin-bottom: 10px;
					}
					.single-activity {
	    					padding: 10px 30px 30px 30px;
						touch-action: none;

						&:not(:last-child):not(:last-of-type){
							margin-bottom:10px;
						}

						&:after {
						    	background-position: 0 0;
	    						display: block !important;
	    						transform: rotate(90deg);
	    						width: 13px;
	    						height: 23px;
	    						top: 5px;
	    						left: 48%;
	    						bottom: initial;
	    						right: initial;
	    						background-size: cover;
						}
					}
				}
			}
		}
		> .buttons-wrap{
			padding: 13px 5%;
			background:#34332f;
			position:fixed;
			bottom:0;
			width:100%;
			z-index:5;

			.btn-cancel{
				background:#34332f;
				border: 1px solid #ffffff;
				color:#ffffff;
				font-size: 17px;
				font-weight: 600;
				height:55px;
				margin-left:2.5%;
				width:48%;
			}
			.btn-next{
				background:#ffffff;
				border: 1px solid #ffffff;
				font-size: 17px;
				font-weight: 600;
				height:55px;
				width:48%;
			}
			.btn-edit,
			.btn-change,
			.btn-delete{
				display:none;
			}

			button:hover:not(:active):not(:disabled) {
			    	opacity: 1;
			}
			
		}
		&.activities-edit{
			.btn-cancel{
				width:31%;
			}
			.btn-delete{
				background:#34332f url('../img/ic-delete-white-small.png') no-repeat center center;
				border: 1px solid #ffffff;
				width:14%;
				display:inline-block;
				margin-left:2.5%;
				height:55px;
			}
		}
		
		.activities-popup.modal-backdrop.activity-info-modal{
			.page-header,
			.buttons-wrap,
			.bottom-part,
			.actions.button-group-lght button.edit{
				display:none;
			}

			.close.back {
			    	right: 10px;
				top:12px;
			}

			.modal-box > .wrap{
				padding:0;

				.modal-header {
					background:none;

					.actions{
						@include make-header;

						padding:8px 20px 0;
						position:static !important;

						.p-title{
							display:inline-block;
							color:#ffffff;
							font-weight:bold;
							font-size: 22px;
							margin-top:5px;
							text-align:left;
							width:55%;
						}
						.add{
							float:left;
							margin:0;
						}
					}

					.top-part {
					    	display: block;
					    	justify-content: initial;
						padding:12px 30px 20px;

						.right-side {
						    	max-width: initial;
							
							h2{
								height:initial;
								color: #dd3a3a;
								font-weight: 600;
							}
							.meta.length,
							.used-sort-activity{
								background: none;
    						padding: 0;
								line-height: 1.38;
			 					color: #000000;
								margin-bottom:18px;
							}
							.used-sort-activity{
								margin-top:0;
							}
							.length-used{
								display: flex;
								justify-content: space-between;
								align-items: center;
							}
						}
						.categories-list {
							li{
								margin-left: 10px;
							}
							li:last-child, 
							li:last-of-type {
							    	margin-left: 0px;
							}
							.txt {
							    	clip: initial;
							    	clip-path: initial;
							    	height: initial;
							    	width: auto;
							    	margin: 0;
							    	position: static;
							    	word-wrap: normal;
							    	line-height: normal; 
			  					height: 32px;
			  					border: solid 1px #76746e;
								padding: 5px 12px;
								display: inline-block;
							}
						}
					}
				}
				.simplebar-scroll {
				   	direction: rtl;
				   	margin-right: 0;
				    	flex-direction: initial;
					min-height: calc(90vh - 130px);
					max-height: calc(90vh - 130px);

					.swiper-container {
					    	width: 100%;

						.single-method .description {
						    margin-top: 20px;
						}
						.m-edit{
							background:transparent url('../img/ic-edit.png') no-repeat center center;
							width:24px;
							height:24px;
							position:absolute;
							left:30px;
							top:16px;
						}
						.activity-info-modal .methods-list {
						    	padding: 16px 30px;
						}
					}
				}
			}
		}
		.activities-popup.modal-backdrop.activity-edit-modal{
			.page-header,
			.modal-header .actions,
			.close.back,
			.file-upload label span,
			.file-upload .input-wrap,
			.file-upload .input-wrap input,
			.file-upload .input-wrap .file-size,			
			.file-upload label.desktop{
				display:none;
			}
			.file-upload{
				margin:0 0 10px;
 
				.input-wrap{
					display:block;
					background:none;
					border:none;
					max-width:initial;
					height:24px;
					line-height:normal;

					.file{
						padding:0;
						height:initial;
					}

					button.remove-file {
					   	background: transparent url(../img/ic-delete.png) no-repeat center center;
					    	width: 24px;
					    	height: 24px;
					    	float: left;
					}
				}
			}
			.close-modal{
				display:block;
				right:15px;
				top:15px;
				font-size:35px;
				color:#000000;
			}
			.modal-box .wrap{
				padding-left:0;
				padding-right:0;
			}
			header {
			    	background: none;

				h2{
				  	font-size: 30px;
				  	line-height: normal;
				  	text-align: center;
				  	color: #000000;
					margin-top:40px;
				}
			}
			.simplebar-scroll {
			    	max-height: calc(85vh - 82px - 15px - 54px);
				margin-right: 0;
				margin-top: 15px;

				> .content {
				    	padding-right: 15px;
					padding-left: 15px;

					.appendix-name .remove-file{
						background:transparent url('../img/ic-delete.png') no-repeat center center;
						width:24px;
						height:24px;
						float: left;
					}
					.file-upload label{
						background-color: #76746e;
					  	font-size: 17px;
					  	font-weight: 600;
					  	line-height: normal;
					  	text-align: center;
					  	color: #ffffff;
						padding: 9px 0px 9px 16px;
					
						&:before{
							    content: '+';
							    width: 48px;
							    height: 48px;
							    background-color: #76746e;
							    font-size: 50px;
    							    font-weight: lighter;
							    line-height: 46px;
							    text-align: center;
							    color: #ffffff;
							    margin-left: 15px;
							    border-left: 1px solid #ffffff;
						}
					}
					.methods-list > li:last-child, 
					.methods-list > li:last-of-type {
					    	padding-bottom: 0;
					}
					input[type='text']{
						font-size:18px;
					}
				}
			}
			footer {
			    	display: block;
			    	padding: 12px 20px 12px 20px;
			}
		}
	}
	.activities-popup.modal-backdrop.confirmation-modal{
		.buttons-wrap, .page-header{
			display:none;
		}
	}
	.activities-popup.modal-backdrop.calendar-info-modal{
		.page-header,
		button.close.back,
		.buttons-wrap .btn-next{
			display:none;
		}
		.buttons-wrap .btn-edit{
			display:inline-block;
		}
		.buttons-wrap {
		    	padding: 13px 0;
		}
	}
	.activities-popup.modal-backdrop.course-info-modal{
		.page-header,
		button.close.back,
		.buttons-wrap,
		.aclivities-list-wrap {
			display:none;
		}
		.close-modal{
			display:inline-block;
		}
	}
	.activities-popup.modal-backdrop.schedule{
		.modal-header,		
		.buttons-wrap,
		button.close.drk{
			display:none;
		}
		fieldset{
			padding: 13px 5%;

			.btn-change{
				display:block;
				background: #000000;
			    	border: 1px solid #000000;
			    	color: #ffffff;
			    	font-size: 17px;
			    	font-weight: 600;
			    	height: 55px;
			    	width: 100%;
			}
		}
		.close.back{
			top: 19px;
    			right: 19px;
		}
		.simplebar-scroll{
			min-height:400px;
		}
		.modal-box .wrap{
			padding:0;

			.page-header{
				margin-bottom:20px;
				align-items: center;
				    	justify-content: space-between;
					min-height:initial;

				button.back{
					display:none;
				}
				
				h1{
					width:71%;
					margin:0 10% 0 0;

					button{
						background:none;
				  		font-size: 22px;
				  		font-weight: bold;
				  		text-align: center;
				  		color: #ffffff;
						margin-top:-6px;
					}

					&:after{
						content:'';
						background:url('../img/ic-drop.svg') no-repeat left 2px;
						height: 16px;
					    	width: 16px;
					    	display: inline-block;
					    	background-size: cover;
						transform: rotate(180deg);
						margin-right:5px;
					}
				}
				
				.selected-time{
					float:left;
					margin:11px 20px 0;
				}
			}
		}
		.single-activity {
	    		&:not(:last-child):not(:last-of-type){
				margin-bottom:10px;
			}
		}
		.single-activity.planner-view .icons-list{
			display:none;
		}
		.single-activity.planner-view h4,
		.single-activity.planner-view p {
    			font-size: 12px;
		}
		.single-activity.planner-view{
			min-height:120px !important;
		}
		.week-view .days-list:not(.no-swipe) .swiper-slide:not(.swiper-slide-active) header {
    			opacity: 1;

		}
		.week-view .single-day-planner header{
			p.date,
			.j-date,
			.time{
				font-size:12px;
				border: none;
				padding:0;
			}
			.time{
				display:none;
			}
		}
		.inline-datepicker {
		    	position:fixed;
			top:80px;
			right:0;

			.datepicker-calendar .pager {
			    	padding-right: 15px;
			    	padding-left: 15px;
			    	top: auto;
				position: static;
				width:100%;
			}
		}
	}
	.single-activity.planner-view{
		min-height: 140px !important;

		 .icons-list{
			top:auto;
			bottom:10px;
		}
		.actions {
    			top: auto !important;
    			bottom: 10px;
			display:block !important;	

			button{
				height:24px;
				width:24px;
				font-size:16px;
				margin: 0 2px;
			}
		}	
	}
	.activities-popup.modal-backdrop.activity-info-list-modal{
		padding: 30px;

		.page-header,
		button.close.back,
		.buttons-wrap,
		.buttons-wrap span {
			display:none;
		}
		.close-modal{
			display:inline-block;
			@include close-button-color(wht);
			top: 10px;
    			left: 10px;
		}
		header{
			background-color: #52504c;
			padding: 20px 15px;

			.activity-date,
			.activity-time{
	  			font-size: 22px;
	  			font-weight: normal;
	  			line-height: 1.36;
	  			color: #ffffff;

				span {
				  font-weight: 600;
				}
			}
		}
		.activity-components-list{
			padding-top:20px;

			.item{
				margin-bottom:20px;
				clear:both;

				.oval-wrap{
					display: inline-block;
					float:right;
					width: 7%;

					.oval{
						height:10px;
						width:10px;
						display: inline-block;
	    					border-radius: 10px;
						margin-left:10px;
					}
				}

				.title-time{
					display:inline-block;
					float:right;
					width: 93%;

					.title {
					 	font-size: 18px;
					  	font-weight: 600;
					  	line-height: 1.22;
					  	color: #000000;
						margin-left:10px;
						display:inline-block;
					}

					.time {
					  	font-size: 16px;
					  	font-weight: normal;
					  	line-height: 1.38;
						display:inline-block;
					}
				}
				&:after {
				  	content: "";
				  	display: table;
				  	clear: both;
				}
			}
			.components-total-time{
				border-top: solid 1px #dfdcd5;
				padding-top:20px;
				margin-top:35px;
				font-size: 22px;
				line-height: 1.27;
				clear:both;

				span {
				 	font-size: 18px;
				  	font-weight: normal;
				  	line-height: 1.56;
				  	color: #000000;
				}
			}
		}
	}
	.activities-popup.select-guide-modal{
		.page-header,
		.button-wrap{
			display:none;	
		}
	}
	.page-creating-activity section.activities .age-select{
		float:none;
		margin:15px 0;
		font-size: 1.1rem;
		font-weight:600;
	}
	.page-creating-activity section.activities .activities-list {
	    border-top: 1px solid #dfdcd5;
	}
}
@media (max-width: 480px){
	.activities-popup.modal-backdrop.schedule .modal-box .wrap .page-header h1 {
	    	width: 61%;
	}
}
@media (max-width: 360px){
	.activities-popup.modal-backdrop.schedule .modal-box .wrap .page-header .selected-time {
    		margin: 7px 15px 0;
	}
	.activities-popup.modal-backdrop.schedule .close.back {
	    	top: 15px;
	    	right: 15px;
	}
	.activities-popup.modal-backdrop.schedule .modal-box .wrap .page-header h1 button {
	   	margin-top: -5px;
	}
	.activities-popup.modal-backdrop.schedule .modal-box .wrap .page-header h1:after {
    		background: url(../img/ic-drop.svg) no-repeat left center;
		background-size: cover;
	}
}
@media (max-width: 320px){
	.page-creating-activity .selected-time {
	    padding: 5px 6px 3px;
	}
	.page-creating-activity .selected-date,
	.activities-popup.modal-backdrop.schedule .modal-box .wrap .page-header h1 button {
	    font-size: 18px;
	}
	.page-creating-activity.activities-edit .btn-cancel {
	    width: 30.5%;
	}
	.page-creating-activity main > .wrap section.activities.empty-ul .empty{
		margin-top:5%;
	}
	.activities-popup.modal-backdrop.schedule .modal-box .wrap .page-header h1 {
	    	width: 60%;
	}
	.activities-popup.modal-backdrop.schedule .modal-box .wrap .page-header h1:after{
		margin-top:-3px;
	}
	.page-creating-activity section.filters .info-icon{
		display:none;
	}
	.activities-popup.modal-backdrop.confirmation-modal.share-activity-confirm .modal-box .actions button {
	   font-size: 13.9px;
	}
}
