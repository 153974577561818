
// sass-lint:disable-all

// TEXT-RELATED MIXINS
//---------------------------------



// CSS image replacement
@mixin text-hide($bg:false,$brd:false,$i:false) {
  @if $i {$i:'!important'} @else {$i:''}
  font: 0/0 a#{$i};
  color: transparent#{$i};
  text-shadow: none#{$i};
  text-decoration: none;
  @if $bg {
    background-color: transparent#{$i};
  }
  @if $brd {
    border: 0#{$i};
  }
}

// other variant
%hide-text {
    text-indent: 150%;
    white-space: nowrap;
    overflow: hidden;
}


@mixin no-text-select($cur:default) {
  user-select: none;
  @if $cur {
    cursor: $cur;
  }
}


@mixin truncate-text($w: 100%, $overflow: ellipsis){
  max-width: $w;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: $overflow; // values are: clip, ellipsis, or a string
}


@function get-height-by-font-size($lines:2,$f:18px,$l:1.5){
  @return round($f*$l*$lines);
}

@mixin multiline-truncate(
  $lines: 3,
  $font-size: 16px,
  $line-height: 1.5,
  $bg: inherit,
  $off-height: 300px,
  $transition: 0.6s,
  $state: null, // possible values:   off, on, null
  $dir: $direction,
  $use-single: false
){

  $dots-side: right; @if ($dir == rtl) { $dots-side: left; }

  @if not ($state == on) {
    background-color: $bg;
    position: relative;
    overflow: hidden;
    // text-align: justify;

    @if ( $lines != 1 ) or (not $use-single) {
      &:before,&:after{
        background-color: inherit;
        display: inline-block;
        position: absolute;
        // #{$dots-side}: 0;
        overflow: hidden;
      }
      &:before {
        content: "\2026";
        bottom: 0;
        border-radius: 0.4em;

        // height: 0.6em;
        // line-height: 0;
        width: 1em;
      }
      &:after {
        content: "";
        @include s(1em,1.2em);
      }
    }
  }

  @if ($state == off) {
    max-height: $off-height;
    transition: max-height $transition;

    @if ( $lines != 1 ) or (not $use-single) {
      &:before,&:after {
        transition: all 0.5s;
        transition-delay: $transition / 2;
        max-width: 0;
        #{$dots-side}: -50px;
      }
    }
  }
  @if ($state == on) or (not ($state == off)) {
    @if ( $lines != 1 ) or (not $use-single) {
      &:before,&:after{
        max-width: 50px;
        max-height: 50px;
        transition-delay:0s;
        #{$dots-side}: 0px;
      }
    }
    @if ($lines == 1) and ($use-single) {
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    max-height: get-height-by-font-size($lines, $font-size, $line-height);
  }
}


@mixin hyphenate() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

@mixin outline-text($color:white, $width:1px) {
  text-shadow: $width*-1 $width*-1 0 $color, $width $width*-1 0 $color, $width*-1 $width 0 $color, $width $width 0 $color;
}
