// sass-lint:disable-all

// SassyCast
// https://github.com/HugoGiraudel/SassyCast
// (shortened to basics)


/// Toggle strict mode, in which script will throw when not able to cast a value
/// into a certain type (mostly color and number). In non-strict mode, it will
/// return the default value from the given type.
/// @access public
/// @type Bool
$sc-strict-mode: false !default;

/// Default return value for the `to-number(..)` function when running in
/// non-strict mode.
/// @access public
/// @type Number
$sc-non-strict-default-number: 0 !default;

/// Default return value for the `to-color(..)` function when running in
/// non-strict mode.
/// @access public
/// @type Number
$sc-non-strict-default-color: transparent !default;

/// Internal map for dynamically accessing default values for non-strict mode.
/// @access private
/// @type Map
$sc-non-strict-defaults: (
  'number': $sc-non-strict-default-number,
  'color': $sc-non-strict-default-color,
);

/// Internal constants map.
/// @access private
/// @type Map
$sc-constants: (
  'DECIMAL_SPACE': ('0', '1', '2', '3', '4', '5', '6', '7', '8', '9'),
  'HEXADECIMAL_SPACE': ('0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'),
  'UNITS': ('px', 'cm', 'mm', '%', 'ch', 'pc', 'in', 'em', 'rem', 'pt', 'ex', 'vw', 'vh', 'vmin', 'vmax', 'ms', 's', 'deg', 'rad', 'grad', 'turn', 'Hz', 'kHz', 'dpi', 'dpcm', 'dppx'),
  'UNIT_VALUES': (1px, 1cm, 1mm, 1%, 1ch, 1pc, 1in, 1em, 1rem, 1pt, 1ex, 1vw, 1vh, 1vmin, 1vmax, 1ms, 1s, 1deg, 1rad, 1grad, 1turn, 1Hz, 1kHz, 1dpi, 1dpcm, 1dppx),
  'FALSEY_VALUES': (false, null, '', 0),
);


/// Convert to bool
/// @param {*} $value - value to cast
/// @return {Bool}
@function to-bool($value) {
  @if index(map-get($sc-constants, 'FALSEY_VALUES'), $value) {
    @return false;
  }

  @return true;
}


/// Helper function to throw when running in strict mode, or warn and return
/// default value for expected type when running in non-strict mode.
/// @param {*} $value
/// @param {String} $expected-type
@function _sc-throw($value, $expected-type) {
  $return-value: map-get($sc-non-strict-defaults, $expected-type);

  @if $sc-strict-mode {
    @error 'Could not cast `#{inspect($value)}` to #{$expected-type}.';
  } @else {
    @warn 'Could not cast `#{inspect($value)}` to #{$expected-type}; returning `#{$return-value}`.';
  }

  @return $return-value;
}


/// Cast a value to a number if possible or return 0
/// @param {String} $value - complete source
/// @return {Number}
@function to-number($value) {
  $type: type-of($value);

  // If the value is already a number, we can safely return it.
  @if ($type == 'number') {
    @return $value;
  }

  // If the value is the `true` boolean, we return 1.
  @if ($value == true)  {
    @return 1;
  }

  // If the value is the `false` boolean, we return 0.
  @if ($value == false) {
    @return 0;
  }

  // If the type is not a string, there is no way we can convert it to a number,
  // so we warn and return the default number value (or throw in strict mode).
  @if ($type != 'string') {
    @return _sc-throw($value, 'number');
  }

  // At this point we have discarded all the edge cases so we can start trying
  // to cast the value into a number.
  $pointer: 1;
  $result: 0;
  $first-character: str-slice($value, $pointer, $pointer);
  $allowed-first-character: join(('-', '.'), map-get($sc-constants, 'DECIMAL_SPACE'));

  // We perform an early check for errors. If value starts with neither a number
  // nor a minus sign, it cannot be casted to a number. Therefore we warn and
  // return the default number value (or throw in strict mode).
  @if not index($allowed-first-character, $first-character) {
    @return _sc-throw($value, 'number');
  }

  // In case the value starts with a dot, we assume it is a float and pad it
  // with a zero.
  @if ($first-character == '.') {
    $value: '0' + $value;
  }

  // We find the integer part of the value.
  $find-integer: _sc-find-integer($value, $pointer);
  $pointer: nth($find-integer, 1);
  $result:  nth($find-integer, 2);

  // If there still is a dot, it means we have to check for the digits part of
  // the value.
  @if (str-slice($value, $pointer, $pointer) == '.') {
    $find-digits: _sc-find-digits($value, $pointer);
    $pointer: nth($find-digits, 1);
    $digits:  nth($find-digits, 2);
    $result: ($result + $digits);
  }

  // If the first character is a minus symbol, it means the number is negative
  // so we can multiply it per -1.
  @if ($first-character == '-') {
    $result: ($result * -1);
  }

  // If we are still not done with the evaluation, it means there could be a CSS
  // unit, so we check for it.
  @if ($pointer <= str-length($value)) {
    $result: _sc-unit($result, str-slice($value, $pointer));
  }

  @return $result;
}

/// Convert to string
/// @param {*} $value - value to cast
/// @return {String}
@function to-string($value) {
  $type: type-of($value);

  // If the value is already a string, we can safely return it.
  @if ($type == 'string') {
    @return $value;
  }

  @if type-of($value) == 'color' {
   // @warn 'Beware! Sass does some color conversion. The resulting string may be different from the color input.';
  }

  @return inspect($value);
}

/// Finding the digits part of a stringified number
/// @access private
/// @param {string} $source - string source
/// @param {Number} $pointer - current pointer
/// @return {List} - new pointer, parsed number
@function _sc-find-digits($source, $pointer) {
  $source: to-lower-case($source);
  $length: str-length($source);
  $numbers: map-get($sc-constants, 'DECIMAL_SPACE');
  $result: 0;
  $runs: 1;

  @while ($pointer <= $length) {
    $token: str-slice($source, $pointer, $pointer);
    $index: index($numbers, $token);

    @if ($token == '.') {
      // @continue;
    } @else if ($index and $index > 0) {
      $runs: ($runs * 10);
      $result: ($result * 10) + ($index - 1);
    } @else {
      @return $pointer, ($result / $runs);
    }

    $pointer: ($pointer + 1);
  }

  @return $pointer, ($result / $runs);
}

/// Finding the integer part of a stringified number
/// @access private
/// @param {String} $source  - string source
/// @param {Number} $pointer - current pointer
/// @return {List} new pointer, parsed number
@function _sc-find-integer($source, $pointer) {
  $source: to-lower-case($source);
  $length: str-length($source);
  $numbers: map-get($sc-constants, 'DECIMAL_SPACE');
  $result: 0;

  @while ($pointer <= $length) {
    $token: str-slice($source, $pointer, $pointer);
    $index: index($numbers, $token);

    @if ($token == '-') {
      // @continue;
    } @else if $index {
      $result: ($result * 10) + ($index - 1);
    } @else {
      @return $pointer, $result;
    }

    $pointer: ($pointer + 1);
  }

  @return $pointer, $result;
}

/// Power function
/// @access private
/// @param {Number} $x - number
/// @param {Number} $n - power
/// @return {Number} $x ^ $n
@function _sc-pow($x, $n) {
  $ret: 1;

  @if $n >= 0 {
    @for $i from 1 through $n {
      $ret: ($ret * $x);
    }
  } @else {
    @for $i from $n to 0 {
      $ret: ($ret / $x);
    }
  }

  @return $ret;
}

/// Tries to find a unit that would match a CSS length
/// @access private
/// @param {Number} $number - number
/// @param {String}   $unit - potential unit
/// @return {Number} length (0 if cast failed)
@function _sc-unit($number, $unit) {
  $units: map-get($sc-constants, 'UNIT_VALUES');
  $index: index(map-get($sc-constants, 'UNITS'), $unit);

  @if not $index {
    @return _sc-throw($number, 'number');
  }

  @return ($number * nth($units, $index));
}
