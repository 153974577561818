// FONTS


// @font-face {
//   font-family: 'Assistant';
//   src: url('../fonts/Assistant-ExtraLight.eot');
//   src: local('Assistant-ExtraLight'),
//       url('../fonts/Assistant-ExtraLight.eot?#iefix') format('embedded-opentype'),
//       url('../fonts/Assistant-ExtraLight.woff2') format('woff2'),
//       url('../fonts/Assistant-ExtraLight.woff') format('woff'),
//       url('../fonts/Assistant-ExtraLight.ttf') format('truetype');
//   font-weight: 200;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Assistant';
//   src: url('../fonts/Assistant-Light.eot');
//   src: local('Assistant-Light'),
//       url('../fonts/Assistant-Light.eot?#iefix') format('embedded-opentype'),
//       url('../fonts/Assistant-Light.woff2') format('woff2'),
//       url('../fonts/Assistant-Light.woff') format('woff'),
//       url('../fonts/Assistant-Light.ttf') format('truetype');
//   font-weight: 300;
//   font-style: normal;
// }

@font-face {
    font-family: 'Assistant';
    src: url('../fonts/Assistant-Regular.eot');
    src: local('Assistant-Regular'),
        url('../fonts/Assistant-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Assistant-Regular.woff2') format('woff2'),
        url('../fonts/Assistant-Regular.woff') format('woff'),
        url('../fonts/Assistant-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Assistant';
    src: url('../fonts/Assistant-SemiBold.eot');
    src: local('Assistant-SemiBold'),
        url('../fonts/Assistant-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Assistant-SemiBold.woff2') format('woff2'),
        url('../fonts/Assistant-SemiBold.woff') format('woff'),
        url('../fonts/Assistant-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
  font-family: 'Assistant';
  src: url('../fonts/Assistant-Bold.eot');
  src: local('Assistant Bold'), local('Assistant-Bold'),
      url('../fonts/Assistant-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Assistant-Bold.woff2') format('woff2'),
      url('../fonts/Assistant-Bold.woff') format('woff'),
      url('../fonts/Assistant-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

// @font-face {
//     font-family: 'Assistant';
//     src: url('../fonts/Assistant-ExtraBold.eot');
//     src: local('Assistant-ExtraBold'),
//         url('../fonts/Assistant-ExtraBold.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Assistant-ExtraBold.woff2') format('woff2'),
//         url('../fonts/Assistant-ExtraBold.woff') format('woff'),
//         url('../fonts/Assistant-ExtraBold.ttf') format('truetype');
//     font-weight: 800;
//     font-style: normal;
// }




