// sass-lint:disable-all

// HELPER FUNCTIONS:
//  LISTS
//---------------------------------


@function first($list) {
  @return nth($list, 1);
}

@function last($list) {
  @return nth($list, length($list));
}

@function prepend($list, $value) {
	@return join($value, $list);
}

@function contains($list,$var) {
  // @return (false == index($list, $var));
  @return (  index($list,$var)  );
}

/// Slice `$list` between `$start` and `$end` indexes
@function slice($list, $start: 1, $end: length($list)) {
  @if length($list) < 1 or $start > $end {
    @return ();
  }
  $result: ();
  @for $i from $start through $end {
    $result: append($result, nth($list, $i));
  }
  @return $result;
}


@function reverse($list, $recursive: false) {
  $result: ();

  @for $i from length($list) *-1 through -1 {
    @if type-of(nth($list, abs($i))) == list and $recursive {
      $result: append($result, reverse(nth($list, abs($i)), $recursive));
    } @else {
      $result: append($result, nth($list, abs($i)));
    }
  }

  @return $result;
}