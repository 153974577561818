// sass-lint:disable-all

// BORDERS
// ----------------------------------------------------------

// @include borders(t 1 x 5 green);
//    > border-top: 1px solid green; border-left/right: 5px solid green;
// @include borders(t 1 x 5, black);
//    > border-top: 1px solid black; border-left/right: 5px solid black;
// @include borders(t x);
//    > border-top-width: 1px; border-left/right-width: 1px


@mixin borders($where: null, $color: null, $width: null,  $style: solid) {

  $extracted: extractor($where, 1px);

  $c: map-get($extracted, color);

  @if ( $color ) {
    $c: $color;
  }

  $w-t: map-get($extracted, top);
  $w-r: map-get($extracted, right);
  $w-b: map-get($extracted, bot);
  $w-l: map-get($extracted, left);

  @if ( $width ) {
    $w-t: $width;
    $w-r: $width;
    $w-b: $width;
    $w-l: $width;
  }

  @if ( $c
    and (not $w-t)
    and (not $w-r)
    and (not $w-b)
    and (not $w-l)
  ) {
    border-color: $c;
  }

  @if ( $w-t ) {
    @if ( strip-unit($w-t) == 0 ) {
      border-top: 0;
    } @else {
      @if not $c {
        border-top-width: $w-t;
      } @else {
        border-top: $w-t $style $c;
      }
    }
  }

  @if ( $w-r ) {
    @if ( strip-unit($w-r) == 0 ) {
      border-right: 0;
    } @else {
      @if not $c {
        border-right-width: $w-r;
      } @else {
        border-right: $w-r $style $c;
      }
    }
  }

  @if ( $w-b ) {
    @if ( strip-unit($w-b) == 0 ) {
      border-bottom: 0;
    } @else {
      @if not $c {
        border-bottom-width: $w-b;
      } @else {
        border-bottom: $w-b $style $c;
      }
    }
  }

  @if ( $w-l ) {
    @if ( strip-unit($w-l) == 0 ) {
      border-left: 0;
    } @else {
      @if not $c {
        border-left-width: $w-l;
      } @else {
        border-left: $w-l $style $c;
      }
    }
  }

}

@mixin b($args...) {
  @include borders($args...);
}


@mixin corner-radius($where) {
  $pos: extractor($where, $border-radius);
  border-top-left-radius: map-get($pos, top-left);
  border-top-right-radius: map-get($pos, top-right);
  border-bottom-right-radius: map-get($pos, bot-right);
  border-bottom-left-radius: map-get($pos, bot-left);
}

@mixin border-radius($args...) {
  @include corner-radius($args...);
}

@mixin radius($args...) {
  @include corner-radius($args...);
}