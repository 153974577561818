// sass-lint:disable-all

// Simple px to rem function
// allows %, but will be calculated relative to root
// inspired by foundation.zurb.com


@function rem($value: null, $base: $global-font-size) {

  $rem-allowed: (($global-unit == 'px') and not ($global-unit-affects-rem-function)) or ($global-unit == 'rem');

  @if unit($base) == '%' {
    $base: ($base / 100%) * 16px;
  }

  @if (can-be-rem($value)) and ($rem-allowed) {
    $value: strip-unit($value) / strip-unit($base) * 1rem;
  }
  @if not ($rem-allowed) {
    $value: check-unit($value);
  }

  @if ($value == 0rem) or ($value == 0px) {
    $value: 0;
  }

  @return $value;
}
