// buttons

$base-button-height: 43px;
$button-border-width: 2px; // 1.5px;

@mixin clear-button($full: false) {
  // &,&:hover,&:focus
    padding: 0;
    border: 0;
    box-shadow: none;
    min-width: 0;
    background-color: transparent;

    @if $full {
      height: auto;
      background-image: none;
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
      line-height: inherit;
    }

    @content;
  // }
}



@mixin make-button($type: basic, $h: $base-button-height) {

  @include button-normalize;
  position: relative;
  overflow: hidden;
  padding: 0;

  height: $h;
  line-height: $h;
  border-radius: 1px;

  font-size: rem(16);
  font-weight: 400;

  &:hover:not(:active):not(:disabled)
  {
    opacity: 0.6;
  }

  span {
    position: relative;
    display: inline-block;
    z-index: 3;
  }

  &:disabled{
    filter: grayscale(100%);
    opacity: 0.5;
  }
}

@mixin color-button($bg: $charcoal, $col: $light-grey-3,$i:false){
  @if $i {$i:'!important';} @else {$i:'';}
  background-color: $bg #{$i};
  color: $col #{$i};
}
%color-button{
  @include color-button;
}

@mixin outline-button($c:white, $w: $button-border-width, $h: $base-button-height,$bg:null) {
  background-color: $bg;
  border: $w solid $c;
  line-height: $h - $w*2;
}

@mixin double-outline-button(
  $border:white, $text: white, $bg: $charcoal, $hover: $brown-grey, $w: $button-border-width, $h: $base-button-height
) {

  &,&:before{
    @include color-button($bg:$bg, $col:$text);
    @include outline-button($c:$border, $w:$w, $h:$h);
  }

  @include p(x 13);
  overflow: visible;

  &:before,&:after {
    @include pseudo;
  }
  &:before {
    @include s( calc(100% + #{$w*2}) );
    @include ps(r -1*($w*3 + 2px) t ($w + 2px));
    z-index: 1;
  }
  &:after {
    @include ps(l 1 r 0 t 1 b 0);
    background-color: $bg;
    box-shadow: $w $w 0 0 $border;
    z-index: 2;
  }

  span{
    position: relative;
    display: inline-block;
    z-index: 3;
  }

  &:hover:not(:active) {
    opacity: 1;
    &,&:before,&:after {
      background-color: $hover;
    }
  }
}

@mixin modify-button(
  $type: basic,
  $h: $base-button-height,
  $b: $button-border-width
){
  @if $type == basic {
    height: $h;
    line-height: $h;
  }
  @if $type == outline {
    height: $h;
    line-height: $h - $b*2;
  }
}


// common

a.button, button,
input[type=submit] {

  @include make-button(basic);

}


// variants

a.button, button,
input[type=submit] {

  &.wht {
    @include color-button($off-white-2, $black);
  }

  &.red {
    @include color-button($pale-red, white);
  }

  &.chrkl {
    @include color-button($charcoal,white);
  }

  &.sm {
    @include modify-button(basic,33px);
  }

}


// close

@mixin close-button-color($c:blk) {
  @if ($c == wht) { $c: off-wht-2; }
  @include icon-class(icn_close,$c);
}

@mixin mod-close($s:15px,$b:12px){
  @include s($s);
  background-size: $b auto;
}

button.close, .button.close, {
  @include clear-button;
  @include text-hide;
  @include s(20);
  background-size: 18px auto;
  background-position: center;

  &.wht {
    @include close-button-color($c:off-wht-2);
  }

  &.drk {
    @include close-button-color($c:blk);
  }

  &.chrkl {
    @include close-button-color($c:chrk);
  }
}

// back

button{
  &.back, &.close-tab {
    background-color: transparent;
    @include text-hide;
    @include s(30);
    @extend %icn_ar_right-wht;
    background-size: 11px auto;
    background-position: center;
  }
}


// edit

button.edit.wht {
  @include clear-button;
  @include text-hide;
  // @include s(20);
  // background-size: 18px auto;
  background-position: center;
  @extend %icn_pencil-wht;
}


// print

button.print {
  @include text-hide;
  width: 43px;
  @extend %icn_print-wht;
  background-size: 28px auto;
  background-position: center;
  min-width: 0 !important;
}


// button that looks like link

.form-link-button, .link-button,
button.link-button, input[type=submit].form-link-button
{

  @include clear-button(true);
  font-weight: 600;
  color: $off-black;
  font-size: rem(15);
  text-decoration: underline;
  white-space: nowrap;

  &:hover {
    color: $greeny-blue;
  }
}

// delete / trash button

button,.button{
  &.trash {
    min-width: 0;
    @include clear-button;
    @include text-hide;
    background-position: center;
    @extend %icn_trash-wht;
  }
}



// a few buttons in a row (like Save & Cancel etc)

@mixin button-group(
  $solid-class: '.solid',
  $solid-bg-color: $off-white-2,
  $solid-color: $black,
  $outlined-class: '.outln',
  $outlined-bg-color: transparent,
  $outlined-color: white,
  $outlined-border-color: white,
  $double-outline-class: '.dbl',
  $double-outline-bg-color: $charcoal,
  $double-outline-color: white,
  $double-outline-border-color: white,
  $pre: ''
) {

  & > span,
  #{$pre}.button, #{$pre}button
  {
    vertical-align: top;

    &:not(:first-child){
      @include m(r 15);
    }
  }

  @if $solid-class {
    #{$pre}#{$solid-class}{
      @include color-button($solid-bg-color, $solid-color);
    }
  }

  @if $outlined-class {
    #{$pre}#{$outlined-class}{
      @include color-button($outlined-bg-color, $outlined-color);
      @include outline-button($outlined-border-color);
    }
  }

  @if $double-outline-class {
    #{$pre}#{$double-outline-class}{
      @include double-outline-button($double-outline-border-color,$double-outline-color,$double-outline-bg-color);
    }
  }

}