.userpic {
  background-color: $light-grey-2;
  background-image: $default-userpic;

  &, span {
    display: block;
    border-radius: 50%;
    overflow: hidden;
    background-position: center;
    background-size: cover;
  }

  .image {
    position: relative;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    border-radius: 50%;
  }
}

@mixin userpic-size($start:45px, $border: 3px, $steps:10px 5px, $border-step: 1px){

  & {
    box-shadow: inset 0 0 0 $border $squash;

    @if $border-step{
      @include mobile{
        box-shadow: inset 0 0 0 ($border - $border-step) $squash;
      }
    }
  }

  .role-border {
    padding: $border;

    @if $border-step{
      @include mobile{
        padding: $border - $border-step;
      }
    }
  }

  &, .role-border {
    // @include desktop{
      @include build-size-css($start, $start, min max);
    // }

    @if $steps {
      @include media(">small-mobile","<=#{$switch-to-mobile}"){
        @include build-size-css( ($start - nth($steps,1)), ($start - nth($steps,1)), min max);
      }

      @include media("<=small-mobile"){
        @include build-size-css( ($start - nth($steps,1) - nth($steps,2)), ($start - nth($steps,1) - nth($steps,2) ),min max);
      }
    }
  }

  .image {
    // @include desktop{
      @include s($start - $border*2);
    // }

    @if $steps {

      @include media(">small-mobile","<=#{$switch-to-mobile}"){
        @include s( $start - nth($steps,1) - ($border - $border-step)*2 );
      }

      @include media("<=small-mobile"){
        @include s( $start - nth($steps,1) - nth($steps,2) - ($border - $border-step)*2 );
      }

    }
  }

}