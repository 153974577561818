// methods

.methods-list{

  & > li {
    display: block;

    @include not-first{
      border-top: 1px solid $light-grey-2;
      @include m(t 20);
      @include p(t 12);
    }
  }

}

.single-method {

  .method-title {
    font-size: rem(25);
    font-weight: 600;

    .de-em {
    // mock
      @if $do-mock {
        &:empty{
          display: inline-block;
          &:before{
            content: "שיחות";
          }
        }
      }
    }
  }

  .description {
    @include m(t 15);

    p {
      &:not(:last-child){
        @include m(b 10);
      }
    }

    ul{
      display: block;
      list-style-type: disc;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
      
      li{
        display: list-item;
      }
    }
    ol {
      display: block;
      list-style-type: decimal;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 36px;

      li{
        display: list-item;
      }
    }
    em, .em {
      font-style: italic;
      font-weight:normal;
    }
    .video-wrapper{
      width: 100%;
      max-width: 100%;
      position: relative;
      overflow: hidden;
      padding-top: calc(315 / 560 * 100%);

      iframe{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
  }

}