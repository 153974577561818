// GENERAL

html {
  the-number-of-the-beast: 666;
}

main,section,article,header,footer {
  display: block;
}

button,
input,
select,
textarea {
  font-family: $font-family-base;
}


.desktop {
  @include mobile {
    display: none !important;
  }
}

.mobile {
  @include desktop {
    display: none !important;
  }
}

.print-only {
  @include not-print {
    display: none !important;
  }
}

.clearfix {
  @include clearfix;
}

form {
  margin: 0;
}

ul,
ol {
  @include reset-list;
}

// .element-invisible,
.visually-hidden {
  @include visually-hidden;
}

h1,
h2,
h3 {
  letter-spacing: -0.5px;
}

span, p, li, tr, h1, h2, h3, h4, h5
{
  &:empty {
    display: none;
    position: static;
  }
}

h2, h3, h4, h5 {
  @include hyphenate;
}

label {
  font-weight: inherit;
}



// hiding stuff

#skip-link,
#block-system-powered-by,
html.js div.contextual-links-wrapper,
div.contextual-links-wrapper,
#main-content,
a#main-content,
.link-wrapper, .rdf-meta, .action-links, .print-link
{
  display: none;
}


// debug stuff

.debug-message {
  display: block;
  padding: 20px 50px;
  @include ps(fix, t 0 x 0);
  text-align: center;
  background-color: rgba(yellow,0.8);
  color: black;
  font-size: 20px;
  z-index: 10000;
  opacity: 0.65;

  &:focus {
    opacity: 1;
  }

  @if not $show-debug {
    display: none;
  }
}

%focus {
  // outline: 1px solid red !important;
  // box-shadow: 0 0 15px red !important;

  outline: 1px dotted #212121;
  outline: 5px auto -webkit-focus-ring-color;
}
:focus {
  @extend %focus;
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}