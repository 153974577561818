
.change-userpic-modal,
.change-password-modal {

  .close.close-modal {
    @include mod-close(20px,18px);
    top: 23px;
    left: 20px;
  }

}

.change-userpic-modal {

  .userpic-wrap {
    display: block;
    position: relative;

    &:not(:hover){
      .trash {
        @include desktop{
          display: none;
        }
      }
    }
  }

  .userpic {
    @include userpic-size(130px, 7px, $steps: false, $border-step: false);
    @include m(x auto);
  }

  button.trash {
    @include s(26);
    background-size: 12px auto;
    @include ps(t 5 r calc(50% - 130px / 2 - 15px));
    z-index: 5;
    background-color: $brown-grey;
    border-radius: 50%;
  }

  .details {
    @include m(y 15);

    p.name {
      font-size: rem(30);
      font-weight: 600;
      line-height: 1.1;

      @include m(b 5);
    }

    p.role {
      font-size: rem(18);
    }
  }

  .error {
    max-width: 250px;
    line-height: 1.1;
    margin: 10px auto 0;
  }
}

.change-password-modal {

  input {
    @include underlined-input();
    @include m(b 15);
  }

  .actions {
    @include m(t 25);
  }

}