// sass-lint:disable-all


// put icon in pseudo element and apply hover state
// works by first darkening color and decreasing opacity, then on hover increasing opacity - thus making icon seem darker

@mixin icon-with-hover($icon, $color, $bg-width: null, $bg-pos: left center, $start-opacity:0.7, $darken-multiplier: 11%, $use:before, $elem-pos:relative, $active-class: active) {

  position: $elem-pos;

  &:#{$use} {
    @include pseudo;
    @include pos;
    @include size(100%);
    background-image: build-svg-icon($icon, darken($color, $darken-multiplier) );
    background-repeat: no-repeat;
    @if $bg-width { background-size: $bg-width auto; }
    @if $bg-pos { background-position: $bg-pos; }
    opacity: $start-opacity;
    z-index: 0;
  }

  &:hover,&.#{$active-class} {
    &:#{$use} {
      opacity: 1;
    }
  }

  &:active {
    &:#{$use} {
      opacity: 1;
      filter: brightness(70%);
    }
  }

}



// DASH LIST STYLE

@mixin dash-li() {

  padding-left: 18px;
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20viewBox%3D%220%200%20100%2020%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M-1-1h102v22H-1z%22%2F%3E%3Cpath%20d%3D%22M0%200h100v20H0z%22%2F%3E%3C%2Fsvg%3E');
  background-size: 8px;
  background-position: left 0px top 10px;

}



@mixin grayscale {
    filter: gray;
    -webkit-filter: grayscale(1);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
    filter: grayscale(100%);
}

// flip horizontal

@mixin flip($d:x){

  @if ( contains($d, x) ) {
    transform: scaleX(-1);
    filter: FlipH;
  }

}


// animated success checkmark in circle

@mixin success-check(
  $check-color: green,
  $bg-color: white,
  $loader-size: 120px,
  $check-height: $loader-size/2,
  $check-width: $check-height/2,
  $check-thickness: 2px,
  $circle-thickness: $check-thickness,
  $check-left: ($loader-size/6 + $loader-size/12),
  $check-top: $check-height
){

  @keyframes border-green{
    0% {
      box-shadow: ($loader-size / 2) ($loader-size / 2 * -1) 0 $circle-thickness $bg-color, ($loader-size / 2 * -1) ($loader-size / 2 * -1) 0 $circle-thickness $bg-color, ($loader-size / 2 * -1) ($loader-size / 2) 0 $circle-thickness $bg-color, ($loader-size / 2) ($loader-size / 2) 0 $circle-thickness $bg-color, 0 0 0 $circle-thickness $check-color;
    }
    25% {
      box-shadow: 0 (($loader-size + 5) * -1) 0 $circle-thickness $bg-color, ($loader-size / 2 * -1) ($loader-size / 2 * -1) 0 $circle-thickness $bg-color, ($loader-size / 2 * -1) ($loader-size / 2) 0 $circle-thickness $bg-color, ($loader-size / 2) ($loader-size / 2) 0 $circle-thickness $bg-color, 0 0 0 $circle-thickness $check-color;
    }
    50% {
      box-shadow: 0 (($loader-size + 5) * -1) 0 $circle-thickness $bg-color, (($loader-size + 5) * -1) 0px 0 $circle-thickness $bg-color, ($loader-size / 2 * -1) ($loader-size / 2) 0 $circle-thickness $bg-color, ($loader-size / 2) ($loader-size / 2) 0 $circle-thickness $bg-color, 0 0 0 $circle-thickness $check-color;
    }
    75% {
      box-shadow: 0 (($loader-size + 5) * -1) 0 $circle-thickness $bg-color, (($loader-size + 5) * -1) 0px 0 $circle-thickness $bg-color, 0px 125px 0 $circle-thickness $bg-color, ($loader-size / 2) ($loader-size / 2) 0 $circle-thickness $bg-color, 0 0 0 $circle-thickness $check-color;
    }
    100% {
      box-shadow: 0 (($loader-size + 5) * -1) 0 $circle-thickness $bg-color, (($loader-size + 5) * -1) 0px 0 $circle-thickness $bg-color, 0px 125px 0 $circle-thickness $bg-color, ($loader-size) ($loader-size / 3) 0 $circle-thickness $bg-color, 0 0 0 $circle-thickness $check-color;
    }
  }

  @keyframes checkmark {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }
    20% {
      height: 0;
      width: $check-width;
      opacity: 1;
    }
    40% {
      height: $check-height;
      width: $check-width;
      opacity: 1;
    }
    100% {
      height: $check-height;
      width: $check-width;
      opacity: 1;
    }
  }

  & {
    border-radius: 50%;
    width: $loader-size;
    height: $loader-size;
    position: relative;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    background: $bg-color;
    z-index: -1;
    box-shadow:  ($loader-size / 2) ($loader-size / 2 * -1) 0 $circle-thickness $bg-color, ($loader-size / 2 * -1) ($loader-size / 2 * -1) 0 $circle-thickness $bg-color, ($loader-size / 2 * -1) ($loader-size / 2)  0 $circle-thickness $bg-color, ($loader-size / 2) ($loader-size / 2)  0 $circle-thickness $bg-color, 0 0 0 $circle-thickness $bg-color;
    animation: border-green .4s ease 1 forwards;
  }

  .checkmark:after {
    opacity: 0;
    height: $check-height;
    width: $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid $check-color;
    border-top: $check-thickness solid $check-color;
    content: '';
    left: $check-left;
    top: $check-top;
    position: absolute;
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    animation-fill-mode: forwards;
    transform: scaleX(-1) rotate(135deg);
    animation-delay: 1s;
  }

}

// animated error cross in circle

@mixin error-cross(
  $cross-color: red,
  $bg-color: white,
  $loader-size: 120px,
  $cross-width: $loader-size/2,
  $cross-thickness: 2px,
  $circle-thickness: $cross-thickness,
  $animation-length: 400ms
){

  @keyframes border-red{
    0% {
      box-shadow: ($loader-size / 2) ($loader-size / 2 * -1) 0 $circle-thickness $bg-color, ($loader-size / 2 * -1) ($loader-size / 2 * -1) 0 $circle-thickness $bg-color, ($loader-size / 2 * -1) ($loader-size / 2) 0 $circle-thickness $bg-color, ($loader-size / 2) ($loader-size / 2) 0 $circle-thickness $bg-color, 0 0 0 $circle-thickness $cross-color;
    }
    25% {
      box-shadow: 0 (($loader-size + 5) * -1) 0 $circle-thickness $bg-color, ($loader-size / 2 * -1) ($loader-size / 2 * -1) 0 $circle-thickness $bg-color, ($loader-size / 2 * -1) ($loader-size / 2) 0 $circle-thickness $bg-color, ($loader-size / 2) ($loader-size / 2) 0 $circle-thickness $bg-color, 0 0 0 $circle-thickness $cross-color;
    }
    50% {
      box-shadow: 0 (($loader-size + 5) * -1) 0 $circle-thickness $bg-color, (($loader-size + 5) * -1) 0px 0 $circle-thickness $bg-color, ($loader-size / 2 * -1) ($loader-size / 2) 0 $circle-thickness $bg-color, ($loader-size / 2) ($loader-size / 2) 0 $circle-thickness $bg-color, 0 0 0 $circle-thickness $cross-color;
    }
    75% {
      box-shadow: 0 (($loader-size + 5) * -1) 0 $circle-thickness $bg-color, (($loader-size + 5) * -1) 0px 0 $circle-thickness $bg-color, 0px 125px 0 $circle-thickness $bg-color, ($loader-size / 2) ($loader-size / 2) 0 $circle-thickness $bg-color, 0 0 0 $circle-thickness $cross-color;
    }
    100% {
      box-shadow: 0 (($loader-size + 5) * -1) 0 $circle-thickness $bg-color, (($loader-size + 5) * -1) 0px 0 $circle-thickness $bg-color, 0px 125px 0 $circle-thickness $bg-color, ($loader-size) ($loader-size / 3) 0 $circle-thickness $bg-color, 0 0 0 $circle-thickness $cross-color;
    }
  }

  @keyframes cross{
    0% {
      background-size: 0% 100%;
    }
    100% {
      background-size: 100% 100%;
    }
  }
  @keyframes cross-ie{
    0% {
      background-color: rgba($cross-color,0);
    }
    100% {
      background-color: $cross-color;
    }
  }
  & {
    border-radius: 50%;
    width: $loader-size;
    height: $loader-size;
    position: relative;
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    background: $bg-color;
    z-index: -1;
    box-shadow:  ($loader-size / 2) ($loader-size / 2 * -1) 0 $circle-thickness $bg-color, ($loader-size / 2 * -1) ($loader-size / 2 * -1) 0 $circle-thickness $bg-color, ($loader-size / 2 * -1) ($loader-size / 2)  0 $circle-thickness $bg-color, ($loader-size / 2) ($loader-size / 2)  0 $circle-thickness $bg-color, 0 0 0 $circle-thickness $bg-color;
    animation: border-red .4s ease 1 forwards;
    position: relative;
  }

  .cross {

    @include s($cross-width);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -1*$cross-width/2;
    margin-top: -1*$cross-width/2;

    $length: 1.4 * $cross-width;

    &:before,&:after
    {
      @include pseudo;
      width: $length;
      height: $cross-thickness;
      //background-color: $cross-color;
      border-radius: $cross-thickness/2;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1*$length/2;
      margin-top: -1*$cross-thickness/2;
      background-image: linear-gradient($cross-color,$cross-color);
      background-size: 0% 100%;
      background-position: left center;
      animation-name: cross;
      animation-duration: $animation-length;
      animation-timing-function: ease;
      animation-fill-mode: forwards;

      @include ie{
        animation-name: cross-ie;
      }
    }
    &:before {
      transform: rotate(45deg);
      animation-delay: $animation-length + $animation-length/2;
    }
    &:after {
      transform: rotate(135deg);
      animation-delay: $animation-length/2;
    }
  }

}



// play-pause button


@mixin play-pause-button(
  $size: 50px,
  $border: 3px,
  $bgcolor: transparent,
  $color: black,
  $trans: 0.3s,
  $zoom: 1.12,
  $start-opacity: 0.65,
  $class-pause: pause,
  $class-play: play
) {

  $play-icon: '<svg viewBox="0 0 14071552" width="1407" height="1552" xmlns="http://www.w3.org/2000/svg"><path fill="fillColor" d="M1384 807.348l-1328 738q-23 13-39.5 3t-16.5-36v-1472q0-26 16.5-36t39.5 3l1328 738q23 13 23 31t-23 31z"/></svg>';

  $pause-icon: '<svg width="1536" height="1537" viewBox="0 0 1536 1537" xmlns="http://www.w3.org/2000/svg"><path fill="fillColor" d="M1536 64v1408q0 26-19 45t-45 19H960q-26 0-45-19t-19-45V64q0-26 19-45t45-19h512q26 0 45 19t19 45zm-896 0v1408q0 26-19 45t-45 19H64q-26 0-45-19t-19-45V64q0-26 19-45T64 0h512q26 0 45 19t19 45z"/></svg>';

  background-color: $bgcolor;
  @include text-hide;
  display:block;
  @include s($size);
  border: $border solid $color;
  border-radius: 50%;
  position:relative;
  z-index:1;
  transition: color $trans, background-color $trans, opaicity $trans, background-size $trans, transform $trans, background-image $trans;
  background-position: center;

  &.#{$class-play} {
    background-image: build-svg($play-icon,$color);
    background-size: 38% auto;
    background-position: 56% 50%;
  }

  &.#{$class-pause} {
    background-image: build-svg($pause-icon,$color);
    background-size: 35% auto;
  }

  opacity: 0;
  &.#{$class-play},&.#{$class-pause} {
    opacity: $start-opacity;

    &:hover, &:focus {
      opacity: 1;
      transform: scale($zoom);
    }
  }
}
