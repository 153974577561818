// sass-lint:disable-all


@mixin slide-border-vert($border-loc:bottom,$border-dir:top,$border-color:black(0.5), $border-color-hover: black(1), $border-height: 5px, $hover: true, $just-border: false) {

  background-image: linear-gradient(to top,  $border-color 0%,  $border-color 100%);
  background-size: 100% $border-height;
  background-position: $border-loc;

  @if not $just-border {

    transition: box-shadow 0.35s ease-in-out;
    box-shadow: inset 0 0 0 0 $border-color;

    @if $hover {
      $hover: '&:hover, &.active';
    } @else {
      $hover: '&.active';
    }

    @if $border-dir == top {
      #{$hover} {
        box-shadow: inset 0  #{-$border-height} 0 0 $border-color-hover;
      }
    } @else if $border-dir == bottom {
      #{$hover} {
        box-shadow: inset 0 $border-height 0 0 $border-color-hover;
      }
    }

  }



}

@mixin slide-border-vert-on($border-loc:bottom,$border-dir:top) {
  @if $border-dir == top {
      box-shadow: inset 0  #{-$border-height} 0 0 $border-color-hover;
  } @else if $border-dir == bottom {
      box-shadow: inset 0 $border-height 0 0 $border-color-hover;
  }
}

//-------------------------------

@mixin slide-border-horiz($border-loc:bottom,$border-dir:right,$border-color:black(0.5), $border-color-hover: black(1), $border-height: 5px, $hover: true, $just-border: false) {

  background-size: 200% $border-height;
  background-repeat: repeat-x;
  transition: background-position 0.35s ease-in-out;

  @if $hover {
    $hover: '&:hover, &.active';
  } @else {
    $hover: '&.active';
  }

  @if $border-dir == left {
    background-position: 0% $border-loc;
    background-image: linear-gradient(to right,  $border-color 0%,  $border-color 50%, $border-color-hover 50%, $border-color-hover 100%);

    #{$hover} {
      background-position: 100% $border-loc;
    }

  } @else if $border-dir == right {
    background-position: 100% $border-loc;
    background-image: linear-gradient(to right,  $border-color-hover 0%,  $border-color-hover 50%, $border-color 50%, $border-color 100%);

    #{$hover} {
      background-position: 0% $border-loc;
    }

  }

}

@mixin slide-border-horiz-on($border-loc:bottom,$border-dir:right) {
  @if $border-dir == left {
      background-position: 100% $border-loc;
  } @else if $border-dir == right {
      background-position: 0% $border-loc;
  }
}
