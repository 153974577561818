// sass-lint:disable-all


// CSS TOOLTIPS

//  adapted by Pavel G from SimpTip v1.0.4 by @ArashManteghi
//  https://github.com/arashmanteghi/simptip/

//    usage:
//      [data-tooltip] { @include simptip();  }


// defaults:

$tooltipHeight: 22px !default;
$tooltipWidth: 130px !default;
$arrowSize: 6px !default;

$tooltipColor: #323232 !default;
$tooltipTextColor: white !default;
$tooltipFontSize: rem(15px);

$tooltipTextAlign: center !default;

$tooltipPadding: 5px 5px !default;
$tooltipHover: '&:hover,&:focus' !default;


// helpers

@mixin simtip-position(
  $position: top,
  $height: $tooltipHeight,
  $arrow: $arrowSize,
  $hover: $tooltipHover,
  $elem: '&:after',
  $elem-ar: '&:before',
  $skip-arrow-pos:false,
  $align-to-host:false,
  $distance:10px,
  $animate-distance: 10px,
  $rounded:true,
  $multiline:true,
  $animate:true,
  $delay:0s
) {

  @if not ( to-bool($animate-distance) ){
    $animate: false;
  }

  @if $animate {
    $distance: $distance - $animate-distance;
  }

  // units

  $height: check-unit($height);
  $arrow: check-unit($arrow);
  $distance: check-unit($distance);
  $animate-distance: check-unit($animate-distance);

  @if ($distance == 0) {
    $distance: 0px;
  }

  // position

  @if ( $position == right ) or ( $position == left ) {
    #{$elem} {bottom: 50%;}
    @if not $skip-arrow-pos {
      #{$elem-ar} {
        bottom: 50%;
        margin-bottom: ($arrow + check-unit(1px)) * -1;
      }
    }
    #{$elem} {margin-bottom: $height/-1.5;}

    @if $multiline {
      #{$elem} {
          transform: translateY(50%);
          filter: blur(0px);
          margin-bottom: 0;
      }
      @if not $skip-arrow-pos {
        #{$elem-ar} {
          transform: translateY(50%);
          filter: blur(0px);
          margin-bottom: 0;
        }
      }
    }
  }

  @if ( $position == right ) {
    #{$elem} {
      left: calc(100% + #{$arrow} + #{$distance});
    }
    @if not $skip-arrow-pos {
      #{$elem-ar} {
        left: calc(100% - #{$arrow + check-unit(1px)} + #{$distance});
      }
    }

    @if $animate {
        #{$hover}{
          #{$elem} { transform: translateX($animate-distance); }
          @if not $skip-arrow-pos {
            #{$elem-ar}{ transform: translateX($animate-distance); }
          }
        }
        @if $multiline {
          #{$hover} {
            #{$elem} { transform: translate($animate-distance, 50%); }
            @if not $skip-arrow-pos {
              #{$elem-ar}{ transform: translate($animate-distance, 50%); }
            }
          }
        }
    }
  }

  @if ( $position == left ) {
    #{$elem} {
      right: calc(100% + #{$arrow} + #{$distance});
    }
    @if not $skip-arrow-pos {
      #{$elem-ar}{
        right: calc(100% - #{$arrow + check-unit(1px)} + #{$distance});
      }
    }

    @if $animate {
      #{$hover}{
        #{$elem} { transform: translateX($animate-distance*-1); }
        @if not $skip-arrow-pos {
          #{$elem-ar} { transform: translateX($animate-distance*-1); }
        }
      }
      @if $multiline {
        #{$hover} {
          #{$elem} { transform: translate($animate-distance*-1, 50%); }
          @if not $skip-arrow-pos {
            #{$elem-ar} { transform: translate($animate-distance*-1, 50%); }
          }
        }
      }
    }
  }

  @if ( $position == top ) or ( $position == bottom ) {
    #{$elem} {
      @if $align-to-host {
        #{$align-to-host}: 0;
      } @else {
        left: 50%;
        transform: translateX(-50%);
      }
    }
    @if not $skip-arrow-pos {
      #{$elem-ar}{
        left: 50%;
        transform: translateX(-50%);
      }
    }
    //#{$elem} {width: auto;}
  }

  @if ( $position == bottom ) {
    #{$elem} {
      top: calc(100% + #{$arrow} + #{$distance});
    }
    @if not $skip-arrow-pos {
      #{$elem-ar} {
        top: calc(100% - #{$arrow + check-unit(1px)} + #{$distance});
      }
    }

    #{$hover}{
      @if not $align-to-host {
        #{$elem} { transform: translate(-50%, 0); }
      }
      @if not $skip-arrow-pos {
        #{$elem-ar} { transform: translate(-50%, 0); }
      }
    }

    @if $animate {
      #{$hover}{
        @if not $align-to-host {
          #{$elem} { transform: translate(-50%, $animate-distance); }
        } @else {
          #{$elem} { transform: translate(0, $animate-distance); }
        }
        @if not $skip-arrow-pos {
          #{$elem-ar} { transform: translate(-50%, $animate-distance); }
        }
      }
    }
  }

  @if ( $position == top ) {
    #{$elem} {
      bottom: calc(100% + #{$arrow} + #{$distance});
    }
    @if not $skip-arrow-pos {
      #{$elem-ar} {
        bottom: calc(100% - #{$arrow + check-unit(1px)} + #{$distance});
      }
    }

    #{$hover}{
      @if not $align-to-host {
        #{$elem} { transform: translate(-50%, 0px); }
      }
      @if not $skip-arrow-pos {
        #{$elem-ar} { transform: translate(-50%, 0px); }
      }
    }

    @if $animate {
        #{$hover}{
          @if not $align-to-host {
            #{$elem} { transform: translate(-50%, $animate-distance*-1); }
          } @else {
            #{$elem} { transform: translate(0, $animate-distance*-1); }
          }
          @if not $skip-arrow-pos {
            #{$elem-ar} { transform: translate(-50%, $animate-distance*-1); }
          }
        }
    }
  }

}


// core mixin

@mixin simptip(

  $position: top, // put false to skip setting position
  $color: $tooltipColor,
  $text-color: $tooltipTextColor,

  $height: $tooltipHeight,
  $width: $tooltipWidth,
  $arrow: $arrowSize,
  $padding: $tooltipPadding,
  $border-radius: $border-radius,
  $distance: 10px,
  $animate-distance: 10px,

  $align: $tooltipTextAlign,
  $font-size: $tooltipFontSize,
  $font-weight: null,
  $attr: data-tooltip,
  $hover: $tooltipHover,

  $display: inline-block,
  $use: after,
  $put-arrow: before,

  $rounded: true,
  $multiline: true,
  $animate: true,
  $delay: 0s,
  $skip-arrow-pos: false,
  $inverse-elems: false,
  $align-to-host: false,

  $content: false,

  $pos: relative

) {

  $z-index: 5000;

  $elem: '&:after';
  @if ($use != after){
    $elem: $use;
  }
  $elem-ar: '&:before';
  @if ($put-arrow != before){
    $elem-ar: $put-arrow;
  }
  $hover-always-on-words: always on true false;
  @if index( $hover-always-on-words, to-string($hover) ) {
    $hover: '&';
  }
  @if $inverse-elems {
    $tmp: $elem;
    $elem: $elem-ar;
    $elem-ar: $tmp;
  }

  // units

  $height: check-unit($height);
  $width: check-unit($width);
  $arrow: check-unit($arrow);
  $padding: check-unit($padding);
  $border-radius: check-unit($border-radius);
  $distance: check-unit($distance);
  $animate-distance: check-unit($animate-distance);
  $font-size: check-unit($font-size);

  // basic styling

  @if $pos {
    position: $pos;
  }
  @if ($use == after) and ($display) {
    display: $display;
  }

  #{$elem-ar}, #{$elem} {
    pointer-events: none;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transform: translate3d(0, 0, 0);

    @if $animate {
      transition: all 0.1s linear;
      transition-delay: $delay;
    }
  }

  // tooltip arrow

  #{$elem-ar} {
    content: '';
    border: ($arrow + check-unit(1px)) solid transparent;
    border-#{$position}-color: $color;
    z-index: $z-index + 1;
  }

  // tooltip box

  #{$elem} {

    z-index: $z-index;
    padding: $padding;
    font-size: $font-size;
    font-weight: $font-weight;
    @if ($use == after) and (not $content){
      content: attr(#{$attr});
    }
    @if ($content){
      content: $content;
    }
    text-align: $align;
    background-color: $color; color: $text-color;
    max-width: 60vw;
    line-height: 1.2;

    @if $multiline {
      width: $width;
      white-space: normal;
    } @else {
      height: $height;
      white-space: nowrap;
    }

    @if $rounded {
      border-radius: $border-radius;
    }
  }

  // position

  @if $position {
    @include simtip-position(
      $position, $height, $arrow, $hover, $elem, $elem-ar, $skip-arrow-pos, $align-to-host, $distance, $animate-distance, $rounded, $multiline, $animate, $delay
      );
  }

  // show it

  #{$hover}{
  	//background-color: transparent;

  	#{$elem-ar}, #{$elem} {
      opacity: 1;
      visibility: visible;
  	}
  }

  // mods

  #{$elem} {
    @content;
  }

}

@mixin modify-simtip(
  $position: null,
  $color: null,
  $arrow: $arrowSize,
  $use: after,
  $height: $tooltipHeight,
  $hover: $tooltipHover,
  $align-to-host:false,
  $distance: 10px,
  $animate-distance: 10px
) {

  // units

  $height: check-unit($height);
  $arrow: check-unit($arrow);
  $distance: check-unit($distance);
  $animate-distance: check-unit($animate-distance);

  // main

    $elem: '&:after';
    @if ($use != after){
      $elem: $use;
    }

    @if $color {
      &:before {
        border: $arrow solid transparent;
        border-#{$position}-color: $color;
      }
    }

    @if $position {
      &:before, #{$elem} {
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;
      }
      &:before{
        @if $color and $arrow {
          border: ($arrow + check-unit(1px)) solid transparent;
          border-#{$position}-color: $color;
        }
      }
      @include simtip-position($position, $height, $arrow, $hover, $elem,
      $distance: $distance, $animate-distance: $animate-distance, $align-to-host: $align-to-host
      );
    }
}