//  VARIABLES
//---------------------------


// APP SETTINGS

$global-font-size: 16px;

$boxpad: 30px;
$body-min-width: 1024px;
$content-max-width: 1280px;

$grid-spacer-x: 30px;
$grid-spacer-y: 30px;

$header-height: 82px;
$header-height-mobile: 65px;
$mob-head-mod: 1.15; //1

$rope-height: 8px;
$footer-height: 67px;
$footer-height-mobile: 85px;

$rope-mod-1: 1px;
$rope-mod-2: 2px;

// $main-menu-height: 92px;

$switch-to-mobile: 'tablet';

$huge-screen: 1600px;
$huge-screen-pad-x: 2.8vw;
$short-screen: 700px;
$very-short-screen: 600px;



// MEDIA QUERIES / BREAKPOINTS

$breakpoints: (
  small-mobile: 374px,  //395
  mobile: 544px,
  // small-tablet: 670px,
  tablet: 768px,
  // big-tablet: $body-min-width,
  laptop: 992px,
  desktop: 1200px,
  huge: $huge-screen
);


// BOOTSTRAP

//$body-color: $gray-dark;
$border-radius: 4px;
// $brand-primary: $green;


// font & font sizes

$font-family-base:  Assistant, 'Open Sans Hebrew', Rubik, Roboto, "Trebuchet MS", Arial, Helvetica, sans-serif;
$font-size-root: $global-font-size;


// headings

$headings-font-family: $font-family-base;
$headings-font-weight: 500;


// inputs

$input-padding-x: 20px;
$input-padding-y: 0;

$input-height: 50px;
$input-line-height: $input-height - 2px;

// $form-text-margin-top: 0;


// buttons

$btn-line-height: $input-line-height;
// $btn-padding-x: 20px;
// $btn-padding-y: 0;
// $btn-border-radius-base: 2px;


// links

//$link-color: $blue;
// $link-decoration: underline;
//$link-hover-color: $blue-dark;



// component variables

$day-header-height: 120px;//105px;//85px;
$day-planner-scroll-offset: 4px;
$activity-box-width: 190px;

$default-simplebar-scroll-window-height: calc(100vh - #{ $header-height + $day-header-height + 50px + 40px + 23px});


// misc

$sidebar-off-state: '#burger-control:not(:checked) ~ .menu-wrap';
$sidebar-on-state: '#burger-control:checked ~ .menu-wrap';

$checkbox: 'input[type=checkbox]:not(#burger-control):not(.multiselect-checkbox)';
