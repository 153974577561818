
// general print styles

@page {
  margin: 1.5cm;
}

@include print{

  html {
    @include grayscale;
    max-width: none;
    overflow: auto;
  }

  * {

    &,&:before,&:after{
      color: black;
      background: none;
      box-shadow: none;
      border-radius: 0;
      border-color: black;
    }

    &:before,&:after {
      display: none;
    }
  }

  .region, .region-page-top,
  body #popupVSButton,
  body #popupVSChat,
  body #popupVSButton div,
  body #popupVSChat div,
  body #popupVSChat #openVSButton,
  .access-panel,
  .activities-popup.modal-backdrop.calendar-info-modal button.close.back,
  .activities-popup.modal-backdrop header .mobile.print-menu,
  .activities-popup.modal-backdrop header .mobile.print-menu .print-popup,
  .activities-popup.modal-backdrop .buttons-wrap
  {
    display: none !important;
  }

  body {
    min-width: 0 !important;
    max-width: none;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }

  .page {
    order: 2;
  }

  .page-header {
    order: 1;
    background: none;
    text-align: left;
    // border-bottom: 2px solid black;
    margin-bottom: 30px;
    page-break-before: avoid;
    page-break-after: avoid;
    display: none !important;

    .wrap {
      @include p(x 0);
    }

    .controls {
      display: none;
    }

    .link-home,.zofim-logo{
      height: inherit;
      width: 100%;
      text-align: left;
    }

    .link-home .zofim-logo {
      @include m(x auto);
      background-position: center right;
      background-size: auto 90%;
      background-repeat: no-repeat;
      margin: 0;
      background-image: $zofim-logo-2;
      -webkit-print-color-adjust: exact;

      // color: transparent;
      // font-size: 0px;
      // text-indent: 300%;
      // overflow: hidden;

      color: black;
      text-align: left;
      font: inherit;
      font-size: 30px;
      line-height: 65px;
    }
  }

  .page-footer {
    background: none;
    order: 10;
    border-top: 2px solid black;

    &:before,&:after,.footer-links {
      display: none;
    }
  }

}