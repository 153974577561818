// inline datepicker

.inline-datepicker {

  background-color: white;
  position: absolute;
  padding: 25px;
  text-align: center;
  z-index: 50;
  width: 100%;

  header{
    display: none;
  }

  .day {

    @include ratio(1);

    .week-day {
      @include visually-hidden;
    }

  }

}