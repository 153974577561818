// sass-lint:disable-all

// LOADING SPINNERS
// more here:
// http://projects.lukehaas.me/css-loaders/
// http://www.codicode.com/art/full_css_spinners.aspx
// http://tobiasahlin.com/spinkit/
// http://samherbert.net/svg-loaders/



@mixin spin-zoomer-pulser($size:16px,$color:#333,$border:false,$border-width:2px) {

  @keyframes sk-scaleout {
    0% {
      transform: scale(0);
    } 100% {
      transform: scale(1.0);
      opacity: 0;
    }
  }

  @include size($size);
  @if $border {
    border: $border-width solid $color;
  } @else {
    background-color: $color;
  }
  border-radius: 100%;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}





// ----

@mixin button-spinner($color:black,$size1:3px,$size2:20px,$use:after) {

  $elem: '&:after';
  @if $use == before { $elem: '&:before'; }
  @if $use == self { $elem: '&'; }

  #{$elem} {

    @include pseudo(inline-block);
    vertical-align: middle;
    @include size($size2);
    overflow: hidden;
    font-size: $size1;
    font-family: Arial;
    text-indent: -999em;
    border-radius: 50%;
    border: $size1 solid rgba($color, 0.3);
    border-bottom: $size1 solid $color;
    animation: spin1 1s infinite linear;

    @keyframes spin1 {
      100% { transform: rotate(360deg); }
    }

  }

}


//----

@mixin dots-spinner($color:black) {

  &,
  &:before,
  &:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: load7 1.8s infinite ease-in-out;
  }

  color: $color;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: 0.16s !important;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
  }
  &:before {
    left: -3.5em;

  }
  &:after {
    left: 3.5em;
    animation-delay: 0.32s;
  }

  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }

}


// blink-border

@mixin blink-border($c: green,$s: null) {
  @keyframes blink-border {
    0% {

    }

    50% {
      border-color: $c;
      @if ($s) {
        box-shadow: inset 0 0 15px 0px lighten($c,$s), 0 0 15px 0px lighten($c,$s);
      }
    }

    100% {

    }
  }
}

