// sass-lint:disable-all


// HELPER FUNCTIONS:
// TYPE CHECKERS
//---------------------------------


// A collection of function for advanced type checking
// @author Hugo Giraudel
// https://css-tricks.com/snippets/sass/advanced-type-checking/


// returns true: 10, 10px, 10vw, 10%
// returns false: '10'
@function is-number($value) {
  @return type-of($value) == 'number';
}

@function is-integer($value) {
  @return is-number($value) and round($value) == $value;
}

@function is-relative-length($value) {
  @return is-number($value) and index('em' 'ex' 'ch' 'rem' 'vw' 'vh' 'vmin' 'vmax', unit($value)) != null;
}

@function is-absolute-length($value) {
  @return is-number($value) and index('cm' 'mm' 'in' 'px' 'pt' 'pc', unit($value)) != null;
}

@function is-percentage($value) {
  @return is-number($value) and unit($value) == '%';
}

@function is-length($value) {
  @return is-relative-length($value) or is-absolute-length($value);
}

@function is-map($var){
  @return type-of($var) == 'map';
}

@function is-list($var){
  @return type-of($var) == 'list';
}

// other checks

@function is-even($number) {
  @return $number % 2 == 0;
}
