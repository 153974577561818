// sass-lint:disable-all


// SASS shadows without images
// https://github.com/Ahrengot/sass-dropshadows

// more shadows here: https://www.fourfront.us/blog/css-shadow-borders-with-curved-box-shadows

@mixin shadow-lifted($op: 0.7, $mod:1) {
  &:before, &:after {
    bottom:12px;
    width:50%; height:55%;
    max-width:300px;
    max-height:100px;
    box-shadow: 0 15px 10px rgba(black, $op);
  }

  &:before { left: 10px; @include rotate(-3deg); }
  &:after { right: 10px; @include rotate(3deg); }
}

@mixin shadow-perspective($op: 0.4, $mod:1) {
  &:before {
    left:80px; bottom:5px;
    width:50%; height:35%;
    max-width:200px;
    max-height:50px;
    box-shadow: -80px 0 8px rgba(black, $op);
    @include skew(50deg, 0 , false);
    @include experimental(transform-origin, 0 100%);
  }
  &:after { display: none; content: none; }
}

@mixin shadow-perspective-right($op: 0.4, $mod:1) {
  &:before {
    right:80px; bottom:5px;
    width:50%; height:35%;
    max-width:200px;
    max-height:50px;
    box-shadow: 80px 0 8px rgba(black, $op);
    @include skew(-50deg, 0 , false);
    @include experimental(transform-origin, 0 100%);
  }
  &:after { display: none; content: none; }
}

@mixin shadow-raised($op: 0.5, $mod:1) {
  box-shadow: 0 15px 10px -10px rgba(black, $op);

  &:before, &:after {
    content: none;
  }
}

@mixin shadow-curved($op: 0.5, $mod:1) {
  &:before {
    top: 1px; bottom: 0;
    left:12px; right:12px;
    box-shadow: 0 0 13px 4px rgba(black, $op);
    border-radius:150px / 10px;
  }
}

@mixin shadow-curved-top($op: 0.5, $mod:1) {
  @include shadow-curved($op, $mod);

  &:before {
    bottom: 50%;
  }
}

@mixin shadow-curved-bottom($op: 0.5, $mod:1) {
  @include shadow-curved($op, $mod);

  &:before {
    top: 50%;
  }
}

@mixin shadow-curved-vertical($op: 0.5, $mod: 1) {
  &:before {
    top:10px*$mod; bottom:10px*$mod;
    left:0; right:0%;
    box-shadow: 0 0 13px 4px rgba(black, $op);
    border-radius:10px / 100px;
    @content;
  }
}

@mixin shadow-curved-vertical-left($op: 0.5, $mod: 1) {
  @include shadow-curved-vertical($op, $mod){
    right: 50%;
    @content;
  };
}

@mixin shadow-curved-vertical-right($op: 0.5, $mod: 1) {
  @include shadow-curved-vertical($op, $mod);

  &:before {
    left: 50%;
  }
}



@mixin shadow($type: lifted, $op: null, $mod: null) {
  position: relative;

  &:before, &:after {
    position: absolute;
    z-index: -2;
    content: ''
  }

  @if $type == lifted {
    @include shadow-lifted($op,$mod);
  }
  @if $type == perspective {
    @include shadow-perspective($op,$mod);
  }
  @if $type == perspective-right {
    @include shadow-perspective-right($op,$mod);
  }
  @if $type == raised {
    @include shadow-raised($op,$mod);
  }
  @if $type == curved {
    @include shadow-curved($op,$mod);
  }
  @if $type == curved-top {
    @include shadow-curved-top($op,$mod);
  }
  @if $type == curved-bottom {
    @include shadow-curved-bottom($op,$mod);
  }
  @if $type == curved-vertical {
    @include shadow-curved-vertical($op,$mod);
  }
  @if $type == curved-vertical-left {
    @include shadow-curved-vertical-left($op,$mod){
      @content;
    };
  }
  @if $type == curved-vertical-right {
    @include shadow-curved-vertical-right($op,$mod);
  }
}

