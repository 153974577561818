// sass-lint:disable-all

// make any property responsive between min/max values for certain resolutions
// calculation idea from: https://css-tricks.com/molten-leading-css/

// EX:  @include responsive(width, 200px, 1200px, 100px, 500px)'
// width will scale between 100 and 500 px on resolutions between 200 and 1200px

// Responsive mixin can take a list of props, but the same min-max values will be applied to them

// ATTN! By default min-max values are expected to be pixesl, to use other units one should use $unit: rem, etc


@function resp-val($min-width, $max-width, $min-val, $max-val, $unit: px){
  $min-width: strip-unit($min-width);
  $max-width: strip-unit($max-width);
  $min-val: strip-unit($min-val);
  $max-val: strip-unit($max-val);

  @return calc(#{$min-val}#{$unit} + (#{$max-val} - #{$min-val}) * ((100vw - #{$min-width}px) / (#{$max-width} - #{$min-width})));
}

// TODO: add not-strict mode, without media-queries, just the calc value
@mixin responsive(
  $prop:null,
  $min-width: 0,
  $max-width: 2000px,
  $min-val: 0px,
  $max-val: 300px,
  $unit: px
){
  $minWidth: strip-unit($min-width);
  $maxWidth: strip-unit($max-width);

  @if $prop {
    @each $p in $prop {
      #{$p}: check-unit($min-val);
    }

    @media (min-width: #{$minWidth}px) and (max-width: #{$maxWidth}px) {
      @each $p in $prop {
        #{$p}: resp-val($min-width, $max-width, $min-val, $max-val, $unit);
      }
    }

    @media (min-width: #{$maxWidth}px) {
      @each $p in $prop {
        #{$p}: check-unit($max-val);
      }
    }
  }
}

@mixin responsive-font(
  $min-width: 320,
  $max-width: 2000px,
  $min-val: 12,
  $max-val: 24,
  $unit: px
  ) {
    @include responsive(
      $prop: font-size,
      $min-width: $min-width,
      $max-width: $max-width,
      $min-val: $min-val,
      $max-val: $max-val,
      $unit: $unit
    );
}

// synonym

@mixin responsive-typography($args...){
  @include responsive-font($args...);
}