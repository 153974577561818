.node-type-page{
	.field-type-text-with-summary,
	.field-type-text-long{

		ul {
			display: block;
		    	list-style-type: disc;
		    	-webkit-margin-before: 1em;
		    	-webkit-margin-after: 1em;
		    	-webkit-margin-start: 0px;
		    	-webkit-margin-end: 0px;
		    	-webkit-padding-start: 15px;

			li {
			    display: list-item;
			    text-align: -webkit-match-parent;
			}
		}
		ol {
		    	display: block;
		    	list-style-type: decimal;
		    	-webkit-margin-before: 1em;
		    	-webkit-margin-after: 1em;
		    	-webkit-margin-start: 0px;
		    	-webkit-margin-end: 0px;
		    	-webkit-padding-start: 15px;

			li {
			    display: list-item;
			    text-align: -webkit-match-parent;
			}
		}
		i, cite, em, var, address, dfn {
		    	font-style: italic;
			font-weight:normal;
		}

	}
}
