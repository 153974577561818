
.page-help {

  #block-system-main, div.content {
    position: static;
  }

  .faq-section {
    min-height: 80vh;

    .region-content {
      position: static;
    }
  }

  .faq-list {
    position: relative;
    z-index: 10;
    text-align: right;

    .single-question {
      display: block;
      @include collapsible($use-class: 'active', $t: 0.2s, $op: true, $h:1500px);

      border-bottom: 1px solid $brown-grey;
      @include p(t 11 b 13);

      &:not(.active) {
        transition-delay: 1s;
      }
    }

    .question {

      @extend %dropdown;      
      font-size: rem(22);
      font-weight: 400;
      margin: 0;

      @include media("<=mobile"){
        font-size: rem(20);
        line-height: 1.2;
      }

      &.active{
        @include media("<=mobile"){
          font-weight: 600;
          letter-spacing: -0.3px;
        }
      }
    }

    .answer {
      line-height: 1.4;
      text-align: right;
      @include m(t 20 b 10);
    }

  }

}


