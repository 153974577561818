// login

$all-login-classes: '.page-login, .page-forgot-password, .page-user-change-password,  .page-help, .page-term-use, .page-user-reset, .login-flow, .page-user, .page-registration';

#{$all-login-classes} {
  padding-top: 0 !important;
}


$login-input-height: 35px;


.page-login,
.page-forgot-password,
.page-user-change-password,
.page-user,
.page-registration {

  .page-footer,
  .page-header {
    display: none;
  }

  main {
    min-height: 100vh;
  }
}

%login-section-box {
  position: relative;
  @include p(t 40 b 35);
  @include box-double-border($a:false);
  @include mod-modal(8px);

  @include overlay-stripes($t:'&:after', $c:rgb(172, 173, 172), $w:100%, $m:false) {
    opacity: 0.7;

    @include mobile {
      left: 0;
      right: 0;
    }
  }

  ;

  @include media(">mobile") {
    background-color: white(0.7);
  }

  @include media("<=mobile") {
    height: 100%;
    min-height: inherit;
    background-color: darken(white, 3%);
    @include flex-align;
    flex-direction: column;

    &:after {
      opacity: 0.5;
      width: 100vw;
      left: 0;
      right: 0;
    }
  }

  &>* {
    position: relative;
    z-index: 2;
  }
}

#{$all-login-classes} {
  section {
    @extend %login-section-box;
  }
}

.page-user-change-password {
  main>.wrap>.region>.block-system:only-child {
    @extend %login-section-box;
  }
}

#{$all-login-classes} {

  // hiding elements

  #skip-link,
  //#messages,
  .region-page-top,
  #cboxOverlay,
  #colorbox,
  #breadcrumb,
  .tabs,
  .form-required {
    display: none;
  }

  // general page

  @include media(">mobile") {
    background-image: $default-bg;
    background-position: left center;
    background-size: cover;
    background-attachment: fixed;
  }

  @include media(">mobile", "<=1140px") {
    background-position: left 27% center;
  }

  main {
    @include media(">mobile") {
      background-color: black(0.4);
      min-height: 100vh;
    }
  }

  main>.wrap {
    @include wrap-pads(y);
    min-height: inherit;
    @include flex-align;

    @include ie {
      height: 1px;
    }

    @include media("<=mobile") {
      padding: 0;
    }
  }

}

%zofim-logo-login {
  @include text-hide;
  @include s(120);
  @include m(x auto b 20);
  background-image: $zofim-logo-3;

  @include ie {
    background-image: $zofim-logo-3-120;
  }

  @include media("<=mobile") {
    @include s(140);

    @include ie {
      background-image: $zofim-logo-3-140;
    }
  }
}

%login-section {
  @include media(">mobile") {
    @include p(x 60);
    max-width: 355px;
  }

  @include media("<=mobile") {
    @include p(x 40);
  }

  @include media("<=small-mobile") {
    @include p(x 30);
  }

  p {
    font-size: rem(18);
    line-height: 1.5;
    text-align: center;
    @include m(x -15);

    em:not(.placeholder) {
      font-weight: inherit;

      @include media("<=mobile") {
        font-size: rem(22);
      }
    }

    @include media("<=mobile") {
      font-size: rem(17);
    }
  }

  .form-submit,.reg-button {
    @include color-button($charcoal, white);
    display: block;
    @include m(x auto);
  }
  .reg-button {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-top:3px;
    width: 100%;
    border: solid 1px #b4b2af;
    padding: 0px 20px 0px 20px;
    border-radius: 1px;
    font-size: 1rem;
    font-weight: 400;
    background-color: #5f6368;
  }
}

.page-login,
.page-forgot-password,
.page-user-reset,
.page-user-change-password,
.page-user,
.page-registration {
  section {
    @extend %login-section;

    .zofim-logo {
      @extend %zofim-logo-login;
    }
  }
}
.page-registration {
  section {
    @extend %login-section;

    .zofim-logo {
      background-image: url(../img/logo-3-120-reg.png);
    }
  }
}

.page-user-change-password {
  main>.wrap>.region>.block-system:only-child {
    @extend %login-section;

    &>.content:before {
      @include pseudo;
      @extend .zofim-logo;
      @extend %zofim-logo-login;
    }
  }
}

.page-login,
.page-forgot-password,
.page-user-change-password,
.page-user,
.page-registration {

  form>div:not([class]) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include media("<=mobile") {
      flex-direction: column;
    }
  }

  .form-item {
    width: 100%;
    @include m(b 18);

    &[type="password"],
    &[name="name"] {
      direction: ltr;
    }
  }

  .form-text, .multiselect-input {
    @include underlined-input($login-input-height);
    height: 40px;

  }

  .link-button,
  #edit-logout,
  input.form-link-button {
    width: auto;
    font-size: rem(14);
    vertical-align: middle;

    &:hover {
      color: inherit;
      opacity: 0.6;
    }
  }

  .form-actions {
    width: 100%;
    transition: margin 0.2s;
    @include m(t 26);
  }

  .form-item {

    &+[type="hidden"]+.form-actions,
    &+[type="hidden"]+[type="hidden"]+.form-actions {
      @include m(t 8);
    }
  }

  #edit-logout {
    @extend .link-button;
    text-align: center;
    width: 100%;
    @include m(x auto t 10);
  }


  // forgot password mods

  form[action*=forgot_password] {
    p {
      font-size: rem(16);
    }
  }

  // errors

  #console,
  .messages.status,
  .messages.error {
    position: static;
    display: inline;
  }

  .messages.error {
    ul {
      margin: 0;
    }

    li {
      display: block;

      @include not-last {
        @include m(b 7);
      }
    }
  }

  .messages.error,
  .form-error {
    @include m(x -15);
    @include p(b 7 t 5);
  }

  // .messages.status,
  // .messages.error,
  .form-error {
    min-height: 35px;
    @include m(b -35);
    font-size: rem(15);
    text-align: center;
    line-height: 1;
    opacity: 0;
    transition: all 0.2s;
    @include flex-align;
  }

  .login-section {
    &:not(.has-error) {

      .messages.error,
      .form-error {
        span {
          display: none;
        }
      }

      .messages.status {
        opacity: 1;
      }
    }

    &.has-error {

      // .messages.error,
      .form-error {
        opacity: 1;
        // @include m(b -20)
      }

      .form-actions {
        // @include m(t 11);
      }
    }
  }

  // removing red border

  input.form-text.error {
    border-color: $charcoal;
  }

}


// close / back button
.page-help,
.page-term-use,
.page-forgot-password,
.page-user-change-password {

  .close {
    z-index: 500;
    top: 20px;

    @include media(">mobile") {
      @include close-button-color(blk);
      position: absolute;
      left: 20px;
    }

    // convert to back button on mobile
    @include media("<=mobile") {
      @include s(30);
      background-size: 11px auto;
      background-position: center;
      position: fixed;
      right: 12px;

      @at-root {

        .page-help,
        .page-term-use {
          .close {
            @include icon-class(icn_ar_right, wht);
          }
        }

        .page-forgot-password,
        .page-user-change-password {
          .close {
            @include icon-class(icn_ar_right, chrk);
          }
        }
      }
    }
  }
}



.page-help,
.page-term-use {

  // big white box for text-content

  .bigbox {

    max-width: 700px;

    @include p(x 40);

    @include media("<=mobile") {
      @include padding-when-fixed-header(20px);
      background-color: white;

      &:after {
        display: none;
      }
    }

    .sectitle {
      position: relative;
      z-index: 10;
      font-weight: 400;
      font-size: rem(30);
      @include m(b 4vh);

      // convert to title on mobile

      @include media("<=mobile") {
        @include make-header(span, span);

        span {
          @include p(b 9);
        }
      }
    }

  }

  // footer for Help & Terms of use pages

  .page-footer {
    @include media(">mobile") {
      display: none;
    }
  }

  main {
    @include media("<=mobile") {
      min-height: calc(100vh - #{$footer-height-mobile - ($rope-height - $rope-mod-1)});
    }

    @include media("<=small-mobile") {
      min-height: calc(100vh - #{$footer-height-mobile - ($rope-height - $rope-mod-2)});
    }
  }

}


.page-user-reset {

  section {
    @include p(x 40);

    .form-submit {
      max-width: 235px;
    }
  }

}

.page-user-change-password {

  .password-strength {
    font-size: rem(15);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;

    .password-strength-title {
      flex-grow: 5;
    }

    .password-indicator {
      width: 100%;
      @include m(t 5);
    }
  }

  .password-strength-text {}

  // help tooltip

  .form-item-pass-pass1 {
    position: relative;
  }

  .help {

    display: block;
    @include circle(15, $charcoal);
    color: white;
    font-size: 13px;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    cursor: pointer;
    @include p(l 1);
    @include ps(l 0 b 11);

    @include simptip($width: 150px,
      $padding: 15px 20px,
      $font-size: rem(15),
      $rounded: false,
      $pos: false,
      $font-weight: normal);

    &:focus {
      outline: 0;
    }
  }

  // .edit-pass-pass1 {
  //   display: block;
  //   width: 100%;

  //   @include simptip(
  //     $padding: 15px 20px,
  //     $font-size: rem(15),
  //     $rounded: false
  //   );

  //   &:hover,&:focus{
  //     &:before,&:after{
  //       opacity: 0.9;
  //     }
  //   }
  // }


}

.page-registration {
  .login-section{
    max-width: 497px;
    @include media(">mobile"){
      padding-right: 100px;
      padding-left: 100px;
    }
  }
  .form-text,.multiselect-input {
    border: 1px solid #666562;
    border-radius: 4px;
    padding: 0 8px;
  }
  .multiselect-wrapper {
    width: 100%;
    .multiselect-input{
      background: none;
      padding-right: 10px;

      @include nice-select(
        $color: $charcoal,
        $symb-off: get-icn(icn_ar_down),
        $symb-on: get-icn(icn_ar_up),
        $symb-size: 13px
      );

      padding-left: 22px;
      cursor: pointer;
      background-position: left center;
      background-repeat: no-repeat;
      border: 1px solid #666562;
      border-radius: 4px;
      padding: 0 8px;
      height: 35px;
      line-height: 34px;
      background-position: 10px;
      margin: 0 !important;

      &.link-select {
        height: auto;
        line-height: inherit;
        border: 0;
        background-color: transparent;
        width: auto;
        text-decoration: underline;
        font-size: rem(15);
        font-weight: 600;
        color: $off-black;

        &:hover,&:focus {
          outline: none;
        }
      }
    }
    .multiselect-dropdown-arrow{
      display: none !important;
    }
    .multiselect-count{
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
    }

  }

  .form-item-age-class-guided{
    position: relative;
    z-index: 5;

    .multiselect-wrapper{
      font-family: Assistant, "Open Sans Hebrew", Rubik, Roboto, "Trebuchet MS", Arial, Helvetica, sans-serif;
      font-size: 16px;
    }
    #edit-age-class-guided_itemList{
      background:#fff;
      opacity:1;
      width: 100%;
      margin-top: 0;

      > span,hr{
        display:none !important;
      }
      ul {
        li{
          padding-right: 0;
          margin: 5px 0;

          label{
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: normal;
          }

          .multiselect-checkbox {
            margin-right: 0;
            margin-left: 5px;
          }
        }
      }
      ul::-webkit-scrollbar {
        width: 5px;
        direction: rtl;
      }

      ul::-webkit-scrollbar-track {
        background: rgba(216,210,194,0.4);
      }

      ul::-webkit-scrollbar-thumb {
        background: #D8D2C2;
      }
      ul::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }

  .form-select {
    border: 1px solid #666562;
    border-radius: 4px;
    padding: 0 8px;
    height: 40px;
    line-height: 34px;
    background-position: 10px;
  }

  #tzofim-register-form .form-submit {
    background-color: #666562 !important;
    width: 165px;
    height: 40px;
    margin-top: 25px;
    display:flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
  }

  #ajax_form_multistep_form .form-item select.error {
    background-repeat: no-repeat;
    background-size: 13px;
    background-position: 10px;
  }

  input.form-text.error,input.multiselect-input.error {
    border: 1px solid red;
  }

  section>*,
  section * {
    position: static;
  }

  .error {
    text-align: right;
    margin-bottom: -14px;
    margin-top: 6px;
  }

  div.error {
    background: none;
  }

  #ajax_form_multistep_form {
    h2 {}

    .form-item-clan-free-text {
      display: none;
    }

    &.step-1 {
      text-align: center;
    }

    &.step-3 {
      .reg-title {
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 34px;
        line-height: 47px;
        margin-top: -15px;
      }

      .reg-subTitle {
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        margin: -8px 0px;
        text-align: center;
      }

      // #edit-direct-manager {
      //   margin-bottom: 3rem;
      // }
      .form-required {
        display: none;
      }

      .option {
        &::before {
          margin-top: 5px;
        }
      }
    }

    &.step-2 {
      .reg-title {
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 34px;
        line-height: 47px;
        margin-top: -15px;
      }

      .u-writeEmail,
      .u-email {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 29px;
        text-align: center;
        margin: -8px 0px;
      }

      #edit-code-fieldset {
        margin: auto;
        position: relative;

        div.error{
          position: absolute;
          bottom: 25px;
          left: 0;
          width: 100%;
          text-align: center;
        }
      }

      .fieldset-wrapper {
        display: flex;
        margin-top: 2.5rem;
        margin-bottom: 1rem;
        direction: ltr;

        #edit-code-1,
        #edit-code-2,
        #edit-code-3,
        #edit-code-4 {
          width: 50px;
          height: 50px;
          background: #FFFFFF;
          border-radius: 0;
          font-size: 20px;
          text-align: center;
          margin: 0 5px;

          &.input-fill {
            border: 1px solid #176AFC;
          }
        }
      }

      .resend-wrapp {
        text-align: center;
        margin: auto;
        margin-bottom: 10rem;

        @include media("<=mobile") {
          margin-bottom: 2rem;
        }

        .resend-text {
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: 0.08em;
          color: rgba(0, 0, 0, 0.6);
          margin-bottom: 6px;
        }

        .resend-link {
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.04em;
          color: #007F00;

          a {
            text-decoration: none;
          }
        }

        .resent-done {
          margin-top: 5px;
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          letter-spacing: 0.08em;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }



    &.step-3 {
      .form-item-direct-manager {
        position: relative;
        z-index: 3;

        #autocomplete {
          background: #fff;
          color: #000;
          z-index: 3;
          opacity: 1 !important;
        }
      }

      input.form-autocomplete {
        background-image: none !important;
      }
      #autocomplete{
        ul{
          li{
            display:block;
          }
        }
      }
    }

    &.step-5 {
      h1 {
        display: none;
      }

      .icon,
      h3,
      .tnx {
        text-align: center;
      }

      .tnx {
        margin-bottom: 3rem;
      }
    }

    #edit-back {
      display: none;
    }

    .back-link {
      text-decoration: none;
      position: absolute;
      top: 15px;
      left: 20px;

      img {
        margin-right: 5px;
      }
    }

    .tournament-popup {
      display: none;
      position: fixed !important;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
      max-width: 90%;
      width: 909px;
      //height: 774px;
      // height: 85%;
      //padding: 3rem 2rem;
      padding: 35px 25px 10px;

      @include box-double-border($a:false);
      @include mod-modal(8px);


      &::before {
        z-index: -1;
      }

      @include media(">mobile") {
        background-color: #F1EFEA;
        //height: 774px;

      }

      @include media("<=mobile") {
        height: 100%;
        min-height: inherit;
        background-color: darken(white, 3%);
        //@include flex-align;
        flex-direction: column;
        max-width: 100%;

        &:after {
          opacity: 0.5;
          width: 100vw;
          left: 0;
          right: 0;
        }
      }
      .tournament-popup-content {
        .content{
          .title{
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            margin-top: 7px;
            margin-bottom: 10px;

            @include media("<=mobile") {
              margin-top: 15px;
            }
          }
          .subtitle{
            p{
              margin-bottom: 0px;
              font-size: 1rem;
              line-height: normal;

              @include media("<=mobile") {
                line-height: 1.4;
              }
            }
          }
        }
      }
      img {
        width: 100%;
        height: auto;
      }

      .next-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: auto;
        width: 165px;
        height: 40px;
        background: #666562;
        border-radius: 2px;
        color: white;
        margin-top: 20px;
        cursor: pointer;
        font-size: 18px;

        &.hidden-btn{
          visibility:hidden;

          @include media("<=mobile") {
            display: none;
          }
        }
      }

      .skip {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: auto;
        width: 165px;
        height: 40px;
        background: none;
        border-radius: 2px;
        cursor: pointer;
        text-decoration: underline;
        text-underline-offset: 2px;
        margin-bottom: 10px;
        font-size: 18px;

        &.last{
          text-decoration: none;
          margin-top: 20px;
          margin-bottom: 50px;
          background: #666562;
          border-radius: 2px;
          color: white;
        }
      }
      .buttons-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: -17px;

        &.last{
          flex-direction: row-reverse;
        }

        .next-slide,
        .skip{
          margin:0;
          width: 135px;
        }
        .skip{
          text-align: right;
          justify-content: flex-start;

          &.last{
            text-align: center;
            justify-content: center;
          }
        }
        .dots-holder{
          margin-top: 27px;

          .slick-dots li {
            margin:0;
          }
        }
      }
    }

    .form-type-textfield,
    .form-type-select {
     > label {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }
    }

    .form-item input.error,
    .form-item textarea.error,
    .form-item select.error {
      border: 1px solid red;
    }
  }

  .tournament-popup {
    position: relative;
    padding: 20px;

    .close-tournament {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.885' height='24.242' viewBox='0 0 24.885 24.242'%3E%3Cg data-name='Group 182'%3E%3Cpath data-name='Path 25' d='m-1041 5868 18.925 18.586' transform='translate(1044.132 -5865.173)' style='fill:none;stroke:%23000;stroke-linecap:round;stroke-width:2px'/%3E%3Cpath data-name='Path 26' d='m-1022.075 5868-18.925 18.587' transform='translate(1043.828 -5865.173)' style='fill:none;stroke:%23000;stroke-linecap:round;stroke-width:2px'/%3E%3C/g%3E%3C/svg%3E");
      background-size: cover;
      width: 15px;
      height: 15px;
      position: absolute;
      left: 10px;
      top: 10px;

      @include media("<=991px") {
        left: 35px;
      }

      @include media("<=mobile") {
        background-size: 18px;
        background-repeat: no-repeat;
        background-position: center;
        left: 20px;
      }
    }

    .slick-arrow {
      display: none !important;
    }

    .slick-dots {
      // position: absolute;
      bottom: -25px;
      display: block;
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
    }

    .slick-dots li {
      position: relative;

      display: inline-block;

      width: 20px;
      height: 20px;
      margin: 0 5px;
      padding: 0;

      cursor: pointer;
    }

    .slick-dots li button {
      font-size: 0;
      line-height: 0;

      display: block;

      width: 20px;
      height: 20px;
      padding: 5px;

      cursor: pointer;

      color: transparent;
      border: 0;
      outline: none;
      background: transparent;
    }

    .slick-dots li button:hover,
    .slick-dots li button:focus {
      outline: none;
    }

    .slick-dots li button:hover:before,
    .slick-dots li button:focus:before {
      opacity: 1;
    }

    .slick-dots li button:before {
      font-family: 'slick';
      font-size: 35px;
      line-height: 20px;

      position: absolute;
      top: 0;
      left: 0;

      width: 20px;
      height: 20px;

      content: '•';
      text-align: center;

      opacity: .25;
      color: black;

      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .slick-dots li.slick-active button:before {
      opacity: .75;
      color: black;
    }

  }
}

// misc

.login-flow {
  .region-content {
    max-width: 100%;
  }
}
