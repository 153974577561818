// activity info modal

.activity-info-modal {


  .wrap > span {
    display: block;
    position: static;
  }

  .modal-header {

    @include m( b 18);

    .top-part {
      @include p(t 17 b 10);
      @include flex-spread;
    }

    .right-side {
      max-width: 70%;
    }

    .categories-list{
      font-size: rem(14);
    }

    .bottom-part {

      border-top: 1px solid rgb(210,209,206);
      @include p(t 15);

      @include flex-spread;

    }

    .note {
      margin: 0;
      @include flex-align(y);

      strong {

      }
    }

    .pager {
      .prev {
        @include m(l 5);
      }
    }

  }

  .modal-box {
    max-height: none;
  }

  .content {
    @include p(x 30 y 30);
  }

  .methods-list {
    padding: 20px 30px;
    background-color: inherit;
  }

  .activity-info {
    display: flex;
    flex-direction: column;
    flex-grow: 10;
    background-color: $off-white-2;
  }

  .description {
    line-height: 1.5;
  }


  // swiper

  .modal-box > .wrap {
    overflow-x: hidden;
  }

  &.onboarding-active {
    .modal-box > .wrap {
      overflow-x: visible;
    }
  }

  @include swiper-extend;

  .swiper-container {
    flex-grow: 5;
    display: flex;
  }


  // scroll

  .simplebar-scroll {
    max-height: calc(90vh - 240px); //$default-simplebar-scroll-window-height;
    min-height: 415px;

    @if $use-native-scroll {
      @include simplebar-native-scroll('.swiper-container', $h: auto, $p: t 100 b 0 r 0, $cp: null);
    } @else {
      @if $put-simplebar-outside{
        @include simplebar-outside($t:'&');
      }
    }

    display: flex;
    flex-direction: column;
  }

}