// sass-lint:disable-all


// NICE SELECT

// needs   onchange="this.blur()"  on select element
// needs   disabled hidden selected  on first option to serve as placeholder
//         + select must be   required

@mixin nice-select(
  $color: black,
  $symb-off: '<svg width="998" height="584" viewBox="0 0 998 584" xmlns="http://www.w3.org/2000/svg"><path fill="fillColor" d="M998 83q0 13-10 23L522 572q-10 10-23 10t-23-10L10 106Q0 96 0 83t10-23l50-50Q70 0 83 0t23 10l393 393L892 10q10-10 23-10t23 10l50 50q10 10 10 23z"/></svg>',
  $symb-on: '<svg width="998" height="584" viewBox="0 0 998 584" xmlns="http://www.w3.org/2000/svg"><path fill="fillColor" d="M998 499q0 13-10 23l-50 50q-10 10-23 10t-23-10L499 179 106 572q-10 10-23 10t-23-10l-50-50Q0 512 0 499t10-23L476 10q10-10 23-10t23 10l466 466q10 10 10 23z"/></svg>',
  $symb-size: 10px,
  $off-x: left 10px,
  $off-y: 50%,
  $placeholder-color: null,
  $hover-bg: null,
  $hover-color: null
) {

  @include reset-input;

  background-image: encoded-svg( fill-svg($symb-off, $color) );
  background-position: #{$off-x} top #{$off-y};
  background-size: #{$symb-size} auto;

  &:focus {
    background-image: encoded-svg( fill-svg($symb-on, $color) );
  }

  &:invalid {
    color: $placeholder-color;
  }

  &.noborder {
    border: 0;
    cursor: pointer;
    background-color: transparent;
  }

  &:focus > option {
    &:checked, &:hover
    {
      @if $hover-bg {
        background: $hover-bg !important;
        box-shadow: inset 0 0 10px 100px $hover-bg;
      }
      @if $hover-color {
        color: $hover-color;
      }
    }
  }

}


