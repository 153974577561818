// sass-lint:disable-all
// Animate.css
// ================================================
// adapted by Pavel G



//  @include use-keyframes(fade-in bounce rubber-band);  <= space-separated list of keyframes to be used in project
//      and:
//  nav {
//    @include animate(fade-in, 1s);  <= particular animation to de used
//  }




@mixin animate($animation:null, $duration: 1s, $repeat: null, $delay: null) {
    animation-name: $animation;
    animation-duration: $duration;
    animation-iteration-count: $repeat;
    animation-delay: $delay;
    animation-fill-mode: both;
    backface-visibility: hidden;
}


@mixin use-keyframes($keyframes-list) {
    @each $keyframe in $keyframes-list {
        @include use-keyframe($keyframe);
    }
}

@mixin use-keyframe($a, $mod:false, $add:'', $sfx:'') {

// Attention Seekers
// ================================================

      // bounce

      @if ($a == bounce) {
        @keyframes #{$a}#{$sfx} {
          0%, 20%, 50%, 80%, 100% {
            transform: translateY(0) translateZ(0);
          }

          40% {
            transform: translateY(-30px) translateZ(0);
          }

          60% {
            transform: translateY(-15px) translateZ(0);
          }
        }
      }

      // pulse

      @if ($a == pulse) {

        $zoom: 1.1;
        @if $mod {
          $zoom: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            transform: scale(1) translateZ(0);
          }

          50% {
            transform: scale($zoom) translateZ(0);
          }

          100% {
            transform: scale(1) translateZ(0);
          }
        }
      }

      // rubberBand

      @if ($a == 'rubber-band') {
        @keyframes #{$a}#{$sfx} {
          0% {
            transform: scale(1) translateZ(0);
          }

          30% {
            transform: scaleX(1.25) scaleY(0.75) translateZ(0);
          }

          40% {
            transform: scaleX(0.75) scaleY(1.25) translateZ(0);
          }

          60% {
            transform: scaleX(1.15) scaleY(0.85) translateZ(0);
          }

          100% {
            transform: scale(1) translateZ(0);
          }
        }
      }

      // shake

      @if ($a == shake) {
        @keyframes #{$a}#{$sfx} {
          0%, 100% {
            transform: translateX(0) translateZ(0);
          }

          10%, 30%, 50%, 70%, 90% {
            transform: translateX(-10px) translateZ(0);
          }

          20%, 40%, 60%, 80% {
            transform: translateX(10px) translateZ(0);
          }
        }
      }

      // wobble

      @if ($a == wobble) {
        @keyframes #{$a}#{$sfx} {
          0% {
            transform: translateX(0%) translateZ(0);
          }

          15% {
            transform: translateX(-25%) rotate(-5deg) translateZ(0);
          }

          30% {
            transform: translateX(20%) rotate(3deg) translateZ(0);
          }

          45% {
            transform: translateX(-15%) rotate(-3deg) translateZ(0);
          }

          60% {
            transform: translateX(10%) rotate(2deg) translateZ(0);
          }

          75% {
            transform: translateX(-5%) rotate(-1deg) translateZ(0);
          }

          100% {
            transform: translateX(0%) translateZ(0);
          }
        }
      }


// Bouncing Entrances
// ================================================

      // bounceIn

      @if ($a == 'bounce-in') {
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 0;
            transform: scale(.3) #{$add} translateZ(0);
          }

          50% {
            opacity: 1;
            transform: scale(1.05) #{$add} translateZ(0);
          }

          70% {
            transform: scale(.9) #{$add} vtranslateZ(0);
          }

          100% {
            transform: scale(1) #{$add} translateZ(0);
          }
        }
      }

      // bounceInDown

      @if ($a == 'bounce-in-down') {
        $offset: 2000px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 0;
            transform: translateY(-$offset) #{$add} translateZ(0);
          }

          60% {
            opacity: 1;
            transform: translateY(30px) #{$add} translateZ(0);
          }

          80% {
            transform: translateY(-10px) #{$add} translateZ(0);
          }

          100% {
            transform: translateY(0) #{$add} translateZ(0);
          }
        }
      }

      // bounceInLeft

      @if ($a == 'bounce-in-left') {
        $offset: 2000px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 0;
            transform: translateX(-$offset) #{$add} translateZ(0);
          }

          60% {
            opacity: 1;
            transform: translateX(30px) #{$add} translateZ(0);
          }

          80% {
            transform: translateX(-10px) #{$add} translateZ(0);
          }

          100% {
            transform: translateX(0) #{$add} translateZ(0);
          }
        }
      }

      // bounceInRight

      @if ($a == 'bounce-in-right') {
        $offset: 2000px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 0;
            transform: translateX($offset) #{$add} translateZ(0);
          }

          60% {
            opacity: 1;
            transform: translateX(-30px) #{$add} translateZ(0);
          }

          80% {
            transform: translateX(10px) #{$add} translateZ(0);
          }

          100% {
            transform: translateX(0) #{$add} translateZ(0);
          }
        }
      }

      // bounceInUp

      @if ($a == 'bounce-in-up') {
        $offset: 2000px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 0;
            transform: translateY($offset) #{$add} translateZ(0);
          }

          60% {
            opacity: 1;
            transform: translateY(-30px) #{$add} translateZ(0);
          }

          80% {
            transform: translateY(10px) #{$add} translateZ(0);
          }

          100% {
            transform: translateY(0) #{$add} translateZ(0);
          }
        }
      }



// Bouncing Exits
// ================================================

      // bounceOut

      @if ($a == 'bounce-out') {
        @keyframes #{$a}#{$sfx} {
          0% {
            transform: scale(1) translateZ(0);
          }

          25% {
            transform: scale(.95) translateZ(0);
          }

          50% {
            opacity: 1;
            transform: scale(1.1) translateZ(0);
          }

          100% {
            opacity: 0;
            transform: scale(.3) translateZ(0);
          }
        }
      }

      // bounceOutDown

      @if ($a == 'bounce-out-down') {
        $offset: 2000px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            transform: translateY(0) translateZ(0);
          }

          20% {
            opacity: 1;
            transform: translateY(-20px) translateZ(0);
          }

          100% {
            opacity: 0;
            transform: translateY($offset) translateZ(0);
          }
        }
      }

      // bounceOutLeft

      @if ($a == 'bounce-out-left') {
        $offset: 2000px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            transform: translateX(0) translateZ(0);
          }

          20% {
            opacity: 1;
            transform: translateX(20px) translateZ(0);
          }

          100% {
            opacity: 0;
            transform: translateX(-$offset) translateZ(0);
          }
        }
      }

      // bounceOutRight

      @if ($a == 'bounce-out-right') {
        $offset: 2000px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            transform: translateX(0) translateZ(0);
          }

          20% {
            opacity: 1;
            transform: translateX(-20px) translateZ(0);
          }

          100% {
            opacity: 0;
            transform: translateX($offset) translateZ(0);
          }
        }
      }

      // bounceOutUp

      @if ($a == 'bounce-out-up') {
        $offset: 2000px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            transform: translateY(0) translateZ(0);
          }

          20% {
            opacity: 1;
            transform: translateY(20px) translateZ(0);
          }

          100% {
            opacity: 0;
            transform: translateY(-$offset) translateZ(0);
          }
        }
      }



// Fading Entrances
// ================================================

      // fadeIn

      @if ($a == 'fade-in') {
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }
      }

      // fadeInDown

      @if ($a == 'fade-in-down') {
        $offset: 20px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 0;
            transform: translateY(-$offset) translateZ(0);
          }

          100% {
            opacity: 1;
            transform: translateY(0) translateZ(0);
          }
        }
      }

      // fadeInDownBig

      @if ($a == 'fade-in-down-big') {
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 0;
            transform: translateY(-2000px) translateZ(0);
          }

          100% {
            opacity: 1;
            transform: translateY(0) translateZ(0);
          }
        }
      }

      // fadeInLeft

      @if ($a == 'fade-in-left') {
        $offset: 20px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 0;
            transform: translateX(-$offset) translateZ(0);
          }

          100% {
            opacity: 1;
            transform: translateX(0) translateZ(0);
          }
        }
      }

      // fadeInLeftBig

      @if ($a == 'fade-in-left-big') {
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 0;
            transform: translateX(-2000px) translateZ(0);
          }

          100% {
            opacity: 1;
            transform: translateX(0) translateZ(0);
          }
        }
      }

      // fadeInRight

      @if ($a == 'fade-in-right') {
        $offset: 20px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 0;
            transform: translateX($offset) translateZ(0);
          }

          100% {
            opacity: 1;
            transform: translateX(0) translateZ(0);
          }
        }
      }

      // fadeInRightBig

      @if ($a == 'fade-in-right-big') {
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 0;
            transform: translateX(2000px) translateZ(0);
          }

          100% {
            opacity: 1;
            transform: translateX(0) translateZ(0);
          }
        }
      }

      // fadeInUp

      @if ($a == 'fade-in-up') {
        $offset: 20px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 0;
            transform: translateY($offset) translateZ(0);
          }

          100% {
            opacity: 1;
            transform: translateY(0) translateZ(0);
          }
        }
      }

      // fadeInUp using position relative

      @if ($a == 'fade-in-up-rel') {
        $offset: 20px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 0;
            position: relative;
            top: $offset;
          }
          99% {
            opacity: 1;
            top: 0px;
          }
          100% {
            opacity: 1;
            top: auto;
          }
        }
      }

      // fadeInUp using margin

      @if ($a == 'fade-in-up-marg') {
        $offset: 20px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 0;
            margin-top: $offset;
            margin-bottom: $offset*-1;
          }

          100% {
            opacity: 1;
            margin-top: 0;
            margin-bottom: 0;
          }
        }
      }

      // fadeInUpBig

      @if ($a == 'fade-in-up-big') {
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 0;
            transform: translateY(2000px) translateZ(0);
          }

          100% {
            opacity: 1;
            transform: translateY(0) translateZ(0);
          }
        }
      }


// Fading Exits
// ================================================

      // fadeOut

      @if ($a == 'fade-out') {
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 1;
          }

          100% {
            opacity: 0;
          }
        }
      }

      // fadeOutDown

      @if ($a == 'fade-out-down') {
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 1;
            transform: translateY(0) translateZ(0);
          }

          100% {
            opacity: 0;
            transform: translateY(20px) translateZ(0);
          }
        }
      }

      // fadeOutDownBig

      @if ($a == 'fade-out-down-big') {
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 1;
            transform: translateY(0) translateZ(0);
          }

          100% {
            opacity: 0;
            transform: translateY(2000px) translateZ(0);
          }
        }
      }

      // fadeOutLeft

      @if ($a == 'fade-out-left') {
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 1;
            transform: translateX(0) translateZ(0);
          }

          100% {
            opacity: 0;
            transform: translateX(-20px) translateZ(0);
          }
        }
      }

      // fadeOutLeftBig

      @if ($a == 'fade-out-left-big') {
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 1;
            transform: translateX(0) translateZ(0);
          }

          100% {
            opacity: 0;
            transform: translateX(-2000px) translateZ(0);
          }
        }
      }

      // fadeOutRight

      @if ($a == 'fade-out-right') {
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 1;
            transform: translateX(0) translateZ(0);
          }

          100% {
            opacity: 0;
            transform: translateX(20px) translateZ(0);
          }
        }
      }

      // fadeOutRightBig

      @if ($a == 'fade-out-right-big') {
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 1;
            transform: translateX(0) translateZ(0);
          }

          100% {
            opacity: 0;
            transform: translateX(2000px) translateZ(0);
          }
        }
      }

      // fadeOutUp

      @if ($a == 'fade-out-up') {
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 1;
            transform: translateY(0) translateZ(0);
          }

          100% {
            opacity: 0;
            transform: translateY(-20px) translateZ(0);
          }
        }
      }

      // fadeOutUpBig

      @if ($a == 'fade-out-up-big') {
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 1;
            transform: translateY(0) translateZ(0);
          }

          100% {
            opacity: 0;
            transform: translateY(-2000px) translateZ(0);
          }
        }
      }



// Flippers
// ================================================

      // flipInX

      @if ($a == 'flip-in-x') {
        $persp: 400px;
        $deg: 90deg;
        @if $mod {
          @if is-list($mod) {
            $persp: nth($mod,1);
            $deg: nth($mod,2);
          } @else {
            $persp: $mod;
            $deg: 90deg;
          }
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            transform: perspective($persp) rotateX($deg) #{$add};
            opacity: 0;
            backface-visibility: visible !important;
          }

          40% {
            transform: perspective($persp) rotateX(-10deg) #{$add};
          }

          70% {
            transform: perspective($persp) rotateX(10deg) #{$add};
          }

          100% {
            transform: perspective($persp) rotateX(0deg) #{$add};
            opacity: 1;
            backface-visibility: visible !important;
          }
        }
      }

      @if ($a == 'flip-in-y') {
        $persp: 400px;
        $deg: 90deg;
        @if $mod {
          @if is-list($mod) {
            $persp: nth($mod,1);
            $deg: nth($mod,2);
          } @else {
            $persp: $mod;
            $deg: 90deg;
          }
        }
        @keyframes #{$a}#{$sfx} {
          from {
            transform: perspective($persp) rotate3d(0, 1, 0, $deg);
            animation-timing-function: ease-in;
            opacity: 0;
            backface-visibility: visible !important;
          }

          40% {
            transform: perspective($persp) rotate3d(0, 1, 0, -20deg);
            animation-timing-function: ease-in;
          }

          60% {
            transform: perspective($persp) rotate3d(0, 1, 0, 10deg);
            opacity: 1;
          }

          80% {
            transform: perspective($persp) rotate3d(0, 1, 0, -5deg);
          }

          to {
            transform: perspective($persp);
            backface-visibility: visible !important;
          }
        }
      }



// Sliders
// ================================================

      // slideInDown
      @if ($a == 'slide-in-down') {
        $offset: 2000px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 0;
            transform: translateY(-$offset) #{$add} translateZ(0);
          }

          100% {
            opacity: 1;
            transform: translateY(0) #{$add} translateZ(0);
          }
        }
      }


      // slideInUp
      @if ($a == 'slide-in-up') {
        $offset: 2000px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 0;
            transform: translateY($offset) #{$add} translateZ(0);
          }

          100% {
            opacity: 1;
            transform: translateY(0) #{$add} translateZ(0);
          }
        }
      }

      // slideInLeft

      @if ($a == 'slide-in-left') {
        $offset: 2000px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 0;
            transform: translateX(-$offset) #{$add} translateZ(0);
          }

          100% {
            opacity: 1;
            transform: translateX(0) #{$add} translateZ(0);
          }
        }
      }

      // slideInRight

      @if ($a == 'slide-in-right') {
        $offset: 2000px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 0;
            transform: translateX($offset) #{$add} translateZ(0);
          }

          100% {
            opacity: 1;
            transform: translateX(0) #{$add} translateZ(0);
          }
        }
      }

      // slideOutLeft

      @if ($a == 'slide-out-left') {
        $offset: 2000px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 1;
            transform: translateX(0) #{$add} translateZ(0);
          }

          100% {
            opacity: 0;
            transform: translateX(-$offset) #{$add} translateZ(0);
          }
        }
      }

      // slideOutRight

      @if ($a == 'slide-out-right') {
        $offset: 2000px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 1;
            transform: translateX(0) #{$add} translateZ(0);
          }

          100% {
            opacity: 0;
            transform: translateX($offset) #{$add} translateZ(0);
          }
        }
      }

      // slideOutUp

      @if ($a == 'slide-out-up') {
        $offset: 2000px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          0% {
            opacity: 1;
            transform: translateY(0) #{$add} translateZ(0);
          }

          100% {
            opacity: 0;
            transform: translateY(-$offset) #{$add} translateZ(0);
          }
        }
      }

// Zoomers
// ================================================

      // zoomIn

      @if ($a == 'zoom-in') {
        $zoom: 0.3;
        @if $mod {
          $zoom: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          from {
            opacity: 0;
            transform: scale3d(#{$zoom}, #{$zoom}, #{$zoom}) translateZ(0);
          }

          50% {
            opacity: 1;
          }
        }
      }

      // zoomInDown

      @if ($a == 'zoom-in-down') {
        $offset: 1000px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          from {
            opacity: 0;
            transform: scale3d(.1, .1, .1) translate3d(0, -$offset, 0);
            animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
          }

          60% {
            opacity: 1;
            transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
          }
        }
      }

      //zoomInLeft

      @if ($a == 'zoom-in-left') {
        $offset: 1000px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          from {
            opacity: 0;
            transform: scale3d(.1, .1, .1) translate3d(-$offset, 0, 0);
            animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
          }

          60% {
            opacity: 1;
            transform: scale3d(.475, .475, .475) translate3d(10px, 0, 0);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
          }
        }
      }

      //zoomInRight

      @if ($a == 'zoom-in-right') {
        $offset: 1000px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          from {
            opacity: 0;
            transform: scale3d(.1, .1, .1) translate3d($offset, 0, 0);
            animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
          }

          60% {
            opacity: 1;
            transform: scale3d(.475, .475, .475) translate3d(-10px, 0, 0);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
          }
        }
      }

      // zoomInUp

      @if ($a == 'zoom-in-up') {
        $offset: 1000px;
        @if $mod {
          $offset: $mod;
        }
        @keyframes #{$a}#{$sfx} {
          from {
            opacity: 0;
            transform: scale3d(.1, .1, .1) translate3d(0, $offset, 0);
            animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
          }

          60% {
            opacity: 1;
            transform: scale3d(.475, .475, .475) translate3d(0, -60px, 0);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
          }
        }
      }

// use-keyframes mixin end
}