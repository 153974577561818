// sass-lint:disable-all

// HELPER FUNCTIONS:
// FONTS
//---------------------------------


// get font size in %
@function font-size-percentage($parent-size:1rem,$target-size:0.5rem) {
  @return strip-unit($target-size)*100/strip-unit($parent-size)*1%;
}
@function fsp($p:null,$t:null){
  @return font-size-percentage($p,$t);
}


