// IMAGES - EMOJI

// crying
$emoji-sad: '<svg xmlns="http://www.w3.org/2000/svg" width="52.8" height="52.8" viewBox="0 0 52.8 52.8"><path d="M7.4 27.9c-.8 0-1.5-.3-2-.8-.6-.5-.9-1.3-.9-2s.3-1.5.8-2.1l2-2.1 2.1 2c1.2 1.1 1.2 3 .1 4.1-.6.6-1.3.9-2.1.9zm-.1-5.3l-1.2 1.2c-.3.3-.5.8-.5 1.2 0 .5.2.9.5 1.2.3.3.8.5 1.2.5.5 0 .9-.2 1.2-.5.7-.7.6-1.8 0-2.4l-1.2-1.2z" fill="fillColor"/><path d="M26.4 52.8C11.8 52.8 0 41 0 26.4S11.8 0 26.4 0s26.4 11.8 26.4 26.4S41 52.8 26.4 52.8zm0-51.3C12.7 1.5 1.5 12.7 1.5 26.4s11.2 24.9 24.9 24.9 24.9-11.2 24.9-24.9S40.1 1.5 26.4 1.5z" fill="fillColor"/><path d="M13.4 40.9l-1.4-.6c2.4-5.9 8.3-9.7 14.7-9.4 5.6.3 10.7 3.9 12.9 9.1l-1.4.6c-2-4.7-6.6-7.9-11.6-8.2-5.7-.2-11 3.2-13.2 8.5zM21.34 19.55h-1.5c0-2.5-2.1-4.6-4.6-4.6s-4.6 2-4.6 4.6h-1.5c0-3.4 2.7-6.1 6.1-6.1s6.1 2.7 6.1 6.1zm21.8 0h-1.5c0-2.5-2.1-4.6-4.6-4.6s-4.6 2-4.6 4.6h-1.5c0-3.4 2.7-6.1 6.1-6.1s6.1 2.7 6.1 6.1z" fill="fillColor"/></svg>';

$emoji-excited: '<svg xmlns="http://www.w3.org/2000/svg" width="52.8" height="52.8" viewBox="0 0 52.8 52.8"><path d="M26.4 52.8C11.8 52.8 0 41 0 26.4S11.8 0 26.4 0s26.4 11.8 26.4 26.4S41 52.8 26.4 52.8zm0-51.3C12.7 1.5 1.5 12.7 1.5 26.4s11.2 24.9 24.9 24.9 24.9-11.2 24.9-24.9S40.1 1.5 26.4 1.5z" fill="fillColor"/><path d="M26.5 47.1c-9.1 0-16.5-7.4-16.5-16.5V29h33v1.6c0 9.1-7.4 16.5-16.5 16.5zm-15-16.6c0 8.3 6.7 15.1 15 15.1s15-6.7 15-15v-.1h-30zM15.24 22.15c-3.4 0-6.1-2.8-6.1-6.2h1.5c0 2.5 2.1 4.6 4.6 4.6s4.6-2.1 4.6-4.6h1.5c0 3.4-2.7 6.2-6.1 6.2zm21.8 0c-3.4 0-6.1-2.8-6.1-6.2h1.5c0 2.5 2.1 4.6 4.6 4.6s4.6-2.1 4.6-4.6h1.5c0 3.4-2.7 6.2-6.1 6.2z" fill="fillColor"/></svg>';

// flying
$emoji-high: '<svg xmlns="http://www.w3.org/2000/svg" width="88.5" height="52.8" viewBox="0 0 88.5 52.8"><path d="M44.4 52.8C29.8 52.8 18 41 18 26.4S29.8 0 44.4 0s26.4 11.8 26.4 26.4S59 52.8 44.4 52.8zm0-51.3c-13.7 0-24.9 11.2-24.9 24.9s11.2 24.9 24.9 24.9 24.9-11.2 24.9-24.9S58.1 1.5 44.4 1.5zM14.9 28.5c-.8 0-1.3-.7-1.8-1.4-.4-.5-.7-1-1.2-1.1-.4-.1-.8.1-1.3.2-.9.3-2.1.7-3.5-.2-1.6-1-1.6-2.6-1.4-3.5-.6-.2-1.8-.6-2.9-2-1-1.5-.7-2.7-.2-3.4-.9-.5-2-1.7-2.5-3.8-.3-.9.1-1.7.9-2.1.7-.4 1.9-.3 2.6.6.1.1.2.2.3.4.8.9 1.1 1.3 5 2.3 5 1.3 6.8 6.2 7.2 7.7.3 1.2.8 4.3 0 5.7-.2.3-.4.5-.8.6h-.4zm-3.1-4c.2 0 .4 0 .6.1 1 .2 1.6 1.1 2.1 1.7.1.1.3.4.5.6.3-.8.1-2.9-.2-4.3-.4-1.3-1.8-5.5-6.1-6.6-4.1-1-4.8-1.5-5.8-2.8-.3-.2-.4-.3-.5-.5-.2-.2-.6-.2-.8-.1 0 0-.2 0-.1.4.4 1.6 1.3 2.6 2 2.9.4.1.6.5.7.9.1.4-.1.8-.4 1.1-.1.1-.5.6.3 1.6C5.2 20.9 6.3 21 6.3 21c.4 0 .7.2.9.6.2.3.2.8.1 1.1-.1.2-.5 1.3.7 2 .9.5 1.5.4 2.3.1.4-.2.9-.3 1.5-.3zm-5.6-2zm-.3-.4zm-3.1-5.3zm70.8 11.7h-.4c-.3-.1-.6-.3-.8-.6-.8-1.4-.3-4.5 0-5.7.4-1.5 2.1-6.4 7.2-7.7 3.9-1 4.3-1.4 5-2.3.1-.1.2-.2.3-.4.6-.7 1.8-1 2.6-.6s1.1 1.2.9 2.1c-.5 2-1.6 3.2-2.5 3.8.5.7.8 1.9-.3 3.3-1.2 1.4-2.3 1.9-2.9 2 .3.9.3 2.5-1.4 3.5-1.5.9-2.6.5-3.5.2-.5-.2-.9-.3-1.3-.2s-.8.6-1.2 1.1c-.4.8-1 1.5-1.7 1.5zm13-16c-.1 0-.4 0-.5.2s-.2.3-.3.4c-1 1.3-1.7 1.8-5.8 2.8-4.3 1.1-5.7 5.3-6.1 6.6-.4 1.4-.5 3.5-.3 4.3.2-.2.4-.5.5-.6.5-.7 1.1-1.5 2.1-1.7.8-.2 1.5.1 2.1.2.8.3 1.4.4 2.3-.1 1.2-.7.8-1.8.7-2-.2-.4-.1-.8.1-1.1.2-.3.6-.5 1-.6 0 0 1.1-.1 2.2-1.5.8-.9.3-1.5.2-1.6-.3-.3-.4-.7-.3-1.1-.2-.3.1-.7.5-.8.7-.3 1.6-1.3 2-2.9.1-.3 0-.4-.1-.4-.1-.1-.2-.1-.3-.1zm-4.3 10z" fill="fillColor"/><path d="M44.9 47.1c-9.1 0-16.5-7.4-16.5-16.5V29h33v1.6c0 9.1-7.4 16.5-16.5 16.5zm-15-16.6c0 8.3 6.7 15.1 15 15.1s15-6.7 15-15v-.1h-30zm9.9-11.1h-1.5a4.65 4.65 0 0 0-9.3 0h-1.5c0-3.4 2.7-6.1 6.1-6.1s6.2 2.7 6.2 6.1zm21.8 0h-1.5c0-2.5-2.1-4.6-4.6-4.6s-4.6 2.1-4.6 4.6h-1.5c0-3.4 2.7-6.1 6.1-6.1s6.1 2.7 6.1 6.1z" fill="fillColor"/></svg>';

// laughing
$emoji-happy: '<svg xmlns="http://www.w3.org/2000/svg" width="52.8" height="52.8" viewBox="0 0 52.8 52.8"><path d="M26.5 47.3c-8.7 0-15.8-7.1-15.8-15.8v-.8h31.5v.8c.1 8.7-7 15.8-15.7 15.8zM12.4 32.4a14.22 14.22 0 0 0 28.4 0H12.4z" fill="fillColor"/><path d="M26.4 52.8C11.8 52.8 0 41 0 26.4S11.8 0 26.4 0s26.4 11.8 26.4 26.4S41 52.8 26.4 52.8zm0-51.3C12.7 1.5 1.5 12.7 1.5 26.4s11.2 24.9 24.9 24.9 24.9-11.2 24.9-24.9S40.1 1.5 26.4 1.5z" fill="fillColor"/><path d="M21.34 19.55h-1.5c0-2.5-2.1-4.6-4.6-4.6s-4.6 2-4.6 4.6h-1.5c0-3.4 2.7-6.1 6.1-6.1s6.1 2.7 6.1 6.1zm21.8 0h-1.5c0-2.5-2.1-4.6-4.6-4.6s-4.6 2-4.6 4.6h-1.5c0-3.4 2.7-6.1 6.1-6.1s6.1 2.7 6.1 6.1z" fill="fillColor"/></svg>';

$emoji-inlove: '<svg xmlns="http://www.w3.org/2000/svg" width="52.8" height="52.8" viewBox="0 0 52.8 52.8"><path d="M26.4 52.8C11.8 52.8 0 41 0 26.4S11.8 0 26.4 0s26.4 11.8 26.4 26.4S41 52.8 26.4 52.8zm0-51.3C12.7 1.5 1.5 12.7 1.5 26.4s11.2 24.9 24.9 24.9 24.9-11.2 24.9-24.9S40.1 1.5 26.4 1.5z" fill="fillColor"/><path d="M26 43.9c-7.9 0-14.5-6.2-15-14.1l1.5-.1c.4 7.4 6.8 13 14.2 12.7 6.8-.4 12.3-5.8 12.6-12.7l1.5.1c-.4 7.6-6.5 13.7-14.1 14.1H26zM19.5 13.8c-1.1 0-2.1.5-2.7 1.3l-.1.1-.1-.1c-.5-.8-1.6-1.3-2.6-1.3-1.9 0-3.2 1.3-3.2 3 0 2.6 5 6.4 5.3 6.7.2.2.4.2.6.2s.5-.1.6-.2c.4-.3 5.4-4.2 5.4-6.7.1-1.8-1.3-3-3.2-3zM38.5 13.8c-1.1 0-2.1.5-2.7 1.3l-.1.1-.1-.1c-.5-.8-1.6-1.3-2.6-1.3-1.9 0-3.2 1.3-3.2 3 0 2.6 5 6.4 5.3 6.7.2.2.4.2.6.2s.5-.1.6-.2c.4-.3 5.4-4.2 5.4-6.7.1-1.8-1.3-3-3.2-3z" fill="fillColor"/></svg>';

// pissed off
$emoji-angry: '<svg xmlns="http://www.w3.org/2000/svg" width="52.8" height="52.8"><path d="M26.4 52.8C11.8 52.8 0 41 0 26.4S11.8 0 26.4 0s26.4 11.8 26.4 26.4S41 52.8 26.4 52.8zm0-51.3C12.7 1.5 1.5 12.7 1.5 26.4s11.2 24.9 24.9 24.9 24.9-11.2 24.9-24.9S40.1 1.5 26.4 1.5z" fill="fillColor"/><path d="M14.4 40.9l-1.4-.6c2.4-5.9 8.3-9.7 14.7-9.4 5.6.3 10.7 3.9 12.9 9.1l-1.4.6c-2-4.7-6.6-7.9-11.6-8.2-5.7-.2-11 3.2-13.2 8.5zM22.34 21.55h-1.5c0-2-1.6-3.6-3.6-3.6s-3.6 1.6-3.6 3.6h-1.5c0-2.8 2.3-5.1 5.1-5.1a5 5 0 0 1 5.1 5.1zm16.8 0h-1.5c0-2-1.6-3.6-3.6-3.6s-3.6 1.6-3.6 3.6h-1.5c0-2.8 2.3-5.1 5.1-5.1s5.1 2.2 5.1 5.1zM27.4 15.66l9.13-4.55.67 1.37L28.07 17zm-13-3.24l.68-1.34 9.12 4.56-.67 1.34z" fill="fillColor"/></svg>';


