
.page-about,
.page-introduction,
.page-error
{

  h2.content-header,
  .content-header > h2 {
    position: relative;
    line-height: 1.2;

    @include responsive-font(
      544, 1200, 35, 50
    );

  }
}

.page-taxonomy-term,
.page-user,
.page-search,
.page-comment,
.node-type-article,
.page-articlepage,
.page-magazin,
.page-magazine,
.page-about,
.page-introduction,
.node-type-webform,
.page-notifications,
.node-type-activity
{
  @include desktop{
    @include drupal-page(r);
  }
}

.page-about,
.page-introduction
{

  main {
    p {
      &:empty {
        display: none;
      }
    }

    h2,h3,h4 {
      font-weight: 400;
    }

    strong {
      font-weight: 600;
    }

    h4 {
      font-size: rem(22);
      @include m(b 15);
    }

    h5 {
      font-size: rem(17);
    }
  }

  .content-header {
    @include break-out-wrap(x,$do-pads:true);
    height: 40vh;
    flex-basis: 100%;
    @include bg-overlay(0.45);
    color: white;
    display: flex;
    align-items: flex-end;

    background-image: $default-bg;
    background-size: cover;

    background-position: center;
    @include media(">huge"){
      background-position: center top 65%;
    }
    @include mobile{
      background-position: left 22% center;
    }

    .subtitle {
      font-size: fsp(50,35);
      display: block;
    }
  }

}

.page-comment,
.page-courses,
.page-about,
.page-introduction,
.node-type-webform
{

  .content-header,
  .item-list section,
  .item-list aside,
  .content > form,
  .content > .node
  {
    @include wrap-pads(y);
  }

  .content-header,
  .item-list > section,
  .view-content > section,
  .content > form,

  // .comment-text,
  .content > .comment,

  .content > .node,
  .content > .node ~ section,
  .view-content > .tab-panels,
  .view-content > .acc-panels
  {
    & > * {
      flex-basis: $content-pages-max-width;
      max-width: $content-pages-max-width;

      @if $content-pages-center-content {
        @include m(x auto);
      }
    }
  }

}
.node-type-article
{

  .content-header,
  .item-list section,
  .item-list aside,
  .content > form,
  .content > .node
  {
    @include wrap-pads(y);
  }

  .content-header,
  .item-list > section,
  .view-content > section,
  .content > form,

  // .comment-text,
  .content > .comment,

  .content > .node .article-item,
  .content > .node ~ .article-item,
  .view-content > .tab-panels,
  .view-content > .acc-panels
  {
    & > * {
      flex-basis: $content-pages-max-width;
      max-width: $content-pages-max-width;
    }
  }

}

.page-taxonomy-term,
.page-user,
.page-search,
.page-articlepage,
.page-magazine {
  .node-article > section,
  .view-content > section {
    @include wrap-pads(y);
  }
  .node-article > section > *,
  .view-content > section > *,
  .view-content ~ .item-list,
  .view-search ~ .item-list {
    flex-basis: $content-pages-max-width;
    max-width: $content-pages-max-width;

    @if $content-pages-center-content {
      @include m(x auto);
    }
  }
}
.page-magazin{
  .node-article > section,
  .view-content > section {
    @include wrap-pads(y);
  }
  .node-article > section > *,
  .view-content .magazine-list > *,
  .view-content ~ .item-list,
  .view-search ~ .item-list {
    flex-basis: $content-pages-max-width;
    max-width: $content-pages-max-width;
  }
}
.page-search .item-list{
  flex-basis: $content-pages-max-width;
  max-width: $content-pages-max-width;
  @if $content-pages-center-content {
    @include m(x auto);
  }
}


// page title on mobile

@mixin page-title-mobile($title:null) {

  $text-holder: '&';
  @if $title {
    $text-holder: '&:after';
  }

  padding: 0;
  margin: 0;
  pointer-events: none;

  height: $header-height-mobile * $mob-head-mod - $rope-height;

  @include media("<=small-mobile"){
    height: $header-height-mobile - $rope-height + $rope-mod-1;
  }

  @include fix(t);
  z-index: 100;
  @include flex-align;


  @if $title {
    @include text-hide;
    &:after {
      display: block;
      content: $title;
      font-family: $font-family-base;
    }
  }

  #{$text-holder} {
    color: white;
    font-size: rem(22);
    font-weight: 700;

    @include media("<=375px"){
      font-size: rem(20);
    }
  }
}

.page-taxonomy-term,
.page-user,
.page-search,
.page-comment,
.node-type-article,
.page-articlepage,
.page-magazin,
.page-magazine,
.page-about,
.page-introduction,
.page-error,
.page-notifications
{
  @include desktop{
    #page-title {
      display: none;
    }
  }
}

.page-taxonomy-term,
.page-user,
.page-search,
.page-comment,
.node-type-article,
.page-articlepage,
.page-magazin,
.page-magazine,
.page-courses,
.page-faq-page,
.page-about,
.page-introduction,
.page-error,
.node-type-webform,
.page-notifications
{
  @include mobile{
    .page-header {
      .link-home {
        pointer-events: none;
        opacity: 0;
      }
    }
  }
}


.page-courses,
.page-faq-page,
.page-about,
.page-introduction,
.page-error,
.page-notifications
{
  @include mobile{
    #page-title {
      @include page-title-mobile;
    }
  }
}

.page-magazin,
.page-magazine {
  @include mobile{
    section.magazine .section-title {
      @include page-title-mobile;
    }
    #page-title {
      display: none;
    }
  }
}

.page-taxonomy-term,
.page-user {
  @include mobile{
    #page-title {
      @include page-title-mobile;
    }
  }
}


.page-search,
.page-comment,
.node-type-article,
.page-articlepage {
  @include mobile{
    #page-title {
      @include page-title-mobile('מגזין הצופים');
    }
  }
}

.node-type-activity{
	.node-activity{
		.content{
			.field{
				background:#f5f5f5;
				margin:15px 0;
				padding:10px 10px 10px 0;

				a{
					text-decoration:none;
				}
				.field-label{
					font-size: 1.17em;
					width: 15%;

					@include mobile{
					    width: 40%;
					}
				}
			}
			.field-collection-container {
				background:none;
			    	border: none;
			   	margin:15px 0;

				.field{
					background:none;
					margin-bottom: 0;
    					padding-bottom: 0;

					.field{
						background:#f5f5f5;
						padding:10px 10px 10px 0;

						.even,.odd{
							background:#f5f5f5;
						}

					}
				}
				.even,.odd{
					background:#e9e9e9;
					border: none;
				}
				.field-collection-view{
					border: none;
					padding-top: 0;
 
					.field-label {
				    		width: 15%;
						@include mobile{
						    width: 40%;
						}
					}				
				}
				.field-label-inline .field-items{
					width:85%;
					
					@include mobile{
					    	margin-top:15px;
					    	width: 100%;

						.field-label-inline .field-items{
							margin-top:0;
							width:60%;
						}
					}
				}
			}
		}
	}
}
