// sass-lint:disable-all


// Visual: Geometric Forms
// by Pavel G
// ----------------------------------------------------------


// circle
@mixin circle($width:30px, $color:null) {
  //display: inline-block;
  width: check-unit($width);
  height: check-unit($width);
  border-radius: 50%; //$width/2;
  line-height: check-unit($width);
  background-color: $color;
}




// TRIANGLE


/// Triangle helper mixin
/// @param {Direction} $direction - Triangle direction, either `top`, `right`, `bottom` or `left`
/// @param {Color} $color [currentcolor] - Triangle color
/// @param {Length} $size [1em] - Triangle size
@mixin triangle($direction, $color: currentcolor, $size: 1em, $wmod:1) {
  @if not index(top right bottom left, $direction) {
    @error "Direction must be either `top`, `right`, `bottom` or `left`.";
  }

  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  border-#{opposite-position($direction)}: ($size * $wmod) solid $color;

  $perpendicular-borders: $size solid transparent;

  @if $direction == top or $direction == bottom {
    border-left:   $perpendicular-borders;
    border-right:  $perpendicular-borders;
  } @else if $direction == right or $direction == left {
    border-bottom: $perpendicular-borders;
    border-top:    $perpendicular-borders;
  }
}


@mixin bubble( $circle-size: 100px, $color: white, $ar-loc: bottom, $elem:before, $wmod:1, $shadow: false) {

  @include circle($circle-size,$color);
  position: relative;

  $arr-size: round($circle-size / 5.7);

  @if $shadow {
    @if ($shadow == true) {
      box-shadow: 0 0 25px 0 black(0.2);
    } @else {
      box-shadow: $shadow;
    }
  }

  &:#{$elem} {
    @include pseudo;
    position: absolute;
    bottom: -($arr-size - 5px);
    left: calc(50% - #{$arr-size});
    @include triangle($ar-loc, $color, $arr-size, $wmod);
    //z-index: 1;
  }

//  & > * {
//    position: relative;
//    z-index: 2;
//  }
}

