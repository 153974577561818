// sass-lint:disable-all


// HELPER FUNCTIONS FOR BOXMODEL-RELATED MIXINS
// by Pavel G
// ----------------------------------------------------------

$possiblie-positions: abs absolute fix fixed rel relative;

$possible-coordinates: top t top-left t-l topleft tl left-top l-t lefttop lt top-right t-r topright tr right-top r-t righttop rt bottom bot b bottom-right bot-right b-r bottomright botright br right-bottom right-bot r-b rightbottom rightbot rb bottom-left bot-left b-l bottomleft botleft bl left-bottom left-bot l-b leftbottom leftbot lb;


@function extractor(
  $loc,
  $def: 0,
  $find: null,
  $unit: $global-unit
) {

  $tw: top t y y-axis;
  $rw: right r x x-axis;
  $bw: bottom bot b y y-axis;
  $lw: left l x x-axis;

  $tlw: top t left l top-left t-l topleft tl left-top l-t lefttop lt;
  $trw: top t right r top-right t-r topright tr right-top r-t righttop rt;
  $brw: bottom bot b right r bottom-right bot-right b-r bottomright botright br right-bottom right-bot r-b rightbottom rightbot rb;
  $blw: bottom bot b left l bottom-left bot-left b-l bottomleft botleft bl left-bottom left-bot l-b leftbottom leftbot lb;

  $ww: width w;
  $hw: height h;

  $pw: $possiblie-positions;

  $top: null;
  $right: null;
  $bot: null;
  $left: null;

  $top-left: null;
  $top-right: null;
  $bot-right: null;
  $bot-left: null;

  $width: null;
  $height: null;

  $position: null;

  $color: null;

  // if just 1 number:  43
  @if ( not is-list($loc) ) and ( can-be-length($loc) ) {

    $val: $loc;

    $top: $val;
    $right: $val;
    $bot: $val;
    $left: $val;
    $top-left: $val;
    $top-right: $val;
    $bot-right: $val;
    $bot-left: $val;
    $width: $val;
    $height: $val;

  // if it's 1 something, it might be position or color
  } @else if ( not is-list($loc) ) and ( not can-be-length($loc) ) {

    @if ( type-of($loc) == 'color' ) {
      $color: $loc;
    }

    @else if (  index($pw, $loc) ) {
      $position: $loc;
      $top: $def;
      $right: $def;
      $bot: $def;
      $left: $def;
    }


  // if just 2 numbers: 10 15
  } @else if ( length($loc) == 2 ) and ( can-be-length(nth($loc,1)) ) and ( can-be-length(nth($loc,2)) ) {

    $val-y: nth($loc,1);
    $val-x: nth($loc,2);

    $width: $val-y;
    $height: $val-x;

    $top: $val-y;
    $right: $val-x;
    $bottom: $val-y;
    $left: $val-x;

  }
    // extracting stuff

    @for $i from 1 through length($loc) {

      $prop: nth($loc,$i);
      $val: $def;

      // $color: null;

      // findig value

      @if ( length($loc) > $i ) {
        $val: nth($loc,$i+1);
      }
      // if value is color so maybe the next item is value
      @if ( length($loc) > $i+1 ) and ( type-of($val) == 'color' ) {
        $color: $val;
        $val: nth($loc,$i+2);
      }

      // checking for top
      @if ( index( $tw, $prop ) ) {
        @if ( can-be-length($val) ) {
          $top: $val;
        } @else {
          $top: $def;
        }
      }

      // checking for right
      @if ( index( $rw, $prop ) ) {
        @if ( can-be-length($val) ) {
          $right: $val;
        } @else {
          $right: $def;
        }
      }

      // checking for bottom
      @if ( index( $bw, $prop ) ) {
        @if ( can-be-length($val) ) {
          $bot: $val;
        } @else {
          $bot: $def;
        }
      }

      // checking for left
      @if ( index( $lw, $prop ) ) {
        @if ( can-be-length($val) ) {
          $left: $val;
        } @else {
          $left: $def;
        }
      }

      // checking for top-left
      @if ( index( $tlw, $prop ) ) {
        @if ( can-be-length($val) ) {
          $top-left: $val;
        } @else {
          $top-left: $def;
        }
      }

      // checking for top-right
      @if ( index( $trw, $prop ) ) {
        @if ( can-be-length($val) ) {
          $top-right: $val;
        } @else {
          $top-right: $def;
        }
      }

      // checking for bot-right
      @if ( index( $brw, $prop ) ) {
        @if ( can-be-length($val) ) {
          $bot-right: $val;
        } @else {
          $bot-right: $def;
        }
      }

      // checking for bot-left
      @if ( index( $blw, $prop ) ) {
        @if ( can-be-length($val) ) {
          $bot-left: $val;
        } @else {
          $bot-left: $def;
        }
      }

      // checking for width
      @if ( index( $ww, $prop ) ) {
        @if ( can-be-length($val) ) {
          $width: $val;
        } @else {
          $width: $def;
        }
      }

      // checking for height
      @if ( index( $hw, $prop ) ) {
        @if ( can-be-length($val) ) {
          $height: $val;
        } @else {
          $height: $def;
        }
      }

      // checking for position
      @if ( index($pw, $prop) ) {
        $position: $prop;
      }

      // checking for color
      @if ( type-of($prop) == 'color' ) {
        $color: $prop;
      }

    }


  $extracted: (
    top: check-unit($top,$unit),
    right: check-unit($right,$unit),
    bot: check-unit($bot,$unit),
    bottom: check-unit($bot,$unit),
    left: check-unit($left,$unit),
    top-left: check-unit($top-left,$unit),
    top-right: check-unit($top-right,$unit),
    bot-right: check-unit($bot-right,$unit),
    bot-left: check-unit($bot-left,$unit),
    bottom-right: check-unit($bot-right,$unit),
    bottom-left: check-unit($bot-left,$unit),
    width: check-unit($width,$unit),
    height: check-unit($height,$unit),
    pos: unshorten-pos($position),
    position: unshorten-pos($position),
    color: $color
  );

  @if not $find {
    @return $extracted;
  }  @else {
    @return map-get($extracted, $find);
  }

}


