
.node-type-webform {

  .meta.submitted,
  .links.inline{
    display: none;
  }

  form .element-invisible {
    position: static !important;
    clip: auto;
    overflow: visible;
    height: auto;
  }

  #page-title {

    font-weight: 400;
    margin: 0;
    @include wrap-pads(t);

    @include desktop {
      font-size: rem(30);
      margin: 0;

      flex-basis: $content-pages-max-width;
      max-width: $content-pages-max-width;

      @if $content-pages-center-content {
        @include m(x auto);
      }
    }

    @include mobile{
      &:before {
        content: 'דיווח על תקלה';
        @include page-title-mobile;
      }

      // changing text on mobile
      font-size: 0px;
      color: transparent;

      &:after {
        display: block;
        content: 'יש תקלה באתר? ספרו לנו על זה!';
        font-size: rem(30);
        color: black;
        max-width: 225px;
      }
    }
  }


  // form

  $input-height: 35px;

  form {
    @include m(b 15);
  }

  .form-required, div.grippie {
    display: none;
  }

  .form-text,textarea {
    border-color: $charcoal-grey;
    margin: 0;
    font-size: rem(18);
    color: $charcoal;

    &:focus {
      outline: none;
    }
  }

  .form-text {
    padding: 0;
    @include borders(x 0 t 0);
    height: $input-height;
    line-height: ($input-height - 1px);
    position: relative;
    z-index: 2;
    background: transparent;

    @include placeholder{
      color: $brown-grey;

      @include desktop{
        color: transparent;
        font-size: 0px;
      }
    }
  }

  textarea {
    resize: none;
    padding: 10px 15px;
    width: 100%;

    @include placeholder{
      color: transparent;
      font-size: 0px;
    }
  }

  .input-wrap,.form-actions,#edit-submitted-print-screen-ajax-wrapper {
    position: relative;
    max-width: 500px;
  }

  .form-actions {
    @include m(t 10);
  }

  .form-submit {

    @include desktop{
      max-width: 230px;
      @include outline-button($charcoal);
    }

    @include mobile{
      @include color-button;
      height: 55px;
      font-size: rem(18);
    }
  }

  .input-wrap {
    min-height: 65px;
    @include p(b 35);
  }
 
  
  // #edit-submitted-print-screen-ajax-wrapper .description{
	// display:none;
  // }
  .form-managed-file{
    .form-file{
      border:none;
      max-width:54%;
      height: initial;
      line-height: normal;
      padding: 0;
    }
    .form-submit[name="submitted_print_screen_upload_button"]{
      display: none;
    }
    .form-submit{
      max-width:60px;
      height: auto;
      padding: 5px 0;
      line-height: normal;
      margin: 5px 0;
    }
    span.file{
      margin-left:10px;
    }
  }

  // label

  .input-wrap label:first-child{
    // height: $input-height;
    // line-height: $input-height;
    display: block;
    pointer-events: none;
    color: black;
    font-size: rem(22);
    z-index: 1;
    padding: 0;
    margin: 0;

    @include mobile{
      color: $brown-grey;
      display: none;
    }
  }

  .webform-component-textarea label:first-child,
  #edit-submitted-print-screen-ajax-wrapper label {
    @include m(b 10);

    @include mobile{
      display: block;
    }
  }

  // .form-text {

  //   & ~ label {
  //     position: absolute;
  //     top: 30px;
  //     right: 0;
  //     transition: all 0.4s;
  //   }

  //   &:focus,&.not-empty{
  //     & ~ label {
  //       top: 0;
  //     }
  //   }
  // }

  // errors & validation

  .form-text {

    &.invalid {
      &:not(:focus){
        border-color: $error-color;
      }
    }

  }

  .input-wrap label.error {
    font-size: rem(15);
    @include ps(b 14 r 0);
  }

}
