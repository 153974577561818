// sass-lint:disable-all


@mixin inherit-all($include-states: false) {

  @if $include-states {

    &, &:hover {
      font-size: inherit;
      font-family: inherit;
      font-weight: inherit;
      line-height: inherit;
      font-style: inherit;
      text-decoration: inherit;
      color: inherit;
      cursor: inherit;
    }

  } @else {

    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    font-style: inherit;
    text-decoration: inherit;
    color: inherit;
    cursor: inherit;

  }

}
%inherit-all {
  @include inherit-all();
}


@mixin inherit-text() {
  text-decoration: inherit;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}


@mixin reset-list($li-dis:inline-block,$ul-dis:block,$sd:false) {
  @include reset-margs-pads();
  list-style-type: none;
  @if not $sd { display: $ul-dis; }
  li {
    @include reset-margs-pads();
    @if not $sd { display: $li-dis; }
  }
}


@mixin reset-position($pos:inherit) {
  @include position($pos, auto auto auto auto);
}


@mixin reset-margs-pads() {
  padding: 0; margin: 0;
}


@mixin reset-element(
  $position:true,
  $size:false,
  $pseudo:false,
  $pads-dims:true,
  $color:true,
  $bg:true,
  $float:true,
  $display:true,
  $shad:true,
  $all: false,
  $force: false
) {
  border: 0;
  border-radius: 0;
  list-style: none;
  transform: none;
  transition: none;
  animation: none;

  @if ($shad) or ($all) { box-shadow: none; }

  @if ($display) or ($all) {
    @if $display == true {
      display: inherit;
    } @else {
      display: $display;
    }
  }
  @if ($float) or ($all) {
    float: none;
  }
  @if ($size) or ($all) {
    @include size(auto,auto);
    min-height: 0;
    min-width: 0;
    max-height: none;
    max-width: none;
  }
  @if ($pads-dims) or ($all) {
    margin: 0;
    padding: 0;
  }

  @if ($pseudo) or ($all) {
    &:before,&:after {
      content:"";
      background: none;
      display: none;
    }
  }
  @if $position or ($all) {
    @if $position == true {
      @include position(relative, auto auto auto auto);
    } @else {
      @include position($position, auto auto auto auto);
    }
  }
  @if ($color) or ($all) {
    color: inherit;
  }
  @if ($bg) or ($all) {
    background: none;
  }

  @content;
}



@mixin button-normalize($extended:true) {

  @if not ($extended == only) {
    @include no-text-select(false);
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
  }

  @if $extended {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    min-width: 0;
    border: 0;
    //border-radius: 0;
    border-style: solid;
    box-shadow: none;
    padding: 0;
  }

  &:disabled, &:disabled:active, &:disabled:hover {
    cursor: $cursor-disabled;
    box-shadow: none;
  }

  &:active, &:disabled
  // , &:focus, &:hover
  {
    outline: 0;
    text-decoration: none;
  }

}


@mixin text-button($extended: true, $do-states: true) {

  $elem: '&';
  @if $do-states {
    $elem: '&, &:hover,&:active,&:focus';
  }

  #{$elem} {
    border: 0;
    background-color: transparent;
  }
  appearance: none;
  white-space: normal;
  text-align: inherit;

  @if $extended {
    margin: 0;
    padding: 0;
    display: inline-block;
    height: auto;
    width: auto;
    cursor: pointer;
  }
}


@mixin reset-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@mixin reset-link {
  #{$this-a-tags}{
    text-decoration: none;
    color: inherit;
  }
}


@mixin remove-autofill($bg: $autofill-bg-color) {

  @keyframes autofill {
    to { background: #{$bg}; }
  }

  &:-webkit-autofill {
    &,&:hover,&:focus {
      //box-shadow: 0 0 0 300px white inset !important;
      animation-name: autofill;
      animation-fill-mode: both;
    }
  }
}
