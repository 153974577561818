// sass-lint:disable-all

//---------------------------------
// PAVEL G MIXINS v.3.7 8.01.2018
//---------------------------------

// Autoprefixer Required !!!


@charset 'UTF-8';

// Include basic CSS or not

$use-global-css: true !default;
$use-bootstrap-reboot: false !default;
$use-pure-css: false !default;
$global-css-seudo-rules: true !default;

// global settings

$direction: ltr !default;
$enable-flex: true !default;
$use-fallbacks: false !default; // if true will provide fallbacks (like floats in addition to flexbox)

$global-font-size: 100% !default; // = 16px in most browsers
$font-size-base: 1rem !default;
// for compatibility:
$base-font-size: 16px;
$root-font-size: 16px;

$border-radius: 4px !default;

$global-unit: rem !default;
$global-unit-affects-rem-function: false !default;

// icon system
$icn-use-real-classes: false !default;
$icn-do-url-encode : true !default;


// disabling window scroll with 'modal-open' class

$include-modal-open: true !default;

$modal-open-classes: '&.menu-open,&.modal-open,&.overlay-open' !default;
$modal-open-class-holder: 'html' !default;
$modal-open-scroll-element: '&' !default;
$modal-open-insist-on-scroll: true !default;
$modal-open-use-scroll-class: false !default;
$modal-open-has-scroll-class: 'has-scroll' !default;
$modal-open-also-do: false !default;
$modal-open-also-do-class: 'fixed' !default;

// GRID

$include-grid: false !default;
$grid-cell-class: '.cell' !default;
$grid-row-class: '.rw' !default;
// .col, .column, .row classes is not recommended - so that not to interfere with other grid systems - i.e. bootstrap. Other good options: .rrow, .grid-row, grid-col, etc

$grid-spacer-x: 20px !default;
$grid-spacer-y: 20px !default;

$multirow-includes-row: true !default;
$grid-use-type: false !default; // use nth-type instead of nth-child
$grid-use-x-margs: true !default; // if false, there will be no horizontal margins between cells, and margins will be done using only 'justify-content: space-between'
$grid-spread-cells: false !default; // related to $grid-use-x-margs
$grid-cell-use-minmax: true !default;

// misc little things
$cursor-disabled: not-allowed !default;
$autofill-bg-color: transparent !default;

// some default media breakpoints

$breakpoints: (
  small-mobile: 374px,
  mobile: 544px,
  small-tablet: 670px,
  tablet: 768px,
  // big-tablet: $body-min-width,
  laptop: 992px,
  desktop: 1200px
) !default;


// utility functions
@import "utils/numbers";
@import "utils/types";
@import "utils/units";
@import "utils/unitconversion";
@import "utils/lists";
@import "utils/strings";
@import "utils/maps";
@import "utils/directions";
@import "utils/sassy-cast";
@import "utils/easings";
@import "utils/helpers";

// functions
@import "functions/colors";
@import "functions/fonts";


// mixins
@import "mixins/shortcuts";
@import "mixins/basic";
@import "mixins/resets";
@import "mixins/bg";
@import "mixins/text";
@import "mixins/forms";


// systems
@import "systems/svg-icons-system";
@import "systems/include-media";
@import "systems/rem";
@import "systems/nth";
@import "systems/grid";


// boxmodel, positioning & centerers
@import "boxmodel-and-positioning/extractor";
@import "boxmodel-and-positioning/helpers";
@import "boxmodel-and-positioning/sizing";
@import "boxmodel-and-positioning/margs-pads";
@import "boxmodel-and-positioning/borders";
@import "boxmodel-and-positioning/position";
@import "boxmodel-and-positioning/centerers-aligners";


// responsive
@import "responsive/media-qrs";
@import "responsive/responsive-props";
@import "responsive/remify";


// solutions
@import "solutions/accessibility";
@import "solutions/flexbox";
@import "solutions/browser-hacks";


// visual
@import "visual/geometric";
@import "visual/dotted-border";
@import "visual/parallax";
@import "visual/shadows";
@import "visual/box";
@import "visual/slide-borders";
@import "visual/visual";


// components
@import "components/acctabs";
@import "components/close-button";
@import "components/nice-checkbox";
@import "components/nice-radio";
@import "components/nice-select";
@import "components/burger";
@import "components/slide-sidebar";
@import "components/simptip";


// animated
@import "animated/animate";
@import "animated/spinners";


// global CSS
@import "global-styles";