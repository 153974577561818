.page-courses, .node-type-activity-course  {

  @extend %drupal-page;

}
.start-end {
  font-size: 16px;
}

// editor

.node-type-activity-course {

  #content.column > .section {
    max-width: 1000px;
    @include m(x auto);
  }

  .node-activity-course {
    @include p(b 4vh);
  }

  .filter-help, .filter-wrapper .form-item, .filter-guidelines
  {
    @include p(x 0);
  }

  .form-actions {
    @include flex-spread;

    .form-submit {
      @include color-button($charcoal,white);
      max-width: 200px;
    }
  }

}


// general styles

.page-courses {

  #content.column > .section {
    @include p(b 50);
  }

  @include mobile{
    #content.column > .section,
    .page > .wrap
    {
      @include p(x 0);
    }
    #content.column > .section {
      @include p(t 0);
    }
  }

}

.course-info-modal .simplebar-scroll,
.page-courses .panel {

  &:not(:last-child){
    @include m(b 40);
  }

  header + p, section:not(:first-child) {
    border-top: 2px solid $light-grey-2;
    @include p(t 20);
    @include m(t 20);
  }

  section, article > p {
    line-height: 1.55;
  }

  .panel-title {
    line-height: 1.2;
  }

  h3 {
    font-size: rem(30);
    font-weight: 400;
  }

  .over-title {
    font-size: rem(22);
    margin: 0;
  }

  .teal {
    font-weight: 600;
    color: $greeny-blue;
  }

  .purple {
    font-weight: 600;
    color: $dark-lavender;
  }

  u,strong {
    font-weight: 600;
  }

  ul {
    line-height: 1.65;

    li {
      display: block;

      &:before{
        @include pseudo(inline-block);
        @include circle(4px,black);
        @include m(l 10);
        vertical-align: middle;
      }
    }
  }

}


// tabs & panels


.page-courses  {

  .tab-list {
    @include m(b 40);
  }

  .tab {
    @include desktop{
      @include p(l 10);
      max-width: 123px;
      font-size: rem(22);

      & > span {
        border-bottom: 3px solid transparent;
        @include p(b 3);
      }

      &[aria-selected="true"]{
        & > span {
          border-bottom: 3px solid black;
        }
      }

    }
  }

  .acc-tab {
    font-size: rem(45);
    font-weight: 600;
    letter-spacing: -1px;
  }

  .tab, .acc-panel-title {
    @include mobile {
      @include wrap-pads(x);
      @include p(y 20);
      border-bottom: 2px solid $light-grey-2;
    }
  }


  .acc-panel.open {
    @include mobile {
      .acc-panel-title {
        background-color: $light-grey-3;
      }
      .tab-panels {
        background-color: lighten($light-grey-3,5%);
      }
    }
  }

  .acc-panels {
    @include mobile {
      .tab-list {
        @include m(b 0);
      }
      .tab {
        @include p(y 15);
      }
    }
  }

  .panel-list{
    .course{
      ul{
        list-style-type: disc;
        padding-right: 16px;
        
        li{
          display: list-item;

          &:before{
            display:none;
          }
        }
      }
    }
  }

}
