



.panel-list {
  &>[aria-hidden='true'] {
    display: none;
  }

  &>[aria-hidden='false'] {
    display: block;

    @include desktop{
      @include animate(slide-in-up, 0.3s);
    }
  }
}

.acc-panel-list {
  &>[aria-hidden='true'] .tab-panels{
    @include desktop{
      display: none;
    }
  }

  &>[aria-hidden='false'] .tab-panels{
    @include desktop{
      display: block;
      @include animate(slide-in-up, 0.3s);
    }
  }
}

.acc-tab-list, .tab-list {
  @include desktop{
    display: flex;
  }
}

.tab-panels, .acc-panels {
  [role="tab"] {

    @include desktop{
      flex: 1;
    }

    cursor: default;
    display: block;
    font-weight: 600;
    color: black(0.3);

    &[aria-selected='true'] {
      color: $black;
    }

    & > span {
      cursor: pointer;
      display: inline-block;
    }

    &:focus {
      outline: 0;

      &[aria-selected="true"] > span {
        @extend %focus;
      }
    }

  }

  .close-tab {
    @include desktop{
      display: none;
    }
  }
}

.tab-panels {

  .panel-title {
    font-size: rem(35);
    font-weight: 400;

    h2 {
      font: inherit;
    }
    .start-end{
      font: initial;
      @include desktop{
        b,strong{
          font-size:18px;
        }
        span{
          margin-left:20px;
        }
        br{
          display:none;
        }
      }
      @media (max-width: 768px) {
        b,strong{
          font-size:18px;
        }
      }
      @media (max-width: 389px) {
        b,strong{
          font-size:16px;
        }
      }
    }
  }

}

// mobile

@mixin close-button-header {
  @include ps(fix r 10);
  z-index: 200;

  top: (($header-height-mobile * $mob-head-mod - $rope-height)/2 - 15px);

  @include media("<=small-mobile"){
    top: (($header-height-mobile - $rope-height + $rope-mod-1)/2 - 15px);
  }
}

.tab-panels {

  @include mobile{

    .tab {
      line-height: 1.25;

      @include media(">small-mobile"){
        font-size: rem(20);
      }
    }

    & > ul.panel-list {
      @include wrap-pads(x);
    }

    .panel-title {
      font-size: rem(30);
      line-height: 1.15;
      margin-bottom: 17px;
      @include m(t 14 b 17);
    }

    @at-root {
      body.panel-open {

        // replacing Burger button with Back button when Panel is open

        .main-nav {
          @include visually-hidden;
        }

        .close-tab {
          @include close-button-header;
        }

        // hiding everything except open panel

        .tab-panels .tab-list,
        .panel[aria-hidden="true"],
        .acc-panels .acc-tab-list,
        .acc-panel[aria-hidden="true"],
        .acc-panel-title
        {
          // display: none;
          @include visually-hidden;
        }

      }
    }

    // open Question style

    .panel {
      background: white;
      // @include p(t $header-height-mobile + 20px);
    }

  }

} // tab-panels


// acc panels

.acc-panels {

  .acc-panel-title {
    @include desktop{
      display: none;
    }
  }

  .acc-tab-list {
    @include mobile{
      display: none;
    }
  }

  .acc-panel-title {
    cursor: pointer;

    span {
      display: block;
      @extend %dropdown;
      background-position: left center;
      pointer-events: none;
    }

  }

  .acc-panel {
    @include mobile{
      @include collapsible($use-class: 'open', $target: '.tab-panels', $t: 0.2s, $op: true, $h:1500px);
    }
  }

}
