// FOOTER

.page-footer {

  z-index: 10;

  @include desktop{
    height: $footer-height - $rope-height;
  }

  @include mobile{
    height: $footer-height-mobile - ($rope-height - $rope-mod-1);
  }

  @include media("<=small-mobile"){
    height: $footer-height-mobile - ($rope-height - $rope-mod-2);
  }

  background-color: white;


  // rope center

  &:after {
    @include pseudo;
    @include s(87, 47);
    @include ps(l 50%);
    @include m(l -87/2);
    background-image: build-svg($img-rope-center,$greyish);
    background-size: 100% auto;
    background-position: center top;

    top: -18px - $rope-height;

    @include mobile{
      @include s(78, 39);
      @include m(l -78/2);
      top: -16px - ($rope-height - $rope-mod-1);
    }
    @include media("<=small-mobile"){
      @include s(58, 29);
      @include m(l -58/2);
      top: -12px - ($rope-height - $rope-mod-2);
    }
  }


  .wrap {

    @include desktop{
      height: 100%;
      @include flex-align(y);
      justify-content: space-between;
    }

    @include mobile{
      @include p(t 20 b 0);
    }
    @include media("<=small-mobile"){
      @include p(t 14);
    }

    // rope

    &:before,&:after {
      @include pseudo;
      background-color: white;
      background-image: build-svg($img-rope-piece,$greyish);
      background-repeat: repeat-x;
      background-position: center top;

      background-size: auto 100%;
      height: $rope-height;
      width: calc(50% - 87px / 2);
      top: -1 * $rope-height;

      @include mobile{
        height: $rope-height - $rope-mod-1;
        width: calc(50% - 78px / 2);
        top: -1 * ($rope-height - $rope-mod-1);
      }
      @include media("<=small-mobile"){
        height: $rope-height - $rope-mod-2;
        width: calc(50% - 58px / 2);
        top: -1 * ($rope-height - $rope-mod-2);
      }

    }

    &:before {
      @include ps(l 0);
    }
    &:after {
      @include ps(r 0);
    }
  }


  font-size: rem(14);
  color: $black;

  .copyright {
    margin: 0;

    @include media("<=small-mobile"){
      letter-spacing: -0.3px;
    }
  }

  a {
    color: inherit;
    font-weight: 600;
    @include m(r 15);

    &:hover,
    &:focus{
	text-decoration:none;
    }
  }

  .zofim-logo {
    @include text-hide;
    @include ratio(28.43,40.8);
    width: 29px;
    background-image: $zofim-logo-2;
    @include m(r 18);

    @include mobile{
      width: 26px;
    }
    @include media("<=small-mobile"){
      @include m(r 12);
    }
  }

  .footer-links {
    @include flex-align(y);

    @include mobile{
      justify-content: space-between;
      @include m(t -6);

      li {
        @include nth(1){
          a {
            margin: 0;
          }
        }
        @include nth(2){
          flex-grow: 1;
        }
      }
    }

    @include media("<=small-mobile"){
      @include m(t -4);
    }

  }

  .facebook-link {
    width: 10px;
    @include desktop{
      @include m(r 45);
      &:hover,
      &:focus{
	opacity:0.6;
      }
    }
    @include mobile{
      width: 12px;
    }
  }
  .home-link{
     @include desktop{
      &:hover,
      &:focus{
	opacity:0.6;
      }
    }
  }
}
