// PAGE: ATTENDANCE


/* 
//this collors everything 
.page{
  .wrap{
    background-color: $light-grey;
  }
} */
p.no_content {
  padding-right: 23px;
  font-size: 22px;
  text-align: right;
}
.page {
  
  &-attendance {
    .title {
      display: none;
    }

    .activity-title {
      font-size: rem(30);
      font-weight: normal;
      line-height: normal;
      @include m(t 28 b 34);
      @include p(r 27);
    }
  }
}


#attendance-report {
  width: 100%;
  font-family: Assistant;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
  height: 100%;

  #apprentice-list {
    width: inherit;
    display: flex;
    background-color: #ffffff;
    div {
      width: 25%;
      .no_content{
        position: absolute;
        text-align: center;
        max-width: 500px;
        margin: auto;
        right: 0;
        left: 0;
        margin-top: 5%;
        font-size: 25px;
      }
      h1 {
        height: 45px;
        font-size: 30px;
        margin: 25px;
      }

      ul {
        display: flex;
        flex-direction: column;
        li:first-child{
          border-top:solid 2px $light-grey-2;
        }

          li{
          border: solid 1px $light-grey-2;
          border-collapse: collapse;
          height:80px;
          display: flex;
          align-items: center;
          &.active{
            background: #dbd7ce;
          }
          
          > p {
            margin-right: 15px;
          }
        }
      }
    }
  
  
    #activities-attendance-list {
      background-color: $light-grey;
      display: flex;
      width: 75%;
      &.empty{
        background-color:transparent;
      }
      ul {
        display: flex;
        margin-top: 85px;
        flex-direction: column;
        width: 100%;

        li {
          background-color: $light-grey-3;
          border: solid 1px $greyish;
          height: 80px;
          margin: 10px 33px;
          display: flex;
	  
          p{
            margin:0;
          }
          .activity-wrap{
		display: flex;
		width: auto;
	  
		  p:first-child{
		    margin-right: 30px;
		  }
		  p:nth-child(2) {
		    font-size: 14px;
		    color: $brown-grey;
		    margin-right: 20px;
		    padding-top: 4px;
		  }
	  }
          .status {
            margin-left: 25px;
            margin-right: auto;
            cursor: pointer;
            .dot {
              // dot colors: greeny-blue(attend), pale-red(missing), squash(clothes)
              border-radius: 50%;
              display: inline-block;
              height: 13px;
              width: 13px;
              margin-right: 10px;
            }
            .dot[status="0"]{
              background-color: $pale-red;
            }
            .dot[status="1"]{
              background-color: $greeny-blue;
            }
            .dot[status="2"]{
              background-color: $squash;
            }
          }
        }
      }
    }
  }
}



#page-attendance {
  width: 100%;
  font-family: Assistant;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
  height: 100%;

  #guides-list{
    display: flex;
    flex-direction: column;
    h1 {
      width: 50%;
      height: 45px;
      font-size: 30px;
      margin: 35px 25px;
    }

    > div {
      display: flex;
      > ul {
        width: 25%;
      }
      ul {
        display: flex;
        flex-direction: column;
        > li {
          padding: 0px 10px;
        }
        li {
          font-size: rem(20);
          border: solid 1px $light-grey-2;
          border-collapse: collapse;
          height:80px;
          display: flex;
          align-items: center;

          &.active {
            opacity: 0.75;
            background-color: #dbd7ce;
          }

          .userpic {
            min-width: 45px;
            min-height: 45px;
            @include m(l 10);
            .role-border{
              height: 45px;
              width: 45px;
            }
            .image{
              height: 39px;
              width: 39px;
            }
          }
        }
      }
      #activity-dates-list {
        display: flex;
        background-color: #ffffff;
        width: 100%;

        .activity-dates-wrap {
          width: 50%;
          ul.activity-dates{
            li{
              justify-content: space-around;
              &.active{
                opacity: 0.75;
                background-color: #dbd7ce;
              }
              > div{
                margin-right: 15px;
                p{
                  margin: 0;
                  display: block;
                }
                p:nth-child(2){
                  color: $brown-grey;
                  font-size: 18px;
                }
              }
              #total-attendance{
                display: flex;
                align-items: center;
                line-height: 1.59;
                margin-left:30px;
                margin-right: auto;            
              }
            } 
          }
          .item-list {
              display: flex;
              margin-top: 30px;

              @include desktop {
                  @include m(b 80);
              }

              @include mobile {
                  @include m(b 50);
              }
          }

          .item-list .pager {
              @include pager-compact;
              flex-direction: row;
              margin-left:auto;
              margin-right:auto;

              @include mobile {
                max-width: 100%;
                display:flex;
                margin-left:auto;
                margin-right:auto;
                width:auto;
              }

              & > li {
                line-height: 34px;
                @include margin(r 5);
                @include margin(l 5);
                padding:0;
                text-align: center;
                justify-content: center;
              }

              .pager-last, .pager-first {
                  display: none;
              }

              .pager-previous, .pager-next{
                  /*transform: scaleX(-1);*/
              }

              .pager-next, .last {
                  @include margin(l 0);
              }

              .pager-item {

                  @include mobile {
                      @include after(5){
                          display: none;
                      }
                  }

              }
              .pager-current {
                color: #ffffff;
                background-color: #34332f;

                a{
                  color: #ffffff;
                }
              }
          }
        }
      
        #activity-particiption {
          background-color: $light-grey;
          display: flex;
          flex-direction: column;
          width: 50%;
          position: relative;
          &.rashgad-space{
            padding-top: 93px;
            .status{
              cursor: auto;
            }
          }

          @extend %magazine-section-striped-bg;

  
          input.edit{
            border-bottom: solid 1px $brown-grey;
            margin-left: 50px;
          }
  
          > div{
            margin: 0px 30px;
            .editing{
              height: 93px;

              @mixin attendance-control-icon($att-icn: null, $icn-col: null) {
                &::before {
                    @include pseudo(inline-block);
                    @include size(14px);
                    @extend %icn_#{$att-icn}-#{$icn-col};
                    background-size: contain;
                    @include p(l 30);
                    @include m(b -3);
                }
            }

              button{
                margin-top:40px;
                position: relative;
                text-decoration: none;

                &.edit {
                  @include attendance-control-icon(pencil, chrk);
                }

                &.add {
                  @include attendance-control-icon(add-user, chrk);
                }
              }
            }
            .simplebar-scroll{
              background-color: $off-white-2;
              display: flex;
              height: 90px;
              align-items: center;
              border: dashed 2px $greyish;
              flex-wrap: nowrap;
              input{
                border:none;
                border-bottom: solid 1px $brown-grey;
                margin-left: 50px;
              }
              span {
                display: flex;
                flex-wrap: nowrap;
                margin-right: auto;
                margin-left: 20px;
              }
            }
          }
  
          form {
            margin: 0px 30px;
            ul {
              width: 100%;
              li {
                background-color: $light-grey-3;
                border: solid 1px $greyish;
                height: 80px;
                margin: 10px 0px;
                display: flex;
                align-items: center;
                input{
                  border: none;
                }
                .status{
                  margin-left: 25px;
                  float: left;
                  white-space: nowrap;
                  cursor: pointer;
                  .dot {
                    // dot colors: greeny-blue(attend), pale-red(missing), squash(clothes)
                    border-radius: 50%;
                    display: inline-block;
                    height: 13px;
                    width: 13px;
                    margin-right: 10px;
                  }
                  .dot[status="0"]{
                    background-color: $pale-red;
                  }
                  .dot[status="1"]{
                    background-color: $greeny-blue;
                  }
                  .dot[status="2"]{
                    background-color: $squash;
                  }
  
                  .trash{
                    height: 43px;
                    width: 43px;
                    border-radius: 1px;
                    border: solid 1.5px $charcoal;
  
                    @extend %icn_trash-chrk;
                  }
                  .loader-gif{
                    background:transparent url('../img/loading-large.gif') no-repeat 0 0;
                    background-size: 100% auto;
                    width:20px;
                    height:20px;
                    display:inline-block;
                    top: 3px;
                    margin-right: 5px;
                    /*
                    &:before, &:after{
                      margin:0;
                      left:50%;
                      top:50%;
                      transform: translate(-50%,-50%);
                      width:20px;
                      height:20px;
                    }
                    &:after{
                      width:10px;
                      height:10px;
                      font-size: 1px;
                    }*/
                  }
                  .confirm{
                    border: 1px solid #52504c;
                    color: #52504c;
                    padding: 0 10px;
                    height: auto;
                    line-height: 2.5;
                    margin: 0 10px 0 0;
                  }
                }                  
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px){
	#page-attendance #guides-list,
	#page-attendance #guides-list > div ul,
	#page-attendance #guides-list > div #activity-dates-list,
	#page-attendance #guides-list > div #activity-dates-list ul,
	#page-attendance #guides-list > div #activity-dates-list #activity-particiption,
	#page-attendance #guides-list > div,
	#attendance-report #apprentice-list,
	#attendance-report #apprentice-list div,
	#attendance-report #apprentice-list div ul,
	#attendance-report #apprentice-list #activities-attendance-list,
	#attendance-report #apprentice-list #activities-attendance-list ul,
	#attendance-report #apprentice-list #activities-attendance-list ul li .activity-wrap{
	    	display: block;
	    	flex-direction: initial;
		width:100%;
	}
	#attendance-report #apprentice-list #activities-attendance-list ul li .activity-wrap{
		width:initial;
	}
	body.page-attendance > .page > .wrap{
		padding-left:0;	
		padding-right:0;
	}
	#page-attendance #guides-list > div #activity-dates-list #activity-particiption form{
		margin:0;
	}
	#page-attendance #guides-list > div #activity-dates-list #activity-particiption:before{
		left:-20px;
		right:-20px;
	}
	#page-attendance #guides-list > div ul li{
		font-size:22px;
		color: #000000;
	}
	#page-attendance #guides-list > div #activity-dates-list ul li {
	    	background-color: #f1efea;
	}
	#page-attendance #guides-list > div #activity-dates-list ul li > div p,
	#page-attendance #guides-list > div #activity-dates-list ul li #total-attendance{
		font-size: 22px;
		color:#000000;
	}
	#page-attendance #guides-list > div #activity-dates-list ul li > div p:nth-child(2) {
	    	color: #000000;
	    	font-size: 18px;
		opacity: 0.58;
	}
	#page-attendance #guides-list > div ul li,
	#attendance-report #apprentice-list div ul li,
	#attendance-report #apprentice-list div ul li:first-child{
		height:95px;
		border:1px solid #e0e2dc;
		background-color: #f1efea;
	}
	#attendance-report #apprentice-list div ul li.active {
	    	opacity: 0.75;
	    	background-color: #dbd7ce;
	}
	#attendance-report #apprentice-list div ul li {
	    	padding: 0px 10px;
		font-size: 22px;
		color: #000000;
	}
	#page-attendance #guides-list > div #activity-dates-list #activity-particiption form ul li{
		margin:0;
		padding:0 10px;
		height:95px;
		border:1px solid #e0e2dc;
	}
	#page-attendance #guides-list > div #activity-dates-list #activity-particiption form ul li input{
		padding:0 15px;
		font-size: 22px;
		color: #000000;
	}
	#page-attendance #guides-list > div #activity-dates-list #activity-particiption form ul li .status{
		color: #000000;
	    	font-size: 18px;
		margin-left: 15px;
	}
	#page-attendance #guides-list > div #activity-dates-list ul.activity-dates{
    		padding-top: 0;
	}
	#page-attendance #guides-list > div #activity-dates-list ul li #total-attendance {
    		margin-left: 15px;
	}
	#attendance-report #apprentice-list #activities-attendance-list ul li {
	    background-color: #f1efea;
	    border: solid 1px #e0e2dc;
	    height: 95px;
	    margin: 0;
	}
	#attendance-report #apprentice-list #activities-attendance-list ul li p,
	#attendance-report #apprentice-list #activities-attendance-list ul li .activity-wrap p:first-child{
		color: #000000;
    		font-size: 22px;
    		margin-right: 15px;
	}
	#attendance-report #apprentice-list #activities-attendance-list ul li p:nth-child(2){
		color: #000000;
    		font-size: 18px;
    		opacity: 0.58;
	}
	#attendance-report #apprentice-list #activities-attendance-list ul li .activity-wrap p:nth-child(2) {
	    	margin-right: 15px;
		color: #000000;
    		font-size: 18px;
    		opacity: 0.58;
	}
	#attendance-report #apprentice-list #activities-attendance-list ul li p.status{
		color: #000000;
    		font-size: 18px;
    		margin-left: 15px;
		opacity:1;
	}
	#attendance-report #apprentice-list #activities-attendance-list ul{
		margin-top:0;
		padding-top:0;
		background-color: #efedea;
	}
	#page-attendance #guides-list > div #activity-dates-list #activity-particiption,
	#page-attendance #guides-list > div #activity-dates-list #activity-particiption.rashgad-space{
		padding-top:0;
	}
	
	#page-attendance button.back,
	#attendance-report button.back{
		display:none;
	}
	body.attendance-step-1 #page-attendance button.back,
	body.attendance-step-2 #page-attendance button.back,
	body.attendance-step-1 #attendance-report button.back,
	body.attendance-step-2 #attendance-report button.back {
	    position: fixed;
	    right: 10px;
	    z-index: 200;
	    top: 18.375px;
	    display: inline-block;
	}
	body.attendance-step-1 .main-nav,
	body.attendance-step-2 .main-nav {
	    border: 0;
	    display: inline;
	    clip: rect(0 0 0 0);
	    clip-path: inset(50%);
	    height: 1px;
	    width: 1px;
	    margin: -1px;
	    overflow: hidden;
	    padding: 0;
	    position: absolute;
	    word-wrap: normal;
	    line-height: 0;
	    min-height: 0;
	    min-width: 0;
	}
	#page-attendance #guides-list > div #activity-dates-list #activity-particiption > div .editing{
		text-align:center;
		padding: 0 20px;
	}
	#page-attendance #guides-list > div #activity-dates-list #activity-particiption > div .actions button {
   		margin: 23px 0 0;
		width:48%;

		&:not(:first-child) {
		    margin-right: 2%;
		}
	}
	#page-attendance #guides-list > div #activity-dates-list #activity-particiption > div {
	    	margin: 0;
	}
	#page-attendance #guides-list > div #activity-dates-list #activity-particiption > div .simplebar-scroll {
	    	display: block;
	    	height: initial;
	    	padding: 20px;
	}
	#page-attendance #guides-list > div #activity-dates-list #activity-particiption > div .simplebar-scroll span {
	    	display: block;
	    	text-align: center;
	    	margin: 20px 0 0;
	}
	#page-attendance #guides-list > div #activity-dates-list #activity-particiption > div .simplebar-scroll span button{
	    	margin:0;
		width:48%;

		&:not(:first-child) {
		    margin-right: 2%;
		}
	}
	#page-attendance #guides-list > div #activity-dates-list #activity-particiption > div .simplebar-scroll input {
	    	margin: 0;
	   	padding: 0;
	}
	.page-attendance .page-header .actions .create#creating-activity-btn{
		display:none;
	}
	.page-attendance .activity-title,
	#attendance-report #apprentice-list div h1{
		display:none;
	}
	.page-attendance .page-header:not(.desktop-only) .zofim-logo{
		width: 64%;
    		right: 11%;
    		text-align: center;
  }
  #page-attendance #guides-list > div #activity-dates-list .activity-dates-wrap {
    width: 100%;
  }
}
@media (max-width: 389px){
	.page-attendance .page-header:not(.desktop-only) .zofim-logo{
		font-size:1.2rem;
	}
}
@media (max-width: 320px){
	.page-attendance .page-header:not(.desktop-only) .zofim-logo{
		font-size:1rem;
	}
	body.attendance-step-1 #page-attendance button.back,
	body.attendance-step-2 #page-attendance button.back,
	body.attendance-step-1 #attendance-report button.back,
	body.attendance-step-2 #attendance-report button.back {
	    	top: 14px;
	}
}
@media (max-width: 374px) and (max-width: 768px){
	body.attendance-step-1 #page-attendance button.back,
	body.attendance-step-2 #page-attendance button.back,
	body.attendance-step-1 #attendance-report button.back,
	body.attendance-step-2 #attendance-report button.back {
	    top: 14px;
	}
}
