

.page-about {

  .page-header {
    .links li:nth-child(2){
      background-color: rgba($off-white-2,0.13);
    }
  }

  section.about {
    .video-wrap {
      @include m(t 25);

      span,&:before,&:after {
        max-width: 650px;
      }
    }
  }


  section.thanks {
    @include break-out-wrap(x,$do-pads:true);
    @include overlay-stripes($bg:$stripe-bg,$w:150vw);

    h3 {
      @include m(b 13);

      @include responsive-font(
        544, 1200, 22, 30
      );

      @include mobile{
        text-align: center;
        @include p(b 20);
        @include m(b 17);
        border-bottom: 1px solid darken($light-grey-2,5%);
      }
    }

    p {
      font-size: rem(15);
      line-height: 1.67;
    }
  }

  section.partners {

    @include mobile{
      text-align: center;
    }

    h4 {
      @include m(b 5vh);
    }

    li {

      @include desktop{
        display: flex;
      }
      @include m(b 6.5vh);

    }

    span {
      display: inline-block;
      background-size: contain;
      background-position: center;
    }

    .txt {
      @include desktop{
        @include m(r 40);
      }
    }

    .logo {
      @include desktop{
        @include flex-align(y);
        flex-basis: 170px;
        justify-content: flex-start;
      }

      @include mobile{
        @include m(b 13);
      }

      img,span {
        max-width: 170px;
        height: auto;

        @include mobile{
          max-width: 145px;
        }
      }
    }

    @include mobile {
      h4, li {
        @include not-last-child{
          border-bottom: 1px solid $light-grey-2;
          @include p(b 24);
          @include m(b 20);
        }
      }
    }

     // mock logos
    li {
      @if $do-mock {
        @include assign-partner-logos;
        .logo span {
          width: 300px;
        }
        img {
          display: none;
        }
      }
    }

  }

}