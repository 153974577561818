
// meta

$meta-height: 17px;

%filename {
  font-size: 96%;
  direction: ltr;
  display: inline-block;
}

.meta {
  font-size: 0px;
  font-weight: 300;

  @include media(">mobile"){
    white-space: nowrap;
  }

  &.length,&.appendices,&.attachments,
  & .length,& .appendices,& .attachments
  {
    font-size: rem(16);
    background-position: right center;
    margin: 0;
    @include p(r 20);
    height: $meta-height;
    line-height: $meta-height;

    // @include truncate-text;
  }

  &.length,&.appendices,&.attachments{
    @if $do-mock {
      &:empty{
        display: block;
      }
    }
  }

  .length,.appendices,.attachments,.time,.date
  {
    display: inline-block;
    vertical-align: middle;

    @if $do-mock {
      &:empty{
        display: inline-block;
      }
    }
    @if not $do-mock {
      &:empty{
        display: none;
      }
    }

    @include not-only{
      @include not-last{
        position: relative;

        &:after{
          @include pseudo;
          @include s(2,13);
          @include ps(l 0 t 2);
          background-color: rgb(196,194,191);
        }

        @include p(l 14);
        @include m(l 12);
      }
    }
  }

  &.length,
  & .length {
    @extend %icn_clock-br-gr;
    background-size: 12px auto;

    // mock
    @if $do-mock {
      &:empty {
        &:before {
          content: "40 דקות";
        }
      }
    }
  }

  &.appendices,
  & .appendices {
    @extend %icn_attach_sm-br-gr;
    background-size: 13px auto;

    .filename {
      @extend %filename;
      @include truncate-text(200px);
    }

    // mock
    @if $do-mock {
      &:empty {
        &:before {
          content: "2 נספחים";
        }
      }
    }
  }

  &.attachments,
  & .attachments {
    @extend %icn_wrench-br-gr;
    background-size: 12px auto;

    // mock
    @if $do-mock {
      &:empty {
        &:before {
          content: "עטים ועפרונות";
        }
      }
    }
  }
}


// appendices in attachments tooltips are similar

.appendices {
  &.links_to_files{
    height: auto;
  }
  .appendix-name {
    &.appendix2{
      margin-right: 10px;
    }
    a{
      display: inline;
    }
  }
  .appendix {
    display: block;
  }
  a {
    width: 100%;
  }
  .filename {
    @extend %filename;
    @include truncate-text;
  }

}

