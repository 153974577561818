// sass-lint:disable-all



@mixin toggle-collapsible(
  $target: '*',
  $max-height: 500px,
  $anim: 0.5s,
  $sib: null
) {

  @keyframes remove-overflow-hidden {
    0% {
      overflow: hidden;
    }
    99% {
      overflow: hidden;
    }
    100% {
      overflow: visible;
    }
  }
  // @keyframes add-overflow-hidden {
  //   0% {
  //     overflow: visible;
  //   }
  //   99% {
  //     overflow: visible;
  //   }
  //   100% {
  //     overflow: hidden;
  //   }
  // }


  @if (not $sib) and ($target == '*') {
    $sib: '+';
  }
  @if (not $sib) and ($target != '*') {
    $sib: '~';
  }

  &#{$sib}#{$target} {
    transition: max-height $anim, min-height $anim, opacity $anim, margin $anim, padding $anim;
    max-height: $max-height;
    opacity: 1;
    animation-fill-mode: both;
    animation-duration: $anim;
  }

  // closed
  &:not(:checked)#{$sib}#{$target} {
    overflow-y: hidden;
    min-height: 0;
    max-height: 0px;
    @include p(y 0);
    @include m(y 0);
    opacity: 0;
  }

  // open
  &:checked#{$sib}#{$target} {
    animation-name: remove-overflow-hidden;
  }

}








//  <div class="co-acctabs">
//
//    <input id="uniq-id-1" class="co-acctabs-cntrlr" type="radio" name="uniq-name-1"/>
//    <label for="uniq-id-1" class="co-acctabs-tab" role="tab">
//      <h4>Title 1</h4>
//    </label>
//    <article class="co-acctabs-panel" role="tabpane">
//      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
//    </article>
//
//  </div>


// show-hide with max-height
@mixin max-height-show-hide(
  $closed: null,
  $open: null,
  $animate: true,
  $h: 2000px,
  $t: 0.4s,
  $o-t: $t,
  $c-t: $t/2,
  $op: false,
  $pmp: 1,
  $do-margs: false
) {

  #{$closed} {
    @if ($animate){
      overflow-y: hidden;
      max-height: 0px;
      //transition: all $c-t;
      transition: max-height $c-t, min-height $c-t, opacity $c-t, margin $c-t, padding #{$c-t*$pmp};
      @include p(y 0);

      @if do-margs {
        @include m(y 0);
      }

      &,* {
        min-height: 0px;
      }

      @if $op {
        opacity: 0;
      }

    } @else {
      display: none;
    }
  }

  #{$open} {
    @if ($animate){
      // max-height: $h;
      //transition: all $o-t;
      transition: max-height $o-t, min-height $o-t, opacity $o-t, margin $o-t, padding #{$o-t*$pmp};

      @if $op {
        opacity: 1;
      }
    } @else {
      display: block;
    }
  }

}


// COLLAPSIBLE
//------------------------------


//        <input type="checkbox" class="collapse-control" id="pd01" aria-hidden="true">
//        <label for="pd01">
//
//        <div class="collapsible">

$collapsible: 'input.collapse-control ~ .collapsible' !default;
$collapsible-closed: 'input.collapse-control:not(:checked) ~ .collapsible' !default;
$collapsible-open: 'input.collapse-control:checked ~ .collapsible' !default;
$collapse-control: 'input.collapse-control + label' !default;
$collapse-control-not-active: 'input.collapse-control:not(:checked) + label' !default;
$collapse-control-active: 'input.collapse-control:checked + label' !default;

@mixin collapsible(
  $use-class:null,
  $target: null,
  $animate:true,
  $h:2000px,
  $t:0.4s,
  $c-t:$t/2,
  $pmp:0.7,
  $direct:false,
  $op:false,
  $do-margs: false
) {

  $collapsible: $collapsible;
  $collapsible-closed: $collapsible-closed;
  $collapsible-open: $collapsible-open;

  @if $direct { $direct:">"; } @else { $direct: " "; }

  @if not $use-class {
    input.collapse-control {
      @include visually-hidden;
    }
    #{$collapse-control} {
      cursor: pointer;
    }
  } @else {
    @if $target { $collapsible: $target; } @else { $collapsible: '.collapsible'; }
    $collapsible-closed: '&:not(.#{$use-class})#{$direct}#{$collapsible}';
    $collapsible-open: '&.#{$use-class}#{$direct}#{$collapsible}';
  }

  @include max-height-show-hide($animate:$animate, $closed:$collapsible-closed, $open:$collapsible-open, $h:$h, $t:$t, $c-t:$c-t, $pmp:$pmp, $op:$op, $do-margs: $do-margs);
}

@mixin collapsible-open() {
  #{$collapsible-closed} {
    display: block;
    opacity: 1;
    max-height: 2000px;
  }
}




// TAB PANELS
//------------------------------

//      <input class="tab-control" type="radio" name="uniq-name" id="uniq-id-1" aria-hidden="true" data-index="1">
//      <input class="tab-control" type="radio" name="uniq-name" id="uniq-id-2" aria-hidden="true" data-index="2">
//
//      <label class="tab" for="uniq-id-1" data-index="1">
//      <label class="tab" for="uniq-id-2" data-index="2">
//
//      <div class="tab-content" data-index="1">
//      <div class="tab-content" data-index="2">


$tabs-all: '.tabs-row .tab';

$tabs-acc-all: '.tab-control ~ .tab';

$tabs-active: '.tab-control[data-index="1"]:checked ~ .tabs-row .tab[data-index="1"], .tab-control[data-index="2"]:checked ~ .tabs-row .tab[data-index="2"], .tab-control[data-index="3"]:checked ~ .tabs-row .tab[data-index="3"], .tab-control[data-index="4"]:checked ~ .tabs-row .tab[data-index="4"], .tab-control[data-index="5"]:checked ~ .tabs-row .tab[data-index="5"], .tabs-row .tab.active, .tabs-row .tab:hover';

$tabs-acc-active: '.tab-control[data-index="1"]:checked ~ .tab[data-index="1"], .tab-control[data-index="2"]:checked ~ .tab[data-index="2"], .tab-control[data-index="3"]:checked ~ .tab[data-index="3"], .tab-control[data-index="4"]:checked ~ .tab[data-index="4"], .tab-control[data-index="5"]:checked ~ .tab[data-index="5"], .tab-control ~ .tab.active, .tab-control ~ .tab:hover';

$tabs-content-all: '.tab-content';

$tabs-content-active: '.tab-control[data-index="1"]:checked ~ .tab-content[data-index="1"], .tab-control[data-index="2"]:checked ~ .tab-content[data-index="2"], .tab-control[data-index="3"]:checked ~ .tab-content[data-index="3"], .tab-control[data-index="4"]:checked ~ .tab-content[data-index="4"], .tab-control[data-index="5"]:checked ~ .tab-content[data-index="5"], .tab-content.active';


@mixin tabbed($animate:true, $h:2000px, $t: 0.4s, $c-t:$t/2, $pmp:0.7) {

  input[type="radio"].tab-control {
    display: none;
  }
  .tab {
    cursor: pointer;
  }

  @include max-height-show-hide($animate:$animate, $closed:$tabs-content-all, $open:$tabs-content-active, $h:$h, $t:$t, $c-t:$c-t, $pmp:$pmp, $op: true);

}



