
.schedule .week-view
{

  min-height: 350px;
  min-height: 45vh;
  $use-height-limiters: true;

  @include mobile{
    min-height: 300px;
  }

  .section-title
  // ,.page-title
  {
    @include mobile{

    }
  }

  .pager {
    @include mobile{

      display: none;

      @at-root {
        .is-desktop &{
          @if $show-pager-on-small-desktops {
            display: flex;
            margin-bottom: -43px;
          }
        }
      }
    }
  }

  .single-day-planner header {
    @include mobile{

      height: auto;
      @include m(b 10);

      .nowrap,p.date {
        white-space: nowrap;
      }

      .nowrap{
        font-size: 0px;
        display: block;
        @include m(t 5 b 2);
      }

      .j-date, .time {
        display: inline-block;
        vertical-align: middle;
      }

      .time {
        font-size: rem(15);
        line-height: 1;
        @include p(r 7);
        word-spacing: -2px;

        @include media("<=#{$switch-to-mobile}",">mobile"){
          font-size: rem(14);
          letter-spacing: -1px;
        }
      }

      .j-date {
        font-size: rem(16);
        line-height: 0.85;
        border-left: 1px solid $greyish;
        @include p(l 7);

        @include media("<=#{$switch-to-mobile}",">mobile"){
          font-size: rem(15);
          letter-spacing: -1px;
          padding-bottom: 2px;
        }
      }

    }
  }


  // SWIPER

  .days-list, .swiper-wrapper, .single-day-planner{
    width: 100%;
  }

  .days-list:not(.no-swipe) {

    @include swiper-extend(95%,15px,1,0.6s,false,false,"<=#{$switch-to-mobile}");

    @include media(">huge"){
      .swiper-container {
        width: 98%;
      }
    }

    @include desktop{

      position: relative;

      .swiper-container {
        @include m(r 0 l auto);
      }

      &:after{
        @include pseudo;
        @include s(30px,100%);
        @include ps(y 0 r -30);
        z-index: 10;
        background-color: white;
      }
    }

    @if $make-paddings-bigger-on-big-screens {
      @include media(">huge"){
        &:after{
          width: $huge-screen-pad-x;
          right: -$huge-screen-pad-x;
        }
      }
    }

    @include mobile{

      .swiper-container {
        width: 90%;
      }

      .swiper-slide header {
        opacity: 1;
        transition: all 0.5s;
      }

      .swiper-slide:not(.swiper-slide-active) header {
        opacity: 0;
      }

    }

    // mod for when 2 slides on screen
    @include media(">mobile","<=#{$switch-to-mobile}"){
      .swiper-slide-active + .swiper-slide-next {
        pointer-events: all;

        header {
          opacity: 1;
        }
      }
    }

  }

  .days-list.no-swipe {
    .swiper-slide{
      flex-grow: 5;
      max-width: 260px;

      @include mobile{

        @include only{
          max-width: none;
        }
      }

      &:last-child{
        margin-left: 0 !important;
      }
    }
  }

  .swiper-container {
    // width: 301px !important;
  }

  .swiper-wrapper  //.days-list
  {
    flex-wrap: nowrap;
  }

  .swiper-slide //.single-day
  {

    position: relative;

    header {
      @include mobile{
        text-align: center;
      }
    }

    // @include mobile{
    @include media("<=mobile"){
      max-width: 100%;
    }

    .single-day-planner {
      max-width: none;
      min-width: 0;
    }

    &:hover {
      z-index: 10;
    }
  }



  // no activities overlay

  .no-activities {
    // @include s( calc(100% + 60px), calc(100% + 74px + 50) );
    // @include ps(x -30 b 0 t -74 b -50 );

    @include s;
    position: fixed;
    // min-height: 60vh;
    max-width: 1300px;
    
    text-align: center;
    color: white;
    text-shadow: 0 2px 4px black(0.2);
    // @include p(t 20 b 40);
    z-index: 10;
    @include popup-width;
    @include flex-align;
    left: 0;
    right: auto;
    top: 34%;
    @include media("<=mobile"){
      top: 14%;
      right: 0;
      margin: auto;
    }
    .close-popup{
      @include close-button(25px);
      background-size: 9px 9px;
      background-color: white!important;
      top: -10px;
      right: -10px;
      position: absolute;
      border-radius: 50%;
      border: 2px solid black;
      z-index: 9;
      @include media("<=mobile"){
        width: 30px;
        height: 30px;
      }
    }
    &:before,&:after {  
      @include pseudo;
      //@include s( 100vw, calc(100% + 74px + 35px) );
      @include popup-width;
      @include ps(b 0 t 0 b 0 );
      @include wrap-pos(r,$neg:true);
      right: auto!important;
    }

    &:before {
      background-color: white;
      background-image: url('../img/no-act-bg.png');
      background-position: right top;
      background-size: auto;
      background-repeat: no-repeat;
      z-index: 0;

      @include media("<=mobile"){
        background-image: url('../img/no-act-bg-mob.png');
        background-position: center top;
      }
    }


    &:after {
      background-color: black(0.85);
      z-index: 1;
    }

    span {
      display: block;
      position: relative;
      z-index: 2;
    }

    .wrap {
      position: relative;
    }


    h3 {
      font-weight: 600;
      font-size: rem(38);
      line-height: 1;

      @include mobile{
        font-size: rem(38);
      }
    }

    p {
      font-size: rem(20);
      @include m(b 35);
      padding: 0 10px;

      @include mobile{
        font-size: rem(26);
        line-height: 1.2;
      }
    }

    .button {
      width: 100%;
      max-width: 230px;
      font-weight: 600;
      text-shadow: none;

      @include media("<=mobile"){
        //display: none;
      }
    }

  }


}