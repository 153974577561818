.modal-backdrop.anchor-guides-list-modal {

  @extend %bigger-cmpct-modal;

  .modal-box h3 {
    @include m (b 15);
  }

  .rashgad-list {
    @include grid(3, 20, 10, 'li');
    border-top: 2px solid $stripe-bg;
    @include p(t 25);

    li {
      line-height: 1.15;
    }
  }

  // scroll

  .simplebar-scroll {
    @include m(t 25);
    max-height: 400px;
    overflow-y: auto;
    direction: ltr;
  }

  .rashgad-list {
    direction: rtl;
    @include m(b 5);
  }

}