// datepicker popup calendar


.modal-backdrop.datepicker-modal {

  .wrap {
    padding: 0;
  }

  .close-modal{
    @include close-button-color(blk);
    top: 23px;
    left: 20px;
  }

  .modal-box {
    max-width: 325px;
    background: white;
    text-align: center;

    @include not-ie{
      margin: auto;
    }
  }

  .picked-date {
    display: inline-block;
    height: 47px;
    line-height: 47px;
    background-color: $light-grey-2;
    font-size: rem(18);
    font-weight: bold;
    @include p(x 25);
  }

}

.datepicker-modal .datepicker-calendar {

  .pager {
    @include p(x 15);
    top: auto;
    position: static;
  }

  .zofim-calendar{

    .weeks {
      border-right: 0;
      border-left: 0;
    }

  }

}