// DRUPAL MODS



// undoing drupal crap

.clearfix {
  &:after {
    content: "";
    visibility: visible;
  }
}

  [class*=panel],
  [id*=panel],
  [class*=panel] > [class*=panel] {
    margin: 0;
    padding: 0;
    // position: static;
    // display: inline;
    width: auto;// !important;
    float: none !important;
  }

.inside {
  margin: 0 !important;
}
.panel-separator,
.helperclass
{
  display: none;
}

.ajax-progress {
  display: none;
}

img[src=""],
img:not([src]),
img[src*="null"]
{
  display: none;
}




// drupal admin panel mod

div.toolbar {
  .element-invisible {
    // @include visually-hidden;
  }
}

.element-hidden {
  display: none;
}


#toolbar.toolbar {
  // display: none;
  transition: all 0.3s;
  z-index: 5000;
  min-height: 30px;

  &:before,&:after {
    pointer-events: all;
  }

  // making it look always RTL
  direction: rtl;
  text-align: right;
  ul li, div.toolbar-shortcuts ul, #toolbar-menu
  {
    float: right;
  }
  #toolbar-user, a#edit-shortcuts
  {
    float: left;
  }
  div.toolbar-shortcuts ul {
    padding: 5px 0 2px 0;
    margin-left: 5px;
  }

  #edit-shortcuts, .path-admin-help
  {
    @include media("<=mobile"){
      display: none;
    }
  }

  a {
    &:active {
      top: auto;
    }
  }

  .home-link,.toggle {
    @include text-hide;
  }

  &:not(:hover){
    top: -30px;

    @include media("<870px"){
      top: -50px;
    }
    @include media("<700px"){
      top: -70px;
    }
    @include media("<400px"){
      top: -90px;
    }
    @include media("<300px"){
      top: -110px;
    }
  }

  &:before {
    @include pseudo;
    @include s(40,30);
    @include ps(b -30 l 50%);
    @include m(l -20);
    @include corner-radius(b 10px);
    background-color: black;
    @extend %icn_ar_down-wht;
    background-position: left 50% bottom 10px;
    background-size: 15px auto;
    cursor: pointer;
  }

  @include desktop-browsers{
    &:after{
      width: 100%;
      height: 10px;
      background-color: transparent;
      @include ps(b -10 x 0);
      visibility: visible;
    }
  }

  .toolbar-drawer {
    min-height: 34px;
  }
}


body.toolbar-drawer #toolbar {

  min-height: 64px;

  &:not(:hover){
    top: -64px;

    @include media("<870px"){
      top: -84px;
    }
    @include media("<700px"){
      top: -104px;
    }
    @include media("<400px"){
      top: -152px;
    }
    @include media("<300px"){
      top: -192px;
    }

  }
}

body{
  &,&.admin-menu{
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
}