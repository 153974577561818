// confirmation dialog


.modal-backdrop.confirmation-modal {

  .modal-box {


    .wrap {
      padding-top: 40px;
    }


  }

}