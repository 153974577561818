
// sass-lint:disable-all


// MARGIN & PADDING MIXINS
// by Pavel G
// ----------------------------------------------------------



// HELPER FUNCTIONS
// ----------------------------------------------------------

@mixin build-marg-css($attr: margin, $loc: null, $i: null, $fl: null, $dir: $direction, $def: 0) {
  @if $i {$i:'!important';} @else {$i:'';}

  $margs: extractor($loc, $def);
  $top: map-get($margs, top);
  $right: map-get($margs, right);
  $bottom: map-get($margs, bot);
  $left: map-get($margs, left);

  @if ($top and $right and $bottom and $left) and ($top != inherit and $right != inherit and $bottom != inherit and $left != inherit) {
    #{$attr}: $top $right $bottom $left#{$i};
  } @else {
    @if $right { #{$attr}-right: $right#{$i}; }
    @if $left { #{$attr}-left: $left#{$i}; }
    @if $top { #{$attr}-top: $top#{$i}; }
    @if $bottom { #{$attr}-bottom: $bottom#{$i}; }
  }

  @if $fl {
    $marg-side: left;
    @if ($dir == rtl) {
      $marg-side: right;
    }
    &:first-child {
      #{$attr}-#{$marg-side}: 0#{$i};
    }
    &:last-child {
      #{$attr}-#{opposite-direction($marg-side)}: 0#{$i};
    }
  }
}




// MARGIN & PADING
// ----------------------------------------------------------

// possible syntax:
// @include margs(t 10px b 20px x 15px ...)
// @include m(t 10 b 20 x 15 ...)
// @include pads(t 10px b 20px x 15px ...)
// @include p(t 10 b 20 x 15 ...)

@mixin margs($loc: null, $i: null, $fl: null, $dir: $direction) {
  @include build-marg-css(margin, $loc, $i, $fl, $dir, $def: auto)
}

@mixin pads($loc: null, $i: null, $fl: null, $dir: $direction) {
  @include build-marg-css(padding, $loc, $i, $fl, $dir)
}

// shortcut mixins
@mixin m($args...) {
  @include margs($args...);
}
@mixin p($args...) {
  @include pads($args...);
}

// longcuts
@mixin margin($args...) {
  @include margs($args...);
}
@mixin padding($args...) {
  @include pads($args...);
}


// JAILBREAK
// ----------------------------------------------------------

@mixin breakout($p, $do-pads: true, $do-width: true) {

  $pads: extractor($p);
    $t: map-get($pads, top);
    $r: map-get($pads, right);
    $b: map-get($pads, bot);
    $l: map-get($pads, left);

  @if ($do-width){ width: auto; }
  @if ($t) and ($t > 0) {
    @include m(t $t*-1);
    @if $do-pads { @include p(t $t); }
  }
  @if ($b) and ($b > 0) {
    @include m(b $b*-1);
    @if $do-pads { @include p(b $b); }
  }
  @if ($l) and ($l > 0) {
    @include m(l $l*-1);
    @if $do-pads { @include p(l $l); }
  }
  @if ($r) and ($r > 0) {
    @include m(r $r*-1);
    @if $do-pads { @include p(r $r); }
  }
}


