// COMMON
body{
	.inner-wrapper{	
		visibility:hidden;
	}

	&.loaded{
		.inner-wrapper{
			visibility:visible;
		}
		.page-loader{
			display:none;
		}
	}
}
.page-loader{
	background:rgba(0, 0, 0, 0.8);
	position:fixed;
	left:0;
	right:0;
	top:0;
	bottom:0;
	height:100%;
	z-index: 100;

	img{
		position:absolute;
		left: 50%;
		top: 50%;
	    	-ms-transform: translate(-50%);
	    	-webkit-transform: translate(-50%);
	    	transform: translate(-50%);
		width:100px;
		height:auto;
	}
}
.zofim-logo {
  display: block;
  background-size: contain;
  margin: 0;
  position: relative;
}

.link-home {
  display: block;
  color: inherit;
  text-decoration: none;

  &:active {
    position: static;
    top: auto;
  }
  &:hover,
  &:focus{
    opacity:0.6;
  }

  // @include desktop{
  //   @include s;
  //   @include ps;
  // }
}

.facebook-link {
  display: block;
  @include text-hide;
  background-size: contain;
  @include ratio(9.1,17.4);
  @extend %icn_facebook-blk;
}

.activity-info
{
  .description{
    br{
      display: none;
    }
  }
}


// action buttons in headers & modals

.actions {

  $pre: ''; //'&>';

  #{$pre}.button,
  #{$pre}button {
    min-width: 115px;
    &.with_padding{
      padding: 0 15px;
    }
    &.link-button {
      min-width: 0;
    }

    &.clear {
      color: white;
      font-weight: 400;
      @include modify-button($b:0);
    }
  }

  &.button-group-lght{
    @include button-group($pre: $pre);
  }
  &.button-group-drk{
    @include button-group(
      $solid-bg-color: $charcoal,
      $solid-color: white,
      $outlined-color: $charcoal,
      $outlined-border-color: $greyish-brown,
      $pre: $pre
    );
  }
  &.smaller-buttons {
    & > .solid {
      @include modify-button(basic,33px);
    }
    & > .outln {
      @include modify-button(outline,33px);
    }
  }
}


// scroll window

.scroll-window {
  overflow-y: auto;

  @include not-ie{
    direction: ltr;

    & > * {
      direction: rtl;
    }
  }

}



// pseudo classes

%drupal-page {
  @include drupal-page;
}

%magazine-section-striped-bg {
  // striped background
  @include overlay-stripes($bg:$stripe-bg,$h:300vh);
}


// various small things


// dropdown or accrodion

@mixin dropdown{
  cursor: pointer;
  background-size: 17px auto;
  background-position: left center;
  padding-left: 30px;

  @extend %icn_ar_down-chrk;
  &.active,
  .open > &
  {
    @extend %icn_ar_up-chrk;
  }
}

%dropdown {
  @include dropdown;
}
