
$zofim-logo-1: url('../img/logo-1.svg');

$zofim-logo-1-black: url('../img/logo-1-black.svg');

$zofim-logo-2: url('../img/logo-2.svg');

$zofim-logo-3: url('../img/logo-3.png');

$zofim-logo-3-120: url('../img/logo-3-120.png');
$zofim-logo-3-140: url('../img/logo-3-140.png');