// ICONS
// vscode-fold=2

$colors-map: (

  blk: black,
  wht: white,

  gold-yel: $golden-yellow,
  off-wht-2: $off-white-2,
  chrk: $charcoal,
  lghtgr2: $light-grey-2,
  grish: $greyish,
  br-gr: $brown-grey

);


// SVG icons map

$icons-map: (

  icn_attach_lg: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" width="13" height="17" viewBox="0 0 13 17"><path fill="fillColor" d="M.62 9.9l.66-1.18.66-1.17 2.64-4.7.66-1.16C6.14 0 8.17-.5 9.74.4c1.6.94 2.13 3 1.2 4.62L9.32 8l-.66 1.2-2.3 4.08c-.55.97-1.76 1.3-2.7.74-.96-.56-1.28-1.8-.73-2.77l2-3.52.38-.7L6.6 4.8c.18-.32.58-.43.9-.25.3.2.42.6.24.93L6.4 7.72l-.4.7-1.97 3.5c-.18.33-.07.74.24.93.3.18.7.07.9-.25l3.3-5.86.3-.6.27-.46.38-.7.33-.58c.55-.97.23-2.22-.7-2.78-.97-.56-2.18-.22-2.73.75l-1 1.75-.32.6-2.3 4.1-.34.58-.66 1.17c-.5.9-.55 1.93-.23 2.83l.06.15.14.3.23.37.14.18.28.32.13.12c.14.13.3.25.47.35 1.58.9 3.6.3 4.5-1.3l1-1.8 2.64-4.7.32-.6.33-.6c.2-.3.6-.43.9-.24.4.17.5.6.3.9l-.3.6-.33.6-3.6 6.5c-1.25 2.22-4.1 3-6.3 1.7-2.27-1.2-3-4.1-1.77-6.36z"/></svg>',
    colors: grish
  )

  ,icn_attach_sm: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 13" width="11" height="13"><path fill="fillColor" d="M8.1.1c.6-.2 1.2-.1 1.8.2 1.1.6 1.5 1.9.8 3l-5 8.3c-.4.7-1.1 1.1-1.8 1.3-.9.2-1.7.1-2.4-.3s-1.2-1-1.4-1.8c-.2-.7-.1-1.5.3-2.1l4-6.5c.1-.2.3-.3.6-.2.2.1.3.4.2.6l-4 6.5c-.3.5-.4 1-.2 1.6.1.5.5 1 1 1.3.5.3 1.1.4 1.6.2.6-.1 1-.5 1.3-1l5-8.3c.4-.7.2-1.5-.5-1.9-.7-.4-1.6-.1-2 .5L4.1 7.1c-.1.1-.1.3-.1.5s.2.3.3.4c.4.1.8 0 1-.3l2.3-3.8c.1-.2.4-.2.6-.1.2.1.3.4.2.6L6 8.1c-.4.7-1.4 1-2.1.6-.4-.2-.6-.5-.7-.9-.1-.4-.1-.8.1-1.1l3.4-5.6c.3-.5.8-.9 1.4-1z"/></svg>',
    colors: br-gr
  )

  ,icn_bold: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.9 13" width="9.9" height="13"><path fill="fillColor" d="M7.9 6.3c.9-.7 1.6-1.7 1.6-2.6C9.5 1.6 7.9 0 5.8 0H0v13h6.5c1.9 0 3.4-1.6 3.4-3.5 0-1.4-.8-2.6-2-3.2zm-5.2-4h2.8c.7 0 1.4.7 1.4 1.4s-.7 1.4-1.4 1.4H2.7V2.3zm3.2 8.4H2.7V7.9H6c.7 0 1.4.7 1.4 1.4s-.6 1.4-1.5 1.4z"/></svg>',
    colors: null
  )

  ,icn_list: ( // bullets
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.14 12.22" width="16.14" height="12.12"><path fill="fillColor" d="M1.68 0H.34C.14 0 0 .17 0 .38V1.9c0 .22.15.4.34.4h1.34c.2 0 .34-.18.34-.4V.4c0-.22-.15-.4-.34-.4zm0 4.96H.34c-.2 0-.34.17-.34.38v1.53c0 .2.15.38.34.38h1.34c.2 0 .34-.17.34-.38V5.34c0-.2-.15-.38-.34-.38zm0 4.97H.34c-.2 0-.34.17-.34.38v1.55c0 .2.15.38.34.38h1.34c.2 0 .34-.17.34-.38V10.3c0-.2-.15-.37-.34-.37zM15.8.76H3.7c-.2 0-.34.17-.34.38v.76c0 .2.15.38.34.38h12.1c.2 0 .34-.17.34-.38v-.76c0-.2-.15-.38-.33-.38zm0 4.58H3.7c-.2 0-.34.17-.34.38v.76c0 .2.15.38.34.38h12.1c.2 0 .34-.17.34-.38v-.76c0-.22-.15-.4-.33-.4v.02zm0 4.58H3.7c-.2 0-.34.17-.34.38v.76c0 .2.15.38.34.38h12.1c.2 0 .34-.17.34-.38v-.76c0-.2-.15-.38-.33-.38z"/></svg>',
    colors: null
  )

  ,icn_calendar: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.2 14.2" width="15.2" height="14.2"><path fill="fillColor" d="M1.4 4.2h12.3v.9H1.4zm4-3h4.4v.9H5.4zm5.5 1.3c.4 0 .7-.3.7-.7V.7c0-.4-.3-.7-.7-.7-.4 0-.7.3-.7.7v1.2c0 .3.3.6.7.6zm-6.8 0h.3c.4 0 .6-.2.6-.5V.5c0-.3-.2-.5-.5-.5h-.4c-.3 0-.5.2-.5.5V2c0 .3.2.5.5.5z"/><path fill="fillColor" d="M12 1.2v.9h2.3v11.2H1V2.1h2.2v-.9H0v12.2c0 .7.6.8.9.8h13.5c.3 0 .8-.2.8-.8V1.2H12z"/></svg>',
    colors: chrk
  )

  ,icn_clock: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 14" width="13" height="14"><path fill="fillColor" d="M11.9 3.4l-.6-.6-.1-.1c-.2-.2-.5-.4-.7-.6l.4-.4c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-.4.3zM4.7 1.2C5 .5 5.7 0 6.5 0S8 .5 8.3 1.2C7.8 1.1 7.1 1 6.5 1s-1.2.1-1.8.2zM1.8 2.7l-.7.7-.3-.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l.4.4c-.2.2-.5.3-.7.5l-.1.1zm4.7-1.3c3.6 0 6.5 2.8 6.5 6.3S10.1 14 6.5 14 0 11.2 0 7.7s2.9-6.3 6.5-6.3zm0 11.6c3 0 5.5-2.4 5.5-5.3S9.5 2.4 6.5 2.4 1 4.8 1 7.7c0 3 2.5 5.3 5.5 5.3zm0-9.6c.3 0 .5.2.5.5v3.4h2c.3 0 .5.2.5.5s-.2.4-.5.4H6.5c-.3 0-.5-.2-.5-.5V3.9c0-.3.2-.5.5-.5z"/></svg>',
    colors: br-gr
  )

  ,icn_close: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><path fill="fillColor" d="M14 1.4L12.6 0 7 5.6 1.4 0 0 1.4 5.6 7 0 12.6 1.4 14 7 8.4l5.6 5.6 1.4-1.4L8.4 7 14 1.4z"/></svg>',
    colors: off-wht-2 chrk
  )

  ,icn_comment: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 14" width="15" height="14"><path fill="fillColor" d="M0 14V0h15v11.3H2.7L0 14zM1 1v10.6l1.3-1.2H14V1H1z"/></svg>',
    colors: chrk
  )

  ,icn_drag: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 10" width="6" height="10"><circle fill="fillColor" cx="1" cy="1" r="1"/><circle fill="fillColor" cx="1" cy="9" r="1"/><circle fill="fillColor" cx="5" cy="1" r="1"/><circle fill="fillColor" cx="5" cy="9" r="1"/><circle fill="fillColor" cx="1" cy="5" r="1"/><circle fill="fillColor" cx="5" cy="5" r="1"/></svg>',
    colors: wht
  )

  ,icn_eye: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 10" width="24" height="10"><path fill="fillColor" d="M24 5l-.4-.3C21 2.6 16.9 0 12 0 7.1 0 3 2.6.4 4.7L0 5l.4.3C3 7.4 7.1 10 12 10c4.9 0 9-2.6 11.6-4.7L24 5zm-7.8 0c0 2.3-1.9 4.2-4.2 4.2-2.3 0-4.2-1.9-4.2-4.2C7.8 2.7 9.7.8 12 .8c2.3 0 4.2 1.9 4.2 4.2zM1.3 5c1.9-1.5 4.5-3.1 7.6-3.8C7.7 2.1 7 3.5 7 5s.7 2.9 1.8 3.8c-3-.7-5.6-2.3-7.5-3.8zm13.9 3.8C16.3 7.9 17 6.5 17 5s-.7-2.9-1.8-3.8c3.1.7 5.7 2.3 7.6 3.8-1.9 1.5-4.6 3.1-7.6 3.8z"/></svg>',
    colors: chrk
  )

  ,icn_facebook: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.1 17.4" width="9.1" height="17.4"><path fill="fillColor" d="M5.9 17.4V9.5h2.7L9 6.4H5.9v-2c0-.9.2-1.5 1.5-1.5H9V.1C8.8.1 7.8 0 6.7 0c-2.4 0-4 1.4-4 4.1v2.3H0v3.1h2.7v7.9h3.2z"/></svg>',
    colors: blk
  )

  ,icn_hamburger: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 15" width="20" height="15"><path fill="fillColor" d="M1.2 0h17.5c.7 0 1.3.6 1.3 1.2 0 .7-.6 1.3-1.3 1.3H1.2C.6 2.5 0 1.9 0 1.2 0 .6.6 0 1.2 0zM1.2 6.3h17.5c.7 0 1.3.6 1.3 1.3 0 .7-.6 1.3-1.3 1.3H1.2C.6 8.8 0 8.2 0 7.5s.6-1.2 1.2-1.2zM1.2 12.5h17.5c.7 0 1.3.6 1.3 1.3 0 .7-.6 1.3-1.3 1.3H1.2C.6 15 0 14.4 0 13.8c0-.7.6-1.3 1.2-1.3z"/></svg>',
    colors: null
  )

  ,icn_italic: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.6 11.2" width="9.6" height="11.2"><path fill="fillColor" d="M3.2 0v2.4H5L2.2 8.8H0v2.4h6.4V8.8H4.6l2.8-6.4h2.2V0"/></svg>',
    colors: null
  )

  ,icn_ar_left: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" width="18.7" height="30.4" viewBox="0 0 18.7 30.4"><path fill="fillColor" d="M18.7 26.9l-3.5 3.5L0 15.2 15.2 0l3.5 3.6L7.1 15.2z"/></svg>',
    colors: lghtgr2 chrk
  )

  ,icn_ar_right: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" width="18.7" height="30.4" viewBox="0 0 18.7 30.4"><path fill="fillColor" d="M11.6 15.2L0 3.6 3.5 0l15.2 15.2L3.5 30.4 0 26.9z"/></svg>',
    colors: lghtgr2 chrk wht
  )

  ,icn_ar_up: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" height="18.7" width="30.4" viewBox="0 0 30.4 18.7"><path fill="fillColor" d="M15.2 7.1L3.6 18.7 0 15.2 15.2 0l15.2 15.2-3.5 3.5z"/></svg>',
    colors: chrk
  )

  ,icn_ar_down: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" height="18.7" width="30.4" viewBox="0 0 30.4 18.7"><path fill="fillColor" d="M26.9 0l3.5 3.5-15.2 15.2L0 3.5 3.6 0l11.6 11.6z"/></svg>',
    colors: wht chrk
  )

  ,icn_link: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" width="23.91" height="5.86" viewBox="0 0 23.91 5.86"><path fill="fillColor" d="M21.78 0c1.17 0 2.13 1 2.13 2.22v1.4c0 .6-.2 1.16-.6 1.58-.4.42-.9.65-1.5.65h-6.1c-.5 0-1.1-.23-1.5-.65-.4-.4-.6-.94-.6-1.5h-3.2c0 .56-.2 1.1-.6 1.5-.4.42-.9.65-1.5.65H2.2c-.58 0-1.1-.23-1.5-.65-.5-.4-.7-.97-.7-1.56v-1.4c0-.6.22-1.16.62-1.58.4-.43.94-.66 1.5-.66h6.1c.57 0 1.1.23 1.5.65.4.4.62.94.63 1.5h3.2c.02-.56.24-1.1.63-1.5.4-.42.94-.65 1.5-.65h6.1zM8.68 4.13c.12-.12.2-.27.2-.43H7.66c-.4 0-.73-.34-.73-.77 0-.42.33-.77.73-.77H8.9c-.03-.16-.1-.3-.2-.43-.14-.13-.3-.2-.48-.2h-6.1c-.17 0-.33.07-.46.2s-.2.3-.2.5v1.4c0 .18.08.36.2.5.13.12.3.2.47.2h6.1c.17 0 .33-.07.46-.2h-.05zm13.77-1.9c0-.4-.3-.7-.66-.7h-6.1c-.2 0-.37.07-.5.2-.1.12-.18.27-.2.43h1.24c.4 0 .73.34.73.77 0 .2-.1.4-.3.54s-.3.22-.5.22H15c0 .1.1.3.2.4.13.1.3.2.47.2h6.08c.18 0 .34-.1.47-.2.13-.2.2-.3.2-.5V2.2l.03.03z"/></svg>',
    colors: null
  )

  ,icn_message: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 34.6" width="35" height="34.6"><path fill="fillColor" d="M34 0H1C.4 0 0 .4 0 1v26.6c0 .6.4 1 1 1h13.8l2.2 5.3c0 .1.1.2.2.3.4.4 1 .4 1.4 0l5.9-5.7H34c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1z"/></svg>',
    colors: null
  )

  ,icn_month: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85 43" width="85" height="43"><path fill="fillColor" d="M8 34.7h6.7V8H8zm10 0h6.7V8H18zm10 0h6.7V8H28z"/><path fill="fillColor" d="M84 0H1C.4 0 0 .4 0 1v41c0 .6.4 1 1 1h83c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zM41.5 41.5h-40v-40h40v40zm15.4-6.8h-7v-7h7v7zm0-9.8h-7v-7h7v7zm0-9.9h-7V8h7v7zm10 19.7h-7v-7h7v7zm0-9.8h-7v-7h7v7zm0-9.9h-7V8h7v7zm10 19.7h-7v-7h7v7zm0-9.8h-7v-7h7v7zm0-9.9h-7V8h7v7z"/></svg>',
    colors: null
  )

  ,icn_numbered_list: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" width="16.79" height="12.68" viewBox="0 0 16.79 12.68"><path fill="fillColor" d="M1.05 4.58C.47 4.58 0 5.05 0 5.63c0 .2.16.35.35.35.2 0 .35-.16.35-.35 0-.2.16-.35.35-.35s.35.16.35.35v.56L.1 7.5c-.1.1-.13.25-.07.38s.18.22.32.22h1.4c.2 0 .35-.16.35-.35 0-.2-.16-.35-.35-.35H1.2l.8-.8c.07-.08.1-.17.1-.26v-.7c0-.6-.47-1.06-1.05-1.06zm0 4.57C.47 9.15 0 9.63 0 10.2c0 .2.16.36.35.36.2 0 .35-.16.35-.35 0-.13.16-.3.35-.3s.35.17.35.36c0 .2-.16.36-.35.36-.2 0-.35.16-.35.35s.16.36.35.36c.2 0 .35.16.35.35 0 .2-.16.36-.35.36s-.35-.2-.35-.37c0-.2-.16-.35-.35-.35-.2 0-.35.16-.35.35 0 .58.47 1.06 1.05 1.06s1.05-.5 1.05-1.07c0-.27-.1-.52-.27-.7.17-.2.27-.44.27-.7 0-.6-.47-1.07-1.05-1.07zm0-9.15h-.7C.15 0 0 .16 0 .35 0 .55.16.7.35.7H.7v2.46c0 .2.16.35.35.35.2 0 .35-.1.35-.3V.35C1.4.16 1.24 0 1.05 0zm15.4 1.4H3.85c-.2 0-.36.17-.36.36v.7c0 .2.14.35.33.35h12.6c.18 0 .34-.1.34-.3v-.7c0-.2-.2-.34-.4-.34l.03-.03zm0 4.23H3.85c-.2 0-.36.16-.36.35v.7c0 .2.14.35.33.35h12.6c.18 0 .34-.16.34-.35v-.7c0-.2-.2-.35-.4-.35h.03zm0 4.23H3.85c-.2 0-.36.16-.36.35v.7c0 .2.14.4.33.4h12.6c.18 0 .34-.15.34-.34v-.7c0-.18-.2-.34-.4-.34l.03-.03z"/></svg>',
    colors: null
  )

  ,icn_play: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64"><path fill="fillColor" d="M0 32C0 14.3 14.3 0 32 0s32 14.3 32 32-14.3 32-32 32S0 49.7 0 32zm44.8 0L24.5 19.5v24.9L44.8 32z"/></svg>',
    colors: wht
  )

  ,icn_plus: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.6 18.6" width="18.6" height="18.6"><path fill="fillColor" d="M8.7 18.6h2V9.9h7.9v-2h-7.9V0h-2v7.9H0v2h8.7"/></svg>',
    colors: null
  )

  ,icn_print: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.47 30.48" width="29.47" height="30.48"><path fill="fillColor" d="M29.47 21.33V10.1c0-1.42-1.15-2.57-2.56-2.57h-3.05V1.65C23.85.75 23.1 0 22.2 0H7.3c-.9 0-1.65.74-1.65 1.65v5.88h-3.1C1.15 7.53 0 8.68 0 10.1v11.23c0 1.4 1.15 2.56 2.56 2.56h3.1v4.9c0 .9.73 1.65 1.64 1.65h14.87c.9 0 1.65-.74 1.65-1.65v-4.94h3.1c1.4 0 2.55-1.14 2.55-2.55zM6.87 1.65c0-.23.2-.42.43-.42h14.87c.23 0 .42.2.42.42v5.88H6.84V1.65zM22.6 28.83c0 .23-.2.42-.43.42H7.3c-.23 0-.42-.2-.42-.42v-12.5h15.7v12.5zm5.64-7.5c0 .73-.6 1.33-1.33 1.33h-3.05V15.7c0-.33-.27-.6-.62-.6H6.27c-.34 0-.62.26-.62.6v6.96h-3.1c-.72 0-1.32-.6-1.32-1.33V10.1c0-.74.6-1.34 1.33-1.34H26.9c.72 0 1.32.6 1.32 1.33v11.2h.02z"/><path fill="fillColor" d="M4.38 11.3c-.16 0-.32.07-.44.18-.1.12-.18.27-.18.43 0 .2.07.36.18.48.12.1.27.18.44.18s.32-.07.44-.18c.1-.1.18-.28.18-.44 0-.15-.07-.3-.18-.43-.12-.1-.28-.17-.44-.17zm6 12.1h8.73c.38 0 .66-.27.66-.62 0-.34-.27-.62-.62-.62H10.4c-.34 0-.62.27-.62.62s.27.62.62.62zm0 3.5h8.73c.38 0 .66-.28.66-.63s-.27-.62-.62-.62H10.4c-.34 0-.62.27-.62.62s.27.62.62.62zm0-7h8.73c.38 0 .66-.26.66-.6s-.27-.63-.62-.63H10.4c-.34 0-.62.27-.62.62s.27.6.62.6z"/></svg>',
    colors: wht
  )

  ,icn_right: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.4 16.9" width="10.4" height="16.9"><path fill="fillColor" d="M0 2l2-2 8.4 8.4L2 16.9l-2-2 6.5-6.5"/></svg>',
    colors: null
  )

  ,icn_search: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.47 13.88" width="13.47" height="13.88"><path fill="fillColor" d="M9.35 9.06c.9-.96 1.45-2.25 1.45-3.66C10.8 2.43 8.38 0 5.4 0S0 2.43 0 5.4s2.42 5.4 5.4 5.4c1.2 0 2.33-.4 3.23-1.07l4 4c.1.1.23.16.35.16s.25-.06.34-.16c.2-.2.2-.5 0-.7L9.35 9.07zM.98 5.4C.98 2.97 2.96.98 5.4.98c2.43 0 4.42 1.98 4.42 4.42S7.84 9.82 5.4 9.82.98 7.82.98 5.4z"/></svg>',
    colors: blk
  )

  ,icn_wrench: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 14" width="13" height="14"><path fill="fillColor" d="M9.93 6.84l1.05 1.25 1.4 1.6c.93 1.1.8 2.7-.3 3.6-1.1.9-2.74.76-3.67-.33L7 11.4l-1.06-1.24-.6-.7c-1.6.2-3.2-.4-4.24-1.65C0 6.5-.3 4.8.3 3.2l.23-.66.45.54L3.4 5.9c.22.24 2.27.42 2.52.2.25-.2.43-2.24.22-2.48L3.26.2l.7-.13c1.66-.3 3.37.3 4.48 1.63 1.05 1.24 1.36 2.9.9 4.43l.6.7zM8.5 6.08c.47-1.32.23-2.78-.68-3.86-.75-.9-1.85-1.4-2.98-1.4L6.77 3.1c.5.57.27 3.12-.32 3.62-.6.5-3.16.27-3.66-.32l-2-2.26c-.2 1.1.1 2.26.9 3.16.9 1.07 2.3 1.56 3.7 1.32l.2-.04.15.18.76.88 1.1 1.26L9 12.5c.64.76 1.77.86 2.52.23s.85-1.74.22-2.5l-1.4-1.66-1.06-1.2-.74-.9-.16-.2.1-.2z"/><path fill="fillColor" d="M9.23 11.64c.35.42 1 .48 1.4.12.43-.35.5-1 .13-1.4-.35-.43-1-.5-1.4-.13-.43.36-.48 1-.13 1.4z"/></svg>',
    colors: br-gr
  )

  ,icn_sized_collapsed: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" width="21.4" height="14.5" viewBox="0 0 21.4 14.5"><path fill="fillColor" d="M3.2 9.18h4.53l.8 2.35h1.8L6.36.07V0H4.4L.67 11.53h1.77l.77-2.35zm.57-1.76l1.63-5 1.73 5H3.77zM0 12.9h11.2v1.6H0zm13.4-7.6l4 4 4-4z"/></svg>',
    colors: null
  )

  ,icn_size_expanded: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" width="21.4" height="14.5" viewBox="0 0 21.4 14.5"><path fill="fillColor" d="M3.2 9.18h4.53l.8 2.35h1.78L6.4.07 6.34 0H4.4L.67 11.53h1.77l.77-2.35zm.57-1.76l1.63-5 1.73 5H3.77zM0 12.9h11.2v1.6H0zm17.4-7.6l-4 4h8z"/></svg>',
    colors: null
  )

  ,icn_trash: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.8 28" width="19.8" height="28"><path fill="fillColor" d="M7.6 24.7c.4 0 .6-.3.6-.6L7.9 7H6.7L7 24.1c0 .3.3.6.6.6zm4.5 0c.3 0 .6-.3.6-.6L13.1 7h-1.2l-.3 17.1c-.1.3.2.6.5.6zM17.6 7L17 25.8c0 .6-.5 1.1-1.1 1.1h-12c-.6 0-1.1-.5-1.1-1.1L2.2 7H1l.6 18.7c0 1.3 1 2.3 2.3 2.3h11.9c1.3 0 2.3-1 2.3-2.3L18.8 7h-1.2zm2.2-3.6H0v1.2h19.8V3.4zM5.7 1.8c0-.2.1-.4.2-.4.1-.1.3-.2.4-.2h7.1c.2 0 .3.1.4.2.2.1.2.2.3.4h1.2c-.1-.6-.3-1-.5-1.2-.3-.4-.8-.6-1.4-.6H6.3c-.5 0-.9.2-1.3.5-.2.2-.4.7-.5 1.3h1.2z"/></svg>',
    colors: wht chrk
  )

  ,icn_underline: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" width="11.2" height="14.4" viewBox="0 0 11.2 14.4"><path fill="fillColor" d="M5.6 11.2c2.64 0 4.8-2.16 4.8-4.8V0h-2v6.4c0 1.52-1.28 2.8-2.8 2.8S2.8 7.92 2.8 6.4V0h-2v6.4c0 2.64 2.16 4.8 4.8 4.8zM0 12.8v1.6h11.2v-1.6H0z"/></svg>',
    colors: null
  )

  ,icn_up: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" width="16.3" height="10" viewBox="0 0 16.3 10"><path fill="fillColor" d="M14.4 10l1.9-1.9L8.1 0 0 8.1 1.9 10l6.2-6.2 6.3 6.2z"/></svg>',
    colors: null
  )

  ,icn_upload: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.7 17.8" width="18.7" height="17.8"><path fill="fillColor" d="M18.2 8.7c-.3 0-.5.2-.5.5v5c0 1.4-1.1 2.5-2.4 2.5H3.5c-1.3 0-2.4-1.1-2.4-2.5V9.1c0-.3-.2-.5-.5-.5s-.6.2-.6.5v5.1c0 2 1.6 3.6 3.5 3.6h11.8c1.9 0 3.5-1.6 3.5-3.6v-5c-.1-.3-.3-.5-.6-.5z"/><path fill="fillColor" d="M6.3 4.4l2.5-2.6V13c0 .3.2.5.5.5s.5-.2.5-.5V1.9l2.5 2.6c.1.1.2.2.4.2.1 0 .3-.1.4-.2.2-.2.2-.6 0-.8L9.7.2C9.6.1 9.5 0 9.4 0c-.2 0-.3.1-.4.2L5.6 3.7c-.2.2-.2.6 0 .8.2.1.5.1.7-.1z"/></svg>',
    colors: br-gr
  )

  ,icn_week: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85 43" width="85" height="43"><path fill="fillColor" d="M84 0H1C.4 0 0 .4 0 1v41c0 .6.4 1 1 1h83c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zM14.7 34.7H8V8h6.7v26.7zm10 0H18V8h6.7v26.7zm10 0H28V8h6.7v26.7zm48.8 6.8h-40v-40h40v40z"/><path fill="fillColor" d="M50.2 8h7v7h-7zm10 0h7v7h-7zm10 0h7v7h-7zm0 9.9h7v7h-7zm0 9.8h7v7h-7zm-10 0h7v7h-7zm0-9.8h7v7h-7zm-10 0h7v7h-7zm0 9.8h7v7h-7z"/></svg>',
    colors: null
  )

  ,icn_week_view: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><path fill="fillColor" d="M75 0h25v100H75zM0 0h25v100H0zm37.5 0h25v100h-25z"/></svg>',
    colors: wht chrk
  )

  ,icn_month_view: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><path fill="fillColor" d="M0 0h25v25H0zm37.5 0h25v25h-25zM75 0h25v25H75zm0 37.5h25v25H75zM75 75h25v25H75zm-37.5 0h25v25h-25zm0-37.5h25v25h-25zM0 37.5h25v25H0zM0 75h25v25H0z"/></svg>',
    colors: wht chrk
  )

  ,icn_pencil: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34.2" width="34" height="34.2"><path fill="fillColor" d="M34 6.6L27.5 0 1.2 26.4 0 34.2 7.4 33l.5-.1L34 6.6zm-3.2 0l-3 3-3.3-3.3 3-3 3.3 3.3zM6.7 30.8l-3.9.6.6-4L22.8 7.9l3.3 3.3L6.7 30.8z"/></svg>',
    colors: wht chrk
  )

  ,icn_shield-filled: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.75 17.22" width="14.75" height="17.22"><path fill="fillColor" d="M14.3 2.68h-.33A9.25 9.25 0 0 1 7.68.11.47.47 0 0 0 7.09.1c-.73.6-1.38 1.06-1.93 1.39a8.54 8.54 0 0 1-4.38 1.2H.45a.44.44 0 0 0-.45.45v4.41c0 2.07.69 4.04 1.99 5.69.94 1.2 2.63 2.48 5.15 3.93.07.04.15.06.23.06s.15-.02.22-.06c2.54-1.45 4.23-2.73 5.17-3.93a9.17 9.17 0 0 0 1.99-5.69V3.14c0-.25-.2-.46-.45-.46zm-.45 4.86a8.2 8.2 0 0 1-1.8 5.13c-.83 1.05-2.4 2.25-4.68 3.57-2.27-1.32-3.84-2.52-4.67-3.57A8.22 8.22 0 0 1 .9 7.54V3.59a9.46 9.46 0 0 0 4.72-1.32c.51-.3 1.09-.71 1.74-1.22a10.09 10.09 0 0 0 6.48 2.55v3.94z"/><path fill="fillColor" d="M6.01 4.34c-.76.45-1.6.68-2.48.68h-.19a.4.4 0 0 0-.41.41V8c0 1.24.41 2.42 1.19 3.41.56.71 1.56 1.47 3.05 2.33.06.04.13.05.2.05s.14-.02.2-.05c1.5-.85 2.49-1.61 3.06-2.33A5.46 5.46 0 0 0 11.82 8V5.43a.4.4 0 0 0-.41-.41h-.19c-1.3 0-2.47-.48-3.57-1.46a.4.4 0 0 0-.53-.02c-.42.35-.79.61-1.11.8z"/></svg>',
    colors: wht
  )

  ,icn_flag: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.96 15.93" width="19.96" height="15.93"><path fill="fillColor" d="M19.84 7.2c-.1-.13-2.31-2.91-5.27-3.02-2.03-.07-4.48-2.24-5.22-2.95l.24-.42A.53.53 0 0 0 9.64.4a.59.59 0 0 0-.25-.33.54.54 0 0 0-.74.2L.07 15.12a.53.53 0 0 0-.05.41c.04.14.13.26.25.33a.54.54 0 0 0 .74-.2l3.33-5.77c.59.55 1.59 1.4 2.74 2.06 1.1.63 2.11.97 3 1 2.4.08 4.37 2.5 4.45 2.6.11.14.29.23.47.21a.52.52 0 0 0 .43-.27l4.43-7.68a.51.51 0 0 0-.02-.61zm-1.1.4l-3.86 6.69c-.84-.86-2.6-2.35-4.75-2.43-2.03-.07-4.47-2.23-5.22-2.94l3.87-6.71c1.12 1.04 3.5 2.98 5.74 3.06 1.95.08 3.61 1.66 4.22 2.33z"/></svg>',
    colors: wht
  )

  ,icn_edited: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.83 16.86" width="18.83" height="16.86"><path fill="fillColor" d="M18.47 3.58l-2.46-1.42a.77.77 0 0 0-.63-.06h-.01a.58.58 0 0 0-.28.21L8.7 13.36a.8.8 0 0 0-.1.43l.25 2.41a.74.74 0 0 0 .73.66c.1 0 .2-.02.29-.06l2.21-.98a.76.76 0 0 0 .34-.3l6.31-10.94a.73.73 0 0 0-.26-1zm-3.28.74l.58-1.01 1.81 1.05-.62 1.08-1.77-1.12zm-.55.95l1.77 1.12-4.88 8.46-1.63.73-.19-1.77 4.93-8.54zm-7.4 9.21a6.74 6.74 0 1 1 5.43-11.34.5.5 0 0 0 .72.02.5.5 0 0 0 .02-.72A7.77 7.77 0 1 0 7.16 15.5h.04a.51.51 0 0 0 .04-1.02z"/><path fill="fillColor" d="M7.45 3.06c-.3 0-.54.24-.54.54v4.98c0 .3.24.54.54.54H9.6c.3 0 .54-.24.54-.54s-.25-.55-.55-.55h-1.6V3.6c0-.3-.25-.54-.54-.54z"/></svg>',
    colors: wht
  )

  ,icn_shield-empty: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.2 16.53" width="14.2" height="16.53"><path fill="fillColor" d="M7.09 16.53a.52.52 0 0 1-.27-.07c-2.4-1.38-4.01-2.6-4.91-3.74A8.77 8.77 0 0 1 0 7.26V3.09c0-.3.25-.55.55-.55h.31a7.9 7.9 0 0 0 4.08-1.12c.51-.3 1.12-.74 1.81-1.3a.55.55 0 0 1 .71.02 8.67 8.67 0 0 0 5.87 2.4h.31c.3 0 .55.25.55.55v4.17c0 1.98-.66 3.87-1.91 5.46-.9 1.14-2.51 2.37-4.92 3.74a.5.5 0 0 1-.27.07zM1.1 3.64v3.62a7.7 7.7 0 0 0 1.67 4.78c.77.97 2.22 2.09 4.32 3.31 2.11-1.22 3.56-2.34 4.33-3.31a7.7 7.7 0 0 0 1.67-4.78V3.64a9.62 9.62 0 0 1-6.01-2.37c-.58.45-1.11.83-1.58 1.1a8.98 8.98 0 0 1-4.4 1.27z"/></svg>',
    colors: wht
  )

  ,icn_add-user: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 14.5" width="18" height="14.5"><path fill="fillColor" d="M9.9 8.5a5.3 5.3 0 0 0 1.9-4.1c0-2.6-1.7-4.4-4.1-4.4-2.4 0-4.1 1.8-4.1 4.4 0 1.7.8 3.3 1.9 4.1a8.3 8.3 0 0 0-5.5 5c-.1.2 0 .5.2.6.1.2.2.4.5.4h13.8c.2 0 .4-.1.5-.3h.1c.3-.1.4-.4.3-.7a8.3 8.3 0 0 0-5.5-5zM4.6 4.4c0-2 1.3-3.4 3.1-3.4 1.9 0 3.1 1.4 3.1 3.4S9.7 8 8.2 8.3h-1c-1.5-.3-2.6-2-2.6-3.9zm2.8 4.9H8c2.8.1 5 1.6 6.2 4.2h-13a6.8 6.8 0 0 1 6.2-4.2z"/><path fill="fillColor" d="M17.5 6.7h-2v-2c0-.3-.2-.5-.5-.5s-.5.2-.5.5v2h-2c-.3 0-.5.2-.5.5s.2.5.5.5h2v2c0 .3.2.5.5.5s.5-.2.5-.5v-2h2c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z"/></svg>',
    colors: chrk
  )

  ,icn_marker: (
    code: '<svg xmlns="http://www.w3.org/2000/svg" width="21.4" height="14.5" viewBox="0 0 21.4 14.5"><path fill="fillColor" d="M3.2 9.18h4.53l.8 2.35h1.8L6.36.07V0H4.4L.67 11.53h1.77l.77-2.35zm.57-1.76l1.63-5 1.73 5H3.77zM0 12.2v1.6H0zm13.4-7.6l4 4 4-4z"/></svg>',
    colors: chrk
  )
  

); // end icon_map

@include make-icon-classes($icons-map);




