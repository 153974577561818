a {
  display: inline-block;
  color: inherit;

  &:focus {
    box-shadow: 0px 0 0 !important;
  }

  // &:hover {
  //   color: $greeny-blue;
  // }
}