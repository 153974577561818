// sass-lint:disable-all


// GRID
// by Pavel G
// ----------------------------------------------------------



// helper functions


@function decode-col($cols) {

  $col-span: null;
  $total-cols: 0;
  $real-cols: null;

  // $cols = 20% 4 or $cols = 2/10 3 or $cols = 2 3 or $cols = 2 10 4
  @if type-of($cols) == list {

    @if is-percentage(nth($cols, 1)) { // $cols = 20% 4
      $col-span: nth($cols, 1);
      $total-cols: nth($cols, 2);

    } @else { // $cols = 2/10 3 or $cols = 2 3 or $cols = 2 10 4
      $cols-1: to-string($cols);
      $not-eq: str-index($cols-1, '/');
      $cols-1: to-string(str-split( $cols-1, ' ', 1 ));

      @if $not-eq { //  $cols = 2/10 3
        $col-span:  to-number(nth( str-split($cols-1,'/'), 1));
        $total-cols:  to-number(nth( str-split($cols-1,'/'), 2));
        $real-cols: nth($cols, 2);
      } @else { // $cols = 2 3 or $cols = 2 10 4
        $col-span: nth($cols, 1);
        $total-cols: nth($cols, 2);
        @if length($cols) > 2 {
          $real-cols: nth($cols, 3);
        }
      }
    }

  } @else { // $cols = 20% or $cols = 2 or $cols = '2/10'

    @if is-percentage($cols) { // $cols = 20%, equal columns
      $col-span: $cols;
      $total-cols: round(100 / strip-unit($cols));

    } @else { //  $cols = 2 or $cols = '2/10'
      $cols: to-string($cols);
      $not-eq: str-index($cols, '/');

      @if $not-eq { // $cols = '2/10'
        $col-span: to-number(str-slice($cols,1,$not-eq - 1));
        $total-cols: to-number(str-slice($cols,$not-eq + 1));

      } @else { // $cols = 2
        $total-cols: to-number($cols);
      }
    }
  }

  $decoded: (
    col-span: $col-span,
    total-cols: $total-cols,
    real-cols: $real-cols
  );

  @return $decoded;

}

@function decode-gutter($gutter:null) {
  $has-gutter: to-bool($gutter);
  @if $has-gutter {
    $gutter: check-unit($gutter);
  } @else {
    $gutter: null;
  }
  @return ($gutter);
}

// get width of individual column for equal width column layout
@function eq-col-width($total-cols:3, $gutter-width:null) {
  @if $gutter-width {
    $gutter-width: ($gutter-width * ($total-cols - 1)) / $total-cols;
    $gutter-width: decimal-round($gutter-width, 2, ceil);
    $col-span:  decimal-round(100% / $total-cols, 2, floor);

    @return calc(#{$col-span} - #{$gutter-width});

  } @else {
    @return 100% / $total-cols;
  }
}

// get width of individual column for columns of different widths
@function get-col-width($col-span:1, $total-cols:12, $gutter-width:null, $real-cols:null) {

  @if not is-percentage(nth($col-span, 1)) {
    $col-span: $col-span * 100% / $total-cols;
  }

  $col-span: decimal-round($col-span, 2, floor);

  @if $real-cols { $total-cols: $real-cols; }

  @if not $gutter-width {
    @return $col-span;
  }

  @if $gutter-width {
    $gutter-width: ($gutter-width * ($total-cols - 1)) / $total-cols;
    $gutter-width: decimal-round($gutter-width, 2, ceil);
    @return calc(#{$col-span} - #{$gutter-width});
  }

}



// put on row element, made for single row
// !!! cells are expected to be direct descendants of row element

@mixin grid-row(
  $item: $grid-cell-class,
  $dir: $direction,
  $use-firstlast: true,
  $force: false,
  $type: $grid-use-type,
  $rev: false,
  $skip: 0,
  $enable-flex: $enable-flex,
  $use-fallbacks: $use-fallbacks,
  $not: 'fieldset',
  $do-margs: $grid-use-x-margs,
  $spread: $grid-spread-cells
)
{

  $first-child: 1 + $skip;
  $i:''; @if $force { $i:'!important'; }
  $marg-side: left; @if ($dir == rtl) { $marg-side: right; }
  $child: 'child'; @if $type { $child: 'of-type' }

  @if (not $enable-flex) or ($use-fallbacks)
  {
    display: block;
  }
  @if
    (not $enable-flex) or
    ($enable-flex and $use-fallbacks and (not $spread))
  {
    @include clearfix;
  }

  @if ($enable-flex and $use-fallbacks and $spread) {
    & + * {
      clear: both;
    }
  }

  @if $enable-flex {
    @if $not {
      &:not(#{$not}){
        display: flex;
      }
    } @else {
      display: flex;
    }

    flex-wrap: wrap;
    flex-basis: 100%;

    @if $spread {
      justify-content: space-between;
    }

    @if ($rev) {
      flex-direction: row-reverse;
    }
  }

  @content;

  & > #{$item}
  {
    @if ($use-firstlast) and
      (($do-margs) or ($use-fallbacks) or (not $spread)) {

      &:nth-#{$child}( #{$first-child} ) {
        margin-#{$marg-side}: 0#{$i};
      }
      &:last-#{$child} {
        margin-#{opposite-direction($marg-side)}: 0#{$i};
      }

    }
  }
}



// legacy naming

@mixin make-rrow($args...) {
  @include grid-row($args...){
    @content;
  }
}


// undo row styles

@mixin clear-row() {
  display: block;
  flex: none;

  // removes clearfix
  @if (not ($enable-flex)) or ($use-fallbacks) {
    &:after {
      display: none;
    }
  }

  @content;
}


// put on row, made for multirow application
// need to provide {{$cols}} - number of cells in 1 row
// after {{$cols}} cells, cell will wrap into another row, with vertical margins set by {{$gutter}}
// !!! cells are expected to be direct descendants of row element

@mixin grid-multirow(
  $cols: 3,
  $gutter: $grid-spacer-y,
  $item: $grid-cell-class,
  $dir: $direction,
  $force: false,
  $clear: null,
  $type: $grid-use-type,
  $type-x: null,
  $type-y: null,
  $rev: false,
  $skip: 0,
  $enable-flex: $enable-flex,
  $use-fallbacks: $use-fallbacks,
  $center-last: false,
  $stretch-last: false,
  $do-row: $multirow-includes-row,
  $do-margs: $grid-use-x-margs,
  $spread: $grid-spread-cells
) {

  $cols: $cols + $skip;
  $i:''; @if $force { $i:'!important'; }
  $marg-side: left; @if ($dir == rtl) { $marg-side: right; }

  @if ($type-x == null) { $type-x: $type; }
  @if ($type-y == null) { $type-y: $type; }

  $child-x: 'child'; @if $type-x { $child-x: 'of-type' }
  $child-y: 'child'; @if $type-y { $child-y: 'of-type' }

  $gutter: decode-gutter($gutter);

  @if (($clear==null) and not ($enable-flex)) or ($use-fallbacks) {
    $clear: true;
  }

  @if $do-row{
    @include grid-row(
      $item: $item,
      $dir: $dir,
      $type: $type-x,
      $rev: $rev,
      $skip: $skip,
      $use-firstlast: false,
      $enable-flex: $enable-flex,
      $use-fallbacks: $use-fallbacks,
      $do-margs: $do-margs,
      $spread: $spread
    );
  }

  & > #{$item} {

    @if $enable-flex {
      flex-grow: 0;
      flex-shrink: 0;
    }

    // horizontal margins

    @if (
      (not $enable-flex) or
      ($do-margs) or
      ($use-fallbacks) or
      ( $enable-flex and (not $do-margs) and (not $spread) )
    ) {

      &:nth-#{$child-x}( #{$cols}n+1 ):nth-#{$child-x}( #{$cols}n+1 ) {
        margin-#{$marg-side}: 0#{$i};
      }

      &:nth-#{$child-x}( #{$cols}n ):nth-#{$child-x}( #{$cols}n ) {
        margin-#{opposite-direction($marg-side)}: 0#{$i};
      }

    }

    &:nth-#{$child-x}( #{$cols}n+1 ):last-#{$child-x}{
      @if ($center-last) and (($do-margs) or ($use-fallbacks)) {
        @include m(x auto, $i: $force);
      }
      @if $stretch-last {
        max-width: none;
        width: 100%;
        flex-basis: 100%;
        @if (($do-margs) or ($use-fallbacks)) {
          @include m(x 0);
        }
      }
    }


    // vertical margins

    @if $gutter {
      &:nth-#{$child-y}( n+#{$cols+1} ) {
        margin-top: $gutter;
      }

      // risky fallback for rows withing rows, may break stuff
      @if (not $enable-flex) or ($use-fallbacks) {
        &#{$grid-row-class} {
          padding-top: $gutter;
        }
      }

    }

    // clearing floats

    @if $clear {
      &:nth-#{$child-x}( #{$cols}n+1 ) {
        clear: both;
      }
    }

    @content;

  }
}

// legacy naming

@mixin multirow($args...) {
  @include grid-multirow($args...){
    @content;
  }
}


// if you need to make any cell full width.
// !!! it will break multirow layout that relies on nth-elements and expects a certain amount of cells in each row.
// but! it will be ok if you use different element (span or p instead of div), and use nth-type for horizontal margins (on grid or grid-multirow mixins), and nth-child for vertical margins.
// also use this if you need to reset (clear) a cell.

@mixin grid-cell-fullwidth(
  $do-margs: $grid-use-x-margs,
  $spread: $grid-spread-cells,
  $force:false,
  $use:100%,
  $enable-flex: $enable-flex
){

  $i:''; @if $force { $i:'!important'; }

  width: #{$use}#{$i};

  @if ($enable-flex) {
    min-width: 0#{$i};
    max-width: none#{$i};
    flex-basis: auto#{$i};
  }

  @if (not ($enable-flex)) or ($use-fallbacks) {
    float: none#{$i};
    clear: both;
  }

  @if (
    (not $enable-flex) or
    ($do-margs) or
    ($use-fallbacks) or
    ( $enable-flex and (not $do-margs) and (not $spread) )
  ) {
    @include m(x 0);
  }

  @content;
}

// legacy naming

@mixin clear-col($args...) {
  @include grid-cell-fullwidth($args...){
    @content;
  }
}


// singe grid mixin, put on row/container
// made for multirow layout with same width cells
// !!! cells are expected to be direct descendants of row element

@mixin grid(
  $cols: 3,
  $gutter-x: $grid-spacer-x,
  $gutter-y: $grid-spacer-y,
  $item: $grid-cell-class,
  $dir: $direction,
  $force: false,
  $type: $grid-use-type,
  $type-x: null,
  $type-y: null,
  $rev: false,
  $skip: 0,
  $enable-flex: $enable-flex,
  $use-fallbacks: $use-fallbacks,
  $center-last: false,
  $stretch-last: false,
  $do-margs: $grid-use-x-margs,
  $spread: $grid-spread-cells
) {

  @if ($type-x == null) { $type-x: $type; }
  @if ($type-y == null) { $type-y: $type; }

  & > #{$item} {
    @include grid-cell(
      $cols: $cols,
      $gutter: $gutter-x,
      $dir: $dir,
      $force: $force,
      $use-firstlast:false,
      $type: $type-x,
      $enable-flex: $enable-flex,
      $use-fallbacks: $use-fallbacks,
      $do-margs: $do-margs,
      $spread: $spread
    );
  }

  @include grid-multirow(
    $cols: $cols,
    $gutter: $gutter-y,
    $item: $item,
    $dir: $dir,
    $force: $force,
    $type-x: $type-x,
    $type-y: $type-y,
    $rev: $rev,
    $skip: $skip,
    $enable-flex: $enable-flex,
    $use-fallbacks: $use-fallbacks,
    $center-last: $center-last,
    $stretch-last: $stretch-last,
    $do-margs: $do-margs,
    $spread: $spread
  );

  @content;
}



//  $cols:     {total-cols}  (equal col width)      4  (col width = 25%)
//  OR $cols:  {col-span} {total-cols}              2 10 (col width = 20%, total cols 10)
//  OR $cols:  {col-span} {total-cols} {real-cols}  2 10 3 (col width = 20%, other 2 columns take up 80%)
//  OR $cols:  {'col-span/total-cols'} {real-cols}    '2/10' 3 (same as above)
//  OR $cols   {percentage} {total-cols=real-cols}  20% 3 (same as above)

//  {col-span} / {total-cols} = relative width
//  if real columns number is differenr from {total-cols}, provide {real-cols}

//  example:
//  make-column(3, 20px) - make column 33% with gutters 20px
//  make-column(2 10, 20px) - make column 20% with gutters 20px
//    => same as make-column('2/10',20px) or make-column(20%,20px)
//  make-column(2 10 3, 20px) - make column 20%, but count gutters only for 3 real columns
//    => is same as make-column(20% 3, 20px);


@mixin grid-cell(
  $cols: 3,
  $gutter: $grid-spacer-x,
  $dir: $direction,
  $use-firstlast: false,
  $type: $grid-use-type,
  $not-only: false,
  $float: null,
  $enable-flex: $enable-flex,
  $use-fallbacks: $use-fallbacks,
  $force: null,
  $do-margs: $grid-use-x-margs,
  $spread: $grid-spread-cells,
  $minmax: $grid-cell-use-minmax
  ) {
    $i:''; @if $force { $i:'!important'; }

  //  cols

  $cols: decode-col($cols);
  $col-span: map-get($cols, col-span);
  $total-cols: map-get($cols, total-cols);
  $real-cols: map-get($cols, real-cols);

  // elements

  $first-child: '&:first-child';
  $last-child: '&:last-child';
  $self: '&';

  @if $not-only {
    $self: '&:not(:only-child)';
  }

  @if $type {
    $first-child: '&:first-child,&:first-of-type';
    $last-child: '&:last-child,&:last-of-type';
  }

  // gutters

  $gutter: decode-gutter($gutter);
  $marg-side: left; @if ($dir == rtl) { $marg-side: right; }

  // float

  @if (not ($enable-flex)) or ($use-fallbacks) {
    @if not $float {
      @if ($dir == rtl) { $float: right; }
      @else { $float: left; }
    }
  }

  // width

  $width: 0;

  @if $col-span {
    $width: get-col-width($col-span,$total-cols,$gutter,$real-cols);
  } @else {
    $width: eq-col-width($total-cols,$gutter);
  }

  // output

  #{$self} {
    @if (not ($enable-flex)) or ($use-fallbacks) {
      float: $float;
    }
    width: $width;

    @if $enable-flex {
      flex: 0 0 $width;
      @if $minmax{
        max-width: $width;
        min-width: $width;
      }
    }

    @if ($gutter) and (
      (not $enable-flex) or
      ($do-margs) or
      ($use-fallbacks) or
      ( $enable-flex and (not $do-margs) and (not $spread) )
    ) {
      @include m(x $gutter/2, $i: $force);
    }

    @content;
  }

  @if (($gutter) and ($use-firstlast)) and (
    (not $enable-flex) or
    ($do-margs) or
    ($use-fallbacks) or
    ( $enable-flex and (not $do-margs) and (not $spread) )
  ) {
    #{$first-child} {
      margin-#{$marg-side}: 0#{$i};
    }
    #{$last-child} {
      margin-#{opposite-direction($marg-side)}: 0#{$i};
    }
  }

}

// legacy naming

@mixin make-column($args...) {
  @include grid-cell($args...){
    @content;
  }
}


// modify column width

@mixin grid-modify-cell(
  $cols:null,
  $gutter:null,
  $do-margs:false,
  $spread: $grid-spread-cells,
  $enable-flex: $enable-flex
){

  $cols: decode-col($cols);
  $col-span: map-get($cols, col-span);     // color1: #{$col-span};
  $total-cols: map-get($cols, total-cols); // color2: #{$total-cols};
  $real-cols: map-get($cols, real-cols);   // color3: #{$real-cols};
  $gutter: decode-gutter($gutter);
  $width: 0;

  @if $col-span {
    $width: get-col-width($col-span,$total-cols,$gutter,$real-cols);
  } @else {
    $width: eq-col-width($total-cols,$gutter);
  }

  // output

  width: $width;
  @if $enable-flex {
    max-width: $width;
    min-width: $width;
    flex-basis: $width;
  }

  @if ($gutter and $do-margs) {
    @include m(x $gutter/2);
  }

  @content;

}

// legacy naming

@mixin modify-column($args...){
  @include grid-modify-cell($args...){
    @content;
  }
}



// simple masonry layout

@mixin masonry-columns(
  $cols: 2,
  $gutter-x: $grid-spacer-x,
  $gutter-y: $grid-spacer-y,

  $item: $grid-cell-class
) {

  // if flex is expected to work, that means modern browsers, that means columns may also work fine
  @if $enable-flex {

    column-count: $cols;
    column-gap: $gutter-x;

    & > #{$item} {
      break-inside: avoid;

      margin-bottom: $gutter-y;
      margin-top: 0;
    }

  }
}