.page-user-change-password {


  // resetting stuff

  div {
    float: none;
    clear: both;
  }

  label//, a
  {
    display: block;
  }
  a {

    &:hover {
      color:$charcoal;
    }
  }

  div.form-item div.password-suggestions,
  .password-strength, div.password-confirm,
  .password-parent, .confirm-parent,
  input
  {
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
  }

  .form-required {
    display: none !important;
  }


  //-----------

  .description,.password-strength-title,.password-confirm{
    display: block;
    font-size: rem(15);
    line-height: 1.1;
  }
  .description, div.form-item div.password-suggestions,div.password-confirm
  {
    @include m(t 5);
  }
  .password-strength-title{
    @include m(b 5);
  }
  .password-strength {
    @include m(b 18);
  }
  div.password-confirm{
    @include m(b 5);
  }
  .password-suggestions {
    li{
      @include not-first{
        &:before{
          content:", ";
        }
      }
    }
  }
  .form-item-terms-use
  {
    @include m(b 35);
  }

}