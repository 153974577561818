// ck editor mods

$ckeditor-uses-custom-icons: true;

.ckeditor{

  .cke_reset,
  .cke_reset_all
  {
    &, & *{
      box-sizing: border-box;
    }
  }

  .cke_top {
    background-color: rgb(244,242,238); // rgba(241, 239, 234, 0.7);
    padding: 6px 8px;
    // border-left-width: 1px;
    // border-left-style: solid;
  }

  .cke_bottom  {
    display: none;
  }

  .cke_toolbar  {
    float: right;
  }

  .cke_wysiwyg_frame, .cke_wysiwyg_div, .cke_inner, .cke_contents
  {
    background: none;
  }

  // icons

  a.cke_button {
    cursor: pointer;

    &.cke_button__link,&.cke_button__numberedlist {
      @include m(l 25);
    }

    @if $ckeditor-uses-custom-icons {

      .cke_button_icon {
        background-repeat: no-repeat !important;
        background-position: center !important;
      }

      .cke_button__link_icon {
        width: 20px;
        background-size: 20px auto !important;
        background-image: build-svg-icon(icn_link,$brown-grey) !important;
      }

      .cke_button__bold_icon {
        background-size: 9px auto !important;
        background-image: build-svg-icon(icn_bold,$brown-grey) !important;
      }

      .cke_button__italic_icon {
        background-size: 9px 13px !important;
        background-image: build-svg-icon(icn_italic,$brown-grey) !important;
      }

      .cke_button__underline_icon {
        width: 13px;
        background-size: 11px auto !important;
        background-image: build-svg-icon(icn_underline,$brown-grey) !important;
      }

      .cke_button__textcolor_icon {
        width: 21px;
        background-size: 20px auto !important;
        background-image: build-svg-icon(icn_sized_collapsed,$brown-grey) !important;
      }

      .cke_button__bgcolor_icon {
        width: 21px;
        background-size: 20px auto !important;
        background-image: build-svg-icon(icn_marker,$brown-grey) !important;
        background-color: yellow !important;
      }

      .cke_button__numberedlist_icon {
        background-size: 16px auto !important;
        background-image: build-svg-icon(icn_numbered_list,$brown-grey) !important;
      }

      .cke_button__bulletedlist_icon {
        background-size: 16px auto !important;
        background-image: build-svg-icon(icn_list,$brown-grey) !important;
      }

      .cke_button__iframe_icon{
        background-size: 16px auto !important;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAKmSURBVHjabJNNa1xlGIav5z3vOTNzMpOMk0mGhjST4idYESzitiJuRHDl3p/g3/APFXRRpYJIKVIhFakapDWNNJlkznyej/d9Hjezkl5wbe7FtbtlfDD6CpgCu0ABBKACFsAcWAPXm30G6EYAPBpmmP6OyC6SFCAKHCIyAT4CHgOfAk+BN4EzoAdcAiuvveNvzHd2rSoupJlPJVanFuqaWP9sqgPgoUvSa+CBOOfFuXuI+wyRB8Ancvvzr5933rh7GJs1zeUfuFiT334fF+fo1TmxeKnLv5+U2tTn9bIIYVmcUK68NeUPuKTju1upDva6YDl1MsU7z+i9D8lHuzgDEu/OfrqXt47ffX2F4+X06u319BLm0y/KR9/+61UcIUlIECRxiIBbL+jELZwpIhkZgU4ipO0eTStHh4dImmOzSeXVOSxLScWQLMW7hNSMLe/wLoHE0/aOtoPGGblGrkNgFYyyKvEvFjUXVyX7LUdaKXkrYbZu6AcjaXsaE9YByjKyCJGLVc1fZcNUlXzZ4EufUgRQU/JKWZlSFTW+KNlRRwPMakXXkWUWWDRKGQwVwMCH7R2020OtQbIMl3kkS1mXNS7LUDGiGWqKmREMAgZmgOFdcd6x9SWrfId2q4O228TuDsuYEAOYGFWEGJXSYGZGbQaAAMmoON3eevLdL/7pj6k9e1zFq7NemE9ctZhRVg2rGpbnzyhbAwrLmFQ1WjcQofXPb1MZH+x/ieqpaBwaNjHVPcxu4ls3pN2943r7N/Dpse69NSz7RzT5ELI+2hmQ/Xn/uYwPRmPgJvAaMACWwDZmv2J6bBq/F7iLxheIfIxv9cl6dzQfHklsahkfjPgf+eaZw01YgVvACXALs/uYvoPFE8R98KrAq0g2wX2gDxwBK2DvvwEA/yZk5/AM7dcAAAAASUVORK5CYII=') !important;
      }

    } @else {
      .cke_button_icon {
        opacity: 0.7;
      }
    }
  }
}
.cke_dialog .cke_dialog_ui_hbox_last select.cke_dialog_ui_input_select{
  background: none !important;
}