@charset "UTF-8";

// SETTINGS

$direction: rtl;
$use-bootstrap-reboot: true;
$global-css-seudo-rules: false;

$global-unit: px;

$enable-flex: true;
$use-fallbacks: false;
$grid-use-x-margs: true;
$grid-spread-cells: false;
$grid-cell-use-minmax: false;
// $grid-row-class: '.rw,.row';

$modal-open-insist-on-scroll: true;
$modal-open-use-scroll-class: false;

$use-page-min-width: true;
$use-page-max-width: false;

$content-pages-center-content: true;
$content-pages-max-width: 785px;
$content-pages-max-width-2-cols: 1015px;

$breakout-menu: false;
$outstretch-menu: true;
$animate-planner-activity-boxes: false;
$make-paddings-bigger-on-big-screens: true;
$adjust-layouts-for-bigger-screens: true;

$use-native-scroll: true;
$put-simplebar-outside: true;

$show-pager-on-small-desktops: false;

$do-mock: true;
$show-debug: false;




/* FUNCTIONS & MIXINS */

@import "pavelgmixins/mixins";


// ASSETS

@import "assets/fonts";
@import "assets/colors";
@import "assets/icons";
@import "assets/images-badges";
@import "assets/images-emoji";
@import "assets/images-logos";
// @import "assets/images-logos-inline";
// @import "assets/images-avatars";
@import "assets/images-ropes";
@import "assets/images";
@import "assets/keyframes";




// VARIABLES (including custom Bootstrap variables)


@import "variables";


// EXTERNAL




// GENERAL

@import "mixins-local";
@import "general";


// ELEMENTS

@import "elements/links";
@import "elements/buttons";
@import "elements/pager";
@import "elements/form-inputs";
@import "elements/userpic";
@import "elements/loader";
@import "elements/simplebar";
@import "elements/file-upload";
@import 'elements/swiper';
@import 'elements/dragula';
@import 'elements/video';
@import 'elements/tab-panels';
@import 'elements/comments';
@import 'elements/efi-game';

// PARTIALS

@import "partials/common";
@import "partials/main";
@import "partials/header";
@import "partials/footer";
@import "partials/main-nav";
@import "partials/modal";
@import "partials/calendar";
@import "partials/category-badges";
@import "partials/datepicker-common";
@import "partials/inline-datepicker";
@import "partials/modal-datepicker";

@import "partials/modal-create-activity-common";
@import "partials/modal-create-activity-info";
@import "partials/modal-create-activity-edit";
@import "partials/modal-calendar-info";
@import "partials/modal-confirmation";
@import "partials/modal-week-schedule";
@import "partials/modal-userpic-password";
@import "partials/modal-select-guide";
@import "partials/modal-anchor-guides-list";
@import "partials/modal-course-info";

@import "partials/onboarding";

@import "partials/single-activity-planner-view";
@import "partials/meta";
@import "partials/methods-list";

@import "partials/drupal-mods";
@import "partials/ckeditor-mods";


// PAGES

@import "pages/login";
@import "pages/login-faq";
@import "pages/change-password";

@import "pages/attendance";
@import "pages/create_activity";
@import "pages/schedule";
@import "pages/schedule-month-view";
@import "pages/schedule-week-view";
@import "pages/schedule-single-day-planner";

@import "pages/rashgad";
@import "pages/courses";

@import "pages/content-pages";
@import "pages/faq";
@import "pages/about";
@import "pages/introduction";
@import "pages/error-report";
@import "pages/notifications";

@import "pages/404";

@import "pages/magazine";
@import "pages/magazine-section";
@import "pages/notifications-section";

@import "pages/article-page";
@import "pages/basic-page";

@import "pages/search-results";



// LAST BUT NOT LEAST

@import "partials/print";

