// sass-lint:disable-all



// POSITION MIXINS
// by Pavel G
// ----------------------------------------------------------



// HELPER FUNCTIONS
// ----------------------------------------------------------



// "POS" MIXIN
// ----------------------------------------------------------

//  possible syntax:
//  @include pos(abs, top 20px right 15px)  => absolute, top 20px right 15px
//  @include pos(fix, t 20px r 15px)  => fixed, top 20px right 15px
//  @include pos(top 20px right 15px)  => absolute, top 20px right 15px
//  @include pos(t 20 r 15)  => absolute, top 20px right 15px
//  @include pos(t r)  => absolute, top 0 right 0

@mixin pos( $position: abs x 0 y 0, $coordinates: null, $z: null ) {

  $pos: absolute;

  $extracted: extractor($position);

  $e-p: map-get($extracted, position);

  @if ( $e-p ) {
    $pos: $e-p;
  }

  $t: map-get($extracted, top);
  $r: map-get($extracted, right);
  $b: map-get($extracted, bot);
  $l: map-get($extracted, left);

  @if ( $coordinates ) {

    $extracted2: extractor($coordinates);

    $e-p2: map-get($extracted2, position);

    @if ( $e-p2 ) {
      $pos: $e-p2;
    }

    $t: map-get($extracted2, top);
    $r: map-get($extracted2, right);
    $b: map-get($extracted2, bot);
    $l: map-get($extracted2, left);

  }

  // css output
  position: $pos;
  top: $t;
  right: $r;
  bottom: $b;
  left: $l;
  z-index: $z;
}

// even shorter
@mixin ps($args...){
  @include pos($args...);
}

// and longer
@mixin position($args...){
  @include pos($args...);
}


// position fixed

@mixin fix($pos: x y, $do-size: true) {

  position: fixed;

  @if $do-size {
    @if ( $pos == top or $pos == t or $pos == bot or $pos == b or $pos == bottom ) {
      width: 100vw;
      max-width: 100%;
    }
    @if ( $pos == right or $pos == r or $pos == left or $pos == l ) {
      height: 100vh;
    }
  }

  @if ( $pos == top or $pos == t ) {
    $pos: t x;
  }
  @if ( $pos == bot or $pos == b or $pos == bottom ) {
    $pos: b x;
  }
  @if ( $pos == left or $pos == l ) {
    $pos: l y;
  }
  @if ( $pos == right or $pos == r ) {
    $pos: r y;
  }

  $extracted: extractor($pos);

  top: map-get($extracted, top);
  right: map-get($extracted, right);
  bottom: map-get($extracted, bot);
  left: map-get($extracted, left);

  @content;
}

// and longer version

@mixin position-fixed($args...){
  @include fix($args...);
}



// z-index nth-child reordering

@mixin nth-zindex($indx:1 2 3 4) {
  @for $i from 1 through length($indx) {
    &:nth-child(#{$i}) {
      z-index: nth($indx, $i);
    }
  }
}
@mixin child-zindex($args...) {
  @include nth-zindex($args...);
}