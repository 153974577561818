// sass-lint:disable-all


// BACKGROUND-RELATED
// ----------------------------------------------------------



%bg-pos-center-cover {
  background-position: center;
  background-size: cover;
}
%bg-pos-center-contain {
  background-position: center;
  background-size: contain;
}





@mixin bg-img-cover($file: null, $position: center center, $size: cover, $repeat: no-repeat, $attach: fixed, $bg-color:null, $opacity:null) {
  @include bg-image($file: $file, $position: $position, $attach: $attach);
}

@mixin bg-image($file: null, $position: center center, $size: cover, $repeat: no-repeat, $attach: null, $bg-color: null, $opacity: null, $use: null, $pos: rel) {
  $elem: '&';
  @if $opacity { $elem: '&:before'; }
  @if $use { $elem: '&:#{$use}'; }
  @if ($pos == rel) { $pos: relative;}
  @else if ($pos == abs) { $pos: absolute; }
  @else if ($pos == keep) { $pos: null; }

  @if ($opacity or $use) {
    position: $pos;
    & > * {
      position: relative;
      z-index: 2;
    }
  }
  #{$elem} {
    background-color: $bg-color;
    @if ($opacity or $use) {
      content: "";
      @include size(100%);
      @include pos(abs);
      opacity: $opacity;
      z-index: 1;
    }
    @if ($file) {
      background-image: url($file);
    }
    background-attachment: $attach;
    background-position: $position;
    background-repeat: no-repeat;
    background-size: $size;
    background-origin: border-box;
  }
}



@mixin bg-overlay(
  $opacity:0.5,
  $color:black,
  $mix: null,
  $use:before,
  $pos:relative,
  $grad:false,
  $dir:bottom,
  $length: 100%,
  $extnd: false,
  $size: 100%,
  $attach: null
) {

  @if not $attach {
    $attach: y
  }

  & //, & > *
  {
    @if $pos { position: #{$pos}; }
    @if $extnd {
      overflow: hidden;
    }
  }

  $elem: $use;

  &:#{$elem} {
    @include pseudo;
    position: absolute;

    @if $extnd {
      @include size(104%);
      @include pos( x -2% #{$attach} -2% );

      left: -2%;
      right: -2%;

      @if ( extractor($attach,$find:top) ){
        top: -2%;
      }
      @if ( extractor($attach,$find:bot) ){
        bottom: -2%;
      }
    } @else {
      width: $size;
      height: $size;
      left: 0;
      right: 0;

      @if ( extractor($attach,$find:top) ){
        top: 0;
      }
      @if ( extractor($attach,$find:bot) ){
        bottom: 0;
      }
    }

    @if $color and (not $grad) {
      background-color: rgba($color, $opacity);
    }

    z-index: 0;
    pointer-events: none;

    @if $color and $grad {
      background-image: linear-gradient( to #{$dir}, rgba($color, $opacity), rgba($color, 0) #{$length} );
    }

    @if $mix {
      mix-blend-mode: $mix;
    }

    @content;
  }

}




// bg color or linear gradient with padding
// use:
// @include bg-with-padding(5px 10px) { background-color: red; }


@mixin bg-with-padding($padding:null) {

  @if $padding {

    $padding: _unpack($padding);
    $padding: check-unit($padding);

    position: relative;

    &:before {
      @include pseudo;
      @include size( calc(100% - #{nth($padding, 2)} - #{nth($padding, 4)} ),  calc(100% - #{nth($padding, 1)} - #{nth($padding, 3)} )  );
      @include pos(abs, #{nth($padding, 1)} null null #{nth($padding, 4)} );
      z-index: 0;

      @content;
    }

  }

}


// linear bg as horizontal line in the middle of element

@mixin strike-line-bg($width: 1px, $color: black) {
  background-image: linear-gradient($color 0%, $color 100%);
  background-size: 100% $width;
  background-position: center;
}