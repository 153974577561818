.modal-backdrop.select-guide-modal {

  @extend %bigger-cmpct-modal;

  .multiple-checkbox-select {
    @include m(b 16);

    @include animate(slide-in-up,0.2s);

    .chack-all-container,
    > .check-all{
      border-bottom: 1px solid #000;
      padding-bottom: 15px;
      margin-bottom: 15px;
      direction: rtl;
      font-weight: 600;

      > span:first-child{
        margin-bottom: 15px;
      }
      > span:last-child{
        margin-bottom: 0;
      }
    }
    $single-option-height: 18px;
    $option-margin-y: 15px;
    $border-width: 1px;
    $padding: 15px;
    @for $i from 1 through 5 {
      &[size="#{$i}"] {
        height: ($single-option-height * $i + $option-margin-y * ($i - 1) + $padding * 2 + $border-width * 2) + 50;
      }
    }
    &[size="0"]{
      .chack-all-container{
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }



}

.modal-backdrop.share-schedule-with{
  .modal-box{
    max-width: 600px;

    .multiple-checkbox-select input[type=checkbox]:not(#burger-control) + label span{
      overflow: visible;
      white-space: normal;
      text-overflow: inherit;
    }
  }
}