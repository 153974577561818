// // magazine section

.magazine-showcase {

  @extend %magazine-section-striped-bg;

  @include padding(t 38 b 50);

  .wrap{
    z-index: 2;

    @include mobile {
      padding: 0;
    }
  }

  .section-title {
    @include flex-spread;
    font-size: rem(30);
    font-weight: normal;
    @include margin(b 24);
    max-width: 1260px;

    .button {
      @include outline-button($charcoal, $bg:$light-grey-3);
      @include padding(x 15);
      font-size: rem(16);
      font-weight: 600;

      &:hover, &:focus {
        color: inherit;
      }
    }
  }

  .magazine-list {
    @include desktop {
      display: flex;
      justify-content: flex-start;
    }
  }

  .magazine-item {
    @include desktop {
      max-width: 300px;

      @include m(b 0);

      &:first-of-type {
        @include m(t 0);
      }
      &:last-of-type {
        @include m(b 0);
      }
    }
  }



  .item-wrapper {
    position: relative;

    .click-catcher {
      @include size;
      @include position;
      z-index: 0;
    }
  }
  .content-wrapper, .item-img {
    position: relative;
    pointer-events: none;
    cursor: pointer;
  }
  .content-wrapper {
      a {
          pointer-events: all;
          position: relative;
          z-index: 5;
          text-decoration: none;

          &:not(.button) {
              &:hover, &:focus {
                text-decoration: none;
              }
          }
      }
  }

  .item-title {
    color: $off-white;
    font-size: rem(24);
    line-height: 1.1;
    background-color: black(0.3);
    text-shadow: 0 0 6px black(0.3);

    @include size;
    @include position;
    @include flex-align(b);
    @include p(x 18 b 15);

    span {
      display: block;
      min-height: 2.17em;
    }
  }

  .item-img {
    @include ratio(240/280);
    background-position: center top;
    background-size: cover;
    @include margin(b 16);

    @include mobile {
      @include ratio(274/180);
    }
  }



  .item-details {
    @include margin(b 10);
  }

  .details-date  {
    padding: 0;

    &:after {
      display: none;
    }

    @include desktop {
      @include media("<=1350px"){
        flex-wrap: wrap;
      }
    }
  }

  .day-name, .date {
    color: black;

    @include desktop {
      font-weight: 600;
    }
  }

  .day-name {
    &:after {
      content: '-';
      display: inline-block;
      vertical-align: middle;
      @include margin(l 3);
    }
  }

  .j-date {

    @include desktop {
      @include media("<=1500px"){
        letter-spacing: -0.5px;
      }
      @include media("<=1350px"){
        width: 100%;
      }
    }

    @include mobile {
      opacity: 0.7;
    }
  }

  .item-content {
    & > p {
      line-height: 1.35;
      max-height: 4em;
      overflow: hidden;
    }
  }

  .content-wrapper .read-more {
    color: black;
    font-weight: 600;
    @include margin(t 10);
    font-size: rem(15);

    text-decoration: underline;
    &:hover, &:focus {
      opacity: 0.75;
    }
  }

  &:not(.related-articles) {

    .details-comment, .details-seen {
      display: none;
    }

    .magazine-list {
      @include desktop {
        @include grid(4, 20, 20, '.magazine-item');
      }
    }

    .magazine-item {
      @include after(4){
        display: none;
      }
    }

    .details-date {
      &:before {
        display: none;
      }
    }

    .date {
      padding: 0;
      @include mobile {
        @include details-limitter;
      }
      @include desktop {
        @include media("<=1350px"){
          &:after{
            display: none;
          }
        }
      }
    }
  }



  // related articles

  &.related-articles {

    // layout

    .magazine-list {
      @include desktop {
        @include grid(3, 20, 20, '.magazine-item');
      }
    }

    .magazine-item {
      @include after(3){
        display: none;
      }
    }

    &:before {
      height: 100%;
    }

    @include desktop {
      &:before {
        bottom: 0;
        right: -50vw;
        width: 200vw;
      }
    }

    // items

    .item-author, .item-details {
      @include margin(b 8);
    }

    .date {
      &:after {
        @include mobile {
          display: none;
        }
      }
    }

    .details-date  {
      padding: 0;

      @include desktop {
        &:before {
          display: none;
        }
        &:before {
          display: none;
        }
      }

      @include mobile {
        @include details-limitter;
      }
    }

  }

}
