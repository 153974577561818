// Calendar

$calendar-border-width: 1px;

@mixin calendar-compactview {
  text-align: center;

  .day-names {
    margin-bottom: 10px;

    li {
      text-align: center;
      @include text-hide;

      &:before {
        content: attr(data-weekday-short);
        font-size: rem(12.8);
        line-height: 1;
        font-weight: 600;
        font-family: $font-family-base;
        color: $black;
      }
    }
  }

  .day {
    min-height: 0;
    padding: 5px;
    text-align: center;

    span{
      @include p(x 0);
      @include flex-align;
    }

    &.passed {
      & > span{

        &:after,
        &:before{
          width: 100%;
        }
      }
    }
  }

  .date-and-time {

    display: block;

    .date, .week-day {
      display: block;
    }

    .week-day {
      text-align: center;
      font-size: rem(13);
      letter-spacing: -0.3px;
    }
  }

  .activity-types {
    text-align: center;
  }

} // compactview


// calendar layout

.zofim-calendar {

  &, .day-names, .days, .weeks, .week {
    width: 100%;
  }

  .week, .day {
    display: block;
  }

  .day-names, .days {
    @include grid-row('.day');

    & > li {
      @include grid-cell(7,0,$minmax:true);
    }
  }

  .day {
    @include ratio(116,110);
    max-height: 10vh;
    padding: 10px;
    min-height: 100px;
  }


  // borders

  .weeks {
    border: 1px solid $light-grey-3;
  }

  .week {
    @include not-last{
      border-bottom: 1px solid $light-grey-3;
    }
  }

  .day {
    @include not-last{
      border-left: 1px solid $light-grey-3;
    }
  }

}


// calendar style

.zofim-calendar {

  user-select: none;

  .day-names {
    font-size: rem(18);
    font-weight: 600;
    @include m(b 25);
  }

  .day {
    position: relative;
    cursor: pointer;

    & > span {
      @include s;
      @include ps;
      @include flex-spread(y, true);
      padding: inherit;
    }

    &:hover {
      background-color: black(0.02);
    }

    &.not-this-month {
      .date-and-time,.activity-types {
        opacity: 0.4;
      }
    }

    &.empty {
      .activity-types{
        display: none;
      }
    }

    &.selected {
      background-color: $brown-grey;
      border-color: $brown-grey;
      box-shadow: 1px 0 0 0 $brown-grey;

      &, .week-day{
        color: white;
      }
    }

    &.passed {
      cursor: not-allowed;

      &:hover {
        background-color: white;
      }

      & > span{
        overflow:hidden;

        &:after,
        &:before
        {
          @include pseudo;
          @include s(60%, 1px);
          background-color: darken($light-grey-3,10%);
          @include ps(t 50% l 50%);
          mix-blend-mode: multiply;
        }
        &:after{
          transform: translate(-50%, -50%) rotate(45deg);
        }
        &:before{
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }

  }

  .date-and-time, .bottom-part{
    width: 100%;
    .holiday{
      font-size: 14px;
    }
  }

  .date-and-time {
    font-size: rem(14);
    font-weight: 400;
    margin: 0;

    @include flex-spread;

    .month,.year {
      @include visually-hidden;
    }

    .week-day {
      color: $brown-grey;
      text-align: left;
      align-self: flex-end;
    }

    @include media("<=mobile"){
      flex-grow: 10;
      @include m(b 5);
    }

    @include media("<=small-mobile"){
      margin: 0;
    }

  }

  .bottom-part {

  }

  .length {
    margin: 0;
    font-size: rem(15);
    font-weight: 300;
  }

  @include media("<=mobile"){
    @include calendar-compactview;

    .length {
      display: none;
    }
  }


  // colored dots

  $ball-marg: 2.5%;
  $ball-width: floor((100% - $ball-marg * 6) / 7);

  .activity-types {

    font-size: 0px;
    display: flex;
    justify-content: flex-start;

    @include media("<=mobile"){
      justify-content: center;
    }

    li {
      display: block;
      @include text-hide;
      border-radius: 50%;
      @include ratio(1);
      width: $ball-width;
      max-width: 9px;

      @include not-last {
        @include m(l $ball-marg);
      }

      @include media(">mobile"){
        @include m(t 3);
      }

      @include media("<=mobile"){
        @include m(t 1);
      }

      // only show first 7

      @include after(7){
        display: none;
      }
      // or 6
      @include media("<=small-mobile"){
        @include after(6){
          display: none;
        }
      }


      // default bg color
      background-color: $brown-grey;

      // mock colors
      @if $do-mock {
        @include assign-colors($target: '&', $props: 'background-color');
      }

      @if not $do-mock {
        &:empty {
          display: none;
        }
      }
    }
  }

}

