// sass-lint:disable-all

//    NTH-CHILD MIXINS
//    https://lukyvj.github.io/family.scss/

// additions by Pavel G


@mixin each-child($n:10,$item:'& >'){

  $from: 1;
  $to: $n;

  @if ( is-list($n) and length($n) == 2 ){
    $from: nth($n,1);
    $to: nth($n,2);
  }

  @for $i from $from through $to {
    #{$item}:nth-child(#{$i}) {
      @content;
    }
  }
}


/// @content [Write the style you want to apply to the children, and it will be added within the @content directive]

@mixin nth($items) {
  @each $item in $items {
    &:nth-child(#{$item}) {
      @content;
    }
  }
}

@mixin nth-type($items) {
  @each $item in $items {
    &:nth-of-type(#{$item}) {
      @content;
    }
  }
}


//                          WITH ARGUMENTS

/// Select all children from the first to `$num`.
/// @param {number} $num - id of the child
@mixin first($num:1) {
  @if $num == 1 {
    //&:first-child {
    &:first-child,&:first-of-type {
      @content;
    }
  } @else {
    &:nth-child(-n + #{$num}) {
      @content;
    }
  }
}

@mixin first-type($num:1) {
  @if $num == 1 {
    //&:first-child {
   &:first-of-type {
      @content;
    }
  } @else {
    &:nth-of-type(-n + #{$num}) {
      @content;
    }
  }
}


/// Select all children from the last to `$num`.
/// @param {number} $num - id of the child
@mixin last($num:1) {
  @if $num == 1 {
    //&:last-child
    &:last-child,&:last-of-type {
      @content;
    }
  } @else {
    &:nth-last-child(-n + #{$num}) {
      @content;
    }
  }
}


/// Select all children after the first to `$num`.
/// @param {number} $num - id of the child
@mixin after-first($num) {
  &:nth-child(n + #{$num + 1}) {
    @content;
  }
}

// same
@mixin after($num) {
  &:nth-child(n + #{$num + 1}) {
    @content;
  }
}


/// Select all children before `$num` from the last.
/// @param {number} $num - id of the child
@mixin from-end($num) {
  &:nth-last-child(#{$num}) {
    @content;
  }
}


/// Select all children between `$first` and `$last`.
@mixin between($first, $last) {
  &:nth-child(n + #{$first}):nth-child(-n + #{$last}) {
    @content;
  }
}

@mixin between-type($first, $last) {
  &:nth-of-type(n + #{$first}):nth-of-type(-n + #{$last}) {
    @content;
  }
}


/// Select all even children between `$first` and `$last`.
@mixin even-between($first, $last) {
  &:nth-child(even):nth-child(n + #{$first}):nth-child(-n + #{$last}) {
    @content;
  }
}


/// Select all odd children between `$first` and `$last`.
@mixin odd-between($first, $last) {
  &:nth-child(odd):nth-child(n + #{$first}):nth-child(-n + #{$last}) {
    @content;
  }
}


/// Select all `$num` children between `$first` and `$last`.
@mixin n-between($num, $first, $last) {
  &:nth-child(#{$num}n):nth-child(n + #{$first}):nth-child(-n + #{$last}) {
    @content;
  }
}


/// Select all children but `$num`.
/// @param {number} $num - id of the child
@mixin all-but($num) {
  &:not(:nth-child(#{$num})) {
    @content;
  }
}


/// Select children each `$num`.
/// @param {number} $num - id of the child
/// @alias every
@mixin each($num) {
  &:nth-child(#{$num}n) {
    @content;
  }
}


/// Select children each `$num`.
/// @param {number} $num - id of the child
@mixin every($num,$step:null) {
  @if $step {
    &:nth-child(#{$step}n+#{$num}) {
      @content;
    }
  } @else {
    &:nth-child(#{$num}n) {
      @content;
    }
  }
}


/// Select the `$num` child from the start and the `$num` child from the last.
/// @param {number} $num - id of the child
@mixin from-first-last($num) {
  &:nth-child(#{$num}),
  &:nth-last-child(#{$num}) {
    @content;
  }
}


/// Select the item in the middle of `$num` child. Only works with odd number
/// chain.
/// @param {number} $num - id of the child
@mixin middle($num) {
  &:nth-child(#{round($num / 2)}) {
    @content;
  }
}


/// Select all children between the `$num` first and the `$num` last.
/// @param {number} $num - id of the child
@mixin all-but-first-last($num) {
  &:nth-child(n + #{$num}):nth-last-child(n + #{$num}) {
    @content;
  }
}

//                          QUANTITY QUERIES


/// This quantity-query mixin will only select the first of `$limit` items. It will not
/// work if there is not as much as item as you set in `$limit`.
/// @param {number} $limit
@mixin first-of($limit) {
  &:nth-last-child(#{$limit}):first-child {
    @content;
  }
}


/// This quantity-query mixin will only select the last of `$limit` items. It will not
/// if there is not as much as item as you set in `$limit`.
/// @param {number} $limit
@mixin last-of($limit) {
  &:nth-of-type(#{$limit}):nth-last-of-type(1) {
    @content;
  }
}


/// This quantity-query mixin will select every items if there is at least `$num` items. It will not
/// if there is not as much as item as you set in `$num`.
/// @param {number} $limit
@mixin at-least($num) {
  $selector: &;
  $child: nth(nth($selector, -1), -1);

  &:nth-last-child(n + #{$num}),
  &:nth-last-child(n + #{$num}) ~ #{$child} {
    @content;
  }
}


/// This quantity-query mixin will select every items if there is at most `$num` items. It will not
/// if there is not as much as item as you set in `$num`.
/// @param {number} $limit
@mixin at-most($num) {
  $selector: &;
  $child: nth(nth($selector, -1), -1);

  &:nth-last-child(-n + #{$num}):first-child,
  &:nth-last-child(-n + #{$num}):first-child ~ #{$child} {
    @content;
  }
}


/// This quantity-query mixin will select every items only if there is between `$min` and `$max` items.
/// @param {number} $limit
@mixin in-between($min, $max) {
  $selector: &;
  $child: nth(nth($selector, -1), -1);

  &:nth-last-child(n + #{$min}):nth-last-child(-n + #{$max}):first-child,
  &:nth-last-child(n + #{$min}):nth-last-child(-n + #{$max}):first-child ~ #{$child} {
    @content;
  }
}


//                          NO ARGUMENTS

/// Select the first exact child
@mixin first-child() {
  &:first-of-type {
    @content;
  }
}


/// Select the last exact child
@mixin last-child() {
  //&:last-of-type {
  &:last-child {
    @content;
  }
}

@mixin last-type() {
  &:last-of-type {
    @content;
  }
}

@mixin not-first() {
  &:not(:first-child):not(:first-of-type) {
    @content;
  }
}

@mixin not-first-child() {
  &:not(:first-child){
    @content;
  }
}

@mixin not-last() {
  &:not(:last-child):not(:last-of-type) {
    @content;
  }
}

@mixin not-last-child() {
  &:not(:last-child) {
    @content;
  }
}


@mixin not-only {
  &:not(:only-of-type) {
    @content;
  }
}

// last but not only
@mixin last-not-only {
  &:last-of-type:not(:only-of-type) {
    @content;
  }
}

/// Select all even children.
@mixin even() {
  &:nth-child(even) {
    @content;
  }
}


/// Select all odd children.
@mixin odd() {
  &:nth-child(odd) {
    @content;
  }
}


/// Select only the first and last child.
@mixin first-last() {
  &:first-child,
  &:last-child {
    @content;
  }
}

@mixin not-first-last() {
  &:not(:first-child):not(:last-child) {
    @content;
  }
}


/// Will only select the child if it’s unique.
/// @alias only
@mixin unique() {
  &:only-child {
    @content;
  }
}


/// Will only select the child if it’s unique.
@mixin only() {
  &:only-child {
    @content;
  }
}


/// Will only select children if they are not unique. Meaning if there is at
/// least 2 children, the style is applied.
@mixin not-unique() {
  &:not(:only-child) {
    @content;
  }
}

//-------------------------------------------------------------

/// This mixin is used to automatically sort z-index in numerical order. But it
/// can also sort them in anti-numerical order, depending the parameters you use.
/// @group using functions

/// @param {number} $num - Number of children
/// @param {string} $direction [forward] - Direction of the sort
/// @param {number} $index [0] - Index of the sorting
@mixin child-index($num, $direction: 'forward', $index: 0) {
  @for $i from 1 through $num {
    @if ($direction == 'forward') {
      &:nth-child(#{$i}) {
        z-index: order-index($i, $index);
        @content;
      }
    } @else if ($direction == 'backward') {
      &:nth-last-child(#{$i}) {
        z-index: order-index($i, $index);
        @content;
      }
    }
  }
}

/// Used by the child-index mixin. It will returned the proper sorted numbers
/// depending on the `$index` value.
/// @access private
/// @param {number} $num - Number of children
/// @param {number} $index - Index of the sorting
@function order-index($i, $index) {
  @return ($index + $i);
}
