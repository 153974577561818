

.activity-info-modal,
.activity-edit-modal
{

  .close-modal {
    @include close-button-color(wht);
  }

  .modal-header {

    // header imitation
    background-image: linear-gradient(to bottom, #{$greyish-brown} 0%, #{$greyish-brown} 100%);
    background-size: 100% $header-height;
    background-position: center top;

    .actions {
      height: $header-height;
      @include p(t 20);
    }

    .link-button.clear {
      @include m(l auto);
    }

    h2 {
      font-size: rem(30);
      font-weight: 400;
      line-height: 1.1;
    }

  }


}