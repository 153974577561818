

.schedule .month-view
{

  display: flex;

  @include mobile{
    flex-wrap: wrap;
  }

  $use-height-limiters: true;

  // layout

  .zofim-calendar {
    @include desktop{
      width: calc(100% - #{$activity-box-width + 30px + $day-planner-scroll-offset});
    }

    @if $adjust-layouts-for-bigger-screens{
      @include media(">huge"){
        max-width: 1000px;
        @include m(x auto);
      }
    }
  }

  .single-day {
    @include desktop{
      width: $activity-box-width + 30px + $day-planner-scroll-offset;
      @include p(r 30);

      display: flex;
      flex-direction: column;
    }
  }

  .single-day-planner {
    @include desktop{
      max-width: $activity-box-width + $day-planner-scroll-offset;

      // height: 100%;
    }
  }

  .pager {
    @include media(">1200px"){
      left: $activity-box-width + 30px + $day-planner-scroll-offset;
    }
    @include mobile{
      @include m(b 15);
    }
  }





  // mobile

  @include mobile{

    .zofim-calendar {
      .date-and-time{
        line-height: 1;
      }
    }

    .single-day {
      display: none;
    }
  }


  // misc

  .no-day-selected {

    $top: 49px;

    background-color: $light-grey; //$orangeish;
    padding: 8px 10px;
    text-align: center;
    color: $greyish-brown; //white;
    line-height: 1.1;
    @include m(t $top);
    @include flex-align;
    user-select: none;

    // height: calc(100% - #{$top});
    flex-grow: 5;
  }

}