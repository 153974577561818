// main menu


%menu-item-active {
  background-color: $light-grey-2;
  font-weight: 600;
  letter-spacing: -0.5px;

  @include desktop{
    @if $outstretch-menu{
      @include outstretch-bg(right, $light-grey-2);
    }
  }
}

.main-nav {
  $border-color: rgb(222,222,213);

  font-size: rem(20);
  position: relative;

  @include desktop{
    z-index: 2;
  }

  @if $breakout-menu{
    @include desktop {
      @include breakout(r $boxpad, false, true);
    }
  }

  @include media(">#{$switch-to-mobile}","<=desktop") {
    @include breakout(r $boxpad, false, true);
  }

  @include desktop{
    background-color: $light-grey-3;
    min-width: 205px;

    @include outstretch-bg(right, $light-grey-3);
  }

  #burger-control,
  .burger-button-wrap {
    @include desktop{
      display: none;
    }
  }

  & > .menu-wrap .menu-holder > .menu,
  .main-menu {

    li {
      margin: 0;
      padding: 0;
      &.hide{
        display: none;
      }

    }
  }

  li {
    display: block;
    position: relative;
    border-bottom: 1px solid $border-color;
    cursor: pointer;

    @include desktop{
      @if $outstretch-menu{
        @include outstretch-border(bottom right, $border-color){
          margin-bottom: -1px;
        }
      }
    }

    a,label {
      color: inherit;
      text-decoration: none;
      display: block;
      min-height: 85px;
      @include flex-align(y);
      @include p(l 20 r 28);
      transition: all 0.3s;
      margin: 0;
      justify-content: space-between;

      @include ie{
        height: 1px;
      }
    }

    // active

    &.active,
    &:hover,
    &:focus {
      @extend %menu-item-active;
      text-align: right;

      &.with-submenu {
        .menu {
          background-color: $light-grey-3;
          font-weight: 400;

          @include desktop{
            @if $outstretch-menu{
              @include outstretch-bg(right, $light-grey-3);
            }
          }
        }
      }
    }

    &.with-submenu {
      a.active {
        @extend %menu-item-active;
      }
    }

  }

  .menu-holder .counter {
    background-color: $light-grey-2;
    display: inline-block;
    font-size: rem(15);
    color: $charcoal;
    font-weight: bold;
    text-align: center;
    height: 38px;
    line-height: 38px;
    @include p(x 10);
  }


  // submenu

  .with-submenu {
    @include collapsible(
      $use-class: 'submenu-open',
      $target: '.menu',
      $h: 500px,
      $op: true
    );

    & > a,
    label {
      @extend %icn_ar_down-chrk;
      background-size: 15px auto;
      background-position: 28px 50%;
    }

    &.submenu-open > a,
    .collapse-control:checked + label
    {
      @extend %icn_ar_up-chrk;
      font-weight: 600;
    }
  }

  .with-submenu > .menu,
  .submenu  {
    font-size: rem(16);
    border-top: 1px solid #deded5;

    a,label{
      min-height: 75px;
    }

    li {
      @include last{
        border: 0;
      }
    }
  }



  // mobile view

  $sidebar-off-state: '#burger-control:not(:checked) ~ .menu-wrap';
  $sidebar-on-state: '#burger-control:checked ~ .menu-wrap';

  $sidebar-width: 300px;
  $sidebar-width-small: 250px;

  @include mobile{

    @include slideout-sidebar(
      $target: '& > .menu-wrap',
      $loc: right,
      $width: $sidebar-width,
      $bg-color: $light-grey-3,
      $burg-color: white,
      $burg-width: 20px,
      $burg-line-height: 2px,
      $burg-line-gutter: 4px,
      $burg-line-brdr-rad: 2px,
      $burg-pos-top: 17px,
      $burg-pos-left: 30px,
      $burg-wrap-pad: 10px,
      $scrollable: true
      // $burg-pos-right: -40px,
      // $burg-on-mods: true,
      // $burg-on-mod-color: black
    );

    & > .menu-wrap,
    & > .menu-wrap .menu-holder > .menu,
    .main-menu {

      padding-right: 0;

      li a, li label{
        min-height: 55px;
        padding-right: 28px + 35px;
      }

      &:after {
        @include pseudo;
        @include s;
        @include ps;
        background-color: inherit;
        z-index: 2;
      }

      &:before{
        @include pseudo;
        @include s;
        @include ps(fix);
        background-color: black(0.8);
        z-index: 1;
        transition: all 0.4s;
        transition-delay: 0.3s;
        opacity: 0;
      }

      li {
        z-index: 5;

        &:not(.active){
          background-color: inherit;
        }
      }
    }

    .burger-button {
      // display: inline-block;
      @include media("<=mobile"){
        @include m(x auto);
      }
    }

    #burger-control:not(:checked){
      & ~ .menu-wrap,
      & ~ .main-menu {
        z-index: -1;
      }
    }

    #burger-control:checked {

      & + label.burger-button-wrap {
        width: calc(100vw - #{$sidebar-width});
        height: 100vh;
        padding-top: 27px;
        right: $sidebar-width;
        left: 0;
        top: 0;
        bottom: 0;

        .burger-button {
          @include media(">mobile","<=#{$switch-to-mobile}"){
            @include m(r 25);
          }
        }
      }

      & ~ .menu-wrap,
      & ~ .main-menu {
        &:before {
          opacity: 1;
        }
      }

    }

  }

  .burger-button-wrap {
    @include media(">mobile","<=#{$switch-to-mobile}"){
      text-align: right;
    }

    @include media("<=mobile"){
      text-align: center;
      right: 10px;
    }
  }


  // smaller menu on small mobiles

  @include media("<=small-mobile"){

    @include modify-sidebar-size(
      $target: '& > .menu-wrap',
      $width: $sidebar-width-small,
      $loc: right,
      $burg-width: 20px,
      $burg-pos-left: 20px,
      $burg-pos-right: -40px,
      $burg-wrap-pad: 10px
    );

    .burger-button-wrap{
      top: 14px;
    }

    #burger-control:checked {
      & + label.burger-button-wrap {
        width: calc(100vw - #{$sidebar-width-small});
        padding-top: 24px;
        right: $sidebar-width-small;
      }
    }

  }


}
