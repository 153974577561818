.modal-backdrop.course-info-modal {


  .close-modal {
    @include close-button-color(blk);
  }

  .simplebar-scroll {
    // margin-bottom: 0;
  }

  // header

  .modal-header {
    @include m(b 20);

    span {
      display: block;
      @include p(b 20);
      border-bottom: 2px solid $light-grey-2;
    }
  }

  h2 {
    font-size: rem(30);
    font-weight: 400;
    margin: 0;
  }

  //

  .modal-box {
    .wrap {
      @include p(t 30);
    }
  }


  // scroll

  .simplebar-scroll {
    @include desktop{
      max-height: $default-simplebar-scroll-window-height;
      min-height: 415px;

      @if $use-native-scroll {
        @include simplebar-native-scroll('.content', $h:auto, $p:t 0 b 0 r 0, $cp: 15, $sp:-30); // added $cp:15, $sp:-30
      } @else {
        @if $put-simplebar-outside{
          @include simplebar-outside($t:'&'); //(20px);
        }
      }

    }
  }

}