// sass-lint:disable-all


// HELPER FUNCTIONS:
// NUMBERS
//---------------------------------


/// Power function
/// @param {Number} $x
/// @param {Number} $n
/// @return {Number}
@function pow($x, $n) {
  $ret: 1;

  @if $n >= 0 {
    @for $i from 1 through $n {
      $ret: $ret * $x;
    }
  } @else {
    @for $i from $n to 0 {
      $ret: $ret / $x;
    }
  }

  @return $ret;
}


/// toFixed() function in Sass
/// @author Hugo Giraudel
/// @param {Number} $float - Number to format
/// @param {Number} $digits [2] - Number of digits to leave
/// @return {Number}
@function to-fixed($float, $digits: 2) {
  $sass-precision: 5;

  @if $digits > $sass-precision {
    @warn "Sass sets default precision to #{$sass-precision} digits, and there is no way to change that for now."
    + "The returned number will have #{$sass-precision} digits, even if you asked for `#{$digits}`."
    + "See https://github.com/sass/sass/issues/1122 for further informations.";
  }

  $pow: pow(10, $digits);
  @return round($float * $pow) / $pow;
}



  // decimal-round(0.666)    => 1
  // decimal-round(0.666, 1) => 0.7
  // decimal-round(0.666, 2) => 0.67
  // decimal-round(0.666, 2, floor) => 0.66

@function decimal-round ($number, $digits: 0, $mode: round) {
    $n: 1;
    // $number must be a number
    @if type-of($number) != number {
        @warn '#{ $number } is not a number.';
        @return $number;
    }
    // $digits must be a unitless number
    @if type-of($digits) != number {
        @warn '#{ $digits } is not a number.';
        @return $number;
    } @else if not unitless($digits) {
        @warn '#{ $digits } has a unit.';
        @return $number;
    }
    @if $digits > 0 {
        @for $i from 1 through $digits {
            $n: $n * 10;
        }
    }
    @if $mode == round {
        @return round($number * $n) / $n;
    } @else if $mode == ceil {
        @return ceil($number * $n) / $n;
    } @else if $mode == floor {
        @return floor($number * $n) / $n;
    } @else {
        @warn '#{ $mode } is undefined keyword.';
        @return $number;
    }
}

@function random-polarity($number) {
    $dice: random(100);

    @if ($dice > 50) {
        @return $number;
    }

    @return -$number;
}

// square root

@function sqrt($r) {
    $x0: 1;
    $x1: $x0;

    @for $i from 1 through 10 {
      $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0);
      $x0: $x1;
    }

    @return $x1;
  }