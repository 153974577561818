
.weekly-modal.schedule {

  .close-modal {
    @include close-button-color(blk);
  }


  // header

  .modal-header {
    @include m(b 25);
  }

  h2 {
    font-size: rem(30);
    font-weight: 400;
  }


  // min-width: $body-min-width;

  .modal-box {

    max-width: 1200px;

    .wrap {
      overflow: hidden;
      @include p(t 40);

      & > span {
        display: block;
      }

      @include media(">huge"){
        @include p(x $huge-screen-pad-x);
      }
    }

  }


  .pager {
    width: 90px;
    @include ps(t 0 r 215);
  }

  .week-view .days-list {
    &:after{
      display: none!important;
    }
  }

  // no pointer cursor because no info popup

  .single-activity.planner-view{
    cursor: default;
  }



  // scroll

  .simplebar-scroll {
    max-height: $default-simplebar-scroll-window-height;
    min-height: 415px;

    @if $use-native-scroll {
      @include simplebar-native-scroll('.week-view');
    } @else {
      @if $put-simplebar-outside{
        @include simplebar-outside($t:'&');
      }
    }

  }

}