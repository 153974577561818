

.schedule {

  .single-day-planner {
    @include animate(fade-in,0.5s);

    &, &.activities-list {
      display: flex;
      flex-direction: column;
      flex-grow: 10;
    }

    white-space: normal;
    max-width: $activity-box-width;
    @include p(b 5);

    @include not-last{
      @include m(l 15);
    }

    header {
      height: $day-header-height;
      position: relative;

      & > span{
        display: block;
        position: relative;
      }

      .date-and-time{
        font-size: inherit;
        font-weight: 400;
        margin: 0;
      }

      p.date,.j-date,.time {
        margin: 0;
        // @include truncate-text;
      }

      p.date {
        font-size: rem(18);
        font-weight: 600;
      }

      .j-date, .time {
        color: black(0.7);
      }

      .schedule-title{
        font-size: rem(24);
        font-weight: 600;
        min-height: 32px;
      }
      .shared-by{
        font-weight: bold;
        font-size: 14px;
        min-height: 18px;
      }
    }
    .has-comment{
      border:3.9px solid #ff2600;
      border-radius:50%;
      display:inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 7px;
      height:30px;
      width:30px;
      margin-bottom: -5px;
      line-height: 0;
      margin-top: -2px;

      @media (max-width:767px){
        height: 25px;
        width: 25px;
        margin-bottom: -4px;
        line-height: 0;
        margin-top: 0px;
        position: relative;
        top: -3px;
      }

      span{
        background: #ff2600;
        border-radius:50%;
        display:inline-block;
        height:10px;
        width:10px;

        @media (max-width:767px){
          height:7px;
          width:7px;
        }
      }
    }

    // tooltip

    .attachments{

      $tooltip-element: "& + .attachments-box";
      $tooltip-arrow-element: "& + .attachments-box:after";

      &:focus {
        outline: 0;
      }

      // @include m(t -22);
      text-align: left;
      position: relative;
      width: 100%;
      @include ps(l 0 b 0);

      $tooltip-offset: 20px; //15px;

      .attachments-icon {

        @include s(21,24);
        display: block;
        @include clear-button;
        @include text-hide;
        @extend %icn_attach_lg-grish;
        background-size: 11px auto;
        background-position: center;
        @include m(l -5 b -3 r auto);
        cursor: pointer;
        z-index: 10;

        &:focus {
          outline: 0;
        }

        @include simptip(
          $use: $tooltip-element,
          $put-arrow: $tooltip-arrow-element,
          $width: 100%,
          $position: bottom,
          $padding: 20px,
          $font-size: rem(15),
          $align: right,
          $rounded: false,
          $skip-arrow-pos: true,
          $distance: 4px
        );

        #{$tooltip-arrow-element} {
          display: block;
          top: -13px;
          left: $tooltip-offset;
          visibility: visible;
          opacity: 1;
        }

        #{$tooltip-element}
        {
          left: -1*$tooltip-offset;
          z-index: 20;
          transform: translateX(0);
          pointer-events: all;
          z-index: 5;

          &:hover,&:focus {
            opacity: 1;
            visibility: visible;
            transform: translate(0, 10px);
          }

          &:before {
            @include pseudo;
            @include s(60,20);
            @include ps(l 0 t -20);
          }
        }

        &:hover,&:focus {
          #{$tooltip-element}
          {
            transform: translate(0, 10px);
          }
        }
      }

      // tooltip content

      .attachments-box {
        font-weight: 300;

        &:hover,&:focus {
          opacity: 1;
          visibility: visible;
        }
        em {
          font-weight: 600;
          display: block;
        }
        p {
          @include m(b 0);

          &:not(:first-child){
            @include m(t 10);
          }
        }

        em,p,a {
          &:empty {
            display: none;
          }
        }

        a {
          color: inherit;
          text-decoration: none;

          &:hover {
            .filename{
              text-decoration: underline;
            }
          }
        }
      }

      @include mobile{
        display: none;
      }

    }

    // adjusting for swiper right-most column

    // @at-root {
    //   @include desktop{
    //     .schedule .swiper-slide-active > .single-day-planner {

    //       .attachments .attachments-box {
    //         left: auto;
    //         right: 0;
    //         width: 90%;

    //         &:after {
    //           left: auto;
    //           right: calc(90% - 10px);
    //         }
    //       }
    //     }
    //   }
    // }

    // planner view (inherits from Partials file)

    .single-activity {

      h4 {
        margin: 0;
      }

      .length {
        @include m(t 2);
      }
    }

    // total time

    //& +
    .total-time {
      text-align: center;
      line-height: 1;
      font-size: rem(15);
      @include m(t 10);
      font-weight: 600;
      color: black(0.7);
    }

  }

}