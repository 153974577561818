
// sass-lint:disable-all



@mixin centerer($x: true, $y: true, $top: 50%, $pos:absolute) {
  position: $pos;
  //backface-visibility: hidden;
  @if ($x and $y) {
    top: $top;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($x) {
    left: 50%;
    transform: translateX(-50%);
  } @else if ($y) {
    top: 50%;
    transform: translateY(-50%);
  }
  @content;
}

@mixin centerer-clear($x:true,$y:true) {
  position: relative;
  @if $x {
    left: auto; right: auto;
  }
  @if $y {
    top: auto; bottom: auto;
  }
  transform: none;
}



// CLASSICS

@mixin classic-centerer($w:null,$h:null) {
  @include pos(top 50% left 50%);
  @include m(l $w/-2 t $h/-2);
}

@mixin table-valign($elem: '*',$c:'xy') {
  display: table;
  & > #{$elem} {
    @include s(100%);
    display: table-cell;
    vertical-align: middle;

    @if ($c == 'x') or ($c == 'xy') {
      text-align: center;
    }

    @content;
  }
}


