// LOCAL MIXINS

@mixin max-width($w) {
  width: 100%;
  max-width: $w;
  @include m(x auto);
}

$pad-mob-m: 0.5;
$huge-ignore: 10000px;

@mixin wrap-pads($dir: x, $x: $boxpad, $y: $boxpad, $do-huge:$make-paddings-bigger-on-big-screens, $do-mobile:true, $do-desktop:true){

  @if (not $do-desktop){
    $do-huge: false;
  }

  $huge: $huge-screen;
  @if (not $do-huge) {
    $huge: $huge-ignore;
  }

  @if ( contains($dir, x) ) or ( contains($dir, r) ) {

    @if $do-huge {
      @include media(">#{$huge}"){
        @include p(r $huge-screen-pad-x);
      }
    }

    @if $do-desktop {
      @include media(">mobile","<=#{$huge}") {
        @include p(r $x);
      }
    }

    @if $do-mobile {
      @include media(">small-mobile","<=mobile") {
        @include p(r ($x - 10px));
      }

      @include media("<=small-mobile") {
        @include p(r ceil($x*$pad-mob-m));
      }
    }

  }

  @if ( contains($dir, x) ) or ( contains($dir, l) ) {

    @if $do-huge {
      @include media(">#{$huge}"){
        @include p(l $huge-screen-pad-x);
      }
    }

    @if $do-desktop {
      @include media(">mobile","<=#{$huge}") {
        @include p(l $x);
      }
    }

    @if $do-mobile {
      @include media(">small-mobile","<=mobile") {
        @include p(l ($x - 10px));
      }

      @include media("<=small-mobile") {
        @include p(l ceil($x*$pad-mob-m));
      }
    }

  }

  @if ( contains($dir, y) ) or ( contains($dir, t) ) {

    @include media(">small-mobile") {
      @include p(t $y);
    }

    @if $do-mobile {
      @include media("<=small-mobile") {
        @include p(t ceil($y*$pad-mob-m));
      }
    }

  }

  @if ( contains($dir, y) ) or ( contains($dir, b) ) {

    @include media(">small-mobile") {
      @include p(b $y);
    }

    @if $do-mobile {
      @include media("<=small-mobile") {
        @include p(b ceil($y*$pad-mob-m));
      }
    }
  }

}

@mixin break-out-wrap($dir: x, $x: $boxpad, $y: $boxpad, $do-pads: false, $do-width: true, $do-huge:$make-paddings-bigger-on-big-screens) {

  $huge: $huge-screen;
  @if (not $do-huge) {
    $huge: $huge-ignore;
  }

  // horizontal

  @if ( contains($dir, x) ) or ( contains($dir, l) ) {

    @if $do-huge {
      @include media(">#{$huge}"){
        @include breakout(l $huge-screen-pad-x, $do-pads, $do-width);
      }
    }

    @include media(">mobile","<=#{$huge}") {
      @include breakout(l $x, $do-pads, $do-width);
    }

    @include media(">small-mobile","<=mobile") {
      @include breakout(l ($x - 10px), $do-pads, $do-width);
    }

    @include media("<=small-mobile") {
      @include breakout(l ceil($x*$pad-mob-m), $do-pads, $do-width);
    }

  }

  @if ( contains($dir, x) ) or ( contains($dir, r) ) {

    @if $do-huge {
      @include media(">#{$huge}"){
        @include breakout(r $huge-screen-pad-x, $do-pads, $do-width);
      }
    }

    @include media(">mobile","<=#{$huge}") {
      @include breakout(r $x, $do-pads, $do-width);
    }

    @include media(">small-mobile","<=mobile") {
      @include breakout(r ($x - 10px), $do-pads, $do-width);
    }

    @include media("<=small-mobile") {
      @include breakout(r ceil($x*$pad-mob-m), $do-pads, $do-width);
    }

  }

  // vertical

  @if ( contains($dir, y) ) or ( contains($dir, t) ) {

    @include media(">small-mobile") {
      @include breakout(t $y, $do-pads, $do-width);
    }

    @include media("<=small-mobile") {
      @include breakout(t ceil($y*$pad-mob-m), $do-pads, $do-width);
    }

  }

  @if ( contains($dir, y) ) or ( contains($dir, b) ) {

    @include media(">small-mobile") {
      @include breakout(b $y, $do-pads, $do-width);
    }

    @include media("<=small-mobile") {
      @include breakout(b ceil($y*$pad-mob-m), $do-pads, $do-widthe);
    }

  }

}


@mixin wrap-pos($dir: x, $x: $boxpad, $y: $boxpad, $do-huge:$make-paddings-bigger-on-big-screens, $neg: false){

  $huge: $huge-screen;
  $mult: 1;

  @if (not $do-huge) {
    $huge: $huge-ignore;
  }
  @if $neg {
    $mult: -1;
  }

  @if ( contains($dir, x) ) or  ( contains($dir, r) ) {

    @if $do-huge {
      @include media(">#{$huge}"){
       right: $huge-screen-pad-x*$mult;
      }
    }

    @include media(">mobile","<=#{$huge}") {
     right: $x*$mult;
    }

    @include media(">small-mobile","<=mobile") {
      right: ($x - 10px)*$mult;
    }

    @include media("<=small-mobile") {
      right: ceil($x*$pad-mob-m*$mult);
    }

  }

  @if ( contains($dir, x) ) or  ( contains($dir, l) ) {

    @if $do-huge {
      @include media(">#{$huge}"){
       left: $huge-screen-pad-x*$mult;
      }
    }

    @include media(">mobile","<=#{$huge}") {
      left: $x*$mult;
    }

    @include media(">small-mobile","<=mobile") {
      left: ($x - 10px)*$mult;
    }

    @include media("<=small-mobile") {
      left: ceil($x*$pad-mob-m*$mult);
    }

  }

  @if ( contains($dir, y) ) or ( contains($dir, t) ) {

    @include media(">small-mobile") {
      top: $y*$mult;
    }

    @include media("<=small-mobile") {
      top: ceil($y*$pad-mob-m*$mult);
    }

  }

  @if ( contains($dir, y) ) or ( contains($dir, b) ) {

    @include media(">small-mobile") {
      bottom: $y*$mult;
    }

    @include media("<=small-mobile") {
      bottom: ceil($y*$pad-mob-m*$mult);
    }

  }
}




@mixin overlay-stripes($c:rgb(220,218,216),$bg:false,$t:'&:before',$v:false,$w:100vw,$h:100%,$m:true){
  #{$t}{
    @include pseudo;
    @if $v {
      @include s(100vw,100vh);
    } @else {
      @include s($w,$h);
    }
    @include ps(r 0 t 0);
    z-index: 1;
    @if $bg {
      background-color: $bg;
    }
    background-image: build-svg($stripes, $c);
    background-repeat: repeat;
    background-size: 15px;

    @if $m {
      @include media("<=#{$switch-to-mobile}"){
        @include wrap-pos(x,$neg:true);
      }
    }
    @content;
  }

  * {
    position: relative;
    z-index: 2;
  }
}

@mixin outstretch-bg($side:null, $color:null, $use:before, $h:100%, $w: 100vw, $t:0) {
  & > * {
    position: relative;
  }
  &:#{$use}{
    @include pseudo;
    @include s($w,$h);
      min-width: $body-min-width;
    position: absolute;
    top: $t;
    bottom:0;
    background-color: $color;
    z-index: 0;

    @if ( contains($side, right) ) {
      left: 0;
    }
    @if ( contains($side, left) ) {
      right: 0;
    }
  }
}

@mixin outstretch-border($side:null, $color:null, $height: 1px, $use:after) {
  & > * {
    position: relative;
  }
  &:#{$use}{
    @include pseudo;
    @include s(100vw,$height);
    min-width: $body-min-width;
    background-color: $color;
    z-index: 0;
    position: absolute;

    @if ( contains($side, top) ) {
      top: 0;
    }
    @if ( contains($side, bottom) ) {
      bottom: 0;
    }
    @if ( contains($side, left) ) {
      right: 0;
    }
    @if ( contains($side, right) ) {
      left: 0;
    }

    @content;
  }
}


@mixin modify-meta-item($h:15, $f:14, $lw:1, $lh:11, $mrg:11) {

  height: $h*1px;
  line-height: $h*1px;
  font-size: rem($f);

  @include not-only{
    @include not-last{
      &:after{
        @include s($lw,$lh);
      }
      @include p(l $mrg);
      @include m(l $mrg);
    }
  }
}


@mixin assign-icons($map: $main-menu-icons-map, $target: '', $sfx: wht, $inject: false, $mod: 1, $class-mod: '', $do-size: true) {
  @each $class, $props in $map {
    .#{$class}#{$class-mod}#{$target} {
      @if $inject {
        @include icon-class(nth($props,1),$sfx);
      } @else {
        @extend %#{nth($props,1)}-#{$sfx};
      }
      @if $do-size {
        background-size: #{ round(nth($props,2)*$mod)} auto;
      }
      @content;
    }
  }
}

@mixin modify-icons-size($map: $main-menu-icons-map, $target: '', $mod: 1) {
  @each $class, $props in $map {
    .#{$class}#{$target} {
      background-size: #{ round(nth($props,2)*$mod)} auto;
      @content;
    }
  }
}

@mixin move-footer-knot($offset:null,$new:null) {

  @if $new {
    &:after {
      left: $new;
    }
    .wrap {
      &:before{
        width: calc(#{$new} - 87px / 2);
        @include mobile{
          width: calc(#{$new} - 78px / 2);
        }
        @include media("<=small-mobile"){
          width: calc(#{$new} - 58px / 2);
        }
      }
      &:after {
        width: calc(#{100% - $new} - 87px / 2);
        @include mobile{
          width: calc(#{100% - $new} - 78px / 2);
        }
        @include media("<=small-mobile"){
          width: calc(#{100% - $new} - 58px / 2);
        }
      }
    }
  }
  @if $offset {
    &:after {
      left: calc(50% #{$offset});
    }
  }
}


// mock colors

$colors-list: $pale-red $orangeish $squash $greeny-blue $cobalt $dark-lavender;

@mixin assign-colors($target: '&', $props: 'background-color', $mod: '&'){
  #{$target} {
    @for $i from 1 through length($colors-list) {
      @include each($i) {
        #{$mod} {
          @for $ii from 1 through length($props) {
            #{ nth($props,$ii) }: nth($colors-list, $i);
          }
        }
      }
    }
  }
}

// mock category badges

$category-image-list: $badge-aid $badge-camping $badge-democracy $badge-identity $badge-nature $badge-oath $badge-orientation $badge-responsibility $badge-skills;

@mixin assign-categories-images($target: '&') {
  #{$target} {
    @for $i from 1 through length($category-image-list) {
      @include each($i) {
        background-image: nth($category-image-list, $i);
      }
    }
  }
}

// mock partner logos

$partner-logos-list: democracy 336 160, yesodot 348 130, shalom-hertman 326 142;
@mixin assign-partner-logos($target:'&', $add: '.logo span'){

  #{$target} {
    @for $i from 1 through length($partner-logos-list) {
      @include each($i) {
        #{$add}{
          @include ratio(nth(nth($partner-logos-list, $i),2),nth(nth($partner-logos-list, $i),3));
          background-image: url('../img/#{nth(nth($partner-logos-list, $i),1)}.png');
        }
      }
    }
  }
}

// shortcut for mobile/desktop view query

@mixin mobile{
  @include media("<=#{$switch-to-mobile}"){
    @content;
  }
}
@mixin desktop{
  @include media(">#{$switch-to-mobile}"){
    @content;
  }
}


// drupal page general

@mixin drupal-page($p: r y){

  // general layout

  #content.column > .section {
    @include wrap-pads($p);

    @content;
  }
}

