.page-notifications { // .content

  // layout

  .view-content ~ .item-list, .element-invisible{
    display: none;
  }

  .content-header {
    font-size: rem(30);
    font-weight: 400;
    border-bottom: 2px solid $light-grey-2;

    @include p(t 27 b 25);

    @include mobile{
      display: none;
    }
  }

  section.notifications{
    @include wrap-pads(y);
  }

  .content-header, section.notifications {
    max-width: 615px;

    @if $content-pages-center-content {
      @include m(x auto);
    }
  }


  // replacing Burger button with Back button (on mobile)

  .main-nav {
    @include mobile{
      // pointer-events: none;
      // opacity: 0;
      display: none;
    }
  }

  button.back {
    @include desktop{
      display: none;
    }

    @include mobile{
      @include close-button-header;
    }
  }



  // days

  .single-day {

    display: block;
    width: 100%;

    @include desktop{
      display: flex;
      @include m(b 20);
    }

    .date-and-time {
      @include m(l 40);
      font-weight: 400;

      @include mobile{
        @include flex-align(y);
      }
    }

    .date {
      font-size: rem(30);
      margin: 0;
    }
    .j-date {
      font-size: rem(16);

      @include mobile{
        @include m(r 15);
        font-size: rem(20);
        color: $brown-grey;
      }
    }
  }


  // notifications

  section.notifications {

    .notifications-list {
      flex-grow: 2;
      @include m(b 20);
    }

    $notification-box-bg: rgb(246,245,243); //rgba($light-grey-2,0.25);

    .single-notification {

      display: block;
      position: relative;
      @include desktop{
        max-width: 475px;
      }
      background-color: $notification-box-bg;
      border-radius: 5px;
      @include p(y 12 x 20);
      @include m(b 20 l auto);

      @include first {
        @include desktop{
          &:after {
            @include triangle(right, $notification-box-bg, 12px, 1.2);
            @include ps(r -14 t 15);
          }
        }
      }

      .time {
        font-weight: 600;
        @include m(b 3);
      }

      p {
        span{
          display: block;
        }
        a {
          @include m(t 3);
        }
      }

    }

  }

}