

.file-upload {

  display: flex;
  flex-wrap: wrap;

  label {
    margin: 0;
    height: $input-height;
    @include flex-align(y);
    font-size: rem(15);
    font-weight: 600;
    line-height: 1.2;
    @include m(l 40);

    .de-em{
      font-size: fsp(15,14);
      color: $brown-grey;
      @include m(r 5);
    }
  }

  .input-wrap {
    display: inline-block;
    width: 100%;
    max-width: 335px;
    padding: 0;
    position: relative;
    overflow: hidden;
    @include flex-align(y);
    cursor: pointer;
    @extend %icn_upload-br-gr;
    background-size: 20px auto;
    background-position: right 17px top 50%;

    input {
      cursor: pointer;
      line-height: 1;
      height: auto;
      border: 0;
      padding: 0;
      @include s;
      @include ps;
      opacity: 0;
    }
  }

  .file {
    margin: 0;
    @include p(x 50);
    line-height: inherit;
    @include s;
    @include ps;
  }

  .file-name, .file-size {
    display: inline-block;
  }

  .remove-file {
    z-index: 10;
    @include mod-close($input-height, 16px);
    @include ps(l 0 y 0);
  }

  .not-valid {
    @include flex-align(y);
    font-size: rem(15);
    @include m(r 40);
  }

}
