//  COLORS

$light-grey-3: rgb(241, 239, 234);  // F1EFEA
$charcoal: rgb(52, 51, 47);         // 34332F
$greeny-blue: rgb(64, 196, 195);    // 40C4C3
$brown-grey: rgb(118, 116, 110);    // 76746E
$nasty-green: rgb(108, 179, 63);    // 6CB33F
$orangeish: rgb(248, 151, 71);      // F89747
$light-grey: rgb(216, 216, 216);    // D8D8D8
$charcoal-grey: rgb(62, 62, 72);    // 3E3E48
$dark-grey: rgb(39, 39, 45);        // 27272D
$light-grey-2: rgb(223, 220, 213);  // DFDCD5
$off-white-2: rgb(251, 250, 248);   // FBFAF8
$squash: rgb(238, 185, 28);         // EEB91C
$dark-lavender: rgb(122, 88, 153);  // 7A5899
$pale-red: rgb(221, 58, 58);        // DD3A3A
$cobalt: rgb(27, 68, 129);          // 1B4481
$light-grey-blue: rgb(151,166,220); // 97A6DC
$greyish: rgb(180, 178, 175);       // B4B2AF
$off-black: rgb(38, 38, 38);        // 262626
$tealish: rgb(43, 195, 158);        // 2BC39E
$pinkish-grey: rgb(199, 193, 173);  // C7C1AD
$brick: rgb(185, 58, 43);           // B93A2B
$off-white: rgb(255, 255, 254);     // FFFFFE
$mud-brown: rgb(84, 50, 18);        // 543212
$golden-yellow: rgb(251, 206, 32);  // FBCE20
$black: rgb(0, 0, 0);
$greyish-brown: rgb(82, 80, 76);    // 52504C
$brownish-grey: rgb(113,111,108);   // 716f6c

$some-gray-1: rgb(151,151,151);     // 979797

$stripe-bg: rgb(239,237,234);

$error-color: rgb(208,2,27); // $brick