// sass-lint:disable-all



// SIZING MIXINS
// by Pavel G
// ----------------------------------------------------------


// helper

@mixin build-size-css($w:null, $h:null, $attr:null, $i: false, $line:false, $unit: $global-unit) {
  @if $i {$i:'!important';} @else {$i:'';}
  $width: null; $height: null;

  @if ( is-list($w) ) {
    $extracted: extractor($w);
    $width: map-get($extracted, width);
    $height: map-get($extracted, height);
  } @else if ($w) {
    $width: check-unit($w,$unit);
    $height: $width;
  }
  @if $h {
    $height: check-unit($h,$unit);
  }

  @if $attr {
    @each $cur-attr in $attr{
      @if $width {
        #{$cur-attr}-width: $width#{$i};
      }
      @if $height {
        #{$cur-attr}-height: $height#{$i};
      }
    }
  } @else {
    @if $width {
      width: $width#{$i};
    }
    @if $height {
      height: $height#{$i};
    }
  }
  @if $line {
    line-height: $height;
  }
}



//  possible syntax:
//  @include size(100%)
//  @include size(100%, 400px)
//  @include size(100% 400)

@mixin size($width: 100%, $height: null, $i:false, $attr:null, $line:false, $unit: $global-unit) {
  @include build-size-css($width,$height,$i:$i,$attr:$attr,$line:$line,$unit: $unit);
}


@mixin max-size($width: 100%, $height: null, $i:false) {
  @include build-size-css($width,$height,max,$i:$i);
}


@mixin min-size($width: 100%, $height: null, $i:false) {
  @include build-size-css($width,$height,min,$i:$i);
}

// shortcuts

@mixin s($width: 100%, $height: null, $i:false, $attr:null, $line:false, $unit: $global-unit) {
  @include build-size-css($width,$height,$i:$i,$attr:$attr,$line:$line);
}
@mixin mas($args...) { @include max-size($args...); }
@mixin mis($args...) { @include min-size($args...); }



// ASPECT RATIO
// ----------------------------------------------------------

//  possible syntax (will make the block always be 16x9 ratio):
//  @include aspect-ratio(16,9);
//  @include aspect-ratio(16/9);
//  @include aspect-ratio(1.77);

@mixin aspect-ratio($ratio-or-width, $height: null) {
  $padding: if($height, percentage($height/$ratio-or-width), percentage(1/$ratio-or-width));
  &:before { content:''; float: left; padding-bottom: $padding;  }
  &:after  { content:''; display: table; clear: both;  }
}

// shortcut
@mixin ratio($args...) {
  @include aspect-ratio($args...);
}


// OTHER RELATED

@mixin coverer {
  @include s;
  @include ps;
}
