// datepicker common styles


.datepicker-modal,
.inline-datepicker {

  header {
    color: $black;
    @include m(b 30);
  }

  h2 {
    font-size: rem(30);
    font-weight: 400;
    color: $black;
    height: 65px;
    @include flex-align;
    color: inherit;
    margin: 0;
  }

}

.datepicker-calendar {

  .pager {
    float: none;
    position: static;
    width: 100%;
    @include pager-compact;
    @include m(b 13);
  }

  .button-wrap {
    padding: 13px 15px;
    text-align: center;

    button {
      width: 100%;
      @extend %color-button;
    }
  }

  .zofim-calendar{
    @include calendar-compactview;
  }

}