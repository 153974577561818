
@mixin prepare-schedule-section{

  & > .wrap {
    // min-height: inherit;
    @include p(t 25 b 35);
    display: flex;
    flex-wrap: wrap;

    @include mobile{
      @include p(r 0 b 50 l 0);
    };

    @include media("height<=#{$short-screen}",">=#{$switch-to-mobile}"){
      @include p(b 25);
    }
  }

  .section-title
  {
    font-size: rem(30);
    font-weight: 400;

    @include media("<=mobile"){
      line-height: 1;
    }
  }

}

section.schedule
{
  // min-height: inherit;

  @include prepare-schedule-section;

  min-height: calc(45vh + 74px + 35px);
  @include mobile{
    min-height: 300px + 35px + 65px;
  }

  // layout

  .section-title, .view-switch + label {
    float: right;
  }

  .section-title {
    max-width: calc(100% - 110px);
    @include m(l 20);

    @include mobile{
      flex-grow: 10;
      @include m(b 0 l auto);
    }
  }

  .pager {

    z-index: 50;
    position: relative;

    @include desktop{
      float: left;
      position: absolute; //relative;
      top: -47px;
      left: 0;
    }
    @include mobile{
      width: 100%;
      float: none;
    }
  }

  .week-view, .month-view {
    position: relative;
    // flex-wrap: wrap;
    width: 100%;
    clear: both;
    @include p(t 25);

    @include mobile{
      @include p(t 15);
    }
  }


  // view switch

  #month-view:not(:checked) {
    & ~ .month-view {
      display: none;
    }
  }

  #week-view:not(:checked){
    & ~ .week-view {
      display: none;
    }
  }


  // view switch toggle

  .view-switch {
    @include visually-hidden;

    & + label {
      @include make-button;
      @include outline-button($charcoal);
      width: 55px;
      @include p(x 8);
      display: block;
      margin: 0;
    }

    &:checked + label {
      background-color: $charcoal;
      color: white;
    }
  }

  // mobile view

  @include mobile{

    .view-switch + label {
      // @include s(40);
      @include s(43);
      @include text-hide;
      background-size: 23px auto;
      background-position: center;
      padding: 0;
    }

    #month-view + label {
      @include icon-class(icn_month_view,chrk);
    }
    #week-view + label {
      @include icon-class(icn_week_view,chrk);
    }
    #month-view:checked + label {
      @include icon-class(icn_month_view,wht);
    }
    #week-view:checked + label {
      @include icon-class(icn_week_view,wht);
    }
  }

}

.schedule {
  &.weekly-modal, .week-view {
    // swiper
    .swiper-container {

      overflow: visible;

      @include desktop{
        @include p(r $day-planner-scroll-offset);
        @include m(r -1*$day-planner-scroll-offset);
      }
    }
  }
}



