// activity info modal
$header-height: 100px;
.calendar-info-modal {

  .close-modal {
    @include close-button-color(wht);
  }

  .modal-header {

    height: $header-height;
    background-color: $greyish-brown;
    @include flex-align(y);
    justify-content: space-between;
    color: white;
    @include m(b 30);

    @include mobile{
      min-height: $header-height;
      height: auto;
      @include p(y 7);
    }

    h2 {
      font-size: rem(22);
      font-weight: 400;
      line-height: 1.1;
      margin: 0;
      line-height: 1.5;

      @include mobile{
        line-height: 1.2;
        height:auto !important;
      }
    }

    .time {
      font-size: rem(18);
      display: inline-block;

      @include mobile{
        font-size: rem(16);
      }
    }

    .j-date {
      font-size: rem(16);

      @include mobile{
        @include m(t 5);
      }
    }

  }

  .actions {
    @include m(l 45);

    button {
      // font-weight: 600;
    }

    @include mobile{
      display: none;
    }
  }


  // layout

  $content-mid-pad: 50px;

  .content {
    display: flex;
    justify-content: space-between;
    padding-bottom: 4px;
  }

  .activity-select {
    flex-grow: 1;
    width:  $activity-box-width + $content-mid-pad;

    @include mobile{
      display: none;
    }
  }

  .activity-info-list {

    @include desktop{
      width: calc(100% - #{$activity-box-width + $content-mid-pad});
    }
    @include mobile{
      width: 100%;
    }

    .single-activity {
      width: 100%;
    }
  }


  // activity select

  .activity-select {

    .single-activity {
      width: $activity-box-width;
      text-decoration: none;

      // &.active
      &.selected-activity
      {
        h4,.length {
          position: relative
        }
        .sawrp {
          position: static;
          &:before {
            @include pseudo;
          }
        }
      }
    }

    .single-activity:first-child .icons-list {
      li {

        @include modify-simtip(
          $position: bottom,
          $distance: 0,
          $color: rgb(50,50,50)
          // ,$align-to-host: right
        );

      }
    }

  }


  // activity info article

  .activity-info-list {

    flex-grow: 10;

    .single-activity {

      @include not-first{
        border-top: 2px solid $light-grey-2;
        @include m(t 25);
        @include p(t 18);
      }
    }

  }

  $desktop-same-as-mobile: true;

  .activity-info {

    display: flex;
    flex-direction: column;

    header {

        @include m(b 20);

    }

    h3 {
      font-size: rem(30);
      font-weight: 400;
      line-height: 1.2;
      @include m(b 0);

      @if not $desktop-same-as-mobile{
        @include desktop {
          font-weight: 600;
          color: inherit !important;
        }
      }

    }

    .activity-type {

      @if not $desktop-same-as-mobile{
        @include desktop {
          @include circle(15px);
          display: inline-block;
          vertical-align: baseline;
          @include m(l 2);
        }
      }

      // default bg color
      background-color: $brown-grey;

      // mock
      @if $do-mock {
        @include assign-colors($target: '&', $props: 'background-color');
      }
    }

    .note {
      @include m(t 5);
    }

  }

  .methods-list{

    & > li {
      @include not-first{
        border-top: 0;
        @include m(t 20);
        @include p(t 0);
      }
    }

  }


  // scroll

  .simplebar-scroll {
    @include desktop{
      max-height: $default-simplebar-scroll-window-height;
      min-height: 415px;

      @if $use-native-scroll {
        @include simplebar-native-scroll('.content', $h:auto, $p:t 100 b 0 r -30, $cp: 15, $sp:-30); // added $cp:15, $sp:-30
      } @else {
        @if $put-simplebar-outside{
          @include simplebar-outside($t:'&'); //(20px);
        }
      }

    }
  }


  // anchors

  .activity-info-list {
    .single-activity {
      position: relative;
    }
    a[name]{
      position: absolute;
      top: -35px;
    }
  }



  //---------------------------------------
  // print
  //---------------------------------------


  @include print{

    // page

    @at-root { html.modal-open {

      & {
        &,&.modal-open.has-scroll {
          max-width: none;
          overflow: auto;
        }
      }

      .page {
        display: none;
      }

      .page-footer {
        display: none;
      }

    } }

    // modal-backdrop

    height: auto;
    max-height: none;
    // overflow: auto;
    overflow: visible;
    padding: 0;
    position: relative;
    z-index: 10000;
    order: 2;
    background: none;
    page-break-before: avoid;
    display: block;


    // modal

    .modal-header {
      @include m(x 0);
      @include p(x 0);
      page-break-before: avoid;
      page-break-after: avoid;
    }

    .modal-box, .simplebar-scroll{
      max-height: none;
      max-width: none;
    }

    .simplebar-scroll {
      @include remove-simplebar;
    }

    .modal-box {
      background: none;

      &:before {
        display:none;
      }

      .wrap {
        @include p(x 0);
      }
    }

    .close-modal,
    .modal-header .actions,
    .activity-select,
    .click-catcher
    {
      display: none;
    }

    .modal-header {
      background: none;
      border-bottom: 3px solid black;
      color: black;

      h2 {
        font-weight: 700;
        font-size: rem(30);
      }
    }

    .activity-info {
      h3 {
        color: black !important;
      }
    }

    .activity-info-list {
      width: auto;

      .single-activity {
        border-color: black !important;
        page-break-inside: avoid;
      }
    }

    .meta {
      &,li {
        @include p(r 0, $i: true);
        background: none;
        font-weight: 400;
      }
      li {
        &:after {
          background: black !important;
        }
      }
    }

  }

}

