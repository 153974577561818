@mixin mag-icons($icn: null, $col: null, $w: null, $h: null) {
    @include flex-align(y);

    &::before {
        @include pseudo();
        @include size(#{$w},#{$h});
        @include m(l 9);
        @extend %icn_#{$icn}-#{$col};
        background-size: contain;
    }
}

@mixin details-limitter($col: rgb(160,160,160), $skip-align:false) {
    @if not $skip-align {
        @include flex-align(y);
    }

    &::after {
        @include pseudo;
        width: 2px;
        height: 13px;
        background-color: $col;
        @include m(x 13 b -1);
    }
}

.magazine {

    &-showcase {
        .section-title {
            font-size: rem(22);

            .button {
               /* display: none; */
            }
        }
    }

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &-item {
        display: block;
        @include m(b 25);

        @include desktop {
            @include m(b 60);
        }

        &:first-of-type {
            @include m(t 30);

            @include desktop {
                @include m(t 60);
            }
        }

        &:last-of-type {
            @include m(b 20);

            @include desktop {
                @include m(b 30);
            }
        }

    }

    .content {
        &-wrapper {
            @include desktop {
                @include p(r 25);
                position: relative;
                display: flex;
                flex-direction: column;
            }
        }
    }

    .item {
        &-wrapper {
            text-decoration: none;
            position: relative;

            @include desktop {
                min-height: 410px;
                display: flex;
                flex-flow: row nowrap;
            }

            .click-catcher {
                @include size;
                @include position;
                z-index: 0;
            }
        }

        &-content-wrapper, &-img {
            position: relative;
            pointer-events: none;
            cursor: pointer;
        }

        &-content-wrapper {
            a {
                pointer-events: all;
                position: relative;
                z-index: 5;
                text-decoration: none;

                &:not(.button) {
                    &:hover, &:focus {
                        text-decoration: underline;
                        color: inherit;
                    }
                }
            }
        }

        &-title {
            line-height: 1.1;
            border: 0;

            @include mobile {
                color: $off-white;
                font-size: rem(30);
                background-color: black(0.3);
                text-shadow: 0 0 6px black(0.3);

                @include size;
                @include position;
                @include flex-align(b);
                @include p(x 12.5 b 9);

                span {
                    display: block;
                    min-height: 2.17em;
                }
            }

            @include desktop {
                color: black;
                font-size: rem(35);
                font-weight: normal;
            }
        }

        &-img {
            background-position: center;
            background-size: cover;
            position: relative;

            @include desktop {
                flex: 0 0 auto;
                width: 295px;
            }
            @include mobile {
                @include ratio(315/245);
                background-position: center top;
            }

            @if $do-mock {
                background-image: url(/sites/all/themes/sky/img/mag-desk-1.jpg);
            }
        }

        &-author {
            font-size: rem(16);
            line-height: 2.19;
            @include m(t 14);
        }

        &-categories {
            @include m(t 25 b 15);

            .img {
                width: 50px;
                height: 50px;
            }

            .category-text {
                color: $charcoal;
                font-size: rem(14);
                line-height: 2.5;
            }
        }

        &-content {
            font-size: rem(16);

            & > p {

                overflow: hidden;

                @include mobile {
                    line-height: 1.35;
                    max-height: 6.6em;
                }
                @include desktop {
                    line-height: 1.56;
                    max-height: 4.5em;
                }
            }
        }

        &-details {

        }

        &-button {
            @include p(t 35);
            @include m(t auto);

            .button, button {
                @include outline-button($charcoal, $bg:transparent);
                min-width: 231px;
                pointer-events: auto;

                &:hover, &:focus {
                    color: inherit;
                }
            }
        }
    }


}

.item-details {
    display: flex;
    flex-flow: row nowrap;
    line-height: 1;
    white-space: nowrap;

    @include mobile {
        margin-bottom: 6px;
    }

    span {
        display: block;
        position: relative;        ;

        font-size: rem(15);

        @include desktop {
            font-size: rem(16);
        }
    }

    .details-date {
        @include mag-icons($icn: calendar, $col: chrk, $w: 14.6, $h: 14.1);

        @include mobile {
            @include details-limitter($skip-align:true);
        }

        @include desktop {
            @include p(l 45);
        }
    }

    .date {
        @include mobile {
            @include p(l 10);
        }

        @include desktop {
            @include details-limitter;
        }
    }

    .j-date {
        //TODO
        @include desktop {
            color: $charcoal;
            float: left;
        }
    }

    .details-comments {
        @include mag-icons($icn: comment, $col: chrk, $w: 14.6, $h: 14);
        @include details-limitter($skip-align:true);
    }

    .details-seen {
        @include mag-icons($icn: eye, $col: chrk, $w: 22.6, $h: 10.4);
    }
    button.favorites{
        margin-right: 10px;
    }
}

.page-search,
.page-user,
.page-magazin,
.page-magazine,
.page-search {

    .section-title {
        margin: 0;

        @include desktop {
            font-size: rem(30);
            font-weight: normal;
        }
    }

    // Pager - magazine

    .view-search ~ .item-list,
    .view-content ~ .item-list {
        display: flex;

        @include desktop {
            @include m(b 80);
        }

        @include mobile {
            @include m(b 50);
        }
    }

    .item-list .pager {
        @include pager-compact;
        /*flex-direction: row-reverse;*/

        @include mobile {
            max-width: 100%;
            display:block;
            margin-left:auto;
            margin-right:auto;
        }

        & > li {
            line-height: normal;
            @include margin(r 5);
            @include margin(l 5);
            @include mobile {
                display:inline-block;
                line-height: normal;
                margin-bottom: 0;
            }
        }

        .pager-last, .pager-first {
            display: none;
        }

        .pager-previous, .pager-next{
            /*transform: scaleX(-1);*/
        }

        .pager-next, .last {
            @include margin(l 0);
        }

        .pager-item {

            @include mobile {
                @include after(5){
                    display: none;
                }
                a{
                    line-height: normal;
                }
            }

        }
    }
}

// SEARCH

.magazine, .node-type-article {

    // removing drupal crap
    .views-exposed-form .views-exposed-widget, .views-exposed-widgets,
    .views-exposed-form .form-item, .views-exposed-form .form-submit,
    .views-exposed-form .views-exposed-widget {
        position: static;
        display: inline;
        float: none;
        padding: 0;
        margin: 0;
    }

    // drupal autocomplete mods
    #edit-submit-search {
        @include make-button;
        @include outline-button($charcoal);
        @include visually-hidden;
    }
    #autocomplete {

        background-color: RGB(240,240,240);

        li, ul {
            display: block;
            width: 100%;
        }
        ul {
            padding: 3px 0;
        }
        li {
            padding: 3px 10px;
            background-color: transparent;
            cursor: pointer;

            &:hover {
                .autocomplete-user-input,
                .autocomplete-suggestion-suffix {
                    text-decoration: underline;
                }
            }
        }
    }
    .search-api-autocomplete-suggestion {
        display: flex;

        .autocomplete-suggestion-results {
            position: static;
            margin-right: auto;
        }
        // .autocomplete-user-input {
        //     margin-left: 5px;
        // }
    }
    
    .magazine-header form {
        @include mobile {
            width: 100%;
        }
    }
    .form-item-search-api-key label{
        display:none;
    }
    iframe{
	    width:100%;
    }
}
.page-magazin .magazine-header,
.node-type-article .magazine-header {
    @media (min-width: 1601px) and (min-width: 769px){
        padding-left: 2.8vw;
    }
}

// search input

html.js input.form-autocomplete.magazine-search {
    outline:none;
    //TODO

    height: 29px;
    font-size: rem(17);
    line-height: 1.41;
    border: none;
    border-bottom: 1px solid $charcoal;
    @extend %icn_search-blk;
    background-size: 18px 18px;
    background-position: left;
    @include p(x 0 b 5);

    @include desktop {
        width: 316px;
    }
    //TODO

}

// article page

.node-type-article {
    .magazine-header + .article-item {
        @include desktop {
            padding-top: 20px;
        }
        .content{
            .field-name-body{
                img{
                    height: auto;
                }
                ul{
                    display: block;
                    list-style-type: disc;
                    margin-block-start: 1em;
                    margin-block-end: 1em;
                    margin-inline-start: 0px;
                    margin-inline-end: 0px;
                    padding-inline-start: 28px;
                    
                    li{
                      display: list-item;
                    }
                }
                ol {
                    display: block;
                    list-style-type: decimal;
                    margin-block-start: 1em;
                    margin-block-end: 1em;
                    margin-inline-start: 0px;
                    margin-inline-end: 0px;
                    padding-inline-start: 25px;
              
                    li{
                      display: list-item;
                    }
                }
                em, .em {
                    font-style: italic;
                    font-weight:normal;
                }
                blockquote {
                    font-style: italic;
                    padding: 2px 20px 2px 8px;
                    border-style: solid;
                    border-color: #ccc;
                    border-width: 0;
                    border-right-width: 5px;
                    margin-right: 13px;
                }
            }
        }
    }
    .magazine-header form {
        @include mobile {
            display: none;
        }
    }
}

// search page, category page

.page-search, .page-taxonomy-term {
    .magazine-header + .section-title {
        margin-top: 20px;
        font-weight: 400;
        font-size: 30px;

        @include mobile {
            font-size: rem(22);
            font-weight: 400;
            margin-bottom: -15px;
        }

        .search-term {
            color: black(0.7);
        }
    }

}

// author page

.page-user {

    .article-author.article-section {
        border-bottom: 1px solid lighten($some-gray-1, 10%);
    }

    .magazine-header + .article-author {
        @include desktop {
            padding-top: 40px;
        }
        @include mobile {
            padding-top: 0;

            .author-title {
                font-weight: 600;
            }
        }
    }

    .article-author + .section-title {
        margin-top: 16px;

        @include mobile {
            font-size: rem(22);
            font-weight: 400;
        }
    }

    .section-title + .magazine-list {
        li:first-child {
            margin-top: 20px;
        }
    }

    .magazine-header form {
        @include mobile {
            display: none;
        }
    }
}

// category page

.page-taxonomy-term {
    .feed-icon {
        display: none;
    }
}

// back link

.magazine-back-link {
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
    @include flex-align(y);

    span {
        display: inline-block;
        vertical-align: middle;
        text-decoration: underline;
    }

    &:before {
        @include pseudo(inline-block);

        @include desktop {
            vertical-align: middle;
            margin-left: 7px;
            @include size(8,12);
            @include icon-class(icn_ar_right,chrk);
            background-size: contain;
        }
        background-position: center;
    }

    // convert to back button on mobile
    @include media("<=mobile"){
        position: fixed;
        right: 12px;
        top: 15px;
        z-index: 100;

	&:active{
		position: fixed;
       	 	right: 12px;
        	top: 15px;
        	z-index: 100;
	}

        span {
            display: none;
        }

        &:after {
	    content:'';
            @include s(30);
            @include icon-class(icn_ar_right,wht);
            background-size: 11px auto;
        }

        @at-root {
            body.page-user, .node-type-article, .page-search, .page-taxonomy-term {
                .main-nav {
                    display: none;
                }
            }
        }
    }
}
.total-art{
	background:#dfdcd5;
	padding: 2px 10px;
}
.active .total-art,
a:hover .total-art,
a:focus .total-art{
	background:#f1efea;
}
.video-filter iframe{
	max-width:100% !important;
}
@media (max-width: 768px){
	.page-magazin .page-header .actions .create#creating-activity-btn,
	.node-type-article .page-header .actions .create#creating-activity-btn,
	.page-user .page-header .actions .create#creating-activity-btn,
	.page-search .page-header .actions .create#creating-activity-btn,
	.page-taxonomy .page-header .actions .create#creating-activity-btn{
		display:none;
	}
}
