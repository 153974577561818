// sass-lint:disable-all


/// svg icon system
// by Pavel G
// ----------------------------------------------------------


// URL ENCODER

// URL Encoder
@function url-encode($string) {
  $map: (
  "%": "%25",
  "<": "%3C",
  ">": "%3E",
  "#": "%23",
  "{":  "%7B",
  "}":  "%7D",
  "&": "%26",

  // replace double quote for single quote, which is allowed in a URL
  // https://codepen.io/tigt/post/optimizing-svgs-in-data-uris
  '"': "'"
   );
  $new: $string;
  @if $icn-do-url-encode  {
    @each $search, $replace in $map {
      $new: str-replace($new, $search, $replace);
    }
  }
  @return $new;
}

// URL-encode long string by breaking into smaller strings
/// @link http://codepen.io/jakob-e/pen/doMoML
@function url-encode-long-string($svg) {

    //  Add missing namespace
    @if not str-index($svg,xmlns) {
      $svg: str-replace($svg, '<svg','<svg xmlns="http://www.w3.org/2000/svg"');
    }

    $encoded: '';
    $slice: 2000;
    $index: 0;
    $loops: ceil(str-length($svg) / $slice);

    @for $i from 1 through $loops {
        $chunk: str-slice($svg, $index, $index + $slice - 1);
        $chunk: url-encode($chunk);
        $encoded: #{$encoded}#{$chunk};
        $index: $index + $slice;
    }

    @return $encoded;
}



// FUNCTIONS FOR SVG ICONS

@function get-icn($key) {
  @if map-has-key($icons-map, $key) {
    @return map-deep-get($icons-map, $key, code);
  }
  @warn "Unknown `#{$key}` in $icons-map";
  @return null;
}

@function get-icn-ratio($key) {
  @if map-has-key($icons-map, $key) {
    @return map-deep-get($icons-map, $key, ratio);
  }
  @return null;
}

// add fill color to SVG
@function fill-svg($string, $color) {
  $search: "fillColor";
  @return str-replace($string, $search, $color);
}

// main SVG build function, returns URL-encoded "url..." string with SVG of given color
@function build-svg-icon($icon,$color) {
    @return unquote(url("data:image/svg+xml,#{url-encode-long-string(fill-svg(get-icn($icon),$color))}"));
}

// same function, to use with any svg code
@function build-svg($svg-code,$color) {
    @return unquote(url("data:image/svg+xml,#{url-encode-long-string(fill-svg($svg-code,$color))}"));
}


// return height of icon for given width
@function get-icn-height($icon,$width) {
    @return ceil($width / get-icn-ratio($icon));
}

// return width of icon for given height
@function get-icn-width($icon,$height) {
    @return ceil($height * get-icn-ratio($icon));
}


// ICON CLASSES GENERATOR MIXINS

// generate single icon class with given color code (color translates according to $colors-map)
@mixin make-icon-class($icn-name,$color-affix,$width:false) {

  $class-name: '.#{$icn-name}-#{$color-affix}';

  @if not $icn-use-real-classes {
    $class-name: '%#{$icn-name}-#{$color-affix}';
  }

  #{$class-name} {
      background-image: build-svg-icon($icn-name, map-get($colors-map, $color-affix));
      background-repeat: no-repeat;

      @if $width {
        background-size: check-unit($width) auto;
      }
  }
}

// to use as include
@mixin icon-class($icn-name,$color-affix,$i:false) {
  @if $i {$i:'!important';} @else {$i:'';}
  background-image: build-svg-icon($icn-name, map-get($colors-map, $color-affix))#{$i};
  background-repeat: no-repeat;
}


// generate all icon classes for all icons, according to settings in $icons-map
@mixin make-icon-classes($map-name,$do-size:false) {
    @each $icn-name, $icn-object in $map-name {
      //@if map-get($icn-object, use) {
      @if map-get($icn-object, colors) {
        @each $color-affix in map-get($icn-object, colors) {
          @if ($do-size) and (map-has-key($icn-object, width)) {
            $do-size: map-get($icn-object, width);
          }
          @include make-icon-class($icn-name,$color-affix,$do-size)
        }
      }
    }
}


// in: svg img code, out: url-encoded url("data..")
@function encoded-svg($svg-code,$color:null) {
  @if $color {
    @return unquote(url("data:image/svg+xml,#{url-encode-long-string(fill-svg($svg-code,$color))}"));
  } @else {
    @return unquote(url("data:image/svg+xml,#{url-encode-long-string($svg-code)}"));
    }
}
